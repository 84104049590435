import { useNavigate } from "react-router-dom";

interface ParentComponentProps {
  to: string;
  label: string | undefined;
  isDisabled: boolean;
  isNotUseTo: boolean;
}
const SecondaryButton: React.FC<ParentComponentProps> = ({
  to,
  label,
  isDisabled,
  isNotUseTo,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (!isNotUseTo) {
      return navigate(to);
    }
  };
  return (
    <div
      className={`secondary-button d-h-32 item-center d-flex font-Quicksand ${
        isDisabled ? "buton-disable" : ""
      } `}
      onClick={onClick}
    >
      <p className="text-xs md:text-sm mb-0">{label}</p>
    </div>
  );
};

export default SecondaryButton;
