import useSWR, { mutate } from "swr";
import { API_NAME } from "../../constant";
import {
  getTwoDBetList,
  postTwoDBet,
  getAgentSetting,
  getTwoDHistoryBetList,
  getTwoDHistoryBetDetail,
  getTwoDResultBetList,
  getTwoDWinnerList,
  getTwoDCollectTotal,
  getTwoDCollectDetail,
  patchCollect,
  patchTermAndCondition,
  getTerms,
  getTwoDRoundResult,
} from "../../twodbetlist";

export const useBetList = (round_id: string) => {
  return useSWR(
    [API_NAME.two_d_bet_list_api, round_id], // Pass an array containing the API endpoint and the id as the key
    () => getTwoDBetList(round_id)
  );
};

export const useAgentSettingList = (round_id: string) => {
  return useSWR(
    [API_NAME.agent_setting_api, round_id], // Pass an array containing the API endpoint and the id as the key
    () => getAgentSetting(round_id)
  );
};

export const postBetNumber = (language?: string) => {
  const postBet = async (betdata: any) => {
    try {
      const responseData = await postTwoDBet(betdata, language);
      mutate(API_NAME.bet_twod_api);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    postBet,
  };
};

export const useHistoryBetList = (fromdate: string, todate: string) => {
  const fetcher = () => getTwoDHistoryBetList(fromdate, todate);
  // Pass an array containing the API endpoint and the id as the key
  const { data, error } = useSWR(
    [API_NAME.two_bet_history_api, fromdate, todate],
    fetcher
  );
  const getHistoryBetList = async (fromdate: string, todate: string) => {
    try {
      const responseData = await getTwoDHistoryBetList(fromdate, todate);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    getHistoryBetList,
  };
};

export const useHistoryBetDetail = (
  id: string,
  pageSize: number,
  pageNumber: number
) => {
  return useSWR(
    [API_NAME.two_bet_history_detail, id, pageSize, pageNumber], // Pass an array containing the API endpoint and the id as the key
    () => getTwoDHistoryBetDetail(id, pageSize, pageNumber)
  );
};

export const useResultBetList = (fromdate: string, todate: string) => {
  const fetcher = () => getTwoDResultBetList(fromdate, todate);
  // Pass an array containing the API endpoint and the id as the key
  const { data, error } = useSWR(["twod-bet-list", fromdate, todate], fetcher);
  const getResultBetList = async (fromdate: string, todate: string) => {
    try {
      const responseData = await getTwoDResultBetList(fromdate, todate);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    getResultBetList,
  };
};

export const useWinnerList = () => {
  return useSWR(
    [API_NAME.two_bet_winner_api], // Pass an array containing the API endpoint and the id as the key
    () => getTwoDWinnerList()
  );
};

export const useCollectAllList = (type: boolean) => {
  let api = "";
  if (type) {
    api = API_NAME.two_bet_colect_all_api;
  } else {
    api = API_NAME.two_bet_colect_history_api;
  }
  return useSWR(
    [api, type ? "D2" : "F2"], // Pass an array containing the API endpoint and the id as the key
    () => getTwoDCollectTotal(type)
  );
};

export const useCollectDetail = (id: string, status: string) => {
  return useSWR(
    [API_NAME.two_bet_history_detail, id, status], // Pass an array containing the API endpoint and the id as the key
    () => getTwoDCollectDetail(id, status)
  );
};

export const patchCollector = () => {
  const patchCollectorFunc = async (id: string) => {
    try {
      const responseData = await patchCollect(id);
      mutate(API_NAME.two_bet_collect_patch_api);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    patchCollectorFunc,
  };
};

export const useTwoDBetRoundList = (date: string) => {
  const fetcher = () => getTwoDRoundResult(date);
  // Pass an array containing the API endpoint and the id as the key
  const { data, error } = useSWR(
    [API_NAME.two_bet_home_page_round, date],
    fetcher
  );
  const getTwoDBetRoundBetList = async (date: string) => {
    try {
      const responseData = await getTwoDRoundResult(date);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    getTwoDBetRoundBetList,
  };
};
export const useTerm = (language: string) => {
  return useSWR(
    [API_NAME.terms_api, language], // Pass an array containing the API endpoint and the id as the key
    () => getTerms(language)
  );
};

export const patchTermAndConditionCollector = () => {
  const patchTermAndConditionFunc = async (isAgreeTerms: boolean) => {
    try {
      const responseData = await patchTermAndCondition(isAgreeTerms);
      mutate(API_NAME.terms_api_patch);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    patchTermAndConditionFunc,
  };
};
