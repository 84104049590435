import React, { useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import FooterButton from "../../layout/mainlayouts/footerButton";
import Modal from "../../components/common/Modal";
import {
  CircleColorIcon,
  HundardPercentIcon,
  NinetyPercentIcon,
  EightyPercentIcon,
  SeventyPercentIcon,
  SixtyPercentIcon,
  FiftyPercentIcon,
  FoutyPercentIcon,
  TwentyPercentIcon,
  ThirtyPercentIcon,
  CloseIcon,
} from "../../components/common/Icon";
import { useWallet, useRoundList } from "../../api/funs/twodhome";
import { useParams, useNavigate } from "react-router-dom";
import { useBetList } from "../../api/funs/threedbetlist";
import { useAgentSettingList } from "../../api/funs/twodbetlist";
import {
  threeDBetListAtom,
  isBetNumberExists,
  findMaxAmountById,
  findMinAmountById,
  removeBetNumberFromArray,
  IsQuickSelectThreeD,
  findStatusById,
  OldThreeDBetListAtom,
  findPercentById,
  NextThreeDBetListAtom,
  roundId,
} from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { translations } from "../../services/localization";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { useWindowSize } from "../../hooks/useWindowSize";

const Betlist: React.FC = () => {
  const { round_id } = useParams<{ round_id: string }>();
  const [choosetype, setChooseType] = useState<string>("000");
  const { data: wallet } = useWallet();
  const { data: agentSetting } = useAgentSettingList(round_id || "");
  const content = translations.betList;
  const commom = translations.commom;
  const [isShow, setIsShow] = useState(false);
  // shared data atom
  const [round, setRoundId] = useAtom(roundId);
  const { data: roundList, isLoading } = useBetList(round_id || "", choosetype);
  // const { data: roundListOne} = useBetList(round_id || "",'100');
  // const { data: roundListTwo} = useBetList(round_id || "",'200');
  // const { data: roundListThree} = useBetList(round_id || "",'300');
  // const { data: roundListFour} = useBetList(round_id || "",'400');
  // const { data: roundListFive} = useBetList(round_id || "",'500');
  // const { data: roundListSix} = useBetList(round_id || "",'600');
  // const { data: roundListSeven} = useBetList(round_id || "",'700');
  // const { data: roundListEight} = useBetList(round_id || "",'800');
  // const { data: roundListNine} = useBetList(round_id || "",'900');
  const { data: roundListTotal } = useBetList(round_id || "", "");

  const [betlist, setBetListData] = useAtom(threeDBetListAtom);
  const [oldBetList, setOldBetList] = useAtom(OldThreeDBetListAtom);
  const [nextBetList, setNextBetList] = useAtom(NextThreeDBetListAtom);
  // end shared data atom

  const [rvalue, setRValue] = useState<string>("");
  const [lastRoom, setLastRoom] = useState<number>(0);
  const [rAmount, setRAmount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRModalOpen, setIsRModalOpen] = useState(false);
  const [isQuickModalOpen, setIsQuickModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [isR, setIsR] = useState(false);
  const [isQuickSelect, setIsQuickSelect] = useAtom(IsQuickSelectThreeD);

  const { data: roudList } = useRoundList("3d");

  const navigate = useNavigate();
  const { windowWidth } = useWindowSize();

  const openModal = () => {
    setIsModalOpen(true);
    setIsShow(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsShow(false);
  };

  const closeQuickModal = () => {
    setIsQuickModalOpen(false);
    setIsShow(false);
  };

  const openQuickModal = () => {
    setIsQuickModalOpen(true);
    setIsShow(true);
  };

  const calculateBorderColor = (percentValue: number) => {
    if (percentValue <= 10) {
      return "btn-number-center-container-ten";
    } else if (percentValue <= 20) {
      return "btn-number-center-container-twenty";
    } else if (percentValue <= 30) {
      return "btn-number-center-container-thirty";
    } else if (percentValue <= 40) {
      return "btn-number-center-container-fouty";
    } else if (percentValue <= 50) {
      return "btn-number-center-container-fifty";
    } else if (percentValue <= 60) {
      return "btn-number-center-container-sixty";
    } else if (percentValue <= 70) {
      return "btn-number-center-container-seventy";
    } else if (percentValue <= 80) {
      return "btn-number-center-container-eighty";
    } else if (percentValue <= 90) {
      return "btn-number-center-container-ninty";
    } else if (percentValue <= 100) {
      return "btn-number-center-container-hundard";
    } else {
      return "btn-number-center-container-ten";
    }
  };

  const calculateDisable = (status: boolean, percentValue: number) => {
    if (!status) {
      return "disable-background";
    }
    if (percentValue >= 100) {
      return "disable-background";
    }
  };

  const changeBetArray = (
    Event: string,
    maxAmount: number,
    minAmount: number,
    status: Boolean,
    percentValue: string
  ) => {
    if (status) {
      if (!isBetNumberExists(Event, betlist)) {
        const newItem = {
          betNumber: Event,
          isSelected: false,
          status: true,
          isR: false,
          isKway: false,
          amount: 0,
          maxAmount: maxAmount,
          minAmount: minAmount,
          percent: percentValue,
        };
        setRValue(Event);
        setLastRoom(betlist.length);
        const updatedBetList = [...betlist, newItem];
        localStorage.setItem("bet", JSON.stringify(updatedBetList));

        setBetListData(updatedBetList);
        if (oldBetList.length > 0) {
          const updatedNextBetList = [...nextBetList, newItem];
          setNextBetList(updatedNextBetList);
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
      } else {
        const removeBetObjList = removeBetNumberFromArray(Event, betlist);
        if (oldBetList.length > 0 && nextBetList.length > 0) {
          if (isBetNumberExists(Event, nextBetList)) {
            const removeNextBetObjList = removeBetNumberFromArray(
              Event,
              nextBetList
            );
            setNextBetList(removeNextBetObjList);
          }
        }
        setRValue("");
        setRAmount(0);
        setLastRoom(0);
        setRValue("");
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
      }
    }
  };

  const isRBetNumber = (number: string) => {
    const bet = betlist.find((bet) => bet.betNumber === number);
    return bet ? bet.isR : false;
  };

  const isSelectedBetNumber = (value: string) => {
    if (!isBetNumberExists(value, betlist)) {
      return "";
    } else {
      return "selected";
    }
  };

  const OnClickBet = () => {
    if (agentSetting?.data?.isSuccess) {
      if (agentSetting?.data?.data?.maxBetCount >= betlist.length) {
        if (betlist.length > 0) {
          setRoundId(round_id || "");
          navigate("/threeD/betchoosethreed/" + round_id);
        } else {
          setMessageModalOpen(true);
          setIsShow(true);
          setMessage(content.pleaseAtLeastOne);
        }
      } else {
        setMessageModalOpen(true);
        setIsShow(true);
        setMessage(
          content.betLimit +
            agentSetting?.data?.data?.maxBetCount +
            content.pleaseReduceBetNumber
        );
      }
    } else if (betlist.length > 1) {
      setRoundId(round_id || "");
      navigate("/threeD/betchoosethreed/" + round_id);
    } else {
      setMessageModalOpen(true);
      setIsShow(true);
      setMessage(content.pleaseAtLeastOne);
    }
  };

  const openRModal = () => {
    if (!(isQuickSelect || betlist.length < 1 || checkNotRNumber())) {
      setIsRModalOpen(true);
      setIsShow(true);
    }
  };

  const clearAll = () => {
    setBetListData([]);
    localStorage.removeItem("bet");
    setBetListData([]);
    setOldBetList([]);
    setNextBetList([]);
    setRValue("");
    setRAmount(0);
    setMessage("");
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
    setIsShow(false);
  };
  const closeRModal = () => {
    setRValue("");
    setRAmount(0);
    setIsRModalOpen(false);
    setIsShow(false);
  };
  const checkNotRNumber = () => {
    if (oldBetList.length > 0) {
      if (nextBetList.length === 1) {
        if (
          nextBetList[0].betNumber === "000" ||
          nextBetList[0].betNumber === "111" ||
          nextBetList[0].betNumber === "222" ||
          nextBetList[0].betNumber === "333" ||
          nextBetList[0].betNumber === "444" ||
          nextBetList[0].betNumber === "555" ||
          nextBetList[0].betNumber === "666" ||
          nextBetList[0].betNumber === "777" ||
          nextBetList[0].betNumber === "888" ||
          nextBetList[0].betNumber === "999"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        const allSameThreeDigit = nextBetList.every((bet) => {
          const num = bet.betNumber;
          if (
            num === "000" ||
            num === "111" ||
            num === "222" ||
            num === "333" ||
            num === "444" ||
            num === "555" ||
            num === "666" ||
            num === "777" ||
            num === "888" ||
            num === "999"
          ) {
            return true;
          } else {
            return false;
          }
        });

        return allSameThreeDigit;
      }
    } else {
      if (betlist.length === 1) {
        if (
          betlist[0].betNumber === "000" ||
          betlist[0].betNumber === "111" ||
          betlist[0].betNumber === "222" ||
          betlist[0].betNumber === "333" ||
          betlist[0].betNumber === "444" ||
          betlist[0].betNumber === "555" ||
          betlist[0].betNumber === "666" ||
          betlist[0].betNumber === "777" ||
          betlist[0].betNumber === "888" ||
          betlist[0].betNumber === "999"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        const allSameThreeDigit = betlist.every((bet) => {
          const num = bet.betNumber;
          if (
            num === "000" ||
            num === "111" ||
            num === "222" ||
            num === "333" ||
            num === "444" ||
            num === "555" ||
            num === "666" ||
            num === "777" ||
            num === "888" ||
            num === "999"
          ) {
            return true;
          } else {
            return false;
          }
        });

        return allSameThreeDigit;
      }
    }
  };

  const onClickRModal = () => {
    const updatedBetList: {
      betNumber: string;
      isSelected: boolean;
      status: boolean;
      isR: boolean;
      isKway: boolean;
      amount: number;
      maxAmount: number;
      minAmount: number;
      percent: string;
    }[] = [];
    let ismessage = false;
    if (oldBetList.length !== 0) {
      for (let j = 0; j < oldBetList.length; j++) {
        //no thing to do
        const newItem = {
          betNumber: oldBetList[j].betNumber,
          isSelected: false,
          status: true,
          isR: Boolean(oldBetList[j].isR),
          isKway: false,
          amount: oldBetList[j].amount,
          maxAmount: oldBetList[j].maxAmount,
          minAmount: oldBetList[j].minAmount,
          percent: oldBetList[j].percent,
        };
        updatedBetList.push(newItem);
      }
      for (let i = 0; i < nextBetList.length; i++) {
        if (
          nextBetList[i].betNumber === "000" ||
          nextBetList[i].betNumber === "111" ||
          nextBetList[i].betNumber === "222" ||
          nextBetList[i].betNumber === "333" ||
          nextBetList[i].betNumber === "444" ||
          nextBetList[i].betNumber === "555" ||
          nextBetList[i].betNumber === "666" ||
          nextBetList[i].betNumber === "777" ||
          nextBetList[i].betNumber === "888" ||
          nextBetList[i].betNumber === "999"
        ) {
          let RemaxAmount = findMaxAmountById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          let RepercentValue = findPercentById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          let ReminAmount = findMinAmountById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          if (RemaxAmount < rAmount) {
            setMessageModalOpen(true);
            setIsShow(true);
            setIsRModalOpen(false);
            setMessage(
              "Your R amount " +
                nextBetList[i].betNumber +
                " is greater than limit amount!"
            );
            ismessage = true;
          }
          if (ReminAmount > rAmount) {
            setMessageModalOpen(true);
            setIsShow(true);
            setIsRModalOpen(false);
            setMessage(
              "Your R amount of " +
                nextBetList[i].betNumber +
                " is less than limit amount!"
            );
            ismessage = true;
          }
          if (parseInt(RepercentValue) >= 100) {
            setMessageModalOpen(true);
            setIsShow(true);
            setIsRModalOpen(false);
            setMessage(nextBetList[i].betNumber + " is limited!");
            ismessage = true;
          }
          if (
            findStatusById(
              nextBetList[i].betNumber,
              roundListTotal?.data?.data
            ) &&
            parseInt(RepercentValue) < 100 &&
            RemaxAmount > rAmount &&
            ReminAmount <= rAmount
          ) {
            //no thing to do
            const newItem = {
              betNumber: nextBetList[i].betNumber,
              isSelected: false,
              status: true,
              isR: false,
              isKway: false,
              amount: 0,
              maxAmount: RemaxAmount,
              minAmount: ReminAmount,
              percent: RepercentValue,
            };
            updatedBetList.push(newItem);
          }
        } else {
          const permute = (str: string, prefix: string = ""): string[] => {
            if (str.length === 0) {
              return [prefix];
            }
            const result: string[] = [];
            for (let i = 0; i < str.length; i++) {
              const char = str[i];
              const rest = str.slice(0, i) + str.slice(i + 1);
              const newPrefix = prefix + char;
              result.push(...permute(rest, newPrefix));
            }
            return result;
          };
          const rarray = permute(nextBetList[i].betNumber);
          // Convert the array to a Set to remove duplicates
          const uniqueSet = new Set(rarray);

          // Convert the Set back to an array
          const perms = Array.from(uniqueSet);
          let RemaxAmount = findMaxAmountById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          let RepercentValue = findPercentById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          let ReminAmount = findMinAmountById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          if (RemaxAmount < rAmount) {
            setMessageModalOpen(true);
            setIsShow(true);
            setIsRModalOpen(false);
            setMessage(
              "Your R amount " +
                nextBetList[i].betNumber +
                " is greater than limit amount!"
            );
            ismessage = true;
          }
          if (ReminAmount > rAmount) {
            setMessageModalOpen(true);
            setIsShow(true);
            setIsRModalOpen(false);
            setMessage(
              "Your R amount of " +
                nextBetList[i].betNumber +
                " is less than limit amount!"
            );
            ismessage = true;
          }
          if (parseInt(RepercentValue) >= 100) {
            setMessageModalOpen(true);
            setIsShow(true);
            setIsRModalOpen(false);
            setMessage(nextBetList[i].betNumber + " is limited!");
            ismessage = true;
          }
          if (
            findStatusById(
              nextBetList[i].betNumber,
              roundListTotal?.data?.data
            ) &&
            parseInt(RepercentValue) < 100 &&
            RemaxAmount > rAmount &&
            ReminAmount <= rAmount
          ) {
            // old data not r value
            //no thing to do
            const newItem = {
              betNumber: nextBetList[i].betNumber,
              isSelected: false,
              status: true,
              isR: false,
              isKway: false,
              amount: 0,
              maxAmount: RemaxAmount,
              minAmount: ReminAmount,
              percent: RepercentValue,
            };
            updatedBetList.push(newItem);
          }
          for (let j = 0; j < perms.length; j++) {
            if (!isBetNumberExists(perms[j], betlist)) {
              let parsedNumber = parseInt(perms[j], 10); // Convert the string to a number
              let maxAmount = 0;
              let minAmount = 0;
              let percentValue = "0";
              maxAmount = findMaxAmountById(
                perms[j],
                roundListTotal?.data?.data
              );
              minAmount = findMinAmountById(
                perms[j],
                roundListTotal?.data?.data
              );
              percentValue = findPercentById(
                perms[j],
                roundListTotal?.data?.data
              );
              if (maxAmount < rAmount) {
                setMessageModalOpen(true);
                setIsShow(true);
                setIsRModalOpen(false);
                setMessage(
                  "Your R amount " + perms[j] + " is greater than limit amount!"
                );
                ismessage = true;
              }
              if (minAmount > rAmount) {
                setMessageModalOpen(true);
                setIsRModalOpen(false);
                setMessage(
                  "Your R amount of " + perms[j] + " is less than limit amount!"
                );
                ismessage = true;
              }
              if (parseInt(percentValue) >= 100) {
                setMessageModalOpen(true);
                setIsShow(true);
                setIsRModalOpen(false);
                setMessage(perms[j] + " is limited!");
                ismessage = true;
              }
              if (nextBetList[i].betNumber === perms[j]) {
                if (
                  findStatusById(
                    nextBetList[i].betNumber,
                    roundListTotal?.data?.data
                  ) &&
                  parseInt(
                    findPercentById(
                      nextBetList[i].betNumber,
                      roundList?.data?.data
                    )
                  ) < 100
                ) {
                  const RnewItem = {
                    betNumber: nextBetList[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: maxAmount,
                    minAmount: minAmount,
                    percent: percentValue,
                  };
                  updatedBetList.push(RnewItem);
                }
              } else {
                if (
                  findStatusById(perms[j], roundListTotal?.data?.data) &&
                  parseInt(
                    findPercentById(
                      nextBetList[i].betNumber,
                      roundList?.data?.data
                    )
                  ) < 100 &&
                  maxAmount > rAmount &&
                  minAmount <= rAmount
                ) {
                  const newItem = {
                    betNumber: perms[j],
                    isSelected: false,
                    status: true,
                    isR: true,
                    isKway: false,
                    amount: rAmount,
                    maxAmount: maxAmount,
                    minAmount: minAmount,
                    percent: percentValue,
                  };
                  updatedBetList.push(newItem);
                }
              }
            }
          }
        }
      }
    } else {
      for (let i = 0; i < betlist.length; i++) {
        if (
          betlist[i].betNumber === "000" ||
          betlist[i].betNumber === "111" ||
          betlist[i].betNumber === "222" ||
          betlist[i].betNumber === "333" ||
          betlist[i].betNumber === "444" ||
          betlist[i].betNumber === "555" ||
          betlist[i].betNumber === "666" ||
          betlist[i].betNumber === "777" ||
          betlist[i].betNumber === "888" ||
          betlist[i].betNumber === "999"
        ) {
          let maxAmount = 0;
          let minAmount = 0;
          let percentValue = "0";
          maxAmount = findMaxAmountById(
            betlist[i].betNumber,
            roundListTotal?.data?.data
          );
          minAmount = findMinAmountById(
            betlist[i].betNumber,
            roundListTotal?.data?.data
          );
          percentValue = findPercentById(
            betlist[i].betNumber,
            roundListTotal?.data?.data
          );
          if (maxAmount < rAmount) {
            // setMessageModalOpen(true);
            setIsRModalOpen(false);
            setIsShow(false);
            setMessage(
              "Your R amount of " +
                betlist[i].betNumber +
                " is greater than limit amount!"
            );
            ismessage = true;
          }
          if (minAmount > rAmount) {
            // setMessageModalOpen(true);
            setIsRModalOpen(false);
            setIsShow(false);
            setMessage(
              "Your R amount of " +
                betlist[i].betNumber +
                " is less than limit amount!"
            );
            ismessage = true;
          }
          if (parseInt(percentValue) >= 100) {
            // setMessageModalOpen(true);
            setIsRModalOpen(false);
            setIsShow(false);
            setMessage(betlist[i].betNumber + " is limited!");
            ismessage = true;
          }
          if (
            findStatusById(betlist[i].betNumber, roundListTotal?.data?.data) &&
            parseInt(
              findPercentById(betlist[i].betNumber, roundList?.data?.data)
            ) < 100 &&
            maxAmount > rAmount &&
            minAmount <= rAmount
          ) {
            //no thing to do
            const newItem = {
              betNumber: betlist[i].betNumber,
              isSelected: false,
              status: true,
              isR: false,
              isKway: false,
              amount: 0,
              maxAmount: maxAmount,
              minAmount: minAmount,
              percent: percentValue,
            };
            updatedBetList.push(newItem);
          }
        } else {
          const permute = (str: string, prefix: string = ""): string[] => {
            if (str.length === 0) {
              return [prefix];
            }
            const result: string[] = [];
            for (let i = 0; i < str.length; i++) {
              const char = str[i];
              const rest = str.slice(0, i) + str.slice(i + 1);
              const newPrefix = prefix + char;
              result.push(...permute(rest, newPrefix));
            }
            return result;
          };
          const perms = permute(betlist[i].betNumber);
          for (let j = 0; j < perms.length; j++) {
            if (!isBetNumberExists(perms[j], updatedBetList)) {
              let parsedNumber = parseInt(perms[j], 10); // Convert the string to a number
              let maxAmount = 0;
              let minAmount = 0;
              let percentValue = "0";
              maxAmount = findMaxAmountById(
                perms[j],
                roundListTotal?.data?.data
              );
              minAmount = findMinAmountById(
                perms[j],
                roundListTotal?.data?.data
              );
              percentValue = findPercentById(
                perms[j],
                roundListTotal?.data?.data
              );
              if (maxAmount < rAmount) {
                // setMessageModalOpen(true);
                setIsRModalOpen(false);
                setIsShow(false);
                setMessage(
                  "Your R amount of " +
                    perms[j] +
                    " is greater than limit amount!"
                );
                ismessage = true;
              }
              if (minAmount > rAmount) {
                // setMessageModalOpen(true);
                setIsRModalOpen(false);
                setIsShow(false);
                setMessage(
                  "Your R amount of " + perms[j] + " is less than limit amount!"
                );
                ismessage = true;
              }
              if (parseInt(percentValue) >= 100) {
                // setMessageModalOpen(true);
                setIsRModalOpen(false);
                setIsShow(false);
                setMessage(perms[j] + " is limited!");
                ismessage = true;
              }
              if (betlist[i].betNumber === perms[j]) {
                if (
                  findStatusById(
                    betlist[i].betNumber,
                    roundListTotal?.data?.data
                  ) &&
                  parseInt(
                    findPercentById(betlist[i].betNumber, roundList?.data?.data)
                  ) < 100 &&
                  maxAmount > rAmount &&
                  minAmount <= rAmount
                ) {
                  const RnewItem = {
                    betNumber: betlist[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: maxAmount,
                    minAmount: minAmount,
                    percent: percentValue,
                  };
                  updatedBetList.push(RnewItem);
                }
              } else {
                if (
                  findStatusById(perms[j], roundListTotal?.data?.data) &&
                  parseInt(findPercentById(perms[j], roundList?.data?.data)) <
                    100 &&
                  maxAmount > rAmount &&
                  minAmount <= rAmount
                ) {
                  const newItem = {
                    betNumber: perms[j],
                    isSelected: false,
                    status: true,
                    isR: true,
                    isKway: false,
                    amount: rAmount,
                    maxAmount: maxAmount,
                    minAmount: minAmount,
                    percent: percentValue,
                  };
                  updatedBetList.push(newItem);
                }
              }
            }
          }
        }
      }
    }

    // if (ismessage) {
    //   //
    // } else {
    setIsR(true);
    setRValue("");
    setRAmount(0);
    setOldBetList(updatedBetList);
    setBetListData(updatedBetList);
    localStorage.setItem("bet", JSON.stringify(updatedBetList));
    setNextBetList([]);
    setIsRModalOpen(false);
    setIsShow(false);
    //}
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setRAmount(value);
  };

  const QuickSelectNumber = (value: string) => {
    if (
      findStatusById(value, roundListTotal?.data?.data) &&
      parseInt(findPercentById(value, roundList?.data?.data)) < 100
    ) {
      if (!isBetNumberExists(value, betlist)) {
        let roomNumber = betlist.length;
        const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
        let maxAmount = 25000;
        let minAmount = 100;
        let percentValue = "0";
        maxAmount = findMaxAmountById(value, roundListTotal?.data?.data);
        minAmount = findMinAmountById(value, roundListTotal?.data?.data);
        percentValue = findPercentById(value, roundListTotal?.data?.data);
        if (parseInt(percentValue) >= 100) {
        } else {
          if (value === "000") {
            setChooseType("000");
          } else if (value === "111") {
            setChooseType("100");
          } else if (value === "222") {
            setChooseType("200");
          } else if (value === "333") {
            setChooseType("300");
          } else if (value === "444") {
            setChooseType("400");
          } else if (value === "555") {
            setChooseType("500");
          } else if (value === "666") {
            setChooseType("600");
          } else if (value === "777") {
            setChooseType("700");
          } else if (value === "888") {
            setChooseType("800");
          } else if (value === "999") {
            setChooseType("900");
          }
          const betItem = {
            betNumber: value,
            isSelected: false,
            status: true,
            isR: false,
            isKway: false,
            amount: 0,
            maxAmount: maxAmount,
            minAmount: minAmount,
            percent: percentValue,
          };
          updatedBetList[roomNumber] = {
            ...betItem,
          };
          roomNumber++;
          setBetListData(updatedBetList);
          localStorage.setItem("bet", JSON.stringify(updatedBetList));

          setRValue("");
          setRAmount(0);
          setLastRoom(0);
          setIsQuickModalOpen(false);
          setIsShow(false);
        }
      }
    }
  };

  return (
    <AppWrapper
      title={content.headerTitleThree}
      isback={true}
      isRefresh={true}
      removeScroll
    >
      <div className="default-height">
        <div className="container p-t-10">
          <div className="header-bet-container row p-t-10 p-l-10 p-r-10 item-center d-flex">
            <div
              className="btn-center-container text-nowrap btn-color-defination "
              onClick={openModal}
            >
              {" "}
              {content.colorDefination}{" "}
            </div>
            <div
              className="btn-center-container text-nowrap btn-clear-all"
              onClick={clearAll}
            >
              {" "}
              {content.clearAll}{" "}
            </div>
          </div>
        </div>
        <br />
        <div className="container bet-choose-type d-h-126">
          <div className="bet-choose-type-row p-l-10 p-r-10 p-t-10 row d-flex item-center">
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "000" ? "selected" : ""
                } `}
                onClick={() => setChooseType("000")}
              >
                000-099
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "100" ? "selected" : ""
                } `}
                onClick={() => setChooseType("100")}
              >
                100-199
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "200" ? "selected" : ""
                } `}
                onClick={() => setChooseType("200")}
              >
                200-299
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "300" ? "selected" : ""
                } `}
                onClick={() => setChooseType("300")}
              >
                300-399
              </span>
            </div>
          </div>
          <div className="bet-choose-type-row p-l-10 p-r-10 p-t-10 row d-flex item-center">
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "400" ? "selected" : ""
                } `}
                onClick={() => setChooseType("400")}
              >
                400-499
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "500" ? "selected" : ""
                } `}
                onClick={() => setChooseType("500")}
              >
                500-599
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "600" ? "selected" : ""
                } `}
                onClick={() => setChooseType("600")}
              >
                600-699
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "700" ? "selected" : ""
                } `}
                onClick={() => setChooseType("700")}
              >
                700-799
              </span>
            </div>
          </div>
          <div className="bet-choose-type-row p-l-10 p-r-10 p-t-10 row d-flex item-center">
            <div className="col-3 fs-12 fw-600 lh-15 d-h-26"></div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "800" ? "selected" : ""
                } `}
                onClick={() => setChooseType("800")}
              >
                800-899
              </span>
            </div>
            <div className={`col-3 px-1`}>
              <span
                className={` btn-center-container fs-12 fw-600 lh-15  d-h-26 ${
                  choosetype === "900" ? "selected" : ""
                } `}
                onClick={() => setChooseType("900")}
              >
                900-999
              </span>
            </div>
            <div className="col-3 fs-12 fw-600 lh-15 d-h-26"></div>
          </div>
        </div>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="container p-b-200">
            <div className="row bet-namber-row d-flex item-center p-t-10">
              {roundList?.data?.data ? (
                roundList?.data?.data?.map((round: any, i: number) => (
                  <div className="col-3 g-2">
                    <div
                      className={` btn-number-center-container 
                      ${calculateBorderColor(parseInt(round.percent))}  
                      ${calculateDisable(round.status, round.percent)}
                      fs-16 fw-500 lh-20 d-h-32 
                      ${isSelectedBetNumber(round.betNumber)}`}
                      onClick={() =>
                        changeBetArray(
                          round.betNumber,
                          round.maxAmount,
                          round.minAmount,
                          parseInt(round.percent) < 100 && round.status,
                          round.percent
                        )
                      }
                    >
                      {round.betNumber}
                      {isRBetNumber(round.betNumber) ? (
                        <div className="text-center circle fs-12 fw-400 d-flex !-top-[5px] !-right-[5px]">
                          R
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        )}
      </div>
      <FooterButton
        isButton={true}
        isQSDisable={false}
        betto=""
        to={"/threeD/kway/" + round_id}
        isQuickSelect={false}
        isKway={false}
        quickto=""
        OnClickQuick={openQuickModal}
        isDisable={false}
        endTime={agentSetting?.data?.data?.roundId?.endTime}
        endDate={roudList?.data?.data[0]?.endDate}
        amount={
          wallet?.data?.data?.userWalletAmount?.amount
            ? wallet?.data?.data?.userWalletAmount?.amount
            : 0
        }
        isRDisable={
          isQuickSelect || betlist.length < 1 || checkNotRNumber()
            ? true
            : false
        }
        OnClickR={openRModal}
        OnClickBet={OnClickBet}
      />
      <Modal
        isShow={isShow}
        isOpen={isModalOpen}
        onClose={closeModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-flex item-center">
            <div className="col-12 color-title color-defination-color">
              {content.colorDefination}
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center">
              <CircleColorIcon className="color-icon"></CircleColorIcon>{" "}
              <span className="p-l-10"> {content.tenPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <TwentyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.twentyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center">
              <ThirtyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.thirtyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <FoutyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.foutyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center">
              <FiftyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.firtyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <SixtyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.sixtyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center item-center">
              <SeventyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.seventyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center item-center">
              <EightyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.eightyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center item-center">
              <NinetyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.nintyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center item-center">
              <HundardPercentIcon className="color-icon" />{" "}
              <span className="p-l-5">{content.hundardPercent}</span>
            </div>
          </div>
          <div className="pt-2 row primary-color noti-label">
            * {content.betLabel}
          </div>
          <div className="row d-flex justify-content-center p-t-10">
            <div
              className="cursor-pointer color-defination-ok"
              onClick={closeModal}
            >
              <span className="font-Quicksand py-2.5 white-color">
                {commom.buttonOk}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isShow={isShow} isOpen={isRModalOpen} onClose={closeRModal}>
        <div className="">
          <div className="text-center row p-b-10 d-flex item-center">
            <div className="px-0 primary-color">{content.rLabel}</div>
          </div>
          <div className="pt-2 row d-h-50">
            <div className="col-12">
              <input
                type="number"
                placeholder={`${agentSetting?.data?.data?.minBetPerUser} ${commom.unit} ${commom.minumun}`}
                onChange={handleChange}
                className="common-text-box d-h-42 w-100 no-arrow"
              />
            </div>
          </div>
          <div className="row d-h-50 d-flex item-center">
            <div className="text-sm uppercase col-6 d-h-42">
              <button className="btn-cancel" onClick={closeRModal}>
                {commom.buttonCancel}
              </button>
            </div>
            <></>

            <div
              className={`uppercase col-6 d-h-42 ${
                rAmount < agentSetting?.data?.data?.minBetPerUser ||
                rAmount > agentSetting?.data?.data?.maxBetAmount
                  ? "opacity-50 pointer-events-none "
                  : ""
              }`}
            >
              <button className="btn-ok" onClick={onClickRModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={messageModalOpen}
        onClose={closeMessageModal}
        popupModalStyle={{
          width: windowWidth < 400 ? windowWidth - 32 : 400,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.alert}</div>
          </div>
          <div className="text-center row d-flex item-center">
            <div className="col primary-color">{message}</div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeMessageModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button className="uppercase btn-ok" onClick={closeMessageModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isQuickModalOpen}
        onClose={closeQuickModal}
      >
        <div className="container">
          <div className="text-center row d-flex item-center">
            <div className="col-10 fs-18 fw-700 color-defination-color">
              {content.sameThree}
            </div>
            <div className="float-right col-2 col-md-1">
              <button className="popup-close-button" onClick={closeQuickModal}>
                <CloseIcon width="10" />
              </button>
            </div>
          </div>

          <div className="row p-t-10 d-flex justify-content-between item-center">
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("000")}
            >
              000
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("111")}
            >
              111
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("222")}
            >
              222
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("333")}
            >
              333
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("444")}
            >
              444
            </div>
          </div>
          <div className="row p-t-10 d-flex justify-content-between item-center">
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("555")}
            >
              555
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("666")}
            >
              666
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("777")}
            >
              777
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("888")}
            >
              888
            </div>
            <div
              className="cursor-pointer col-2 quick-col-three d-flex d-h-32"
              onClick={() => QuickSelectNumber("999")}
            >
              999
            </div>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default Betlist;
