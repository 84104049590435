import Backgroundheader from "../../assets/images/BG2.png";
import Reload from "../../assets/images/refresh.png";
import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BackIcon,
  RefreshIcon,
  CloseIcon,
  EnglishIcon,
  MyanmarIcon,
  ChineseIcon,
  CheckedRadioButtonIcon,
  UnCheckedRadioButtonIcon,
  RefreshRightIcon,
} from "../../components/common/Icon";
import Button from "../../components/common/Button";
import { backProfileUrl } from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { appContext } from "../../contexts/appContext";
import Modal from "../../components/common/Modal";
import { strings, translations } from "../../services/localization";
import { useWallet } from "../../api/funs/twodhome";

type Props = {
  title: string | undefined;
  isback: boolean | false;
  isRefresh: boolean | false;
};

const Header = (props: Props) => {
  const { title, isback, isRefresh } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [isShow, setIsShow] = useState(false);
  const [previousLocation, setPreviousLocation] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  //const [language, setLanguage] = useState(localStorage.getItem('language')?localStorage.getItem('language'):undefined);
  const [token, setToken] = useState(
    localStorage.getItem("access_token")
      ? localStorage.getItem("access_token")
      : undefined
  );
  const [backUrl] = useState(localStorage.getItem("back_url"));
  const [blockType, setBlockType] = useState<number>(0);
  const [rotating, setRotating] = useState<boolean>(false);

  const { data: checkblockdata } = useWallet();
  const { language, changeLanguage } = useContext(appContext)!;
  const content = translations.header;
  const common = translations.commom;

  const [type, setType] = useState(
    localStorage.getItem("language")
      ? localStorage.getItem("language")
      : undefined
  );
  useEffect(() => {
    setPreviousLocation(location.pathname);
  }, [location]);

  useEffect(() => {
    setType(language);
  }, [language]);
  useEffect(() => {
    if (checkblockdata?.data?.data?.isSystemMaintenance) {
      setIsBanModalOpen(true);
      setIsShow(true);
      setBlockType(3);
    } else {
      if (checkblockdata?.data?.data?.userProfile) {
        if (!checkblockdata?.data?.data?.userProfile?.status) {
          setIsBanModalOpen(true);
          setIsShow(true);
          setBlockType(1);
        }
        if (!checkblockdata?.data?.data?.userProfile?.agentId?.status) {
          setIsBanModalOpen(true);
          setIsShow(true);
          setBlockType(2);
        }
      }
    }
  }, [checkblockdata]);
  const goToPreviousRoute = () => {
    const parts = location.pathname.split("/");
    if (parts.length > 3) {
      if (parts[1] === "twoD") {
        if (parts[2] === "quickselect") {
          navigate("/twoD/betlist/" + parts[3]);
        } else if (parts[2] === "betlist") {
          navigate("/twoD/twodhome");
        } else if (parts[2] === "twodhome") {
          navigate("/home/" + language + "/" + token);
        } else if (parts[3] === "bethistorydetail") {
          navigate("/twoD/bethistory");
        } else if (parts[2] === "betchoosethreed") {
          navigate("/twoD/betlist/" + parts[3]);
        } else {
          navigate(-1);
        }
      } else if (parts[1] === "threeD") {
        if (parts[2] === "betlist") {
          navigate("/threeD/threedhome");
        } else {
          navigate(-1);
        }
      }
    } else {
      if (parts[1] === "twoD") {
        if (parts[2] === "twodhome") {
          navigate("/home/" + language + "/" + token);
        } else if (
          parts[2] === "betwinner" ||
          parts[2] === "bethistory" ||
          parts[2] === "collectlist" ||
          parts[2] === "betresultlist" ||
          parts[2] === "betholiday"
        ) {
          navigate("/twoD/twodhome");
        } else if (parts[2] === "betconfirm") {
          navigate(-1);
        }
      } else if (parts[1] === "threeD") {
        if (parts[2] === "threedhome") {
          navigate("/home/" + language + "/" + token);
        } else if (
          parts[2] === "betwinner" ||
          parts[2] === "collectlist" ||
          parts[2] === "betresultlist" ||
          parts[2] === "bethistory"
        ) {
          navigate("/threeD/threedhome");
        }
      } else if (parts[1] === "profile") {
        if (backUrl) {
          navigate(backUrl);
        } else {
          navigate(-1);
        }
      }
    }
    //swindow.history.back(); // Go back using browser history
  };

  const handleReload = () => {
    setRotating(!rotating);
    setTimeout(() => {
      window.location.reload();
    }, 2000); // Adjust the time according to your animation duration
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsShow(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsShow(false);
  };

  const onSelect = () => {
    setIsModalOpen(false);
    setIsShow(false);

    changeLanguage(type || "english");
    setIsModalOpen(false);

    window.location.reload();
  };

  const returnIcon = () => {
    if (language === "english") {
      return <EnglishIcon />;
    } else if (language === "chinese") {
      return <ChineseIcon />;
    } else if (language === "myanmar") {
      return <MyanmarIcon />;
    } else {
      return <EnglishIcon />;
    }
  };

  return (
    <div
      className="header-layout"
      style={{
        backgroundImage: `url(${Backgroundheader})`,
      }}
    >
      <div className="container d-h-72 d-flex justify-content-between align-items-center">
        <div
          className="d-flex header-left d-w-40"
          onClick={() => goToPreviousRoute()}
        >
          {isback ? <BackIcon className="p-0 border-0 btn"></BackIcon> : <></>}
        </div>
        <div className="justify-content-center header-middle font-Quicksand fs-16 fw-600 lh-23">
          {title}
        </div>

        {isRefresh ? (
          <div className="d-flex header-right d-w-80">
            <div className="d-flex justify-content-center align-items-center d-w-40 d-h-40">
              <div
                className="p-0 border-0 reload-icon d-w-25 d-h-25 btn"
                onClick={() => openModal()}
              >
                {returnIcon()}
              </div>
            </div>
            <div className="p-t-8 p-l-10">
              <div className="reload d-flex justify-content-center align-items-center d-w-32 d-h-32">
                <div className="p-b-3 ">
                  <div
                    className="p-0 border-0 btn reload-icon d-w-25 d-h-25"
                    onClick={() => handleReload()}
                  >
                    {/* <RefreshRightIcon className="reload-icon"/> */}
                    <RefreshRightIcon
                      className={` ${
                        rotating ? "rotating-circle" : "reload-icon"
                      }`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex header-right d-w-50">
            <div className="d-flex justify-content-center align-items-center d-w-32 d-h-32">
              <div className="p-b-3 p-r-2">
                <div
                  className="p-0 border-0 reload-icon d-w-25 d-h-25 btn"
                  onClick={() => openModal()}
                >
                  {returnIcon()}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        isShow={isShow}
        isOpen={isModalOpen}
        onClose={closeModal}
        popupModalStyle={{
          width: 300,
        }}
      >
        <div className="container w-100">
          <div className="flex text-center item-center">
            <div className="col-11 p-l-30 fs-16 fw-700 text-nowrap color-defination-color">
              {content.languageSelect}
            </div>
            <button
              className="float-right w-1 h-1 popup-close-button"
              onClick={closeModal}
            >
              <CloseIcon width="10" />
            </button>
          </div>
          <div className="flex pt-2 text-center item-center">
            <div className="col-12 fs-16 lh-20 fw-400 ">
              {content.languageTitle}
            </div>
          </div>
          <div className="row p-t-10 w-[200px]">
            <div className="">
              <div
                className="row p-t-10 d-flex justify-content-between item-center"
                onClick={() => setType("english")}
              >
                <div
                  className={`${
                    (type === "english" || language === "english") &&
                    type !== "myanmar" &&
                    type !== "chinese"
                      ? "language-col-select"
                      : "language-col"
                  } 
                        justify-content-between cursor-pointer fs-16 fw-600 lh-20 d-flex d-h-52`}
                >
                  <div className="row">
                    <div className="col-4 ">
                      <EnglishIcon />
                    </div>
                    <div className="col-8 d-flex item-center p-b-5">
                      <span>English</span>
                    </div>
                  </div>
                  <div>
                    {type == "english" ? (
                      <div className="col-12">
                        <CheckedRadioButtonIcon />
                      </div>
                    ) : (
                      <div className="col-12">
                        <UnCheckedRadioButtonIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer row p-t-10 d-flex justify-content-between item-center"
                onClick={() => setType("chinese")}
              >
                <div
                  className={`${
                    (type === "chinese" || language === "chinese") &&
                    type !== "myanmar" &&
                    type !== "english"
                      ? "language-col-select"
                      : "language-col"
                  } 
                        justify-content-between fs-16 fw-600 lh-20 d-flex d-h-52`}
                >
                  <div className="row">
                    <div className="col-4 ">
                      <ChineseIcon />
                    </div>
                    <div className="col-8 d-flex item-center p-b-5">
                      <span>中国人</span>
                    </div>
                  </div>
                  <div>
                    {type == "chinese" ? (
                      <div className="col-12">
                        <CheckedRadioButtonIcon />
                      </div>
                    ) : (
                      <div className="col-12">
                        <UnCheckedRadioButtonIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="cursor-pointer row p-t-10 d-flex justify-content-between item-center"
                onClick={() => setType("myanmar")}
              >
                <div
                  className={`${
                    (type === "myanmar" || language === "myanmar") &&
                    type !== "english" &&
                    type !== "chinese"
                      ? "language-col-select"
                      : "language-col"
                  } 
                        justify-content-between fs-16 fw-600 lh-20 d-flex d-h-52`}
                >
                  <div className="row">
                    <div className="col-4 ">
                      <MyanmarIcon />
                    </div>
                    <div className="col-8 d-flex item-center p-b-5">
                      <span>မြန်မာ</span>
                    </div>
                  </div>
                  <div>
                    {type == "myanmar" ? (
                      <div className="col-12">
                        <CheckedRadioButtonIcon />
                      </div>
                    ) : (
                      <div className="col-12">
                        <UnCheckedRadioButtonIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row p-t-15">
                <Button
                  buttonOnClick={onSelect}
                  to=""
                  label={common.buttonSelect}
                  buttonClass={`${
                    type === "" ? "ok-button-disable" : "ok-button"
                  } w-100 d-h-44 d-flex justify-content-center item-center `}
                  buttonLabelClass={`${
                    type === "" ? "ok-button-label-disable" : "ok-button-label"
                  } fs-16 fw-600 lh-20 `}
                  isAction={true}
                />
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal isShow={isShow} isOpen={isBanModalOpen} onClose={closeModal}>
        <div className="container d-w-300">
          <div className="text-center row d-flex item-center">
            <div className="col-12 p-b-10 p-l-30 fs-18 fw-700 color-defination-color">
              {blockType === 3 ? content.SystemMaintenance : content.blocktitle}
            </div>
          </div>
          <div className="text-center row d-flex d-h-60 item-center">
            <div className="col-12 p-l-30 fs-16 fw-500 color-defination-color">
              {blockType === 1
                ? content.blockmessage
                : blockType === 2
                ? content.blockagentmessage
                : blockType === 3
                ? content.SystemMaintenanceMessage
                : ""}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Header;
