import { atom } from 'jotai';
// two d object list
type BetObject = {
    betNumber: string;
    isSelected: Boolean;
    status:Boolean;
    isR:Boolean;
    isKway:Boolean;
    amount:number;
    maxAmount:number;
    minAmount:number;
    percent:string;
};
//two d data 
export const betListAtom = atom<BetObject[]>([]);
export const oldBetListAtom = atom<BetObject[]>([]);
export const nextBetListAtom = atom<BetObject[]>([]);
export const twoDKwayBetListAtom = atom<BetObject[]>([]);

export const twoDQuickBetListAtom = atom<BetObject[]>([]);

export const twoDBetAmount = atom<number>(0);
export const backProfileUrl = atom<string>('');
export const isTwoDBetKway = atom<boolean>(false);

export const IsBig = atom<boolean>(false);
export const IsSmall = atom<boolean>(false);
export const IsEven = atom<boolean>(false);
export const IsOdd = atom<boolean>(false);
export const IsSame = atom<boolean>(false);
export const IsEE = atom<boolean>(false);
export const IsEO = atom<boolean>(false);
export const IsOE = atom<boolean>(false);
export const IsOO = atom<boolean>(false);

export const IsZeroInclude = atom<boolean>(false);
export const IsOneInclude = atom<boolean>(false);
export const IsTwoInclude = atom<boolean>(false);
export const IsThreeInclude = atom<boolean>(false);
export const IsFourInclude = atom<boolean>(false);
export const IsFiveInclude = atom<boolean>(false);
export const IsSixInclude = atom<boolean>(false);
export const IsSevenInclude = atom<boolean>(false);
export const IsEightInclude = atom<boolean>(false);
export const IsNineInclude = atom<boolean>(false);

export const IsHeadZeroInclude = atom<boolean>(false);
export const IsHeadOneInclude = atom<boolean>(false);
export const IsHeadTwoInclude = atom<boolean>(false);
export const IsHeadThreeInclude = atom<boolean>(false);
export const IsHeadFourInclude = atom<boolean>(false);
export const IsHeadFiveInclude = atom<boolean>(false);
export const IsHeadSixInclude = atom<boolean>(false);
export const IsHeadSevenInclude = atom<boolean>(false);
export const IsHeadEightInclude = atom<boolean>(false);
export const IsHeadNineInclude = atom<boolean>(false);

export const IsTailZeroInclude = atom<boolean>(false);
export const IsTailOneInclude = atom<boolean>(false);
export const IsTailTwoInclude = atom<boolean>(false);
export const IsTailThreeInclude = atom<boolean>(false);
export const IsTailFourInclude = atom<boolean>(false);
export const IsTailFiveInclude = atom<boolean>(false);
export const IsTailSixInclude = atom<boolean>(false);
export const IsTailSevenInclude = atom<boolean>(false);
export const IsTailEightInclude = atom<boolean>(false);
export const IsTailNineInclude = atom<boolean>(false);

export const IsPowerTypeOne = atom<boolean>(false);
export const IsPowerTypeTwo = atom<boolean>(false);
export const IsPowerTypeThree = atom<boolean>(false);
export const IsPowerTypeFour = atom<boolean>(false);

export const IsBrakeZero = atom<boolean>(false);
export const IsBrakeOne = atom<boolean>(false);
export const IsBrakeTwo = atom<boolean>(false);
export const IsBrakeThree = atom<boolean>(false);
export const IsBrakeFour = atom<boolean>(false);
export const IsBrakeFive = atom<boolean>(false);
export const IsBrakeSix = atom<boolean>(false);
export const IsBrakeSeven = atom<boolean>(false);
export const IsBrakeEight = atom<boolean>(false);
export const IsBrakeNine = atom<boolean>(false);

export const IsLimitOne = atom<boolean>(false);
export const IsLimitTwo = atom<boolean>(false);
export const IsLimitThree = atom<boolean>(false);
export const IsLimitFour = atom<boolean>(false);
export const IsLimitFive = atom<boolean>(false);

export const IsCollect = atom<boolean>(false);

export const IsQuickSelect = atom<boolean>(false);
export const IsQuickSelectThreeD = atom<boolean>(false);
export const IsR = atom<boolean>(false);
export const roundId = atom<string>('');

export const isBetNumberExists = (number: string, betData: BetObject[]): boolean => {
    return betData?.some(bet => bet?.betNumber === number);
};

export const findStatusById = (number: string, betData: BetObject[]) => {
    let status = true;
    for (const bet of betData) {
        if (bet.betNumber === number) {
            return  bet.status;
        }
    }
    return status;
};


export const findPercentById = (number: string, betData: BetObject[]) => {
    let percent = '0';
    for (const bet of betData) {
        if (bet.betNumber === number) {
            return  bet.percent;
        }
    }
    return percent;
};
export const findMaxAmountById = (number: string, betData: BetObject[]) => {
    let maxAmount = 0;
    for (const bet of betData) {
        if (bet.betNumber === number && bet.maxAmount > maxAmount) {
            maxAmount = bet.maxAmount;
        }
    }
    return maxAmount;
};

export const findMinAmountById = (number: string, betData: BetObject[]) => {
    let minAmount = 200;
    for (const bet of betData) {
        if (bet.betNumber === number ) {
            minAmount = bet.minAmount;
        }
    }
    return minAmount;
};

export const removeBetNumberFromArray = (betNumberToRemove: string, arrayOfObjects: BetObject[]) => {
    return arrayOfObjects.filter(obj => obj.betNumber !== betNumberToRemove);
};
//end two d object shared data
export const threeDBetListAtom = atom<BetObject[]>([]);
export const OldThreeDBetListAtom = atom<BetObject[]>([]);
export const NextThreeDBetListAtom = atom<BetObject[]>([]);
export const threeDBetAmount = atom<number>(0);

export const isThreeBetNumberExists = (number: string, betData: BetObject[]): boolean => {
    return betData?.some(bet => bet.betNumber === number);
};
