import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import AppWrapper from "../../layout/AppWrapper";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "../../components/common/Icon";
import Modal from "../../components/common/Modal";
import BetListOneCard from "../../components/common/BetListOneCard";
import BetListTwoCard from "../../components/common/BetListTwoCard";
import { useResultBetList } from "../../api/funs/twodbetlist";
import dateFormat from "dateformat";
import { translations } from "../../services/localization";
import emptyList from "../../assets/images/empty_list_icon.png";
import moment from "moment";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const BetResultList: React.FC = () => {
  const current = new Date();
  const today = new Date(current.getTime());
  today.setDate(today.getDate() - 7);
  useEffect(() => {
    setStartSelectDate(today);
  }, []);
  const [buttonlabel, setButtonLabel] = useState<string>("Month");
  const [startDate, setStartDate] = useState<any | null>(null);
  const [startSelectDate, setStartSelectDate] = useState(new Date());
  const [endDate, setEndDate] = useState<any | null>(null);
  const [endSelectDate, setEndSelectDate] = useState(new Date());
  const { data: datalist,isLoading } = useResultBetList(
    startDate
      ? dateFormat(startDate, "yyyy-mm-dd")
      : moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate
      ? dateFormat(endDate, "yyyy-mm-dd")
      : dateFormat(new Date(), "yyyy-mm-dd")
  );
  const navigate = useNavigate();

  const content = translations.betResult;
  const common = translations.commom;

  const handleFromDateChange = (date: Date) => {
    setStartSelectDate(date);
  };

  const handleToDateChange = (date: Date) => {
    setEndSelectDate(date);
  };

  const handleSearch = async () => {
    try {
      if (endDate || startDate) {
        setStartSelectDate(today);
        setEndSelectDate(new Date());
        setEndDate(null);
        setStartDate(null);
      } else {
        if (endSelectDate && startSelectDate) {
          setEndDate(endSelectDate);
          setStartDate(startSelectDate);
        }
      }
    } catch (error) {
      console.error("Error fetching bet history:", error);
      // Handle error, display error message, etc.
    }
  };

  const onclickFooterButton = (e: any) => {
    e.preventDefault();
    navigate("twoD/betlist");
  };
  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="result-list-container default-height">
        <div className="container p-b-10">
          <div className="flex items-center justify-center py-3 space-x-2">
            <div className="search-col">
              <div className="flex items-center justify-center text-center custom-date-picker">
                <DatePicker
                  selected={startSelectDate}
                  onChange={handleFromDateChange}
                  dateFormat="MM/dd/yy"
                  className="mt-0 text-sm custom-date-input"
                  popperPlacement="top-start"
                  disabledKeyboardNavigation
                  onFocus={(e) => e.target.blur()}
                  disabled={startDate ? true : false}
                />
                <CalendarIcon className="calendar-icon"></CalendarIcon>
              </div>
            </div>
            <div className="search-col">
              <div className="flex items-center justify-center text-center custom-date-picker">
                <DatePicker
                  selected={endSelectDate}
                  onChange={handleToDateChange}
                  dateFormat="MM/dd/yy"
                  className="mt-0 text-sm custom-date-input"
                  disabledKeyboardNavigation
                  onFocus={(e) => e.target.blur()}
                  disabled={endDate ? true : false}
                />
                <CalendarIcon className="calendar-icon"></CalendarIcon>
              </div>
            </div>
            <div
              className="flex search-button-col item-center"
              onClick={handleSearch}
            >
              <button className="btn-serch  flex justify-center item-center w-full p-[10px] text-sm">
                {endDate || startDate ? "Reset" : common.buttonSearch}
              </button>
            </div>
          </div>

          {
            isLoading ? (
              <LoadingSpinner />
            ) :  <div className="py-2 list-card">
            {datalist?.data?.data?.length !== 0 ? (
              datalist?.data?.data?.map((betresult: any, i: number) => (
                <BetListTwoCard
                  key={i}
                  title={dateFormat(betresult?.date, "mmmm d, yyyy ( dddd )")}
                  resultDate={betresult?.date}
                  firstAnnounceTime={betresult?.rounds?.[0]?.announceTime}
                  firstset={betresult?.rounds?.[0]?.set}
                  firstvalue={betresult?.rounds?.[0]?.value}
                  //title="August 5, 2022 (Monday)"
                  firstdate={betresult?.rounds?.[0]?.round?.roundName}
                  firstnumber={betresult?.rounds?.[0]?.winningNumber}
                  secondset={betresult?.rounds?.[1]?.set}
                  secondvalue={betresult?.rounds?.[1]?.value}
                  seconddate={betresult?.rounds?.[1]?.round?.roundName}
                  secondAnnounceTime={
                    betresult?.rounds?.[1]?.round?.announceTime
                  }
                  thirdAnnounceTime={
                    betresult?.rounds?.[2]?.round?.announceTime
                  }
                  fourAnnounceTime={betresult?.rounds?.[3]?.round?.announceTime}
                  secondnumber={betresult?.rounds?.[1]?.winningNumber}
                  thirddate={betresult?.rounds?.[2]?.round?.roundName}
                  thirdnumber={betresult?.rounds?.[2]?.winningNumber}
                  thirdset={betresult?.rounds?.[2]?.set}
                  thirdvalue={betresult?.rounds?.[2]?.value}
                  fourdate={betresult?.rounds?.[3]?.round?.roundName}
                  fournumber={betresult?.rounds?.[3]?.winningNumber}
                  fourset={betresult?.rounds?.[3]?.set}
                  fourvalue={betresult?.rounds?.[3]?.value}
                />
              ))
            ) : (
              <div className="container">
                <div className="d-flex justify-content-center p-t-120">
                  <img className=" w-14" src={emptyList} alt="empty-list" />
                </div>
                <div className="d-flex row p-t-5">
                  <div className="col-12 nodata-title d-flex justify-content-center">
                    {common.emptyTitle}
                  </div>
                </div>
                <div className="d-flex row p-t-10 p-b-10">
                  <div className="col-12 nodata-list d-flex justify-content-center">
                    {common.emptyMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
          }
        
          {/* <div className="container list-card">
            {betresultList ? (
              betresultList.map((betresult: any, i: number) => (
                <BetListTwoCard
                  key={i}
                  title={dateFormat(betresult?.date, "mmmm d, yyyy ( dddd )")}
                  //title="August 5, 2022 (Monday)"
                  firstdate={
                    betresult?.rounds?.[0]
                      ? betresult?.rounds?.[0].round?.roundName
                      : ""
                  }
                  firstnumber={
                    betresult?.rounds?.[0]
                      ? betresult?.rounds?.[0].winningNumber
                      : ""
                  }
                  seconddate={
                    betresult?.rounds?.[1]
                      ? betresult?.rounds?.[1].round?.roundName
                      : ""
                  }
                  secondnumber={
                    betresult?.rounds?.[1]
                      ? betresult?.rounds?.[1].winningNumber
                      : ""
                  }
                  thirddate={
                    betresult?.rounds?.[2]
                      ? betresult?.rounds?.[2].round?.roundName
                      : ""
                  }
                  thirdnumber={
                    betresult?.rounds?.[2]
                      ? betresult?.rounds?.[2].winningNumber
                      : ""
                  }
                  fourdate={
                    betresult?.rounds?.[3]
                      ? betresult?.rounds?.[3].round?.roundName
                      : ""
                  }
                  fournumber={
                    betresult?.rounds?.[3]
                      ? betresult?.rounds?.[3].winningNumber
                      : ""
                  }
                />
              ))
            ) : (
              <></>
            )}
          </div> */}
        </div>
      </div>
    </AppWrapper>
  );
};

export default BetResultList;
