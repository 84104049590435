export default {
  home: {
    headerTitle: "ကြိုဆိုပါတယ်",
    headerThreeTitle: "ကြိုဆိုပါတယ်",
    betClose: "ထီပိတ်ချိန်",
    betOpen: "ထီဖွင့်ပါတော့မည်",
    notiParagraph: "2D ကို အဆ 80 ",
    notiParagraphThree: "3D ကို အဆ 720 နဲ့",
    notisecondParagraph: "နဲ့ တစ်နေ့ ၄ ကြိမ် ထိုးပြီး ကံစမ်း လိုက်စို့နော်",
    notisecondParagraphThree:
      "နဲ့ တစ်နေ့ ၄ ကြိမ် ထိုးပြီး ကံစမ်း လိုက်စို့နော်",
    button2D: "2D ထိုးမည်",
    button3D: "3D ထိုးမည်",
    roundSelect: "ပွဲစဥ်ရွေးပါ",
    roundSelectTitle: "ကျေးဇူးပြု၍ ပွဲစဥ်ရွေးချယ်ပါ",
    termAndCondition: "စည်းမျဉ်းများနဲ့အခြေအနေများ",
  },
  profile: {
    headerTitle: "ကျွန်ုပ်အကောင့်",
    personalTitle: "အသုံးပြုသူ အချက်အလက်များ",
    name: "အသုံးပြုသူ နာမည်",
    phoneno: "ဖုန်းနံပါတ်",
  },
  winner: {
    headerTitle: "ထိပ်ဆုံးအနိုင်ရသူများ",
    headerTitleThree: "ထိပ်ဆုံးအနိုင်ရသူများ",
    tootWinnerTitle: "တွပ်ပတ်လည် Winner",
    dade: "ဒဲ့",
    resultDate: "ထီဖွင့်ရက်",
    first: "ပထမ",
    second: "ဒုတိယ",
    third: "တတိယ",
    bet: "ထိုးမည်",
    win: "နိုင်သည်",
  },
  betHistory: {
    headerTitle: "စာရင်း",
  },
  betHistoryDetail: {
    headerTitle: "အသေးစိတ်ကြည့်ရန်",
    betDate: "ထိုးသည့်ရက်စွဲ",
  },
  betHoliday: {
    headerTitle: "ထီပိတ်ရက်များ",
    title: "လာမည့် ထီပိတ်ရက်များ",
  },
  betResult: {
    headerTitle: "ထွက်ဂဏန်းများ",
    popTitle: "လရွေးချယ်ပါ",
    January: "ဇန်နဝါရီ",
    February: "ဖေဖော်ဝါရီ",
    March: "မတ်",
    April: "ဧပြီ",
    May: "မေ",
    June: "ဇွန်",
    July: "ဇူလိုင်",
    August: "သြဂုတ်",
    September: "စက်တင်ဘာ",
    October: "အောက်တိုဘာ",
    November: "နိုဝင်ဘာ",
    December: "ဒီဇင်ဘာ",
  },
  collectList: {
    headerTitle: "ငွေယူမည်",
    toCollect: "ငွေရယူရန်",
    collected: "ငွေရယူပြီး",
    emptyTitle: "စာရင်းတွင် ဘာမှမရှိပါ",
    noCollectMessage: "ငွေရယူရန်အတွက် နိုင်ထားသည့်ငွေမရှိပါ",
    noCollectedMessage: "အနိုင်ရငွေစုဆောင်းမှု မရှိသေးပါ",
  },
  collect: {
    winNumber: "ထွက်ဂဏန်း",
    firstNoti: "ထိုးကြေးပမာဏ ",
    secondNoti: " ဖြစ်ပြီး အနိုင်ရငွေပမာဏ ",
    secondNotiTwo: "ဖြစ်သည်။",
    thirdNoti: "",
    thirdNotiTwo: " အတွင်း အနိုင်ရငွေရယူနိုင်ပါသည်။",
    fouthNoti: "အနိုင်ရငွေကို",
    firthNoti: ", you can collect ",
    seeMore: "နောက်ထပ်",
    withdrawMessage: "ငွေရယူခြင်းပြီးပါပြီ",
  },
  collectDetail: {
    headerTitle: "ငွေယူစာရင်းအသေးစိတ်",
    firstNoti: "",
    secondNoti: " ကျပ် အနိုင်ရရှိခဲ့ပြီး",
    collect: "ငွေယူမည်",
    unit: "ကျပ် ရယူနိုင်ပါသည်။",
    noRecord: "မှတ်တမ်းမရှိပါ...",
  },
  betList: {
    headerTitle: "ကြိုဆိုပါတယ်",
    headerTitleThree: "ကြိုဆိုပါတယ်",
    colorDefination: "အရောင်များ အဓိပ္ပါယ်",
    clearAll: "ရှင်းမည်",
    r: "R",
    k: "ခွေ",
    tenPercent: "10%",
    twentyPercent: "20%",
    thirtyPercent: "30%",
    foutyPercent: "40%",
    firtyPercent: "50%",
    sixtyPercent: "60%",
    seventyPercent: "70%",
    eightyPercent: "80%",
    nintyPercent: "90%",
    hundardPercent: "100%",
    betLabel: "Lottery Numbers will be closed when bet trending is 100 %",
    rLabel: "R သည့်ဂဏန်းအတွက် ပမာဏထည့်ပါ",
    betWillClose: "ပွဲစဉ်ပိတ်ချိန်",
    rAmountIsGreater: "R ပမာဏသည် ကန့်သတ်ပမာဏထက် ပိုများနေပါသည်",
    AmountIsGreater: "ပမာဏသည် ကန့်သတ်ပမာဏထက် ပိုများနေပါသည်",
    rAmountIsLess: "R ပမာဏသည် ကန့်သတ်ပမာဏထက် ပိုနည်းနေပါသည်",
    pleaseAtLeastTwo: "အနည်းဆုံးဂဏန်းနှစ်လုံးရွေးပေးပါ",
    pleaseAtLeastOne: "အနည်းဆုံးဂဏန်းတစ်လုံးရွေးပေးပါ",
    betLimit: "ထိုးဂဏန်းအရေအတွက် ကန့်သတ်ချက်မှာ ",
    pleaseReduceBetNumber: " ဖြစ်ပါသည်။ ကျေးဇူးပြု၍ ထိုးဂဏန်းကို လျှော့ပါ။",
    alert: "အသိပေးချက်",
    sameThree: "သုံးလုံးတူ",
  },
  quickSelect: {
    headerTitle: "အမြန်ထိုးမည်",
    singleDouble: "အမြန်ထိုးဂဏန်းရွေးရန်",
    big: "ကြီး",
    smmall: "သေး",
    odd: "မ",
    even: "စုံ",
    same: "အပူး",
    ee: "စုံစုံ",
    eo: "စုံမ",
    oe: "မစုံ",
    oo: "မမ",
    head: "ထိပ်",
    tail: "နောက်",
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    title: "ပါဝါ၊ နက္ခတ်၊ ညီကို၊ ဘရိတ်",
    power: "ပါဝါ",
    netkat: "နက္ခတ်",
    nyiko: "ညီကို",
    brake: "ဘရိတ်",
    selectionNumber: "ရွေးထားသောဂဏန်း",
    selectionOne: "00-19",
    selectionTwo: "20-39",
    selectionThree: "40-59",
    selectionFour: "60-79",
    selectionFive: "80-99",
    message: "သေး သို့ ကြီး တစ်ခုကိုသာရွေးပါ",
  },
  kway: {
    headerTitle: "ခွေ",
    title: "ခွေထိုးရန် အနည်းဆုံးဂဏန်းသုံးလုံးထည့်ပါ",
    warningMessage: "တူညီသောဂဏန်းနှစ်လုံးထည့်၍မရပါ",
    warningMessageTwo: "အနည်းဆုံး ဂဏန်း 3 လုံးနှင့် အများဆုံး ဂဏန်း 10 လုံး",
  },
  betChoose: {
    headerTitle: "ထိုးကြေးရွေးပါ",
    messagePartOne: " အတွက်အများဆုံးထိုးကြေးပမာဏသည် ",
    messagePartTwo: " ကျေးဇူးပြု၍ ထိုးကြေးပမာဏကို လျှော့ပါ",
    messagePartThree: "လက်ကျန်ငွေ ",
    messagePartFour: "စုစုပေါင်းထိုးကြေး ",
  },
  betConfirm: {
    headerTitle: "အတည်ပြုမည်",
    editTitle: "ပြင်ဆင်မည်",
    betMaxMessage: "အများဆုံးထိုးကြေး",
    betMinMessage: "အနည်းဆုံးထိုးကြေး",
    deleteMessage: "သင်၏နံပါတ်ကိုဖျက်မှာ သေချာပါသလား?",
    confirmTitle: "အတည်ပြုသည်",
    successTitle: "အောင်မြင်သည်",
    goodLuck: "ကံကောင်းပါစေ",
  },
  twoDLayout: {
    winner: "အနိုင်ရသူများ",
    betHistory: "စာရင်း",
    collect: "ငွေယူမည်",
    result: "ထွက်ဂဏန်း",
    holiday: "ထီနားရက်",
  },
  header: {
    languageSelect: "ဘာသာစကားရွေးချယ်မည်",
    languageTitle: "ကျေးဇူးပြု၍ ဘာသာစကားရွေးချယ်ပါ",
    SystemMaintenance: "ပြုပြင်မှုလုပ်ဆောင်နေပါသည်",
    SystemMaintenanceMessage:
      "အဆင်မပြေမှုအတွက် တောင်းပန်ပါသည်။ လောလောဆယ်တွင် ပြုပြင်ထိန်းသိမ်းမှုအချို့ လုပ်ဆောင်နေပါသည်။",
    blockmessage: "အကောင့်ကိုပိတ်ထားပါသည်",
    blockagentmessage: "Agent ကိုပိတ်ထားပါသည်။ Agent ကိုဆက်သွယ်ပါ။",
    blocktitle: "အကောင့်ပိတ်ထားပါသည်",
  },
  roundCard: {
    value: "Value",
    set: "Set",
  },
  commom: {
    wallet: "ပိုက်ဆံအိတ်",
    commingSoon: "မကြာမီ လာမည်",
    comming: "မကြာမီ",
    soon: "လာမည်",
    upDateTime: "ထီထွက်ချိန်",
    buttonSelect: "ရွေးပါ",
    viewProfile: "အကောင့်ကြည့်မည်",
    winnerNoDataLabel: "အနိုင်ရသူမရှိ",
    winnerNoDataNextLabel: "အနိုင်ရသူစာရင်းတွင်ပါရန် နောက်ပွဲစဥ်ကိုထိုးပါ",
    buttonSearch: "ရှာမည်",
    unit: "ကျပ် ",
    buttonOk: "အိုခေ",
    buttonAgree: "သဘောတူပါသည်",
    success: "အောင်မြင်ပါသည်",
    successfully: "အောင်မြင်ပါသည်!",
    buttonCancel: "ပယ်ဖျက်မည်",
    buttonQuickSelect: "အမြန်ရွေး",
    buttonBet: "ထိုးမည်",
    message: "သတိပေးချက်",
    buttonToot: "တွပ်ပတ်လည်",
    buttonDade: "ဒဲ့",
    buttonAccept: "လက်ခံသည်",
    agree: "သဘောတူသည်",
    buttonConfirm: "အတည်ပြုမည်",
    winners: "ထီပေါက်သူ",
    noWinners: "အနိုင်ရသူမရှိပါ",
    collect: "ရွေးချယ်",
    result: "ရလဒ်",
    betHistory: "ထိုးမှတ်တမ်း",
    emptyTitle: "စာရင်းတွင် ဘာမှမရှိပါ",
    emptyMessage: "မှတ်တမ်းမရှိပါ။ ကံကောင်းပါစေ။",
    minumun: "အနည်းဆုံး",
    processing: "လုပ်ဆောင်နေသည်",
    notice: "သတိပေးချက်",
  },
  tableHeader: {
    no: "စဉ်",
    name: "ထိုးသူအမည်",
    bet: "ထိုးကြေး(ကျပ်)",
    winAmount: "နိုင်သည်(ကျပ်)",
    betNumber: "ဂဏန်း",
    profit: "အဆ",
    amount: "ပမာဏ",
    won: "အနိုင်ရခဲ့သည်",
    edit: "ပြင်ဆင်မည်",
    delete: "ဖျက်မည်",
    editAndDelete: "ပြင်ဆင်မည် /ဖျက်မည်",
    totalBetAmount: "စုစုပေါင်းထိုးကြေး",
  },
};
