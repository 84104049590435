import axios from "axios";
import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getTwoDBetList = async (round_id: string) => {
  return axiosInstance.get(
    `${URL}${API_NAME.two_d_bet_list_api}` + "?roundId=" + round_id
  );
};

export const getAgentSetting = async (round_id: string) => {
  return axiosInstance.get(
    `${URL}${API_NAME.agent_setting_api}` + "/" + round_id
  );
};

export const postTwoDBet = async (betdata: any, language?: string) => {
  try {
    const response = await axiosInstance.post(
      `${URL}${API_NAME.bet_twod_api}?language=${language}`,
      betdata
    );
    return response.data; // Assuming you want to return the response data
  } catch (error) {
    console.error("Error occurred while posting the bet:", error);
    throw error; // Propagate the error up to the caller
  }
};

export const getTwoDHistoryBetList = async (
  fromdate: string,
  todate: string
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.two_bet_history_api}` +
      "?from=" +
      fromdate +
      "&to=" +
      todate
  );
};

export const getTwoDHistoryBetDetail = async (
  id: string,
  pageSize: number,
  pageNumber: number
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.two_bet_history_detail}` +
      id +
      "/details" +
      "?pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
};

export const getTwoDResultBetList = async (
  fromdate: string,
  todate: string
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.two_bet_result_api}` +
      "?from=" +
      fromdate +
      "&to=" +
      todate
  );
};

export const getTwoDWinnerList = async () => {
  return axiosInstance.get(`${URL}${API_NAME.two_bet_winner_api}`);
};

export const getTwoDCollectDetail = async (id: string, status: string) => {
  return axiosInstance.get(
    `${URL}${API_NAME.two_bet_collect_detail_api}` +
      "" +
      id +
      "?status=" +
      status
  );
};

export const getTwoDCollectTotal = async (type: boolean) => {
  if (type) {
    return axiosInstance.get(`${URL}${API_NAME.two_bet_colect_all_api}`);
  } else {
    return axiosInstance.get(`${URL}${API_NAME.two_bet_colect_history_api}`);
  }
};

export const patchCollect = async (id: any) => {
  try {
    const response = await axiosInstance.patch(
      `${URL}${API_NAME.two_bet_collect_patch_api}` + id
    );
    return response.data; // Assuming you want to return the response data
  } catch (error) {
    console.error("Error occurred while posting the bet:", error);
    throw error; // Propagate the error up to the caller
  }
};

export const getTwoDRoundResult = async (date: string) => {
  return axiosInstance.get(
    `${URL}${API_NAME.two_bet_home_page_round}` + "?date=" + date
  );
};

export const getTerms = async (langauge: string) => {
  let lang = "en";
  if (langauge === "english") {
    lang = "en";
  } else if (langauge === "myanmar") {
    lang = "mm";
  } else if (langauge === "chinese") {
    lang = "zh";
  }
  // return axiosInstance.get(`https://dev.allin1.click/api/terms/en`);
  return axiosInstance.get(`${URL}${API_NAME.terms_api}` + "/" + lang);
};

export const patchTermAndCondition = async (isAgreeTerms: boolean) => {
  try {
    const patchData = {
      isAgreeTerms: true,
    };

    const response = await axiosInstance.patch(
      `${URL}${API_NAME.terms_api_patch}`,
      patchData
    );
    return response.data; // Assuming you want to return the response data
  } catch (error) {
    console.error("Error occurred while posting the bet:", error);
    throw error; // Propagate the error up to the caller
  }
};
