
export function getAccessToken() {
    return localStorage.getItem('access_token')?localStorage.getItem('access_token'):undefined;
}


export async function setAccessToken(value:any) {
    await localStorage.setItem("access_token", value);
}


export function getLanguage() {
    return localStorage.getItem('language')?localStorage.getItem('language'):undefined;
}


export async function setLanguage(value:any) {
    await localStorage.setItem("language", value);
}

export function getLanguageFromStorage() {
    return localStorage.getItem('language');
}

export function setLanguageToStorage(language: string) {
    return localStorage.setItem('language', language);
}


export function getTerm() {
    return localStorage.getItem('term')?localStorage.getItem('term'):undefined;
}


export async function setTerm(value:any) {
    await localStorage.setItem("term", value);
}
