import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./contexts/authContext";
import { AppProvider } from "./contexts/appContext";
import { Provider as JotaiProvider, createStore } from "jotai";
//import { DevTools as JotailDevtool } from "jotai-devtools";
//main app.scss
import "./style/App.scss";
import "./index.css";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const customStore = createStore();

root.render(
  <React.StrictMode>
    <JotaiProvider store={customStore}>
      <AuthContextProvider>
        <App />
      </AuthContextProvider>
    </JotaiProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
