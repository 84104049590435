import React, { useState, ReactNode, useCallback, useEffect } from "react";
import "../../style/modal.scss"; // You can style the modal in a separate CSS file
import { useWindowSize } from "../../hooks/useWindowSize";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  isShow?: boolean | undefined;
  popupModalStyle?: any;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  isShow,
  popupModalStyle,
}) => {
  const { windowWidth } = useWindowSize();

  // console.log(popupModalStyle);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  if (isShow) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "auto";
  }

  const styles = {
    ...popupModalStyle,
    minWidth: windowWidth < 400 ? windowWidth - 32 : 400,
  };

  return (
    <>
      {isOpen && (
        <div className="popup-modal-overlay">
          <div className="popup-modal !m-0" style={styles}>
            <div className="py-2">{children}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
