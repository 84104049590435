import React, { useState, useEffect, useContext } from "react";
import AppWrapper from "../../layout/AppWrapper";
import Footer from "../../layout/mainlayouts/footer";
import MainWallet from "../../components/common/MainWallet";
import Collect from "../../components/common/Collect";
import TwoDIconLayout from "../../components/common/TwoDIconLayout";
import Advertisement from "../../components/common/Advertisement";
import RoundCard from "../../components/common/RoundCard";
import { useNavigate, useSearchParams } from "react-router-dom";
import Modal from "../../components/common/Modal";
import ModalTerm from "../../components/common/ModalTerm";
import { CloseIcon } from "../../components/common/Icon";
import Button from "../../components/common/Button";
import { useWallet, useRoundList } from "../../api/funs/twodhome";
import { useHoliday, useBanner } from "../../api/funs/home";
import {
  useCollectAllList,
  useTwoDBetRoundList,
  useTerm,
  patchTermAndConditionCollector,
} from "../../api/funs/twodbetlist";
import { convertTimeToAMPM } from "../../services/sharedFunction";
import {
  betListAtom,
  twoDBetAmount,
  roundId,
  isTwoDBetKway,
  twoDKwayBetListAtom,
  IsBig,
  IsSmall,
  isBetNumberExists,
  IsOdd,
  IsEven,
  IsSame,
  IsEE,
  IsEO,
  IsOE,
  IsOO,
  IsZeroInclude,
  IsOneInclude,
  IsTwoInclude,
  IsThreeInclude,
  IsFourInclude,
  IsFiveInclude,
  IsSixInclude,
  IsSevenInclude,
  IsEightInclude,
  IsNineInclude,
  IsHeadZeroInclude,
  IsHeadOneInclude,
  IsHeadTwoInclude,
  IsHeadThreeInclude,
  IsHeadFourInclude,
  IsHeadFiveInclude,
  IsHeadSixInclude,
  IsHeadSevenInclude,
  IsHeadEightInclude,
  IsHeadNineInclude,
  IsTailZeroInclude,
  IsTailOneInclude,
  IsTailTwoInclude,
  IsTailThreeInclude,
  IsTailFourInclude,
  IsTailFiveInclude,
  IsTailSixInclude,
  IsTailSevenInclude,
  IsTailEightInclude,
  IsTailNineInclude,
  IsPowerTypeOne,
  IsPowerTypeTwo,
  IsPowerTypeThree,
  IsPowerTypeFour,
  IsBrakeZero,
  IsBrakeOne,
  IsBrakeTwo,
  IsBrakeThree,
  IsBrakeFour,
  IsBrakeFive,
  IsBrakeSeven,
  IsBrakeSix,
  IsBrakeEight,
  IsBrakeNine,
  IsLimitOne,
  IsLimitTwo,
  IsLimitThree,
  IsLimitFour,
  IsR,
  IsLimitFive,
  oldBetListAtom,
  nextBetListAtom,
} from "../../services/sharedAtoms";
import dateFormat from "dateformat";
import { useAtom } from "jotai";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import { setAccessToken, setTerm } from "../../services/storage";
import { setLanguageToStorage } from "../../services/storage";
import { translations } from "../../services/localization";
import { appContext } from "../../contexts/appContext";
import decode from "html-entities-decoder";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dayjs from "dayjs";
import moment from "moment";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const TwoDHome: React.FC = () => {
  const bannersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const { data: bannerList } = useBanner();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParamsd] = useSearchParams();
  const searchParams = new URLSearchParams(location.search);
  const tValue = searchParams.get("t");
  const lang = searchParams.get("lang");
  const termAndCondition = searchParams.get("terms");
  const { changeLanguage } = useContext(appContext)!;
  const content = translations.home;
  const commom = translations.commom;

  const { data: terms } = useTerm(
    localStorage.getItem("language") || "english"
  );
  const [termAndConditionModalOpen, setTermAndConditionModalOpen] =
    useState(false);
  const [agree, setAgree] = useState(localStorage.getItem("term"));
  const { patchTermAndConditionFunc } = patchTermAndConditionCollector();
  // Get today's date
  const today = new Date();

  // Get the last month
  const lastMonth = today.getMonth() === 0 ? 11 : today.getMonth() - 1;

  // Get the last day of the last month
  const lastDay = new Date(today.getFullYear(), lastMonth + 1, 0);

  // Format the date
  const formattedDate = lastDay.toLocaleString("en-US", {
    month: "short",
    day: "numeric",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    second: "2-digit",
    hour12: true,
  });
  useEffect(() => {
    if (tValue) {
      setAccessToken(tValue);
    }
  }, [tValue]);
  useEffect(() => {
    if (termAndCondition) {
      setTerm(termAndCondition);
    }
  }, [termAndCondition]);
  useEffect(() => {
    if (lang) {
      if (lang === "en") {
        setLanguageToStorage("english");
        changeHomeRoute("english");
      } else if (lang === "mm") {
        setLanguageToStorage("myanmar");
        changeHomeRoute("myanmar");
      } else if (lang === "zh") {
        setLanguageToStorage("chinese");
        changeHomeRoute("chinese");
      }
    }
  }, []);
  const changeHomeRoute = (language: string) => {
    const parts = location.pathname.split("/");
    if (parts.length === 3) {
      if (parts[1] === "twoD") {
        if (parts[2] === "twodhome") {
          navigate("/twoD/twodhome");
          changeLanguage(language);
        }
      }
    }
  };
  // use shared Atoms
  const [betlist, setBetListData] = useAtom(betListAtom);
  const [oldBetList, setOldBetList] = useAtom(oldBetListAtom);
  const [nextBetList, setNextBetList] = useAtom(nextBetListAtom);
  const [betAmount, setBetAmount] = useAtom(twoDBetAmount);
  const [isKway, setIsKway] = useAtom(isTwoDBetKway);
  const [kwayBetList, setKwayBetList] = useAtom(twoDKwayBetListAtom);
  const [startDate, setStartDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [betresultList, setBetResultList] = useState([]);
  // const { data: datalist,getResultBetList } = useResultBetList(dateFormat(startDate,'yyyy-mm-dd'),dateFormat(endDate,'yyyy-mm-dd'));
  const {
    data: dataRoundList,
    getTwoDBetRoundBetList,
    isLoading: isLoadingRoundList,
  } = useTwoDBetRoundList(dateFormat(startDate, "yyyy-mm-dd"));
  //shared data
  const [isBig, setIsBig] = useAtom(IsBig);
  const [isSmall, setIsSmall] = useAtom(IsSmall);
  const [isOdd, setIsOdd] = useAtom(IsOdd);
  const [isEven, setIsEven] = useAtom(IsEven);
  const [isSame, setIsSame] = useAtom(IsSame);
  const [isEE, setIsEE] = useAtom(IsEE);
  const [isEO, setIsEO] = useAtom(IsEO);
  const [isOE, setIsOE] = useAtom(IsOE);
  const [isOO, setIsOO] = useAtom(IsOO);
  const [isR, setIsR] = useAtom(IsR);

  const [isZeroInclude, setIsZeroInclude] = useAtom(IsZeroInclude);
  const [isOneInclude, setIsOneInclude] = useAtom(IsOneInclude);
  const [isTwoInclude, setIsTwoInclude] = useAtom(IsTwoInclude);
  const [isThreeInclude, setIsThreeInclude] = useAtom(IsThreeInclude);
  const [isFourInclude, setIsFourInclude] = useAtom(IsFourInclude);
  const [isFiveInclude, setIsFiveInclude] = useAtom(IsFiveInclude);
  const [isSixInclude, setIsSixInclude] = useAtom(IsSixInclude);
  const [isSevenInclude, setIsSevenInclude] = useAtom(IsSevenInclude);
  const [isEightInclude, setIsEightInclude] = useAtom(IsEightInclude);
  const [isNineInclude, setIsNineInclude] = useAtom(IsNineInclude);
  //for head number include
  const [isHeadZeroInclude, setIsHeadZeroInclude] = useAtom(IsHeadZeroInclude);
  const [isHeadOneInclude, setIsHeadOneInclude] = useAtom(IsHeadOneInclude);
  const [isHeadTwoInclude, setIsHeadTwoInclude] = useAtom(IsHeadTwoInclude);
  const [isHeadThreeInclude, setIsHeadThreeInclude] =
    useAtom(IsHeadThreeInclude);
  const [isHeadFourInclude, setIsHeadFourInclude] = useAtom(IsHeadFourInclude);
  const [isHeadFiveInclude, setIsHeadFiveInclude] = useAtom(IsHeadFiveInclude);
  const [isHeadSixInclude, setIsHeadSixInclude] = useAtom(IsHeadSixInclude);
  const [isHeadSevenInclude, setIsHeadSevenInclude] =
    useAtom(IsHeadSevenInclude);
  const [isHeadEightInclude, setIsHeadEightInclude] =
    useAtom(IsHeadEightInclude);
  const [isHeadNineInclude, setIsHeadNineInclude] = useAtom(IsHeadNineInclude);
  //end of head number include
  //for tail number include
  const [isTailZeroInclude, setIsTailZeroInclude] = useAtom(IsTailZeroInclude);
  const [isTailOneInclude, setIsTailOneInclude] = useAtom(IsTailOneInclude);
  const [isTailTwoInclude, setIsTailTwoInclude] = useAtom(IsTailTwoInclude);
  const [isTailThreeInclude, setIsTailThreeInclude] =
    useAtom(IsTailThreeInclude);
  const [isTailFourInclude, setIsTailFourInclude] = useAtom(IsTailFourInclude);
  const [isTailFiveInclude, setIsTailFiveInclude] = useAtom(IsTailFiveInclude);
  const [isTailSixInclude, setIsTailSixInclude] = useAtom(IsTailSixInclude);
  const [isTailSevenInclude, setIsTailSevenInclude] =
    useAtom(IsTailSevenInclude);
  const [isTailEightInclude, setIsTailEightInclude] =
    useAtom(IsTailEightInclude);
  const [isTailNineInclude, setIsTailNineInclude] = useAtom(IsTailNineInclude);
  //end of tail number include

  const [isPowerTypeOne, setIsPowerTypeOne] = useAtom(IsPowerTypeOne);
  const [isPowerTypeTwo, setIsPowerTypeTwo] = useAtom(IsPowerTypeTwo);
  const [isPowerTypeThree, setIsPowerTypeThree] = useAtom(IsPowerTypeThree);
  const [isPowerTypeFour, setIsPowerTypeFour] = useAtom(IsPowerTypeFour);

  //brake
  const [isBrakeZero, setIsBrakeZero] = useAtom(IsBrakeZero);
  const [isBrakeOne, setIsBrakeOne] = useAtom(IsBrakeOne);
  const [isBrakeTwo, setIsBrakeTwo] = useAtom(IsBrakeTwo);
  const [isBrakeThree, setIsBrakeThree] = useAtom(IsBrakeThree);
  const [isBrakeFour, setIsBrakeFour] = useAtom(IsBrakeFour);
  const [isBrakeFive, setIsBrakeFive] = useAtom(IsBrakeFive);
  const [isBrakeSix, setIsBrakeSix] = useAtom(IsBrakeSix);
  const [isBrakeSeven, setIsBrakeSeven] = useAtom(IsBrakeSeven);
  const [isBrakeEight, setIsBrakeEight] = useAtom(IsBrakeEight);
  const [isBrakeNine, setIsBrakeNine] = useAtom(IsBrakeNine);

  //limit
  const [isLimitOne, setIsLimitOne] = useAtom(IsLimitOne);
  const [isLimitTwo, setIsLimitTwo] = useAtom(IsLimitTwo);
  const [isLimitThree, setIsLimitThree] = useAtom(IsLimitThree);
  const [isLimitFour, setIsLimitFour] = useAtom(IsLimitFour);
  const [isLimitFive, setIsLimitFive] = useAtom(IsLimitFive);
  // end shared Atoms //
  const { data: wallet, error, isLoading } = useWallet();
  const { data: holidayData } = useHoliday();
  const [isShow, setIsShow] = useState(false);
  const { data: collectAll } = useCollectAllList(true);
  const { data: roudList } = useRoundList("2d");
  const [currentTime, setCurrentTime] = useState<string>(getCurrentTime());
  const [endTime, setEndTime] = useState<string>("");
  // Function to get the current time
  function getCurrentTime() {
    const now = new Date();
    const hour = now.getHours().toString().padStart(2, "0");
    const minute = now.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minute}`;
  }
  // useEffect(() => {
  //     if(wallet?.data?.data?.userProfile){
  //         if(!wallet.data.data.userProfile?.isAgreeTerms){
  //             setTermAndConditionModalOpen(true);
  //         }
  //     }
  // }, [wallet]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const termAndCondition = searchParams.get("terms");

    if (searchParams.get("terms") === "false") {
      setTermAndConditionModalOpen(true);
      setIsShow(true);
    }
    // setTermAndConditionModalOpen((termAndCondition as any) || false);

    localStorage.setItem("one-time-modal-2d", JSON.stringify(termAndCondition));
  }, [location.search]);
  useEffect(() => {
    if (termAndCondition === "true" ? true : false) {
      setTermAndConditionModalOpen(true);
      setIsShow(true);
    }
  }, [agree]);
  useEffect(() => {
    const today = new Date();
    checkDate();
  }, [holidayData]);
  useEffect(() => {
    let checkRound = false;
    const roundData = dataRoundList?.data?.data?.data;
    if (dataRoundList?.data?.data?.data) {
      for (const round of roundData) {
        if (round.winningNumber) {
          checkRound = true;
          setEndTime(round.roundId.endTime);
        }
      }
    }
    if (checkRound === false) {
      if (dataRoundList?.data?.data?.data) {
        if (roundData[0]?.roundId?.endTime) {
          setEndTime(roundData[0]?.roundId?.endTime);
        }
      }
    }
  }, [dataRoundList]);
  const checkDate = async (): Promise<void> => {
    const inputDateObj = new Date();
    let currentDate = inputDateObj;
    const holidayList = holidayData?.data?.data;
    if (holidayList) {
      while (true) {
        const currentDateStr = dateFormat(currentDate, "dd-mm-yyyy");

        // Check if current date is Saturday or Sunday
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek === 0 || dayOfWeek === 6) {
          // If it's Saturday or Sunday, set currentDate to previous Friday
          // currentDate.setDate(
          //   currentDate.getDate() - (dayOfWeek === 0 ? 2 : 1)
          // );
        }

        if (holidayList.includes(currentDateStr)) {
          currentDate.setDate(currentDate.getDate() - 1); // Reduce one day
        } else {
          setToDate(currentDate);
          await handleSearch(currentDate); // Pass the result to handleSearch function
          break;
        }
      }
    }
  };

  const handleSearch = async (date: Date) => {
    try {
      const fromDateFormatted = dateFormat(date, "yyyy-mm-dd");
      const result = await getTwoDBetRoundBetList(fromDateFormatted);
      if (result.data.status === "success") {
        setBetResultList(result?.data?.data?.data);
      }
    } catch (error) {
      console.error("Error fetching bet history:", error);
      // Handle error, display error message, etc.
    }
  };
  const onClick = () => {
    //
  };
  const [type, setType] = useState<string>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setType("");
    setIsModalOpen(true);
    setIsShow(true);
  };
  const onSelect = () => {
    if (type) {
      setBetListData([]);
      setOldBetList([]);
      setNextBetList([]);
      setBetAmount(0);
      setIsKway(false);
      setKwayBetList([]);
      setIsBig(false);
      setIsSmall(false);
      setIsOdd(false);
      setIsEven(false);
      setIsSame(false);
      setIsEE(false);
      setIsEO(false);
      setIsOE(false);
      setIsOO(false);
      setIsZeroInclude(false);
      setIsOneInclude(false);
      setIsTwoInclude(false);
      setIsThreeInclude(false);
      setIsFourInclude(false);
      setIsFiveInclude(false);
      setIsSixInclude(false);
      setIsSevenInclude(false);
      setIsEightInclude(false);
      setIsNineInclude(false);
      setIsHeadZeroInclude(false);
      setIsHeadOneInclude(false);
      setIsHeadTwoInclude(false);
      setIsHeadThreeInclude(false);
      setIsHeadFourInclude(false);
      setIsHeadFiveInclude(false);
      setIsHeadSixInclude(false);
      setIsHeadSevenInclude(false);
      setIsHeadEightInclude(false);
      setIsHeadNineInclude(false);
      setIsTailZeroInclude(false);
      setIsTailOneInclude(false);
      setIsTailTwoInclude(false);
      setIsTailThreeInclude(false);
      setIsTailFourInclude(false);
      setIsTailFiveInclude(false);
      setIsTailSixInclude(false);
      setIsTailSevenInclude(false);
      setIsTailEightInclude(false);
      setIsTailNineInclude(false);
      setIsPowerTypeOne(false);
      setIsPowerTypeTwo(false);
      setIsPowerTypeThree(false);
      setIsPowerTypeFour(false);
      setIsBrakeZero(false);
      setIsBrakeOne(false);
      setIsBrakeTwo(false);
      setIsBrakeThree(false);
      setIsBrakeFour(false);
      setIsBrakeFive(false);
      setIsBrakeSix(false);
      setIsBrakeSeven(false);
      setIsBrakeEight(false);
      setIsBrakeNine(false);
      setIsLimitOne(false);
      setIsLimitTwo(false);
      setIsLimitThree(false);
      setIsLimitFour(false);
      setIsLimitFive(false);
      setIsR(false);
      return navigate("/twoD/betlist/" + type);
      setIsModalOpen(false);
      setIsShow(false);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setIsShow(false);
  };

  const OnSelectType = (
    round_id: string,
    status: boolean,
    isRoundOpenByAgent: boolean,
    startTime: string,
    endTime: string
  ) => {
    if (timeDisable(status, isRoundOpenByAgent, startTime, endTime)) {
      setType(round_id);
    }
  };
  // Function to determine if label should be disabled based on start and end time
  const timeDisable = (
    status: boolean,
    isRoundOpenByAgent: boolean,
    startTime: string,
    endTime: string
  ): boolean => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    // return true;

    // If it's Saturday (6) or Sunday (0), return false regardless of the status
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      return false;
    }
    //today is holiday , all close bet round
    if (holidayData?.data?.data) {
      const holidayList = holidayData?.data?.data;
      const currentDateString = today.toDateString();
      for (let i = 0; i < holidayList.length; i++) {
        const dateFromArray = new Date(holidayList[i].date);
        const dateFromArrayString = dateFromArray.toDateString(); // Get string representation of date from array (without time)

        // Compare the current date string with the date from the array
        if (currentDateString === dateFromArrayString) {
          return false; // If the dates match, return false
        }
      }
    }

    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes(); // Convert current time to minutes

    // Check if tomorrow is Saturday or Sunday
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
    const tomorrowDay = tomorrow.getDay();
    if (tomorrowDay === 6 || tomorrowDay === 0) {
      // Saturday (6) or Sunday (0)
      // Check if current time is greater than 15:58 (15*60 + 58 = 958 minutes)
      if (currentTime > 958) {
        return false;
      }
    }
    // tomorrow is holiday
    if (holidayData?.data?.data) {
      const holidayList = holidayData?.data?.data;
      const tomorrowDateString = tomorrow.toDateString();
      for (let i = 0; i < holidayList.length; i++) {
        const dateFromArray = new Date(holidayList[i].date);
        const dateFromArrayString = dateFromArray.toDateString(); // Get string representation of date from array (without time)
        // Compare the tomorrow date string with the date from the array
        if (tomorrowDateString === dateFromArrayString) {
          if (currentTime > 958) {
            return false;
          }
        }
      }
    }
    if (status && isRoundOpenByAgent) {
      const currentTime = new Date();
      const [startHour, startMinute] = startTime.split(":").map(Number);
      const [endHour, endMinute] = endTime.split(":").map(Number);

      const start = new Date(currentTime);
      start.setHours(startHour, startMinute, 0); // Set start time

      const end = new Date(currentTime);
      end.setHours(endHour, endMinute, 0); // Set end time
      // Check if start time is greater than end time
      if (
        startHour > endHour ||
        (startHour === endHour && startMinute > endMinute)
      ) {
        // If start time is greater than end time, disable label if current time is NOT between end time and start time
        const currentHours = currentTime.getHours();
        const currentMinutes = currentTime.getMinutes();
        const currentSeconds = currentTime.getSeconds();
        var returnvalue = false;
        if (
          currentHours > startHour ||
          (currentHours === startHour && currentMinutes > startMinute)
        ) {
          returnvalue = true;
        }
        if (
          currentHours < endHour ||
          (currentHours === endHour && currentMinutes < endMinute)
        ) {
          returnvalue = true;
        }
        return returnvalue;
      } else {
        // If start time is less than or equal to end time, disable label if current time is between start time and end time
        return currentTime >= start && currentTime <= end;
      }
    } else {
      return false;
    }

    //return currentTime < start || currentTime > end;
  };

  const disableButtonBetFun = () => {
    const today = new Date();
    const dayOfWeek = today.getDay(); // 0 for Sunday, 1 for Monday, ..., 6 for Saturday
    // return true;

    // If it's Saturday (6) or Sunday (0), return false regardless of the status
    if (dayOfWeek === 6 || dayOfWeek === 0) {
      return false;
    }
    //today is holiday , all close bet round
    if (holidayData?.data?.data) {
      const holidayList = holidayData?.data?.data;
      const currentDateString = today.toDateString();
      for (let i = 0; i < holidayList.length; i++) {
        const dateFromArray = new Date(holidayList[i].date);
        const dateFromArrayString = dateFromArray.toDateString(); // Get string representation of date from array (without time)

        // Compare the current date string with the date from the array
        if (currentDateString === dateFromArrayString) {
          return false; // If the dates match, return false
        }
      }
    }

    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const currentTime = currentDate.getHours() * 60 + currentDate.getMinutes(); // Convert current time to minutes

    // Check if tomorrow is Saturday or Sunday
    const tomorrow = new Date(currentDate);
    tomorrow.setDate(tomorrow.getDate() + 1); // Get tomorrow's date
    const tomorrowDay = tomorrow.getDay();
    if (tomorrowDay === 6 || tomorrowDay === 0) {
      // Saturday (6) or Sunday (0)
      // Check if current time is greater than 15:58 (15*60 + 58 = 958 minutes)
      if (currentTime > 958) {
        return false;
      }
    }
    // tomorrow is holiday
    if (holidayData?.data?.data) {
      const holidayList = holidayData?.data?.data;
      const tomorrowDateString = tomorrow.toDateString();
      for (let i = 0; i < holidayList.length; i++) {
        const dateFromArray = new Date(holidayList[i].date);
        const dateFromArrayString = dateFromArray.toDateString(); // Get string representation of date from array (without time)
        // Compare the tomorrow date string with the date from the array
        if (tomorrowDateString === dateFromArrayString) {
          if (currentTime > 958) {
            return false;
          }
        }
      }
    }
    // if (status && isRoundOpenByAgent) {
    //   const currentTime = new Date();
    //   const [startHour, startMinute] = startTime.split(":").map(Number);
    //   const [endHour, endMinute] = endTime.split(":").map(Number);

    //   const start = new Date(currentTime);
    //   start.setHours(startHour, startMinute, 0); // Set start time

    //   const end = new Date(currentTime);
    //   end.setHours(endHour, endMinute, 0); // Set end time
    //   // Check if start time is greater than end time
    //   if (
    //     startHour > endHour ||
    //     (startHour === endHour && startMinute > endMinute)
    //   ) {
    //     // If start time is greater than end time, disable label if current time is NOT between end time and start time
    //     const currentHours = currentTime.getHours();
    //     const currentMinutes = currentTime.getMinutes();
    //     const currentSeconds = currentTime.getSeconds();
    //     var returnvalue = false;
    //     if (
    //       currentHours > startHour ||
    //       (currentHours === startHour && currentMinutes > startMinute)
    //     ) {
    //       returnvalue = true;
    //     }
    //     if (
    //       currentHours < endHour ||
    //       (currentHours === endHour && currentMinutes < endMinute)
    //     ) {
    //       returnvalue = true;
    //     }
    //     return returnvalue;
    //   } else {
    //     // If start time is less than or equal to end time, disable label if current time is between start time and end time
    //     return currentTime >= start && currentTime <= end;
    //   }
    // } else {
    //   return false;
    // }

    //return currentTime < start || currentTime > end;
  };
  // Function to handle checkbox change
  const onAgree = async () => {
    setTermAndConditionModalOpen(false);
    setIsShow(false);

    if (agree) {
      const result = await patchTermAndConditionFunc(true);
      if (result?.isSuccess) {
        localStorage.setItem("one-time-modal-2d", JSON.stringify("true"));

        setTermAndConditionModalOpen(false);
        setIsShow(false);
        setTerm(false);
      }
    }
  };

  const closeTermAndConditionModal = () => {
    setTermAndConditionModalOpen(false);
    setIsShow(false);
  };

  return (
    <AppWrapper title={content.headerTitle} isback={false} isRefresh={true}>
      {isLoading || isLoadingRoundList ? (
        <LoadingSpinner />
      ) : (
        <div className="container mb-10 three-d-layout p-b-30 p-t-10">
          <MainWallet
            isShowProfile={true}
            label={commom.wallet}
            amount={
              wallet?.data?.data?.userWalletAmount?.amount
                ? wallet?.data?.data?.userWalletAmount?.amount
                : 0
            }
            image={wallet?.data?.data?.userProfile?.imageUrl}
          ></MainWallet>
          {collectAll?.data?.data?.length ? (
            <Collect
              isTwoD={true}
              isDetail={true}
              type=""
              roundName={collectAll?.data?.data[0].roundName}
              winnumber={collectAll?.data?.data[0].winningNumber}
              seemoreamount={collectAll?.data?.data.length - 1}
              coindate={collectAll?.data?.data[0].resultDate}
              //coindate='5 Jan 2024 (Round 1)'
              betamount={collectAll?.data?.data[0].totalBetAmount}
              winamount={collectAll?.data?.data[0].totalWinningAmount}
              collectdate="Dec 31, 2024, 11:23:05 AM"
              iscollect={false}
              isseemore={collectAll?.data?.data.length > 1}
              collectId={collectAll?.data?.data[0]?.twodResultId}
              status="unclaim"
              isExpiredTime={collectAll?.data?.data[0]?.createdAt}
            />
          ) : (
            <></>
          )}
          <TwoDIconLayout label=""></TwoDIconLayout>

          {bannerList?.data?.data?.length === 1 && (
            <Advertisement
              bannerimage={bannerList?.data?.data?.[0]?.bannerImageUrl}
              notiParagraph={bannerList?.data?.data?.[0]?.bannerText}
              notisecondParagraph=""
              isboldnotisecondParagraph={true}
              bannerLink={bannerList?.data?.data?.[0]?.bannerLink}
            ></Advertisement>
          )}
          {bannerList?.data?.data?.length > 1 ? (
            <Slider {...bannersettings}>
              {bannerList?.data?.data?.map((banner: any, i: number) => (
                <Advertisement
                  key={i}
                  bannerimage={banner.bannerImageUrl}
                  notiParagraph={banner.bannerText}
                  notisecondParagraph=""
                  isboldnotisecondParagraph={true}
                  bannerLink={banner.bannerLink}
                ></Advertisement>
              ))}
            </Slider>
          ) : (
            <></>
          )}

          <div className="py-4 d-flex justify-content-center">
            <div className="container text-center row fs-16 fw-500 lh-20">
              <div className="primary-color">
                {dataRoundList?.data?.data?.betCloseTime
                  ? content.betClose
                  : content.betOpen}
              </div>
              {dataRoundList?.data?.data?.betCloseTime ? (
                <div className="fs-12 w-500 lh-15 p-t-10">
                  {}
                  {dataRoundList?.data?.data?.betCloseTime
                    ? moment(
                        dataRoundList?.data?.data?.betCloseTime,
                        "YYYY-MM-DD, hh:mm"
                      ).format("MMM D, YYYY")
                    : "--"}
                  {", "}
                  {dataRoundList?.data?.data?.betCloseTime
                    ? moment(
                        dataRoundList?.data?.data?.betCloseTime,
                        "YYYY-MM-DD, hh:mm"
                      ).format("hh:mm A")
                    : ""}
                </div>
              ) : (
                <div className="fs-12 w-500 lh-15 p-t-10">
                  {dataRoundList?.data?.data?.betOpenTime
                    ? moment(
                        dataRoundList?.data?.data?.betOpenTime,
                        "YYYY-MM-DD, hh:mm"
                      ).format("MMM D, YYYY")
                    : "--"}
                  {", "}
                  {dataRoundList?.data?.data?.betOpenTime
                    ? moment(
                        dataRoundList?.data?.data?.betOpenTime,
                        "YYYY-MM-DD, hh:mm"
                      ).format("hh:mm A")
                    : "--"}
                </div>
              )}
            </div>
          </div>
          {/* <>{console.log(betresultList)}</> */}

          {betresultList ? (
            betresultList?.map((betresult: any, i: number) => (
              <React.Fragment key={i}>
                {/* <> {console.log("betresult => ", betresult)}</> */}
                {betresult ? (
                  <RoundCard
                    title={betresult?.roundId?.roundName}
                    date={
                      commom.upDateTime +
                      ": " +
                      convertTimeToAMPM(betresult?.announceTime) +
                      dateFormat(betresult?.resultDate, " dddd, mmmm d, yyyy")
                    }
                    betnumber={
                      betresult?.winningNumber ? (
                        betresult?.winningNumber
                      ) : (
                        <div className="">
                          {commom.comming}
                          <br />
                          {commom.soon}
                        </div>
                      )
                      //
                    }
                    set={betresult?.set ? betresult?.set : "--"}
                    value={betresult?.value ? betresult?.value : "--"}
                  />
                ) : (
                  <></>
                )}
              </React.Fragment>
            ))
          ) : (
            <></>
          )}
        </div>
      )}

      <Footer
        firstOnClick={ openModal}
        secondOnClick={onClick}
        is2D={true}
        isFirstButton={true}
        firstbuttonLabel={content.button2D}
        firstto="betlist"
        firstButtonClass="ok-button w-100 d-h-44 d-flex justify-content-center item-center"
        firstButtonLabelClass="ok-button-label fs-16 fw-600 lh-20"
        isSecondButton={false}
        secondButtonClass=""
        secondto=""
        secondbuttonLabel=""
        secondButtonLabelClass=""
      />
      <Modal isShow={isShow} isOpen={isModalOpen} onClose={closeModal}>
        <div className="container">
          <div className="flex text-center item-center">
            <div className="col-11 p-l-30 fs-18 fw-700 color-defination-color">
              {content.roundSelect}
            </div>
            <div className="float-right col-1 col-md-1">
              <button className="popup-close-button" onClick={closeModal}>
                <CloseIcon width="10" />
              </button>
            </div>
          </div>
          <div className="flex text-center item-center p-t-5">
            <div className="col-12 fs-16 lh-20 fw-400 ">
              {content.roundSelectTitle}
            </div>
          </div>
          <div className="row p-t-10">
            <div className="container">
              {roudList?.data?.data ? (
                roudList?.data?.data?.map((round: any, i: number) => (
                  <div
                    key={i}
                    className="row p-t-10 d-flex justify-content-between item-center"
                    onClick={() =>
                      OnSelectType(
                        round._id,
                        round.status,
                        round.isRoundOpenByAgent,
                        round.openTime,
                        round.endTime
                      )
                    }
                  >
                    <div
                      className={`${
                        type === round._id ? "quick-col-select" : "quick-col"
                      }  
                        ${
                          timeDisable(
                            round.status,
                            round.isRoundOpenByAgent,
                            round.openTime,
                            round.endTime
                          )
                            ? ""
                            : "bg-white bg-opacity-30 text-gray-400 !border !border-primary !border-opacity-30"
                        }
                        fs-16 fw-600 lh-20 d-flex d-h-42`}
                    >
                      {round?.roundName ? round?.roundName : ""} -{" "}
                      {convertTimeToAMPM(
                        round?.announceTime ? round?.announceTime : ""
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
          <div className="row p-t-10">
            <Button
              buttonOnClick={onSelect}
              to=""
              label={commom.buttonSelect}
              buttonClass={`${
                type === "" ? "ok-button-disable" : "ok-button"
              } w-100 d-h-44 d-flex justify-content-center item-center `}
              buttonLabelClass={`${
                type === "" ? "ok-button-label-disable" : "ok-button-label"
              } fs-16 fw-600 lh-20 `}
              isAction={true}
            />
          </div>
        </div>
      </Modal>
      {terms && (
        <ModalTerm
          isShow={isShow}
          isOpen={termAndConditionModalOpen}
          onClose={closeTermAndConditionModal}
        >
          <div className="container">
            <div className="text-center row d-flex item-center">
              <div className="col-12 p-l-30 fs-18 fw-700 color-defination-color">
                {content.termAndCondition}
              </div>
            </div>

            <div className="row p-t-10 d-w-300">
              <div className="container modal-scroll-container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: terms?.data?.data?.terms?.terms
                      ? decode(terms?.data?.data?.terms?.terms)
                      : decode(""),
                  }}
                />
              </div>
            </div>
            <div className="row p-t-10">
              <Button
                buttonOnClick={onAgree}
                to=""
                label={commom.buttonAccept}
                buttonClass={` ok-button w-100 d-h-44 d-flex justify-content-center item-center `}
                buttonLabelClass={`ok-button-label fs-16 fw-600 lh-20 `}
                isAction={true}
              />
            </div>
          </div>
        </ModalTerm>
      )}
    </AppWrapper>
  );
};

export default TwoDHome;
