import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { useNavigate } from "react-router-dom";
import GridTable from "../../components/common/GridTable";
import Badge from "../../assets/images/badge.png";
import { useWinnerList } from "../../api/funs/twodbetlist";
import {
  ThirdCrownIcon,
  SecondCrownIcon,
  FirstCrownIcon,
  NoDataIcon,
  UserProfileIcon,
  Rank1Icon,
  Rank2Icon,
  Rank3Icon,
} from "../../components/common/Icon";
import dateFormat from "dateformat";
import { convertTimeToAMPM } from "../../services/sharedFunction";
import { translations } from "../../services/localization";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const BetWinner: React.FC = () => {
  const navigate = useNavigate();
  const [winnerList, setWinnerList] = useState<any[]>([]);

  const [cardlabel, setCardLabel] = useState<string>("Round 1");
  const [winNumber, setWinNumber] = useState<string>();
  const [firstImage, setFirstImage] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [secondName, setSecondName] = useState<string>();
  const [thirdName, setThirdName] = useState<string>();
  const [firstPhone, setFirstPhone] = useState<string>();
  const [secondPhone, setSecondPhone] = useState<string>();
  const [thirdPhone, setThirdPhone] = useState<string>();
  const [isNoData, setIsNoData] = useState<boolean>(false);
  const [firstBetAmount, setFirstBetAmount] = useState<number>(0);
  const [secondBetAmount, setSecondBetAmount] = useState<number>(0);
  const [thirdBetAmount, setThirdBetAmount] = useState<number>(0);
  const [firstWinAmount, setFirstWinAmount] = useState<number>(0);
  const [secondWinAmount, setSecondWinAmount] = useState<number>(0);
  const [thirdWinAmount, setThirdWinAmount] = useState<number>(0);
  const [value, setValue] = useState<string>();
  const [set, setSet] = useState<string>();

  const { data: datalist, isLoading } = useWinnerList();
  const content = translations.winner;
  const commom = translations.commom;
  const roundCard = translations.roundCard;
  const tableHeader = translations.tableHeader;
  useEffect(() => {
    setCardLabel(datalist?.data?.data?.lastRound?.roundId?.roundName);
    setWinNumber(datalist?.data?.data?.lastRound?.winningNumber);
    setSet(datalist?.data?.data?.lastRound?.set);
    setValue(datalist?.data?.data?.lastRound?.value);
    if (datalist?.data?.data?.twodWinnerLists?.length === 0) {
      setIsNoData(true);
    } else {
      if (datalist?.data?.data?.twodWinnerLists?.[0]) {
        setFirstName(datalist?.data?.data?.twodWinnerLists?.[0]?.userName);
        setFirstPhone(
          maskPhoneNumber(datalist?.data?.data?.twodWinnerLists?.[0]?.phone)
        );
        setFirstBetAmount(
          datalist?.data?.data?.twodWinnerLists?.[0]?.totalBetAmount
        );
        setFirstWinAmount(
          datalist?.data?.data?.twodWinnerLists?.[0]?.totalWinningAmount
        );
      }
      if (datalist?.data?.data?.twodWinnerLists?.[1]) {
        setSecondName(datalist?.data?.data?.twodWinnerLists?.[1]?.userName);
        setSecondPhone(
          maskPhoneNumber(datalist?.data?.data?.twodWinnerLists?.[1]?.phone)
        );
        setSecondBetAmount(
          datalist?.data?.data?.twodWinnerLists?.[1]?.totalBetAmount
        );
        setSecondWinAmount(
          datalist?.data?.data?.twodWinnerLists?.[1]?.totalWinningAmount
        );
      }
      if (datalist?.data?.data?.twodWinnerLists?.[2]) {
        setThirdName(datalist?.data?.data?.twodWinnerLists?.[2]?.userName);
        setThirdPhone(
          maskPhoneNumber(datalist?.data?.data?.twodWinnerLists?.[2]?.phone)
        );
        setThirdBetAmount(
          datalist?.data?.data?.twodWinnerLists?.[2]?.totalBetAmount
        );
        setThirdWinAmount(
          datalist?.data?.data?.twodWinnerLists?.[2]?.totalWinningAmount
        );
      }
      if (datalist?.data?.data?.twodWinnerLists?.length > 0) {
        const allData = datalist?.data?.data?.twodWinnerLists;
        const updatedBetList = [];
        let index = 0;
        for (let i = 0; i < allData?.length; i++) {
          updatedBetList[index] = {
            ...allData[i],
          };
          index++;
        }
        setWinnerList(updatedBetList);
        setIsNoData(false);
      }
    }
  }, [datalist]);
  function maskPhoneNumber(phoneNumber: string) {
    const visibleDigits = 4; // Number of visible digits at the end
    const maskedLength = phoneNumber?.length - visibleDigits;

    // Repeat '*' characters for the masked part
    const maskedPart = "*".repeat(maskedLength);

    // Concatenate the masked part with the visible part (last four digits)
    const maskedPhoneNumber = maskedPart + phoneNumber?.slice(maskedLength);

    return maskedPhoneNumber;
  }
  const headerdata = [
    {
      name: tableHeader.no,
    },
    {
      name: tableHeader.name,
    },
    {
      name: tableHeader.bet,
    },
    {
      name: tableHeader.winAmount,
    },
  ];

  const CheckTopThreeIcon = (countNumber: number) => {
    if (countNumber === 1) {
      return <Rank1Icon />;
    } else if (countNumber === 2) {
      return <Rank2Icon />;
    } else if (countNumber === 3) {
      return <Rank3Icon />;
    } else {
      return countNumber;
    }
  };

  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container default-height">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="px-2 row p-t-50">
            <div className="round-card">
              <div className="row justify-content-center item-center">
                <div className="round-title d-w-100 d-h-42 d-flex item-center">
                  {datalist?.data?.data?.lastRound?.roundId?.roundName || "--"}
                </div>
              </div>
              <div className="">
                <div className="row fs-16 fw-500 item-center d-h-110 lh-20">
                  <div className="col">
                    <div className="primary-color d-flex justify-content-center item-center d-h-130 fs-40 fw-400 lh-40">
                      <span>
                        {datalist?.data?.data?.lastRound?.winningNumber || "--"}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-center item-center d-h-130 fs-14 fw-500 lh-25">
                      <span>
                        {roundCard.set || "---"}
                        <br />
                        {datalist?.data?.data?.lastRound?.set || "---"}
                      </span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex justify-content-center item-center d-h-130 fs-14 fw-500 lh-25">
                      <span>
                        {roundCard.value}
                        <br />
                        {datalist?.data?.data?.lastRound?.value
                          .split(",")
                          .join(", ") || "---"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row h-41 justify-content-center item-center">
                <hr className="full-line"></hr>
              </div>
              <div className="row d-h-41 justify-content-center item-center">
                <div className="fs-14 fw-500 lh-17 d-flex justify-content-center item-center">
                  {content.resultDate || "---"} :{" "}
                  {datalist?.data?.data?.lastRound?.resultDate
                    ? dateFormat(
                        datalist?.data?.data?.lastRound?.resultDate,
                        "mmm dd, yyyy, "
                      )
                    : "--"}
                  {datalist?.data?.data?.lastRound?.announceTime
                    ? convertTimeToAMPM(
                        datalist?.data?.data?.lastRound?.announceTime
                      )
                    : "--"}
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          winnerList?.length === 0 && (
            <div className="container">
              <div className="d-flex justify-content-center p-t-120">
                <NoDataIcon />
              </div>
              <div className="d-flex row p-t-5">
                <div className="col-12 nodata-title d-flex justify-content-center">
                  {commom.winnerNoDataLabel}
                </div>
              </div>
              <div className="d-flex row p-t-10 p-b-10">
                <div className="col-12 nodata-list d-flex justify-content-center">
                  {commom.winnerNoDataNextLabel}
                </div>
              </div>
            </div>
          )
        )}

        {!isNoData && winnerList?.length !== 0 && (
          <>
            <div className="pt-4">
              <section className="py-[40px]">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 pt-8">
                    <div className="relative flex justify-center">
                      <div className="absolute">
                        <img
                          src={require("../../assets/images/badge.png")}
                          className="w-full xxs:h-[170px] h-[170px]"
                          alt="badge"
                        />
                      </div>
                      <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                        <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                          <div className="flex flex-col items-center">
                            <SecondCrownIcon />
                            {datalist?.data?.data?.twodWinnerLists?.[1]
                              ?.userImageUrl ? (
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={
                                  datalist?.data?.data?.twodWinnerLists?.[1]
                                    ?.userImageUrl
                                }
                                alt=""
                              />
                            ) : (
                              <UserProfileIcon width="40" height="40" />
                            )}
                          </div>
                          <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                            <div className="second">
                              <div className="text-xs lh-15">
                                {content.second}
                              </div>
                            </div>
                            {secondName ? (
                              <>
                                <div className="space-y-2 xxs:space-y-1">
                                  <div className="text-xs text-center text-yellow-400">
                                    {secondName}
                                  </div>
                                  <div className="text-xs text-center text-white">
                                    {secondPhone}
                                  </div>
                                  <div className="space-y-1">
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                      <span>{content.bet}</span>
                                      <span>
                                        {secondBetAmount.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                      <span>{content.win}</span>
                                      <span>
                                        {secondWinAmount.toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <div className="mt-4 text-[10px] text-center text-yellow-400">
                                {commom.noWinners}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="relative flex justify-center">
                      <div className="absolute">
                        <img
                          src={require("../../assets/images/badge.png")}
                          className="w-full xxs:h-[170px] h-[170px]"
                          alt="badge"
                        />
                      </div>
                      <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                        <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                          <div className="flex flex-col items-center">
                            <FirstCrownIcon />
                            {datalist?.data?.data?.twodWinnerLists?.[0]
                              ?.userImageUrl ? (
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={
                                  datalist?.data?.data?.twodWinnerLists?.[0]
                                    ?.userImageUrl
                                }
                                alt=""
                              />
                            ) : (
                              <UserProfileIcon width="40" height="40" />
                            )}{" "}
                          </div>
                          <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                            <div className="first">
                              <div className="text-xs lh-15">
                                {content.first}
                              </div>
                            </div>
                            {firstName ? (
                              <>
                                {" "}
                                <div className="space-y-2 xxs:space-y-1">
                                  <div className="text-xs text-center text-yellow-400">
                                    {firstName}
                                  </div>
                                  <div className="text-xs text-center text-white">
                                    {firstPhone}
                                  </div>
                                  <div className="space-y-1">
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                      <span>{content.bet}</span>
                                      <span>
                                        {firstBetAmount.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                      <span>{content.win}</span>
                                      <span>
                                        {firstWinAmount.toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="mt-4 text-[10px] text-center text-yellow-400">
                                  {commom.noWinners}
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 pt-8">
                    <div className="relative flex justify-center">
                      <div className="absolute">
                        <img
                          src={require("../../assets/images/badge.png")}
                          className="w-full xxs:h-[170px] h-[170px]"
                          alt="badge"
                        />
                      </div>
                      <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                        <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                          <div className="flex flex-col items-center">
                            <ThirdCrownIcon />
                            {datalist?.data?.data?.twodWinnerLists?.[2]
                              ?.userImageUrl ? (
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={
                                  datalist?.data?.data?.twodWinnerLists?.[2]
                                    ?.userImageUrl
                                }
                                alt=""
                              />
                            ) : (
                              <UserProfileIcon width="40" height="40" />
                            )}{" "}
                          </div>
                          <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                            <div className="third">
                              <div className="text-xs lh-15">
                                {content.third}
                              </div>
                            </div>
                            {thirdName ? (
                              <div className="space-y-2 xxs:space-y-1">
                                <div className="text-xs text-center text-yellow-400">
                                  {thirdName}
                                </div>
                                <div className="text-xs text-center text-white">
                                  {thirdPhone}
                                </div>
                                <div className="space-y-1">
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                    <span>{content.bet}</span>
                                    <span>
                                      {thirdBetAmount.toLocaleString()}
                                    </span>
                                  </div>
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                    <span>{content.win}</span>
                                    <span>
                                      {thirdWinAmount.toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-4 text-[10px] text-center text-yellow-400">
                                {commom.noWinners}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* <div className="row p-t-30 d-h-255 d-flex justify-content-between">
                <div className="p-0 col-4 align-self-end d-flex justify-content-center">
                  <div
                    className="second-winner d-w-106 d-h-150 cover"
                    style={{ backgroundImage: `url(${Badge})` }}
                  >
                    <div className="mr-2 winner-crown">
                      <SecondCrownIcon />
                    </div>
                    <div className="mr-2 winner-photo">
                      <UserProfileIcon width="30" height="30" />
                    </div>
                    <div className="winner-card -pt-10 d-w-100 p-l-10 align-self-start">
                      <div className="d-flex justify-content-center">
                        <div className="second-label d-flex justify-content-center d-w-60 d-h-20">
                          <span className="fs-12 fw-500 lh-15 p-t-3">
                            {content.second}
                          </span>
                        </div>
                      </div>
                      {secondName ? (
                        <div className="d-flex flex-column">
                          <span className="name fs-13 fw-500 lh-17 p-t-10">
                            {secondName}
                          </span>
                          <span className="phone-number fs-12 fw-500 lh-15 p-t-10 d-h-30">
                            {secondPhone}
                          </span>
                          <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                            {content.bet} {secondBetAmount.toLocaleString()}
                          </span>
                          <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                            {content.win} {secondWinAmount.toLocaleString()}
                          </span>
                        </div>
                      ) : (
                        <div className="mt-4 text-[10px] text-center text-yellow-400">
                          {commom.noWinners}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-4 align-self-center d-flex justify-content-center">
                  <div
                    className="p-t-15 first-winner d-flex flex-column align-items-center d-w-106 d-h-150 cover"
                    style={{ backgroundImage: `url(${Badge})` }}
                  >
                    <div className="winner-crown">
                      <FirstCrownIcon />
                    </div>
                    <div className="mr-2 winner-photo">
                      <UserProfileIcon width="30" height="30" />
                    </div>
                    <div className="winner-card d-w-100 p-l-10 align-self-start">
                      <div className="d-flex justify-content-center">
                        <div className="first-label d-flex justify-content-center d-w-60 d-h-20">
                          <span className="fs-12 fw-500 lh-15 p-t-3">
                            {content.first}
                          </span>
                        </div>
                      </div>
                      {firstName ? (
                        <div className="d-flex flex-column">
                          <span className="name fs-13 fw-500 lh-17 p-t-10">
                            {firstName}{" "}
                          </span>
                          <span className="phone-number fs-12 fw-500 lh-15 p-t-10 d-h-30">
                            {firstPhone}
                          </span>
                          <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                            {content.bet} {firstBetAmount.toLocaleString()}
                          </span>
                          <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                            {content.win} {firstWinAmount.toLocaleString()}
                          </span>
                        </div>
                      ) : (
                        <div className="mt-4 text-[10px] text-center text-yellow-400">
                          {commom.noWinners}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-4 align-self-end d-flex justify-content-center">
                  <div
                    className="p-t-15 third-winner d-flex flex-column align-items-center d-w-106 d-h-150 cover"
                    style={{ backgroundImage: `url(${Badge})` }}
                  >
                    <div className="winner-crown">
                      <ThirdCrownIcon />
                    </div>
                    <div className="winner-photo">
                      <UserProfileIcon width="30" height="30" />
                    </div>
                    <div className="winner-card d-w-100 align-self-start">
                      <div className="d-flex justify-content-center">
                        <div className="third-label d-flex justify-content-center d-w-60 d-h-20">
                          <span className="fs-12 fw-500 lh-15 p-t-3">
                            {content.third}
                          </span>
                        </div>
                      </div>
                      {thirdName ? (
                        <div className="d-flex flex-column p-l-6">
                          <span className="name fs-13 fw-500 lh-17 p-t-10">
                            {thirdName}
                          </span>
                          <span className="phone-number fs-12 fw-500 lh-15 p-t-10 d-h-30">
                            {thirdPhone}
                          </span>
                          <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                            {content.bet} {thirdBetAmount.toLocaleString()}
                          </span>
                          <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                            {content.win} {thirdWinAmount.toLocaleString()}
                          </span>
                        </div>
                      ) : (
                        <div className="mt-4 text-[10px] text-center text-yellow-400">
                          {commom.noWinners}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {winnerList?.length ? (
              <div className="container">
                <div className="row p-t-20 p-b-10 item-center">
                  <div className="col-12">
                    <GridTable headerdata={headerdata}>
                      {winnerList?.map((winner: any, i: number) => (
                        <tr
                          key={i}
                          className="table-row bottom-dashed h-50 fs-14 lh-25"
                        >
                          <td>{CheckTopThreeIcon(i + 1)}</td>
                          <td>
                            {winner.userName}
                            <br />
                            {maskPhoneNumber(winner.phone)}
                          </td>
                          <td>{winner.totalBetAmount.toLocaleString()}</td>
                          <td>{winner.totalWinningAmount.toLocaleString()}</td>
                        </tr>
                      ))}
                    </GridTable>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
      </div>
    </AppWrapper>
  );
};

export default BetWinner;
