import React, { useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../components/common/Button";
import {
  isTwoDBetKway,
  isBetNumberExists,
  twoDKwayBetListAtom,
  betListAtom,
  findMaxAmountById,
  findMinAmountById,
  findStatusById,
  findPercentById,
} from "../../services/sharedAtoms";
import { useBetList } from "../../api/funs/twodbetlist";
import { useAtom } from "jotai";
import { translations } from "../../services/localization";

const BetKway: React.FC = () => {
  const { round_id } = useParams<{ round_id: string }>();
  const navigate = useNavigate();
  const content = translations.kway;
  const commom = translations.commom;
  // shared data
  const [isKway, setIsKway] = useAtom(isTwoDBetKway);
  const [kwayBetList, setKwayBetList] = useAtom(twoDKwayBetListAtom);
  const [betlist, setBetListData] = useAtom(betListAtom);

  //end shared data
  const { data: roundList } = useBetList(round_id || "");

  const [disable, setDisable] = useState<boolean>(true);
  const [kwayNumber, setKwayNumber] = useState<string>("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setKwayNumber(value);
    if (event.target.value.length > 2 && event.target.value.length < 11) {
      const digits = String(value);
      let foundRepeatedDigit = false;
      /* check same number */
      for (let i = 0; i < digits.length; i++) {
        for (let j = i + 1; j < digits.length; j++) {
          if (digits[i] === digits[j]) {
            foundRepeatedDigit = true;
            break;
          }
        }
        if (foundRepeatedDigit) {
          break;
        }
      }
      /* check same number */
      if (foundRepeatedDigit) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    } else {
      setDisable(true);
    }
  };

  const reverseString = (input: string): string => {
    return input.split("").reverse().join("");
  };

  const onClick = () => {
    if (!disable) {
      const digits = String(kwayNumber).split("").map(Number);
      const updatedKwayBetList = []; // Create a new array to hold updated items
      const updatedBetList: {
        betNumber: string;
        isSelected: boolean;
        status: boolean;
        isR: boolean;
        isKway: boolean;
        amount: number;
        maxAmount: number;
        minAmount: number;
        percent: string;
      }[] = [];
      for (let i = 0; i < digits.length; i++) {
        for (let j = i + 1; j < digits.length; j++) {
          if (
            findStatusById(`${digits[i]}${digits[j]}`, roundList?.data?.data) &&
            parseInt(
              findPercentById(`${digits[i]}${digits[j]}`, roundList?.data?.data)
            ) < 100
          ) {
            const newItem = {
              betNumber: `${digits[i]}${digits[j]}`,
              isSelected: false,
              status: true,
              isR: false,
              isKway: true,
              amount: 0,
              maxAmount: findMaxAmountById(
                `${digits[i]}${digits[j]}`,
                roundList?.data?.data
              ),
              minAmount: findMinAmountById(
                `${digits[i]}${digits[j]}`,
                roundList?.data?.data
              ),
              percent: findPercentById(
                `${digits[i]}${digits[j]}`,
                roundList?.data?.data
              ),
            };
            updatedKwayBetList.push(newItem); // Push the new item to the updated array
            if (!isBetNumberExists(`${digits[i]}${digits[j]}`, betlist)) {
              updatedBetList.push(newItem);
            }
          }
          let reverseBet = reverseString(`${digits[i]}${digits[j]}`);
          if (
            findStatusById(reverseBet, roundList?.data?.data) &&
            parseInt(findPercentById(reverseBet, roundList?.data?.data)) < 100
          ) {
            const reverseNewItem = {
              betNumber: reverseBet,
              isSelected: false,
              status: true,
              isR: false,
              isKway: true,
              amount: 0,
              maxAmount: findMaxAmountById(reverseBet, roundList?.data?.data),
              minAmount: findMinAmountById(reverseBet, roundList?.data?.data),
              percent: findPercentById(reverseBet, roundList?.data?.data),
            };
            updatedKwayBetList.push(reverseNewItem); // Push the new item to the updated array
            if (!isBetNumberExists(reverseBet, betlist)) {
              updatedBetList.push(reverseNewItem);
            }
          }
        }
      }

      localStorage.setItem(
        "bet",
        JSON.stringify([...betlist, ...updatedBetList])
      );
      setBetListData((prevBetList) => [...prevBetList, ...updatedBetList]);

      setKwayBetList(updatedKwayBetList);
      setIsKway(true);
      navigate("/twoD/betlist/" + round_id);
    }
    //
  };
  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container default-height">
        <div className="container p-t-20">
          <div className="row">
            <div className="col kway-header xs:text-lg text-sm fw-400">
              {content.title}
            </div>
          </div>
          <div className="row p-t-10">
            <input
              type="number"
              placeholder="Enter Numbers"
              onChange={handleChange}
              className="kway-text-box d-h-50 w-100 fs-18 fw-400 lh-23 no-arrow"
            />
          </div>
          <div className="row p-t-10">
            <span className="primary-color xs:text-sm text-xs lh-18 fw-400">
              ** {content.warningMessage} ** <br />
              ** {content.warningMessageTwo} **
            </span>
          </div>
          <div className="row p-t-10">
            <Button
              to=""
              buttonOnClick={onClick}
              label={commom.buttonConfirm}
              buttonClass={`${
                !disable ? "ok-button" : "ok-button-disable"
              } d-h-44 d-flex justify-content-center item-center`}
              buttonLabelClass={`${
                !disable ? "ok-button-label" : "ok-button-label-disable"
              }  fs-16 fw-600 lh-20 `}
              isAction={true}
            />
          </div>
        </div>
      </div>
    </AppWrapper>
  );
};

export default BetKway;
