import React, { createContext, useEffect, useState } from "react";
import { getLanguageFromStorage, setLanguageToStorage } from "../services/storage";
import { useLocation,useNavigate } from 'react-router-dom';
import { onSetLanguageToEnglish, onSetLanguageToMyanmar, onSetLanguageToChinese } from "../services/localization";

interface AppContextProps {
    language: string;
    changeLanguage: (lang: string) => void; // Update the type signature of changeLanguage
}

export const appContext = createContext<AppContextProps | undefined>(undefined);

const ENGLISH = 'english';
const MYANMAR = 'myanmar';
const CHINESE = 'chinese';

const DEFAULT_LANGUAGE = ENGLISH;

export const AppProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const lang = getLanguageFromStorage();
    const [language, setLanguage] = useState(lang || DEFAULT_LANGUAGE);

    const changeLanguage = (lang: string) => {
        setLanguage(lang);
        if (lang === ENGLISH) {
            setLanguageToStorage('english');
            onSetLanguageToEnglish();
        } else if (lang === MYANMAR) { 
            setLanguageToStorage("myanmar");
            onSetLanguageToMyanmar();
        } else if (lang === CHINESE) { 
            setLanguageToStorage("chinese");
            onSetLanguageToChinese();
        }
        // window.location.reload();
    };

    useEffect(() => {
        let lang=language;
        if(localStorage.getItem('language')){
            lang=localStorage.getItem('language')|| 'english';
            setLanguageToStorage(lang);
        }
        else {
            setLanguageToStorage(language);
        }
        if (lang === ENGLISH) {
            onSetLanguageToEnglish();
        } else if (lang === MYANMAR) { 
            onSetLanguageToMyanmar();
        } else if (lang === CHINESE) { 
            onSetLanguageToChinese();
        }
    }, [language]);
    const loadLang= async () => {
        const languagedata = await getLanguageFromStorage();
        if (languagedata) {
            setLanguage(languagedata)
        }
        else {
            setLanguage("english");
        }
    }

    useEffect(() => {
        loadLang();
    }, [])
    return (
        <appContext.Provider value={{ language, changeLanguage }}>
            {children}
        </appContext.Provider>
    );
};
