/* eslint-disable import/named */
import axios from "axios";
import { getAccessToken, getLanguageFromStorage } from "./storage";
import { API_DOMAIN } from "../config";
import { useContext } from "react";
import { appContext } from "../contexts/appContext";
// eslint-disable-next-line no-undef
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;

class AxiosService {
  axiosInstance = {};

  constructor() {
    this.initInstance();
  }


  initInstance() {
    const lang = getLanguageFromStorage();


    this.axiosInstance = axios.create({
      baseURL: URL,
      withCredentials: false,
      headers: {
        "accept-language":lang ==="myanmar"?"mm":lang ==="english"? "en":"zh"
      }
    });

    this.axiosInstance.interceptors?.request.use((config) => {
      // get token to set 'Authorization' header for send back to server

      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${getAccessToken()}`;
      return config;
    });

    return this.axiosInstance;
  }

  getInstance() {
    return this.axiosInstance || this.initInstance();
  }
}

export default new AxiosService();
