import { WinIcon, AirrowRightIcon } from "../../components/common/Icon";
import React, { useState } from "react";
import Modal from "./Modal";
import dateFormat from "dateformat";
import { useNavigate } from "react-router-dom";
import { translations } from "../../services/localization";
import moment from "moment";
interface ParentComponentProps {
  winnumber: string | undefined;
  coindate: string | undefined;
  roundName: string | undefined;
  betamount: number | 0;
  winamount: number | 0;
  collectdate: string | undefined;
  iscollect: boolean | false;
  isseemore: boolean | false;
  isDetail: boolean | false;
  seemoreamount: number | undefined;
  type: string;
  collectId: string;
  isTwoD: boolean;
  status: string;
  isExpiredTime?: string;
}
const Collect: React.FC<ParentComponentProps> = ({
  winnumber,
  coindate,
  roundName,
  betamount,
  winamount,
  collectdate,
  iscollect,
  isseemore,
  isDetail,
  seemoreamount,
  collectId,
  type,
  isTwoD,
  status,
  isExpiredTime,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const navigate = useNavigate();
  const content = translations.collect;
  const common = translations.commom;
  const collectList = translations.collectList;
  const getLastDayOfYear = (year: number): Date => {
    const date = new Date(year, 11, 31); // Month is 0-indexed, so 11 corresponds to December
    return date;
  };

  const currentYear = new Date().getFullYear();
  const lastDayOfYear = getLastDayOfYear(currentYear);
  const openColletModal = () => {
    debugger;
    setIsModalOpen(true);
    setIsShow(true);
  };

  const closeColletModal = () => {
    setIsModalOpen(false);
    setIsSuccessModalOpen(true);
  };

  const collectDetail = () => {
    if (isseemore) {
      //nothing to do
      // seemore();
    } else {
      if (isTwoD) {
        navigate("/twoD/collectDeail/" + collectId + "/" + status);
      } else {
        navigate(
          "/threeD/collectDeail/" + collectId + "/" + type + "/" + status
        );
      }
    }
  };
  const seemore = () => {
    if (isTwoD) {
      navigate("/twoD/collectlist");
    } else {
      navigate("/threeD/collectlist");
    }
  };
  const closeSuccessModal = () => {
    setIsModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsShow(false);
  };
  return (
    <div className=" cursor-pointer p-t-15 p-b-5">
      <div className="">
        <div className={`collect container`}>
          <div className="row" onClick={collectDetail}>
            <div className="">
              <div className="coin-container">
                <WinIcon />
                <div className="coin-row ">
                  <div className="coin-number h-18 d-flex gap-0.5 item-center">
                    <span className="coin-label fs-10 fw-500">
                      {content.winNumber} -
                    </span>
                    <span className="coin-amount fs-10 fw-700 primary-color">
                      {winnumber}
                    </span>
                  </div>
                  <div className="coin-date fs-10 fw-500">
                    {dateFormat(coindate, "dd mmm, yyyy")}{" "}
                    {roundName
                      ? "(" +
                        roundName.charAt(0).toUpperCase() +
                        roundName.slice(1).toLowerCase() +
                        ")"
                      : ""}{" "}
                  </div>
                </div>
                {/* <div className="h-16 coin-date fs-10 fw-500">{dateFormat(coindate,"dd mmm, yyyy")}  {roundName?'('+roundName+')':''} </div> */}
              </div>
            </div>
            <div className="fs-12 fw-600 lh-24 p-t-5 p-b-5">
              {content.firstNoti}
              <span className="primary-color">
                {betamount.toLocaleString()} {common.unit}
              </span>
              {content.secondNoti}
              <span className="primary-color">
                {winamount.toLocaleString()} {common.unit}
              </span>
              {content.secondNotiTwo}
              <br />
              {content.thirdNoti}
              {isExpiredTime &&
                moment(isExpiredTime).add(7, "days").format("MMM DD YYYY") +
                  " at " +
                  moment(isExpiredTime).format("hh:mm:ss A")}
              {content.thirdNotiTwo}
            </div>
            <div className=" d-flex p-b-10">
              {iscollect ? (
                <div>
                  <button
                    className=" d-w-63 d-h-27 btn-collect fs-12"
                    onClick={openColletModal}
                  >
                    {collectList.headerTitle}
                  </button>
                </div>
              ) : (
                <></>
              )}
              {isseemore ? (
                <div className="p-l-1">
                  <button
                    className=" d-w-92 d-h-27 btn-see-more primary-color fs-12"
                    onClick={seemore}
                  >
                    {content.seeMore} +{seemoreamount}
                  </button>
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
          {/* {isDetail ? (
              <div className="col-2 d-flex justify-content-center align-items-center">
                <AirrowRightIcon></AirrowRightIcon>
              </div>
            ) : (
              <></>
            )} */}
        </div>

        <Modal isShow={isShow} isOpen={isModalOpen} onClose={closeColletModal}>
          <div className="container">
            <div className="text-center row d-h-50 d-flex item-center">
              <div className="col primary-color">{collectList.headerTitle}</div>
            </div>
            <div className="row d-h-50">
              <div className="col">
                {content.fouthNoti}{" "}
                <span className="primary-color">
                  {betamount.toLocaleString()} {common.unit}
                </span>
                {content.firthNoti}
                <span className="primary-color">
                  {winamount.toLocaleString()} {common.unit}
                </span>
                .
              </div>
            </div>
            <div className="row d-h-50">
              <button className="btn-ok" onClick={closeColletModal}>
                {common.buttonOk}
              </button>
            </div>
          </div>
        </Modal>
        <Modal isShow={isShow} isOpen={isSuccessModalOpen} onClose={closeSuccessModal}>
          <div className="container">
            <div className="text-center row d-h-50 d-flex item-center">
              <div className="col primary-color">{common.success}</div>
            </div>
            <div className="text-center row d-h-70 d-flex item-center">
              <div className="col">
                {content.withdrawMessage}
                <br />
                {common.successfully}
              </div>
            </div>
            <div className="row d-h-50">
              <button className="btn-ok" onClick={closeSuccessModal}>
                {common.buttonOk}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Collect;
