import { useNavigate } from "react-router-dom";

interface ParentComponentProps {
  to: string;
  label: string | undefined;
  isDisabled: boolean;
}
const BetButton: React.FC<ParentComponentProps> = ({
  to,
  label,
  isDisabled,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    return navigate(to);
  };
  return (
    <div
      className={`bet-button w-396 item-center d-flex font-Quicksand f-16 fw-600 ${
        isDisabled ? "buton-disable" : ""
      } `}
      onClick={onClick}
    >
      {label}
    </div>
  );
};

export default BetButton;
