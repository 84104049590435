// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.popup-modal-overlay {
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative !important;
  margin: 20px;
}

.popup-modal-term {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative !important;
}

.popup-close-button {
  width: 15px;
  height: 15px;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  outline: none;
  position: relative;
  top: -15px;
  right: -15px;
}

.detail-popup {
  font-size: 16px;
  font-weight: 400;
}

.detail-popup-date {
  font-size: 14px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/style/modal.scss"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACJ;;AAEE;EACE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,uCAAA;EACA,6BAAA;EACA,YAAA;AACJ;;AAEE;EAEE,iBAAA;EACA,aAAA;EACA,kBAAA;EACA,uCAAA;EACA,6BAAA;AAAJ;;AAGE;EACE,WAAA;EACA,YAAA;EACA,eAAA;EACA,YAAA;EACA,gBAAA;EACA,UAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;EACA,YAAA;AAAJ;;AAGE;EACE,eAAA;EACA,gBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,gBAAA;AAAJ","sourcesContent":[".popup-modal-overlay {\n    position: fixed!important;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    background: rgba(0, 0, 0, 0.5);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n  \n  .popup-modal {\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    position: relative!important;\n    margin: 20px;\n  }\n  \n  .popup-modal-term {\n    // max-height:75%;\n    background: white;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    position: relative!important;\n  }\n  \n  .popup-close-button {\n    width: 15px;\n    height: 15px;\n    cursor: pointer;\n    border: none;\n    background: none;\n    padding: 0;\n    outline: none;\n    position: relative;\n    top: -15px;\n    right: -15px;\n  }\n\n  .detail-popup{\n    font-size: 16px;\n    font-weight: 400;\n  }\n  \n  .detail-popup-date{\n    font-size: 14px;\n    font-weight: 500;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
