import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import Footer from "../../layout/mainlayouts/footer";
import { atom, useAtom } from "jotai";
import { useParams, useNavigate } from "react-router-dom";
import { useAgentSettingList } from "../../api/funs/twodbetlist";
import { betListAtom, twoDBetAmount } from "../../services/sharedAtoms";
import Modal from "../../components/common/Modal";
import { useWallet } from "../../api/funs/twodhome";
import { translations } from "../../services/localization";
import { useWindowSize } from "../../hooks/useWindowSize";
interface BetObject {
  amount: number;
}

const selectedBetAmountAtom = atom<number | null>(null);

const BetChooseTwoD: React.FC = () => {
  const { round_id } = useParams<{ round_id: string }>();
  const navigate = useNavigate();
  const [isShow, setIsShow] = useState(false);
  const [betAmount, setBetAmount] = useAtom(twoDBetAmount);
  const { data: agentSetting } = useAgentSettingList(round_id || "");
  const { data: wallet } = useWallet();
  const [betlist, setBetList] = useAtom(betListAtom);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [betOptions, setBetOptions] = useState<Array<BetObject>>([]);
  const [disabledBetBtn, setDisabledBtn] = useState<boolean>(true);
  const content = translations.betChoose;
  const commom = translations.commom;
  const { windowWidth } = useWindowSize();
  const betst =
    localStorage.getItem("bet") &&
    JSON.parse(localStorage.getItem("bet") || "[{}]");

  const [selectedBetAmount, setSelectedBetAmount] = useAtom(
    selectedBetAmountAtom
  );

  const OnClickAmount = (Amount: string) => {
    const value = parseInt(Amount);
    const updatedBetlist = betst?.map((bet: any) => {
      if (!bet.isR) {
        return { ...bet, amount: value };
      }
      return bet;
    });
    localStorage.setItem("bet", JSON.stringify(updatedBetlist));
    setBetList(updatedBetlist);

    setBetAmount(value);
  };

  const CheckBetList = (Amount: string) => {
    const value = parseInt(Amount);
    let totalAmount = 0;
    for (const bet of betlist) {
      if (bet.isR) {
        totalAmount = totalAmount + bet.amount;
      } else {
        totalAmount = totalAmount + value;
      }
      if (value > bet.maxAmount) {
        setMessage(
          bet.betNumber +
            content.messagePartOne +
            bet.maxAmount +
            content.messagePartTwo
        );
      }
    }
    if (message === "") {
      if (totalAmount > wallet?.data?.data?.userWalletAmount?.amount) {
        setMessage(
          content.messagePartThree +
            wallet?.data?.data?.userWalletAmount?.amount +
            content.messagePartFour +
            totalAmount +
            content.messagePartTwo
        );
        setMessageModalOpen(true);
        setIsShow(true);
        return false;
      } else {
        return true;
      }
    } else {
      setMessageModalOpen(true);
      setIsShow(true);
      return false;
    }
  };
  const onClick = () => {
    //
  };
  const onOkClick = () => {
    if (betAmount) {
      if (CheckBetList(betAmount.toString())) {
        OnClickAmount(betAmount.toString());
        navigate("/twoD/betconfirm");
      }
    }
  };

  const closeMessageModal = () => {
    setMessage("");
    setMessageModalOpen(false);
    setIsShow(false);
  };
  useEffect(() => {
    // Function to generate array of objects based on rules
    const generateArrayObjects = (
      minAmount: number,
      maxAmount: number
    ): Array<BetObject> => {
      const arrayObjects: Array<BetObject> = [];

      // Start with minAmount
      let currentAmount = minAmount;

      while (currentAmount <= maxAmount) {
        arrayObjects.push({ amount: currentAmount });

        // Increase amount based on rules
        if (currentAmount < 1000) {
          currentAmount += 100;
        } else if (currentAmount < 10000) {
          currentAmount += 500;
        } else if (currentAmount < 100000) {
          currentAmount += 5000;
        } else {
          currentAmount += 100000;
        }

        // Break if maxAmount is less than the increase amount
        if (maxAmount < currentAmount) {
          break;
        }
      }

      return arrayObjects;
    };

    // Define min and max amounts
    let minAmount = 100;
    let maxAmount = 250000;
    if (agentSetting?.data?.isSuccess) {
      maxAmount = agentSetting?.data?.data?.maxBetPerUser;
    }
    if (agentSetting?.data?.isSuccess) {
      minAmount = agentSetting?.data?.data?.minBetPerUser;
    }

    // Generate array of objects
    const options = generateArrayObjects(minAmount, maxAmount);

    // Set betOptions state
    setBetOptions(options);
  }, [agentSetting]); // Empty dependency array ensures this effect runs only once, similar to componentDidMount

  useEffect(() => {
    if (betAmount === selectedBetAmount) {
      setDisabledBtn(false);
    }
  }, [betAmount, selectedBetAmount]);

  return (
    <AppWrapper
      title={content.headerTitle}
      isback={true}
      isRefresh={true}
      removeScroll
    >
      <div className="container min-h-[calc(100vh-76px)]">
        <div className="container py-8 fs-16 fw-600">
          <div className="pb-24 row bet-namber-row d-flex item-center">
            {betOptions.map((betOption: any, i: number) => (
              <div
                className="col-3 d-h-44 g-2"
                onClick={() => {
                  OnClickAmount(betOption.amount);

                }}
              >
                <div
                  className={`choose-col item-center h-100 ${
                    betAmount === betOption.amount ? "selected" : ""
                  }`}
                >
                  {betOption.amount.toLocaleString()}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer
        firstOnClick={onClick}
        secondOnClick={onOkClick}
        is2D={false}
        isFirstButton={true}
        firstbuttonLabel={commom.buttonCancel}
        firstto={"/twoD/betlist/" + round_id}
        firstButtonClass="cancel-button w-100 d-h-42 d-flex justify-content-center item-center"
        firstButtonLabelClass="cancel-button-label fs-16 fw-600 lh-20"
        isSecondButton={true}
        secondButtonClass="ok-button w-100 d-h-42 d-flex justify-content-center item-center uppercase"
        secondto=""
        secondbuttonLabel={commom.buttonOk}
        secondButtonLabelClass="ok-button-label fs-16 fw-600 lh-20 uppercase"
        isDisable={betAmount ===0?true:false}
      />
      <Modal
        isShow={isShow}
        isOpen={messageModalOpen}
        onClose={closeMessageModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color p-0">{commom.message} !! </div>
          </div>
          <div className="text-center row d-h-80 d-flex item-center">
            <div className="col primary-color p-0">
              {content.messagePartThree +
                wallet?.data?.data?.userWalletAmount?.amount +
                content.messagePartFour +
                betst?.reduce(
                  (accumulator: any, current: any) =>
                    accumulator + current.amount,
                  0
                ) +
                content.messagePartTwo}
            </div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeMessageModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button className="uppercase btn-ok" onClick={closeMessageModal}>
                {commom.buttonBet}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default BetChooseTwoD;
