// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-table {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .table-header {
    color: white;
    font-size: 12px;
    text-align: center;
    background: linear-gradient(180deg, #F44336 0%, #FF4E41 100%);
  }
}
@media screen and (min-width: 768px) {
  .table-header {
    color: white;
    text-align: center;
    background: linear-gradient(180deg, #F44336 0%, #FF4E41 100%);
  }
}
.tbl-container {
  box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1);
  width: 100%;
}

.bdr {
  border-radius: 6px;
  overflow: hidden;
}

.table-row {
  text-align: center;
  color: rgb(0, 0, 0);
}

.black-color {
  color: rgb(0, 0, 0);
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.red-color {
  color: rgb(159, 20, 10);
}

.bottom-dashed {
  border-bottom: 1px dashed rgb(192, 192, 192);
}`, "",{"version":3,"sources":["webpack://./src/style/Gridtable.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EAEI;IACI,YAAA;IACA,eAAA;IACA,kBAAA;IACA,6DAAA;EAAN;AACF;AAEA;EACI;IACI,YAAA;IACA,kBAAA;IACA,6DAAA;EAAN;AACF;AAIA;EACI,kDAAA;EACA,WAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,gBAAA;AAFJ;;AAKA;EACI,kBAAA;EACA,mBAAA;AAFJ;;AAKA;EACI,mBAAA;AAFJ;;AAKA;EACI,gBAAA;AAFJ;;AAIA;EACI,kBAAA;AADJ;;AAIA;EACI,iBAAA;AADJ;;AAIA;EACI,uBAAA;AADJ;;AAIA;EACI,4CAAA;AADJ","sourcesContent":[".grid-table{\n    width: 100%;\n    \n}\n@media screen and (max-width: 480px) {\n    \n    .table-header{\n        color:white;\n        font-size: 12px;\n        text-align: center;\n        background: linear-gradient(180deg, #F44336 0%, #FF4E41 100%);\n    }\n}\n@media screen and (min-width: 768px) {\n    .table-header{\n        color:white;\n        text-align: center;\n        background: linear-gradient(180deg, #F44336 0%, #FF4E41 100%);\n    }\n}\n\n\n.tbl-container {\n    box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1);\n    width: 100%;\n}\n  \n.bdr {\n    border-radius: 6px;\n    overflow: hidden;\n}\n\n.table-row {\n    text-align: center;\n    color: rgba(0, 0, 0, 1);\n}\n\n.black-color {\n    color: rgba(0, 0, 0, 1);\n}\n\n.text-align-left{\n    text-align: left;\n}\n.text-align-center{\n    text-align: center;\n}\n\n.text-align-right{\n    text-align: right;\n}\n\n.red-color {\n    color: rgba(159, 20, 10, 1);\n}\n\n.bottom-dashed{\n    border-bottom: 1px dashed rgba(192, 192, 192, 1);\n    //border-bottom: 1px dashed rgba(192, 192, 192, 1);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
