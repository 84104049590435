import LocalizedStrings from 'react-localization'
import initialLanguageStore from './localizationmain'

export let translations = new LocalizedStrings(initialLanguageStore);

export let strings = new LocalizedStrings({
  initialLanguageStore
});

export const onSetLanguageToEnglish = () => {
  translations.setLanguage('en');
}
export const onSetLanguageToMyanmar = () => {
  translations.setLanguage('mm');
}
export const onSetLanguageToChinese = () => {
  translations.setLanguage('ch');
}