import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import moment from "moment";
interface ParentComponentProps {
  number: { number: string; amount: number }[];
  date: string | undefined;
  amount: number | 0;
  to: string;
}
const HistoryCardThreeD: React.FC<ParentComponentProps> = ({
  number,
  date,
  amount,
  to,
}) => {
  const navigate = useNavigate();
  const showNumber = number.map((item) => item.number).join(", ");
  const onClick = () => {
    return navigate(to);
  };
  return (
    <div
      className="container history-card-three-d d-h-106 w-100"
      onClick={onClick}
    >
      <div className="row d-flex justify-content-center d-h-70 item-center">
        <div className="col-history-card-number fs-22 fw-500 ">
          {showNumber}
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-8 d-flex justify-content-start fs-12 fw-500 lh-20">
          {moment(date).format("MMMM DD,YYYY , hh:mm:ss A")}
          {/* {dateFormat(date, "mmmm dd, yyyy , hh:MM:ss TT")} */}
        </div>
        <div className="col-4 d-flex justify-content-end primary-color fs-12 fw-400 lh-20">
          {amount.toLocaleString()} MMK
        </div>
      </div>
    </div>
  );
};

export default HistoryCardThreeD;
