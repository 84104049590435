import AppWrapper from "../../layout/AppWrapper";
import { useNavigate } from "react-router-dom";
import { fetchData } from "../../services/fetch";
import React, { useState, useEffect } from "react";
import dateFormat from "dateformat";
import { translations } from "../../services/localization";
import moment from "moment";

const BetHoliday: React.FC = () => {
  const navigate = useNavigate();
  const [holidaylist, setHolidayList] = useState<any[]>([]);

  const content = translations.betHoliday;
  const fetchHolidayList = async () => {
    fetchData({
      url: "api/holidays",

      method: "get",
    })
      .then((res) => {
        if (res.data.isSuccess) {
          setHolidayList(res.data.data);
          var datete = res.data.data[0].date;
          const apiDate = new Date(datete);
        }
      })
      .catch((err) => {})
      .finally(() => {});
  };
  useEffect(() => {
    fetchHolidayList();
  }, []);
  const onclickFooterButton = (e: any) => {
    e.preventDefault();
    navigate("twoD/betlist");
  };


  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container default-height">
        <div className="row d-flex justify-content-center d-h-70 item-center fs-18 fw-400 primary-color">
          {content.title}
        </div>
        <div className="">
          {holidaylist?.map((_e:any) => ({
    ..._e,
    date:moment(_e?.date)?.format()
  }))?.sort((a,b)=> a?.date - b?.date)?.map((holiday, i) => {
            let holidaydateformat = new Date(holiday?.date);
            return (
              <React.Fragment key={i}>
                {/* <div className="row p-t-10 p-b-10">
                  <div className="holiday-card d-flex justify-content-between ">
                    <div className="px-2 py-3 text-sm date-card text-nowrap">
                      <p>{dateFormat(holidaydateformat, "mmm dd, yyyy")}</p>
                      {dateFormat(holidaydateformat, "dddd")}
                    </div>
                    <div className="flex items-center justify-center holiday-reason">
                      {holiday.holidayName}
                    </div>
                  </div>
                </div> */}

                <div className="flex items-center justify-between p-3 mb-2 text-xs holiday-card sm:text-sm">
                  <div className="date-card ">
                    <div className=" w-[90px]">
                      {dateFormat(holidaydateformat, "mmm dd, yyyy")}
                    </div>
                    <div> {dateFormat(holidaydateformat, "dddd")}</div>
                  </div>
                  <div className="holiday-reason">{holiday.holidayName}</div>
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </AppWrapper>
  );
};

export default BetHoliday;
