import { createContext, useState, useEffect ,ReactNode} from "react"
import { getAccessToken } from '../services/storage';


type Props = {
    children?: ReactNode;
}
type IAuthContext = {
    authenticated: boolean,
    setAuthenticated: (newState:boolean) => void,
    user: any,
    setUser: React.Dispatch<React.SetStateAction<any>>,
}
const initialValue ={
    authenticated: false,
    setAuthenticated: () => {},
    user:undefined,
    setUser:()=> {},
}

export const AuthContext = createContext<IAuthContext>(initialValue);

export const AuthContextProvider = ({ children }:Props) => {
    const [authenticated, setAuthenticated] = useState(initialValue.authenticated);
    const [user, setUser] = useState();
    const loadUser = async () => {
        const accesstoken = await getAccessToken() 
        if (accesstoken) {
            setAuthenticated(true);
        }
    }

    useEffect(() => {
        loadUser();
      }, []);
     //use Memo
  
    return <AuthContext.Provider value={{   authenticated, setAuthenticated ,user,setUser }}>
        {children}
    </AuthContext.Provider>
}