import React from "react";
import { BeatLoader } from "react-spinners";

const LoadingSpinner = () => {
  return (
    <div className="absolute top-0 left-0 flex items-center justify-center w-screen h-screen">
      <BeatLoader color="#9F140A" size={8} />
    </div>
  );
};

export default LoadingSpinner;
