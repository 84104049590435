import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { DownAirrowIcon, CloseIcon } from "../../components/common/Icon";
import Modal from "../../components/common/Modal";
import BetListOneCard from "../../components/common/BetListOneCard";
import BetListThreeCard from "../../components/common/BetListThreeCard";
import { useBetResultList } from "../../api/funs/threedbetlist";
import dateFormat from "dateformat";
import { translations } from "../../services/localization";
import emptyList from "../../assets/images/empty_list_icon.png";
import dayjs from "dayjs";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const BetResultList: React.FC = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear(); // Get the current year
  const currentMonth = currentDate.getMonth(); // Get the current month (0-indexed)
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isShow, setIsShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [betResultList, setBetResultList] = useState([]);
  const { data: datalist, getThreeDBetResult,isLoading } = useBetResultList(
    dateFormat(startDate, "yyyy-mm-dd"),
    dateFormat(endDate, "yyyy-mm-dd")
  );
  const content = translations.betResult;
  const common = translations.commom;
  const monthLabels = [
    content.January,
    content.February,
    content.March,
    content.April,
    content.May,
    content.June,
    content.July,
    content.August,
    content.September,
    content.October,
    content.November,
    content.December,
  ];
  const [buttonlabel, setButtonLabel] = useState<string>("Month");

  const openModal = () => {
    setIsModalOpen(true);
    setIsShow(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsShow(false);
    setButtonLabel(monthLabels[currentMonth]);
  };

  useEffect(() => {
    const toDate = new Date(currentYear, currentMonth + 1, 0);
    const fromDate = new Date(currentYear, currentMonth, 1);
    setEndDate(toDate);
    setStartDate(fromDate);
    setBetResultList(datalist?.data?.data);
    setButtonLabel(monthLabels[currentMonth]);
  }, []);

  const chooseMonth = async (value: number) => {
    try {
      setButtonLabel(monthLabels[value]);
      const toDate = new Date(currentYear, value + 1, 0);
      const fromDate = new Date(currentYear, value, 1);
      setEndDate(toDate);
      setStartDate(fromDate);
      const result = await getThreeDBetResult(
        dateFormat(fromDate, "yyyy-mm-dd"),
        dateFormat(toDate, "yyyy-mm-dd")
      );
      if (result.data.isSuccess) {
        setBetResultList(result?.data?.data);
        setIsModalOpen(false);
        setIsShow(false);
      }
    } catch (error) {
      console.error("Error fetching bet history:", error);
      // Handle error, display error message, etc.
    }
  };
  const resultCard = datalist?.data?.data?.map((betresult: any) => {
    return (
      <div className="px-3">
        <div className="col-bet-list-card-title primary-color lh-20 ">
          {monthLabels[betresult.month - 1] + ", " + betresult.year}
        </div>
        <div className="flex flex-wrap justify-between">
          {betresult?.rounds?.map((el: any) => {

            return (
              <div className={`${betresult?.rounds?.length > 1 ? "w-[44%]" : "w-full"}`}>
                <BetListOneCard
                title={monthLabels[betresult.month - 1] + ", " + betresult.year}
                firstdate={el ? el?.resultDate : ""}
                firstnumber={el ? el?.winningNumber : ""}
                tootnumber={el ? el?.tootNumbers : ""}
                  resultDate={el ? el?.resultDate : ""}
                  announceTime={el?.announceTime}
              />
              </div>
            );
          })}
        </div>
      </div>
    );
  });
  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
    {

isLoading?<LoadingSpinner/> : <div className="result-list-container default-height">
<div className="container p-t-20">
  <div className="row d-flex item-center p-t-10">
    <div className="col">
      <div
        className="result-month-button d-flex justify-content-between item-center fs-16 lh-20 fw-500 d-h-42"
        onClick={openModal}
      >
        <span className="p-l-20">{buttonlabel}</span>
        <div className="d-w-30">
          <DownAirrowIcon />
        </div>
      </div>
    </div>
  </div>
  <div className="py-2 list-card">
    {datalist?.data?.data?.length !== 0 ? (
      resultCard
    ) : (
      <div className="container">
        <div className="d-flex justify-content-center p-t-120">
          <img className=" w-14" src={emptyList} alt="empty-list" />
        </div>
        <div className="d-flex row p-t-5">
          <div className="col-12 nodata-title d-flex justify-content-center">
            {common.emptyTitle}
          </div>
        </div>
        <div className="d-flex row p-t-10 p-b-10">
          <div className="col-12 nodata-list d-flex justify-content-center">
            {common.emptyMessage}
          </div>
        </div>
      </div>
    )}
  </div>
</div>
</div>
    }
      <Modal isShow={isShow} isOpen={isModalOpen} onClose={closeModal}>
        <div>
          <div className="text-center d-flex item-center">
            <div className="col-10 fs-18 fw-700 color-defination-color">
              {content.popTitle}
            </div>
            <div className="float-right col-2 col-md-2">
              <button className="popup-close-button" onClick={closeModal}>
                <CloseIcon width="10" />
              </button>
            </div>
          </div>
          <div className=" p-t-10">
            <div>
              <div className="p-t-10 d-flex justify-content-between item-center">
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(0)}
                >
                  <span className="text-sm px-[12px]">{content.January}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(1)}
                >
                  <span className="text-sm px-[12px]">{content.February}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(2)}
                >
                  <span className="text-sm px-[12px]">{content.March}</span>
                </div>
              </div>
              <div className="p-t-10 d-flex justify-content-between item-center">
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(3)}
                >
                  <span className="text-sm px-[12px]">{content.April}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(4)}
                >
                  <span className="text-sm px-[12px]"> {content.May}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(5)}
                >
                  <span className="text-sm px-[12px]"> {content.June}</span>
                </div>
              </div>
              <div className="p-t-10 d-flex justify-content-between item-center">
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(6)}
                >
                  <span className="text-sm px-[12px]">{content.July}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(7)}
                >
                  <span className="text-sm px-[12px]"> {content.August}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(8)}
                >
                  <span className="text-sm px-[12px]">{content.September}</span>
                </div>
              </div>
              <div className="p-t-10 d-flex justify-content-between item-center">
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(9)}
                >
                  <span className="text-xs px-[12px]">{content.October}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(10)}
                >
                  <span className="text-sm px-[12px]">{content.November}</span>
                </div>
                <div
                  className="month-col d-flex d-h-32 "
                  onClick={() => chooseMonth(11)}
                >
                  <span className="text-sm px-[12px]">{content.December}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default BetResultList;
