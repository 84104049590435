import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { UserProfileIcon } from "../../components/common/Icon";
import { backProfileUrl } from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { translations } from "../../services/localization";
interface ParentComponentProps {
  label: string | undefined;
  amount: number;
  isShowProfile: boolean;
  image: string | undefined;
}
const MainWallet: React.FC<ParentComponentProps> = ({
  label,
  amount,
  isShowProfile,
  image,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  //const [backUrl, setBackUrl] = useAtom(backProfileUrl);
  const common = translations.commom;
  const onClick = () => {
    localStorage.setItem("back_url", location.pathname);
    //setBackUrl(location.pathname);
    return navigate("/profile");
  };
  return (
    <div className="container">
      <div className="row">
        <div className="main-wallet w-100 d-h-70 d-flex item-center">
          {isShowProfile ? (
            <div className="flex gap-2">
              <div className="w-12 d-flex item-center justify-content-center">
                {image ? (
                  <img
                    className="w-12 h-12 border rounded-full object-cover"
                    src={image}
                    alt="profile"
                  />
                ) : (
                  <UserProfileIcon></UserProfileIcon>
                )}
              </div>
              <div className="ps-0">
                <div className="row">
                  <div className="col-12 font-Quicksand">
                    <span className="main-wallet-label ">{label}</span>
                    <div className="col">
                      <span
                        className="main-wallet-link text-[10px] sm:text-xs"
                        onClick={onClick}
                      >
                        {common.viewProfile}
                        {">"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="font-Quicksand main-wallet-label">{label}</div>
          )}

          <div className="main-wallet-amount text-sm sm:text-base fw-700 lh-20 font-Quicksand text-nowrap">
            {amount.toLocaleString()} {common.unit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainWallet;
