import BackgroundAdvertisement from "../../assets/images/Rectangle.png";
interface ParentComponentProps {
  bannerimage: string | undefined;
  notiParagraph: string | undefined;
  notisecondParagraph: string | undefined;
  isboldnotisecondParagraph: boolean;
  bannerLink: string | undefined;
}
const Advertisement: React.FC<ParentComponentProps> = ({
  bannerimage,
  notiParagraph,
  notisecondParagraph,
  isboldnotisecondParagraph,
  bannerLink,
}) => {
  return (
    <div className="py-2">
      <div
        className="advertisement-layout image-container"
        onClick={(event) => {
          if (bannerLink) {
            event.preventDefault();
            window.open(bannerLink, "_blank");
          }
        }}
      >
        <img
          src={bannerimage}
          className="advertisement-image"
          alt="Advertisement Image"
        />
        <div className="text-overlay">
          <p className="advertisement-paragraph capitalize text-start font-Quicksand fs-20 fw-400 relative">
            {notiParagraph}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Advertisement;
