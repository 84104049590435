import React, { useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import MainWallet from "../../components/common/MainWallet";
import { useNavigate } from "react-router-dom";
import GridTable from "../../components/common/GridTable";
import Modal from "../../components/common/Modal";
import { EditIcon, DeleteIcon } from "../../components/common/Icon";
import { useWallet } from "../../api/funs/twodhome";
import {
  betListAtom,
  twoDBetAmount,
  roundId,
  isTwoDBetKway,
  twoDKwayBetListAtom,
  IsBig,
  IsSmall,
  isBetNumberExists,
  IsOdd,
  IsEven,
  IsSame,
  IsEE,
  IsEO,
  IsOE,
  IsOO,
  IsZeroInclude,
  IsOneInclude,
  IsTwoInclude,
  IsThreeInclude,
  IsFourInclude,
  IsFiveInclude,
  IsSixInclude,
  IsSevenInclude,
  IsEightInclude,
  IsNineInclude,
  IsHeadZeroInclude,
  IsHeadOneInclude,
  IsHeadTwoInclude,
  IsHeadThreeInclude,
  IsHeadFourInclude,
  IsHeadFiveInclude,
  IsHeadSixInclude,
  IsHeadSevenInclude,
  IsHeadEightInclude,
  IsHeadNineInclude,
  IsTailZeroInclude,
  IsTailOneInclude,
  IsTailTwoInclude,
  IsTailThreeInclude,
  IsTailFourInclude,
  IsTailFiveInclude,
  IsTailSixInclude,
  IsTailSevenInclude,
  IsTailEightInclude,
  IsTailNineInclude,
  IsPowerTypeOne,
  IsPowerTypeTwo,
  IsPowerTypeThree,
  IsPowerTypeFour,
  IsBrakeZero,
  IsBrakeOne,
  IsBrakeTwo,
  IsBrakeThree,
  IsBrakeFour,
  IsBrakeFive,
  IsBrakeSeven,
  IsBrakeSix,
  IsBrakeEight,
  IsBrakeNine,
  IsLimitOne,
  IsLimitTwo,
  IsLimitThree,
  IsLimitFour,
  IsLimitFive,
} from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { postBetNumber, useAgentSettingList } from "../../api/funs/twodbetlist";
import { translations } from "../../services/localization";
import { formatAmount } from "../../util/cn";
import { BeatLoader } from "react-spinners";
import { getLanguageFromStorage } from "../../services/storage";

const BetConfirm: React.FC = () => {
  const navigate = useNavigate();
  const [round, setRoundId] = useAtom(roundId);
  const roundIdSt =
    localStorage.getItem("round") &&
    JSON.parse(localStorage.getItem("round") || "");
  const language = getLanguageFromStorage();
  const { data: agentSetting } = useAgentSettingList(roundIdSt || "");
  const [isShow, setIsShow] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [oldbetnumber, setOldBetNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [oldamount, setOldAmount] = useState<string>("");
  const [betnumber, setBetNumber] = useState<string>("");
  const [index, setIndex] = useState<number>(0);

  const [deletebetnumber, setDeleteBetNumber] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [minAmount, setMinAmount] = useState<number>(0);
  const { data: wallet } = useWallet();
  // use shared Atoms
  const [betlist, setBetListData] = useAtom(betListAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [betAmount, setBetAmount] = useAtom(twoDBetAmount);
  const [isKway, setIsKway] = useAtom(isTwoDBetKway);
  const [kwayBetList, setKwayBetList] = useAtom(twoDKwayBetListAtom);
  //shared data
  const [isBig, setIsBig] = useAtom(IsBig);
  const [isSmall, setIsSmall] = useAtom(IsSmall);
  const [isOdd, setIsOdd] = useAtom(IsOdd);
  const [isEven, setIsEven] = useAtom(IsEven);
  const [isSame, setIsSame] = useAtom(IsSame);
  const [isEE, setIsEE] = useAtom(IsEE);
  const [isEO, setIsEO] = useAtom(IsEO);
  const [isOE, setIsOE] = useAtom(IsOE);
  const [isOO, setIsOO] = useAtom(IsOO);

  const [isZeroInclude, setIsZeroInclude] = useAtom(IsZeroInclude);
  const [isOneInclude, setIsOneInclude] = useAtom(IsOneInclude);
  const [isTwoInclude, setIsTwoInclude] = useAtom(IsTwoInclude);
  const [isThreeInclude, setIsThreeInclude] = useAtom(IsThreeInclude);
  const [isFourInclude, setIsFourInclude] = useAtom(IsFourInclude);
  const [isFiveInclude, setIsFiveInclude] = useAtom(IsFiveInclude);
  const [isSixInclude, setIsSixInclude] = useAtom(IsSixInclude);
  const [isSevenInclude, setIsSevenInclude] = useAtom(IsSevenInclude);
  const [isEightInclude, setIsEightInclude] = useAtom(IsEightInclude);
  const [isNineInclude, setIsNineInclude] = useAtom(IsNineInclude);
  //for head number include
  const [isHeadZeroInclude, setIsHeadZeroInclude] = useAtom(IsHeadZeroInclude);
  const [isHeadOneInclude, setIsHeadOneInclude] = useAtom(IsHeadOneInclude);
  const [isHeadTwoInclude, setIsHeadTwoInclude] = useAtom(IsHeadTwoInclude);
  const [isHeadThreeInclude, setIsHeadThreeInclude] =
    useAtom(IsHeadThreeInclude);
  const [isHeadFourInclude, setIsHeadFourInclude] = useAtom(IsHeadFourInclude);
  const [isHeadFiveInclude, setIsHeadFiveInclude] = useAtom(IsHeadFiveInclude);
  const [isHeadSixInclude, setIsHeadSixInclude] = useAtom(IsHeadSixInclude);
  const [isHeadSevenInclude, setIsHeadSevenInclude] =
    useAtom(IsHeadSevenInclude);
  const [isHeadEightInclude, setIsHeadEightInclude] =
    useAtom(IsHeadEightInclude);
  const [isHeadNineInclude, setIsHeadNineInclude] = useAtom(IsHeadNineInclude);
  //end of head number include
  //for tail number include
  const [isTailZeroInclude, setIsTailZeroInclude] = useAtom(IsTailZeroInclude);
  const [isTailOneInclude, setIsTailOneInclude] = useAtom(IsTailOneInclude);
  const [isTailTwoInclude, setIsTailTwoInclude] = useAtom(IsTailTwoInclude);
  const [isTailThreeInclude, setIsTailThreeInclude] =
    useAtom(IsTailThreeInclude);
  const [isTailFourInclude, setIsTailFourInclude] = useAtom(IsTailFourInclude);
  const [isTailFiveInclude, setIsTailFiveInclude] = useAtom(IsTailFiveInclude);
  const [isTailSixInclude, setIsTailSixInclude] = useAtom(IsTailSixInclude);
  const [isTailSevenInclude, setIsTailSevenInclude] =
    useAtom(IsTailSevenInclude);
  const [isTailEightInclude, setIsTailEightInclude] =
    useAtom(IsTailEightInclude);
  const [isTailNineInclude, setIsTailNineInclude] = useAtom(IsTailNineInclude);
  //end of tail number include

  const [isPowerTypeOne, setIsPowerTypeOne] = useAtom(IsPowerTypeOne);
  const [isPowerTypeTwo, setIsPowerTypeTwo] = useAtom(IsPowerTypeTwo);
  const [isPowerTypeThree, setIsPowerTypeThree] = useAtom(IsPowerTypeThree);
  const [isPowerTypeFour, setIsPowerTypeFour] = useAtom(IsPowerTypeFour);

  //brake
  const [isBrakeZero, setIsBrakeZero] = useAtom(IsBrakeZero);
  const [isBrakeOne, setIsBrakeOne] = useAtom(IsBrakeOne);
  const [isBrakeTwo, setIsBrakeTwo] = useAtom(IsBrakeTwo);
  const [isBrakeThree, setIsBrakeThree] = useAtom(IsBrakeThree);
  const [isBrakeFour, setIsBrakeFour] = useAtom(IsBrakeFour);
  const [isBrakeFive, setIsBrakeFive] = useAtom(IsBrakeFive);
  const [isBrakeSix, setIsBrakeSix] = useAtom(IsBrakeSix);
  const [isBrakeSeven, setIsBrakeSeven] = useAtom(IsBrakeSeven);
  const [isBrakeEight, setIsBrakeEight] = useAtom(IsBrakeEight);
  const [isBrakeNine, setIsBrakeNine] = useAtom(IsBrakeNine);

  //limit
  const [isLimitOne, setIsLimitOne] = useAtom(IsLimitOne);
  const [isLimitTwo, setIsLimitTwo] = useAtom(IsLimitTwo);
  const [isLimitThree, setIsLimitThree] = useAtom(IsLimitThree);
  const [isLimitFour, setIsLimitFour] = useAtom(IsLimitFour);
  const [isLimitFive, setIsLimitFive] = useAtom(IsLimitFive);
  // end shared Atoms //

  const content = translations.betConfirm;
  const tableHeader = translations.tableHeader;
  const commom = translations.commom;
  const betList = translations.betList;

  const { postBet } = postBetNumber(language as string);

  const betst =
    localStorage.getItem("bet") &&
    JSON.parse(localStorage.getItem("bet") || "[{}]");

  const betListSt = betst?.sort(
    (a: any, b: any) => +a?.betNumber - b?.betNumber
  );
  const [totalBetAmount, setTotalBetAmount] = useState<number>(
    betListSt?.reduce((total: any, bet: any) => {
      return total + bet.amount;
    }, 0)
  );

  const updateAmountById = (i: number, number: string, newAmount: number) => {
    let totalbet = totalBetAmount;
    totalbet = totalbet - parseInt(oldamount);
    totalbet = totalbet + newAmount;
    const datalist = betListSt;
    datalist[i].betNumber = number;
    datalist[i].amount = newAmount;
    localStorage.setItem("bet", JSON.stringify(datalist));

    setBetListData(datalist);

    setTotalBetAmount(
      betListSt?.reduce((total: any, bet: any) => {
        return total + bet.amount;
      }, 0)
    );
  };

  const openEditModal = (
    i: number,
    betnumber: string,
    amount: string,
    maxAmount: number,
    minAmount: number
  ) => {
    setIndex(i);
    setOldBetNumber(betnumber);
    setOldAmount(amount);
    setBetNumber(betnumber);
    setAmount(amount);
    setMaxAmount(maxAmount);
    setMinAmount(minAmount);
    setIsEditModalOpen(true);
    setIsShow(true);
  };

  const OnEditModal = () => {
    if (
      !(
        (betnumber.length === 2 &&
          betnumber === oldbetnumber &&
          parseInt(amount) > maxAmount) ||
        parseInt(amount) < minAmount ||
        !amount
      )
    ) {
      updateAmountById(index, betnumber, parseInt(amount));
      setIsEditModalOpen(false);
      setIsShow(false);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setIsShow(false);
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
    setIsShow(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setIsShow(false);
  };

  const closeMessageModal = () => {
    setMessage("");
    setMessageModalOpen(false);
    setIsShow(false);
  };

  const openDeleteModal = (betnumber: string) => {
    setDeleteBetNumber(betnumber);
    setIsDeleteModalOpen(true);
    setIsShow(true);
  };

  const closeDeleteModal = () => {
    setBetListData((prevBetList) => {
      // Filter out the bet with the given betNumber
      const updatedBetlist = prevBetList.filter(
        (bet) => bet.betNumber !== deletebetnumber
      );
      localStorage.setItem("bet", JSON.stringify(updatedBetlist));
      return updatedBetlist;
    });

    // Calculate the new total bet amount
    const deletedBet = betListSt?.find(
      (bet: any) => bet.betNumber === deletebetnumber
    );
    if (deletedBet) {
      const deletedAmount = deletedBet.amount;
      setTotalBetAmount(
        (prevTotalBetAmount) => prevTotalBetAmount - deletedAmount
      );
    }
    if (betlist.length > 1) {
    } else {
      setDeleteBetNumber(betnumber);
      clearData();
      // setMessage(betList.pleaseAtLeastOne);
      // setMessageModalOpen(true);
      navigate("/twoD/betlist/" + round);
    }
    // Close the delete modal
    setIsDeleteModalOpen(false);
    setIsShow(false);
  };
  const handlePostBet = async () => {
    setIsLoading(true);
    try {
      let betarray: { number: string; amount: number }[] = [];
      await Promise.all(
        betListSt?.map(async (bet: any) => {
          const newItem = {
            number: bet.betNumber,
            amount: bet.amount,
          };
          betarray.push(newItem);
        })
      );
      const betData = {
        roundId: roundIdSt,
        betNumbers: betarray,
      };
      const result = await postBet(betData);
      if (result.isSuccess) {
        setIsSuccessModalOpen(true);
        setIsShow(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        setMessage(result.message);
        setMessageModalOpen(true);
        setIsShow(true);
      }
      // Handle successful post
    } catch (error) {
      // Handle error
      setIsLoading(false);
    }
  };
  const openSuccessModal = () => {
    handlePostBet();
  };
  const clearData = () => {
    setBetListData([]);
    localStorage.removeItem("bet");

    setBetAmount(0);
    setIsKway(false);
    setKwayBetList([]);
    setIsBig(false);
    setIsSmall(false);
    setIsOdd(false);
    setIsEven(false);
    setIsSame(false);
    setIsEE(false);
    setIsEO(false);
    setIsOE(false);
    setIsOO(false);
    setIsZeroInclude(false);
    setIsOneInclude(false);
    setIsTwoInclude(false);
    setIsThreeInclude(false);
    setIsFourInclude(false);
    setIsFiveInclude(false);
    setIsSixInclude(false);
    setIsSevenInclude(false);
    setIsEightInclude(false);
    setIsNineInclude(false);
    setIsHeadZeroInclude(false);
    setIsHeadOneInclude(false);
    setIsHeadTwoInclude(false);
    setIsHeadThreeInclude(false);
    setIsHeadFourInclude(false);
    setIsHeadFiveInclude(false);
    setIsHeadSixInclude(false);
    setIsHeadSevenInclude(false);
    setIsHeadEightInclude(false);
    setIsHeadNineInclude(false);
    setIsTailZeroInclude(false);
    setIsTailOneInclude(false);
    setIsTailTwoInclude(false);
    setIsTailThreeInclude(false);
    setIsTailFourInclude(false);
    setIsTailFiveInclude(false);
    setIsTailSixInclude(false);
    setIsTailSevenInclude(false);
    setIsTailEightInclude(false);
    setIsTailNineInclude(false);
    setIsPowerTypeOne(false);
    setIsPowerTypeTwo(false);
    setIsPowerTypeThree(false);
    setIsPowerTypeFour(false);
    setIsBrakeZero(false);
    setIsBrakeOne(false);
    setIsBrakeTwo(false);
    setIsBrakeThree(false);
    setIsBrakeFour(false);
    setIsBrakeFive(false);
    setIsBrakeSix(false);
    setIsBrakeSeven(false);
    setIsBrakeEight(false);
    setIsBrakeNine(false);
    setIsLimitOne(false);
    setIsLimitTwo(false);
    setIsLimitThree(false);
    setIsLimitFour(false);
    setIsLimitFive(false);
  };
  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsShow(false);
    clearData();
    navigate("/twoD/twodhome");
  };
  const headerdata = [
    {
      name: tableHeader.no,
    },
    {
      name: tableHeader.betNumber,
    },
    {
      name: tableHeader.profit,
    },
    {
      name: tableHeader.amount,
    },
    {
      name: tableHeader.editAndDelete,
      class: "w-[120px] sm:pr-2 sm:w-[100px]",
    },
  ];

  const betDisable = () => {
    if (wallet?.data?.data?.userWalletAmount?.amount) {
      if (wallet?.data?.data?.userWalletAmount?.amount > totalBetAmount) {
        return "btn-ok";
      } else {
        return "btn-ok-disable";
      }
    } else {
      return "btn-ok-disable";
    }
  };
  const validBetNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    if (event.target.value.length <= 2) {
      setBetNumber(event.target.value);
    }
  };
  const validBetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setAmount(event.target.value);
  };

  return (
    <AppWrapper
      title={content.headerTitle}
      isback={true}
      isRefresh={true}
      removeScroll
    >
      <div className="p-3">
        <div className="pb-12">
          <GridTable headerdata={headerdata}>
            {betListSt
              ?.sort((a: any, b: any) => +a?.betNumber - b?.betNumber)
              ?.map((bet: any, i: number) => (
                <tr
                  key={i}
                  className="table-row bottom-dashed d-h-50 fw-500 fs-16 lh-20"
                >
                  <td className="text-sm font-normal">{i + 1}</td>
                  <td className="text-sm font-normal">{bet.betNumber}</td>
                  <td className="text-sm font-normal">
                    {agentSetting?.data?.data?.odds}x
                  </td>
                  <td className="text-sm font-normal">
                    {formatAmount(bet.amount)}
                  </td>
                  <td className="text-sm font-normal">
                    <div className="flex items-center justify-center">
                      <EditIcon
                        onClick={() =>
                          openEditModal(
                            i,
                            bet.betNumber,
                            bet.amount,
                            bet.maxAmount,
                            bet.minAmount
                          )
                        }
                        className="p-r-3 icon-button w-[20px] h-[20px] xs:w-[25px] xs:h-[25px] sm:w-[26px] sm:h-[26px]"
                      ></EditIcon>
                      <DeleteIcon
                        onClick={() => openDeleteModal(bet.betNumber)}
                        className="icon-button w-[17px] h-[17px] xs:w-[22px] xs:h-[22px] sm:w-[23px] sm:h-[23px]"
                      ></DeleteIcon>
                    </div>
                  </td>
                </tr>
              ))}

            <tr className="d-h-50">
              <td
                className="fw-700 fs-14 lh-17 text-nowrap black-color p-l-10"
                colSpan={2}
              >
                {tableHeader?.totalBetAmount}
              </td>
              {/* <td></td> */}
              <td></td>
              <td
                className="pr-2 fw-700 fs-14 lh-17 red-color text-nowrap text-align-center"
                scope="col"
              >
                {formatAmount(totalBetAmount)?.toLocaleString()} {commom.unit}
              </td>
            </tr>
          </GridTable>
          {/* <div className="bg-white">
            <div className="flex items-center justify-between p-2 ">
              <td className="text-sm font-normal black-color" colSpan={3}>
                {tableHeader.totalBetAmount}
              </td>
              <td className="text-sm font-normal red-color" colSpan={2}>
                {totalBetAmount?.toLocaleString()} {commom.unit}
              </td>
            </div>
          </div> */}
          <div className="p-t-15">
            <MainWallet
              isShowProfile={false}
              label={commom.wallet}
              amount={
                wallet?.data?.data?.userWalletAmount?.amount
                  ? wallet?.data?.data?.userWalletAmount?.amount
                  : 0
              }
              image={wallet?.data?.data?.userProfile?.imageUrl}
            ></MainWallet>
          </div>
          <div className="p-t-15">
            <button
              className={`${betDisable()} w-100 d-h-44 d-flex justify-content-center item-center gap-x-2 ${
                isLoading ? "opacity-30" : ""
              }`}
              onClick={openSuccessModal}
              disabled={isLoading}
            >
              {isLoading && <BeatLoader color="#fff" size={10} />}

              <span className="ok-button-label fs-16 fw-600 lh-20">
                {isLoading ? commom.processing : commom.buttonBet}
              </span>
            </button>
          </div>
        </div>
      </div>
      <Modal
        isShow={isShow}
        isOpen={isEditModalOpen}
        onClose={closeEditModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div>
          <div className="text-red-600 text-base text-center mb-3">
            {content.editTitle}
          </div>
          {parseInt(amount) > maxAmount ? (
            <div className="px-2 row d-h-70">
              <div className="col primary-color">
                {content.betMaxMessage} {maxAmount}.
              </div>
            </div>
          ) : (
            <></>
          )}{" "}
          {parseInt(amount) < minAmount ? (
            <div className="px-2 row d-h-70">
              <div className="col primary-color">
                {content.betMinMessage} {minAmount}.
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row d-h-50">
            <div className="col">
              <input
                type="number"
                className="common-text-box d-h-42 w-100 no-arrow"
                value={betnumber}
                disabled
              />
            </div>
          </div>
          <div className="relative">
            <input
              type="number"
              className="w-full d-h-42 pl-3 pr-[60px] rounded-lg no-arrow !border !border-gray-350 focus:!border-red-50"
              value={amount}
              onChange={validBetAmount}
            />
            <span className="absolute top-0 bottom-0 right-3 flex items-center text-yellow-100">
              {commom.unit}
            </span>
          </div>
          <div className="row d-h-50 d-flex item-center">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeEditModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button
                className={`uppercase ${
                  (betnumber.length === 2 &&
                    betnumber === oldbetnumber &&
                    parseInt(amount) > maxAmount) ||
                  parseInt(amount) < minAmount ||
                  !amount
                    ? "btn-ok-disable"
                    : "btn-ok"
                } `}
                onClick={OnEditModal}
              >
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.confirmTitle}</div>
          </div>
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col">{content.deleteMessage}</div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeDeleteModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button className="uppercase btn-ok" onClick={closeDeleteModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isConfirmModalOpen}
        onClose={closeDeleteModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.confirmTitle}</div>
          </div>
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col">{content.deleteMessage}</div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeDeleteModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button className="uppercase btn-ok" onClick={closeDeleteModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.successTitle}</div>
          </div>
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col">{content.goodLuck}...</div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className=" d-h-42">
              <button
                className="uppercase btn-ok w-364"
                onClick={closeSuccessModal}
              >
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={messageModalOpen}
        onClose={closeMessageModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{commom.message}</div>
          </div>
          <div className="text-center row d-h-70 d-flex item-center">
            <div className="col primary-color">{message}</div>
          </div>
          <div className="row d-h-50 d-flex item-center">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeMessageModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button className="uppercase btn-ok" onClick={closeMessageModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default BetConfirm;
