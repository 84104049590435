import axios from "axios";
import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getThreeDBetList = async (
  roundId: string,
  startnumber: string
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.three_d_bet_list_api}` +
      "?roundId=" +
      roundId +
      "&start_num=" +
      startnumber
  );
};

export const getThreeDBetResultList = async (from: string, to: string) => {
  //return axiosInstance.get(`${URL}${API_NAME.bet_result_threed_api}`+'?from=2024-02-11');
  return axiosInstance.get(
    `${URL}${API_NAME.bet_result_threed_api}` + "?from=" + from + "&to=" + to
  );
};

export const postThreeDBet = async (betdata: any, language?: string) => {
  try {
    const response = await axiosInstance.post(
      `${URL}${API_NAME.bet_threed_api}?language=${language}`,
      betdata
    );
    return response.data; // Assuming you want to return the response data
  } catch (error) {
    console.error("Error occurred while posting the bet:", error);
    throw error; // Propagate the error up to the caller
  }
};

export const getThreeDHistoryBetList = async (
  fromdate: string,
  todate: string
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.three_bet_history_api}` +
      "?from=" +
      fromdate +
      "&to=" +
      todate
  );
};

export const getThreeDHistoryBetDetail = async (
  id: string,
  pageSize: number,
  pageNumber: number
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.three_bet_history_detail}` +
      id +
      "/details" +
      "?pageSize=" +
      pageSize +
      "&pageNumber=" +
      pageNumber
  );
};

export const getThreeDBoxWinnerList = async () => {
  return axiosInstance.get(`${URL}${API_NAME.three_bet_winner_box_api}`);
};

export const getThreeDStraightWinnerList = async () => {
  return axiosInstance.get(`${URL}${API_NAME.three_bet_winner_straight_api}`);
};

export const getThreeDCollectDetail = async (
  id: string,
  type: string,
  status: string
) => {
  return axiosInstance.get(
    `${URL}${API_NAME.three_bet_collect_detail_api}` +
      id +
      "?type=" +
      type +
      "&status=" +
      status
  );
};

export const getThreeDCollectTotal = async (type: boolean) => {
  if (type) {
    return axiosInstance.get(`${URL}${API_NAME.three_bet_colect_all_api}`);
  } else {
    return axiosInstance.get(`${URL}${API_NAME.three_bet_colect_history_api}`);
  }
};

export const patchCollect = async (id: any, type: string) => {
  try {
    const response = await axiosInstance.patch(
      `${URL}${API_NAME.three_bet_collect_patch_api}` + id + "?type=" + type
    );
    return response.data; // Assuming you want to return the response data
  } catch (error) {
    console.error("Error occurred while posting the bet:", error);
    throw error; // Propagate the error up to the caller
  }
};

export const getThreeDLastRound = async () => {
  return axiosInstance.get(`${URL}${API_NAME.three_bet_last_round}`);
};
