import React from "react";
import {
  WinnerIcon,
  CollectIcon,
  ResultIcon,
  HistoryIcon,
  HolidayIcon,
} from "../../components/common/Icon";
import { strings, translations } from "../../services/localization";
import { Link } from "react-router-dom";
interface ParentComponentProps {
  label: string | undefined;
}
const TwoDIconLayout: React.FC<ParentComponentProps> = ({ label }) => {
  const content = translations.twoDLayout;
  return (
    <div className="container py-2">
      <div className=" main-wallet row d-flex justify-content-between p-2">
        <Link
          to="/twoD/betwinner"
          className="p-0 col bet-winner-icon remove-underline-a p-t-5 d-flex item-center flex-column"
        >
          <div>
            <WinnerIcon />
          </div>
          <div className="w-500 lh-15 p-t-10">
            <span className="primary-color  text-[9px] sm:text-xs text-nowrap">
              {content.winner}
            </span>
          </div>
        </Link>
        <Link
          to="/twoD/bethistory"
          className="p-0 col bet-history-icon remove-underline-a p-t-5 d-flex item-center flex-column"
        >
          <div>
            <HistoryIcon />
          </div>
          <div className="w-500 lh-15 p-t-10">
            <span className="primary-color  text-[10px] sm:text-xs text-nowrap">
              {content.betHistory}
            </span>
          </div>
        </Link>
        <Link
          to="/twoD/collectlist"
          className="p-0 col bet-collect-icon remove-underline-a p-t-5 d-flex item-center flex-column"
        >
          <div>
            <CollectIcon />
          </div>
          <div className="w-500 lh-15 p-t-10">
            <span className="primary-color  text-[10px] sm:text-xs text-nowrap">
              {content.collect}
            </span>
          </div>
        </Link>
        <Link
          to="/twoD/betresultlist"
          className="p-0 col bet-result-icon remove-underline-a p-t-5 d-flex item-center flex-column"
        >
          <div>
            <ResultIcon />
          </div>
          <div className="w-500 lh-15 p-t-10">
            <span className="primary-color  text-[10px] sm:text-xs text-nowrap">
              {content.result}
            </span>
          </div>
        </Link>
        <Link
          to="/twoD/betholiday"
          className="p-0 col bet-holiday-icon remove-underline-a p-t-5 d-flex item-center flex-column"
        >
          <div>
            <HolidayIcon />
          </div>
          <div className="w-500 lh-15 p-t-10">
            <span className="primary-color  text-[10px] sm:text-xs text-nowrap">
              {content.holiday}
            </span>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default TwoDIconLayout;
