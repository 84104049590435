import { Link } from "react-router-dom";
import {
  WinnerIcon,
  CollectIcon,
  ResultIcon,
  HistoryIcon,
} from "../../components/common/Icon";
import { translations } from "../../services/localization";
interface ParentComponentProps {
  label: string | undefined;
}
const ThreeDIconLayout: React.FC<ParentComponentProps> = ({ label }) => {
  const content = translations.twoDLayout;

  return (
    <div className="container p-t-15">
      <div className="row">
        <div className="main-wallet d-h-95 pt-15">
          <div className="row">
            <Link
              to="/threeD/betwinner"
              className="col remove-underline-a p-t-16 d-flex item-center flex-column bet-winner-icon p-0"
            >
              <div>
                <WinnerIcon />
              </div>
              <div className="w-500 lh-15 p-t-10">
                <span className="primary-color text-[10px] sm:text-xs">
                  {content.winner}
                </span>
              </div>
            </Link>
            <Link
              to="/threeD/collectlist"
              className="col remove-underline-a p-t-16 d-flex item-center flex-column bet-winner-icon p-0"
            >
              <div>
                <CollectIcon />
              </div>
              <div className="w-500 lh-15 p-t-10">
                <span className="primary-color text-[10px] sm:text-xs">
                  {content.collect}
                </span>
              </div>
            </Link>
            <Link
              to="/threeD/betresultlist"
              className="col remove-underline-a p-t-16 d-flex item-center flex-column bet-winner-icon p-0"
            >
              <div>
                <ResultIcon />
              </div>
              <div className=" w-500 lh-15 p-t-10">
                <span className="primary-color text-[10px] sm:text-xs">
                  {content.result}{" "}
                </span>
              </div>
            </Link>
            <Link
              to="/threeD/bethistory"
              className="col remove-underline-a p-t-16 d-flex item-center flex-column bet-winner-icon p-0"
            >
              <div>
                <HistoryIcon />
              </div>
              <div className=" w-500 lh-15 p-t-10">
                <span className="primary-color text-[10px] sm:text-xs dot-label">
                  {content.betHistory}
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThreeDIconLayout;
