import { useNavigate } from "react-router-dom";
import dateFormat from "dateformat";
import { translations } from "../../services/localization";
interface ParentComponentProps {
  title: string | undefined;
  number: { number: string; amount: number }[];
  date: string | undefined;
  amount: number | 0;
  to: string;
}
const HistoryCardThreeD: React.FC<ParentComponentProps> = ({
  title,
  number,
  date,
  amount,
  to,
}) => {
  const navigate = useNavigate();
  const showNumber = number?.map((item) => item?.number)?.join(", ");

  const common = translations.commom;
  const onClick = () => {
    return navigate(to);
  };
  return (
    <div className="container history-card-two-d d-h-130 " onClick={onClick}>
      <div className="">
        <div className="flex justify-center item-center">
          <div className="round-title d-w-100 d-h-42 d-flex item-center">
            <span>{title}</span>
          </div>
        </div>
        <div>
          <div className="row d-flex justify-content-center d-h-100 pt-3 item-center text-center">
            <div className="col-history-card-number text-2xl font-medium ">
              {showNumber}
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center">
          <div className="text-xs">
            {dateFormat(date, "mmm dd,yyyy , hh:MM:ss TT")}
          </div>
          <div className="primary-color text-sm">
            {amount?.toLocaleString()} {common.unit}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoryCardThreeD;
