import Button from "../../components/common/Button";
import React, { useState } from "react";
import { cn } from "../../util/cn";
interface ParentComponentProps {
  is2D: boolean;
  firstto: string;
  secondto: string;
  isFirstButton: boolean | undefined;
  isSecondButton: boolean | undefined;
  firstbuttonLabel: string | undefined;
  firstButtonClass: string | undefined;
  firstOnClick: () => void;
  secondOnClick: () => void;
  firstButtonLabelClass: string | undefined;
  secondbuttonLabel: string | undefined;
  secondButtonClass: string | undefined;
  secondButtonLabelClass: string | undefined;
  className?: string;
  isDisable?: boolean;
}
const Footer: React.FC<ParentComponentProps> = ({
  is2D,
  firstto,
  secondto,
  isFirstButton,
  isSecondButton,
  firstOnClick,
  secondOnClick,
  isDisable,
  firstbuttonLabel,
  firstButtonClass,
  firstButtonLabelClass,
  secondbuttonLabel,
  secondButtonClass,
  secondButtonLabelClass,
  className,
}) => {
  return (
    <div className={cn("footer p-y-15 cursor-pointer", className)}>
      {isFirstButton && isSecondButton ? (
        <div className="container">
          <div className="d-flex row">
            <div className="col-6">
              <Button
                to={firstto}
                buttonOnClick={firstOnClick}
                label={firstbuttonLabel}
                buttonClass={firstButtonClass}
                buttonLabelClass={firstButtonLabelClass}
                isAction={false}
              />
            </div>
            <div className="col-6">
              <Button
                to={secondto}
                buttonOnClick={secondOnClick}
                label={secondbuttonLabel}
                buttonClass={secondButtonClass}
                buttonLabelClass={secondButtonLabelClass}
                isAction={isSecondButton}
                isDisabled={isDisable}
              />
            </div>
          </div>
        </div>
      ) : isFirstButton ? (
        <div className="container">
          <div className="d-flex row">
            <div className="col-12">
              <Button
              isDisabled={isDisable}
                to={firstto}
                buttonOnClick={firstOnClick}
                label={firstbuttonLabel}
                buttonClass={firstButtonClass}
                buttonLabelClass={firstButtonLabelClass}
                isAction={is2D}
              />
            </div>
          </div>
        </div>
      ) : isSecondButton ? (
        <Button
          to={secondto}
          buttonOnClick={secondOnClick}
          label={secondbuttonLabel}
          buttonClass={secondButtonClass}
          buttonLabelClass={secondButtonLabelClass}
          isAction={false}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Footer;
