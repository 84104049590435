import './App.css';
import RootRouter from "./app/routes";
import "bootstrap/dist/css/bootstrap.min.css";
import 'react-datepicker/dist/react-datepicker.css';
import { AppProvider } from './contexts/appContext';

function App() {
  return (
    <AppProvider>
      <RootRouter/>
    </AppProvider>
  );
}

export default App;
