import React, { ReactNode } from "react";
import Header from "./mainlayouts/header";
import { cn } from "../util/cn";

type Props = {
  children?: ReactNode;
  title: string;
  isback: boolean | false;
  isRefresh: boolean | false;
  removeScroll?: boolean;
};
const AppWrapper = (props: Props) => {
  const { children, title, isback, isRefresh } = props;

  return (
    <div className="backgourd-black">
      <div className="main-layout" data-bs-spy="scroll">
        <Header title={title} isback={isback} isRefresh={isRefresh} />
        <div className={cn(!props.removeScroll && "scroll-container")}>
          {/* className="scroll-container */}
          {children}
        </div>
      </div>
    </div>
  );
};
export default AppWrapper;
