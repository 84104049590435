import useSWR, { mutate } from "swr";
import { API_NAME } from "../../constant";
import {
  getThreeDBetList,
  getThreeDBetResultList,
  postThreeDBet,
  getThreeDHistoryBetList,
  getThreeDHistoryBetDetail,
  getThreeDStraightWinnerList,
  getThreeDBoxWinnerList,
  getThreeDCollectTotal,
  getThreeDCollectDetail,
  getThreeDLastRound,
  patchCollect,
} from "../../threedbetlist";

export const useBetList = (roundId: string, startnumber: string) => {
  return useSWR(
    [API_NAME.three_d_bet_list_api, roundId, startnumber], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDBetList(roundId, startnumber)
  );
};
export const useBetResultList = (from: string, to: string) => {
  const fetcher = () => getThreeDBetResultList(from, to);

  // Pass an array containing the API endpoint and the id as the key
  const { data, error } = useSWR(
    [API_NAME.bet_result_threed_api, from, to],
    fetcher
  );
  const getThreeDBetResult = async (fromdate: any, todate: any) => {
    try {
      const responseData = await getThreeDBetResultList(fromdate, todate);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    getThreeDBetResult,
  };
};

export const postBetNumber = (language?: string) => {
  const postBet = async (betdata: any) => {
    try {
      const responseData = await postThreeDBet(betdata, language);
      mutate(API_NAME.bet_threed_api);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };
  return {
    postBet,
  };
};

export const useHistoryBetList = (fromdate: string, todate: string) => {
  const fetcher = () => getThreeDHistoryBetList(fromdate, todate);

  // Pass an array containing the API endpoint and the id as the key
  const { data, error } = useSWR(
    [API_NAME.two_bet_history_api, fromdate, todate],
    fetcher
  );
  const getHistoryBetList = async (fromdate: string, todate: string) => {
    try {
      const responseData = await getThreeDHistoryBetList(fromdate, todate);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
    getHistoryBetList,
  };
};

export const useHistoryBetDetail = (
  id: string,
  pageSize: number,
  pageNumber: number
) => {
  return useSWR(
    [API_NAME.three_bet_history_detail, id, pageSize, pageNumber], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDHistoryBetDetail(id, pageSize, pageNumber)
  );
};

export const useStraightWinnerList = () => {
  return useSWR(
    [API_NAME.three_bet_winner_straight_api], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDStraightWinnerList()
  );
};

export const useBoxWinnerList = () => {
  return useSWR(
    [API_NAME.three_bet_winner_box_api], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDBoxWinnerList()
  );
};

export const useCollectAllList = (type: boolean) => {
  let api = "";
  if (type) {
    api = API_NAME.three_bet_colect_all_api;
  } else {
    api = API_NAME.three_bet_colect_history_api;
  }
  return useSWR(
    [type ? "D" : "F"], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDCollectTotal(type)
  );
};

export const useCollectDetail = (id: string, type: string, status: string) => {
  return useSWR(
    [API_NAME.three_bet_history_detail, id, type, status], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDCollectDetail(id, type, status)
  );
};

export const patchCollector = () => {
  const patchCollectorFunc = async (id: string, type: string) => {
    try {
      const responseData = await patchCollect(id, type);
      mutate(API_NAME.three_bet_collect_patch_api);
      return responseData;
    } catch (error) {
      console.error("Error occurred while posting the bet:", error);
      // Handle error
      throw error; // Propagate the error up to the caller if needed
    }
  };

  return {
    patchCollectorFunc,
  };
};

export const useThreeDLastRound = () => {
  return useSWR(
    [API_NAME.three_bet_last_round], // Pass an array containing the API endpoint and the id as the key
    () => getThreeDLastRound()
  );
};
