import { useNavigate } from "react-router-dom";
interface ParentComponentProps {
  to: string;
  label: string | undefined;
  buttonClass: string | undefined;
  buttonLabelClass: string | undefined;
  isAction: boolean;
  isDisabled?: boolean;
  buttonOnClick: () => void;
}
const Button: React.FC<ParentComponentProps> = ({
  to,
  label,
  buttonClass,
  buttonLabelClass,
  isAction,
  isDisabled,
  buttonOnClick,
}) => {
  const navigate = useNavigate();
  const onClick = () => {
    if (isAction) {
      buttonOnClick();
    } else {
      return navigate(to);
    }
  };
  return (
    <div
      className={`${buttonClass ? buttonClass : ""} ${
        isDisabled ? "buton-disable cursor-not-allowed " : "cursor-pointer"
      }`}
      onClick={onClick}
    >
      <div
        className={`remove-underline-a ${
          buttonLabelClass ? buttonLabelClass : ""
        } ${isDisabled ? " cursor-not-allowed " : "cursor-pointer"}`}
      >
        <p className="font-Quicksand">{label}</p>
      </div>
    </div>
  );
};

export default Button;
