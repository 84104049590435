import React, { useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import FooterButton from "../../layout/mainlayouts/footerButton";
import Modal from "../../components/common/Modal";
import {
  CircleColorIcon,
  HundardPercentIcon,
  NinetyPercentIcon,
  EightyPercentIcon,
  SeventyPercentIcon,
  SixtyPercentIcon,
  FiftyPercentIcon,
  FoutyPercentIcon,
  TwentyPercentIcon,
  ThirtyPercentIcon,
} from "../../components/common/Icon";
import { useParams, useNavigate } from "react-router-dom";
import { useBetList, useAgentSettingList } from "../../api/funs/twodbetlist";
import { useWallet } from "../../api/funs/twodhome";
import "../../style/Betlist.scss";
import {
  twoDBetAmount,
  IsBig,
  IsSmall,
  IsOdd,
  IsEven,
  IsSame,
  IsEE,
  IsEO,
  IsOE,
  IsOO,
  IsZeroInclude,
  IsOneInclude,
  IsTwoInclude,
  IsThreeInclude,
  IsFourInclude,
  IsFiveInclude,
  IsSixInclude,
  IsSevenInclude,
  IsEightInclude,
  IsNineInclude,
  IsHeadZeroInclude,
  IsHeadOneInclude,
  IsHeadTwoInclude,
  IsHeadThreeInclude,
  IsHeadFourInclude,
  IsHeadFiveInclude,
  IsHeadSixInclude,
  IsHeadSevenInclude,
  IsHeadEightInclude,
  IsHeadNineInclude,
  IsTailZeroInclude,
  IsTailOneInclude,
  IsTailTwoInclude,
  IsTailThreeInclude,
  IsTailFourInclude,
  IsTailFiveInclude,
  IsTailSixInclude,
  IsTailSevenInclude,
  IsTailEightInclude,
  IsTailNineInclude,
  IsPowerTypeOne,
  IsPowerTypeTwo,
  IsPowerTypeThree,
  IsPowerTypeFour,
  IsBrakeZero,
  IsBrakeOne,
  IsBrakeTwo,
  IsBrakeThree,
  IsBrakeFour,
  IsBrakeFive,
  IsBrakeSeven,
  IsBrakeSix,
  IsBrakeEight,
  IsBrakeNine,
  IsLimitOne,
  IsLimitTwo,
  IsLimitThree,
  IsLimitFour,
  IsLimitFive,
  betListAtom,
  isBetNumberExists,
  findMaxAmountById,
  findMinAmountById,
  roundId,
  isTwoDBetKway,
  twoDKwayBetListAtom,
  removeBetNumberFromArray,
  IsQuickSelect,
  findStatusById,
  oldBetListAtom,
  findPercentById,
  nextBetListAtom,
  IsR,
} from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { translations } from "../../services/localization";
import { cn } from "../../util/cn";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { useWindowSize } from "../../hooks/useWindowSize";

const Betlist: React.FC = () => {
  const { round_id } = useParams<{ round_id: string }>();
  const { data: roundList, error, isLoading } = useBetList(round_id || "");
  const { data: wallet } = useWallet();
  const { data: agentSetting } = useAgentSettingList(round_id || "");
  const content = translations.betList;
  const commom = translations.commom;
  const { windowWidth } = useWindowSize();



  // shared data atom
  const [isQuickSelect, setIsQuickSelect] = useAtom(IsQuickSelect);
  const [round, setRoundId] = useAtom(roundId);
  const [betlist, setBetListData] = useAtom(betListAtom);
  const [oldBetList, setOldBetList] = useAtom(oldBetListAtom);
  const [nextBetList, setNextBetList] = useAtom(nextBetListAtom);
  const [isKway, setIsKway] = useAtom(isTwoDBetKway);
  const [kwayBetList, setKwayBetList] = useAtom(twoDKwayBetListAtom);

  const [betAmount, setBetAmount] = useAtom(twoDBetAmount);
  //shared data
  const [isBig, setIsBig] = useAtom(IsBig);
  const [isSmall, setIsSmall] = useAtom(IsSmall);
  const [isOdd, setIsOdd] = useAtom(IsOdd);
  const [isEven, setIsEven] = useAtom(IsEven);
  const [isSame, setIsSame] = useAtom(IsSame);
  const [isEE, setIsEE] = useAtom(IsEE);
  const [isEO, setIsEO] = useAtom(IsEO);
  const [isOE, setIsOE] = useAtom(IsOE);
  const [isOO, setIsOO] = useAtom(IsOO);

  const [isZeroInclude, setIsZeroInclude] = useAtom(IsZeroInclude);
  const [isOneInclude, setIsOneInclude] = useAtom(IsOneInclude);
  const [isTwoInclude, setIsTwoInclude] = useAtom(IsTwoInclude);
  const [isThreeInclude, setIsThreeInclude] = useAtom(IsThreeInclude);
  const [isFourInclude, setIsFourInclude] = useAtom(IsFourInclude);
  const [isFiveInclude, setIsFiveInclude] = useAtom(IsFiveInclude);
  const [isSixInclude, setIsSixInclude] = useAtom(IsSixInclude);
  const [isSevenInclude, setIsSevenInclude] = useAtom(IsSevenInclude);
  const [isEightInclude, setIsEightInclude] = useAtom(IsEightInclude);
  const [isNineInclude, setIsNineInclude] = useAtom(IsNineInclude);
  //for head number include
  const [isHeadZeroInclude, setIsHeadZeroInclude] = useAtom(IsHeadZeroInclude);
  const [isHeadOneInclude, setIsHeadOneInclude] = useAtom(IsHeadOneInclude);
  const [isHeadTwoInclude, setIsHeadTwoInclude] = useAtom(IsHeadTwoInclude);
  const [isHeadThreeInclude, setIsHeadThreeInclude] =
    useAtom(IsHeadThreeInclude);
  const [isHeadFourInclude, setIsHeadFourInclude] = useAtom(IsHeadFourInclude);
  const [isHeadFiveInclude, setIsHeadFiveInclude] = useAtom(IsHeadFiveInclude);
  const [isHeadSixInclude, setIsHeadSixInclude] = useAtom(IsHeadSixInclude);
  const [isHeadSevenInclude, setIsHeadSevenInclude] =
    useAtom(IsHeadSevenInclude);
  const [isHeadEightInclude, setIsHeadEightInclude] =
    useAtom(IsHeadEightInclude);
  const [isHeadNineInclude, setIsHeadNineInclude] = useAtom(IsHeadNineInclude);
  //end of head number include
  //for tail number include
  const [isTailZeroInclude, setIsTailZeroInclude] = useAtom(IsTailZeroInclude);
  const [isTailOneInclude, setIsTailOneInclude] = useAtom(IsTailOneInclude);
  const [isTailTwoInclude, setIsTailTwoInclude] = useAtom(IsTailTwoInclude);
  const [isTailThreeInclude, setIsTailThreeInclude] =
    useAtom(IsTailThreeInclude);
  const [isTailFourInclude, setIsTailFourInclude] = useAtom(IsTailFourInclude);
  const [isTailFiveInclude, setIsTailFiveInclude] = useAtom(IsTailFiveInclude);
  const [isTailSixInclude, setIsTailSixInclude] = useAtom(IsTailSixInclude);
  const [isTailSevenInclude, setIsTailSevenInclude] =
    useAtom(IsTailSevenInclude);
  const [isTailEightInclude, setIsTailEightInclude] =
    useAtom(IsTailEightInclude);
  const [isTailNineInclude, setIsTailNineInclude] = useAtom(IsTailNineInclude);
  //end of tail number include

  const [isPowerTypeOne, setIsPowerTypeOne] = useAtom(IsPowerTypeOne);
  const [isPowerTypeTwo, setIsPowerTypeTwo] = useAtom(IsPowerTypeTwo);
  const [isPowerTypeThree, setIsPowerTypeThree] = useAtom(IsPowerTypeThree);
  const [isPowerTypeFour, setIsPowerTypeFour] = useAtom(IsPowerTypeFour);

  //brake
  const [isBrakeZero, setIsBrakeZero] = useAtom(IsBrakeZero);
  const [isBrakeOne, setIsBrakeOne] = useAtom(IsBrakeOne);
  const [isBrakeTwo, setIsBrakeTwo] = useAtom(IsBrakeTwo);
  const [isBrakeThree, setIsBrakeThree] = useAtom(IsBrakeThree);
  const [isBrakeFour, setIsBrakeFour] = useAtom(IsBrakeFour);
  const [isBrakeFive, setIsBrakeFive] = useAtom(IsBrakeFive);
  const [isBrakeSix, setIsBrakeSix] = useAtom(IsBrakeSix);
  const [isBrakeSeven, setIsBrakeSeven] = useAtom(IsBrakeSeven);
  const [isBrakeEight, setIsBrakeEight] = useAtom(IsBrakeEight);
  const [isBrakeNine, setIsBrakeNine] = useAtom(IsBrakeNine);

  //limit
  const [isLimitOne, setIsLimitOne] = useAtom(IsLimitOne);
  const [isLimitTwo, setIsLimitTwo] = useAtom(IsLimitTwo);
  const [isLimitThree, setIsLimitThree] = useAtom(IsLimitThree);
  const [isLimitFour, setIsLimitFour] = useAtom(IsLimitFour);
  const [isLimitFive, setIsLimitFive] = useAtom(IsLimitFive);
  // end shared Atoms //
  const [isShow, setIsShow] = useState(false);
  const [rvalue, setRValue] = useState<string>("");
  const [rMaxAmount, setRMaxAmount] = useState<number>(0);
  const [rAmount, setRAmount] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRModalOpen, setIsRModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [message, setMessage] = useState<string>("");
  const [lastRoom, setLastRoom] = useState<number>(0);
  const [isR, setIsR] = useAtom(IsR);
  const [rTime, setRTime] = useState<number>(0);

  const navigate = useNavigate();

  const calculateBorderColor = (percentValue: number) => {
    if (percentValue <= 10) {
      return "btn-number-center-container-ten";
    } else if (percentValue <= 20) {
      return "btn-number-center-container-twenty";
    } else if (percentValue <= 30) {
      return "btn-number-center-container-thirty";
    } else if (percentValue <= 40) {
      return "btn-number-center-container-fouty";
    } else if (percentValue <= 50) {
      return "btn-number-center-container-fifty";
    } else if (percentValue <= 60) {
      return "btn-number-center-container-sixty";
    } else if (percentValue <= 70) {
      return "btn-number-center-container-seventy";
    } else if (percentValue <= 80) {
      return "btn-number-center-container-eighty";
    } else if (percentValue <= 90) {
      return "btn-number-center-container-ninty";
    } else if (percentValue <= 100) {
      return "btn-number-center-container-hundard";
    } else {
      return "btn-number-center-container-ten";
    }
  };
  const calculateDisable = (status: boolean, percentValue: number) => {
    if (!status) {
      return "disable-background";
    }
    if (+percentValue >= 100) {
      return "disable-background";
    }
  };

  const isKBetNumber = (number: string) => {
    const bet = betlist?.find((bet: any) => bet?.betNumber === number);
    return bet ? bet.isKway : false;
  };
  const isRBetNumber = (number: string) => {
    const bet = betlist?.find((bet: any) => bet?.betNumber === number);
    return bet ? bet.isR : false;
  };
  const isSelectedBetNumber = (value: string) => {
    if (
      isBetNumberExists(value, betlist) &&
      betlist?.find((_d: any) => _d?.betNumber === value)?.percent !== "100.00"
    ) {
      return "selected";
    } else {
      return "";
    }
  };
  const reverseString = (input: string): string => {
    return input.split("").reverse().join("");
  };

  const changeBetArray = (
    Event: string,
    maxAmount: number,
    minAmount: number,
    status: boolean,
    percentValue: string
  ) => {
    if (status && parseInt(percentValue) < 100) {
      if (!isBetNumberExists(Event, betlist)) {
        const newItem = {
          betNumber: Event,
          isSelected: false,
          status: true,
          isR: false,
          isKway: false,
          amount: 0,
          maxAmount: maxAmount,
          minAmount: minAmount,
          percent: percentValue,
        };
        let reverseBet = reverseString(Event);
        //setLastRoom(betlist.length);
        if (!isBetNumberExists(reverseBet, betlist)) {
          setRMaxAmount(findMaxAmountById(reverseBet, roundList?.data?.data));
          setRValue(reverseBet);
        }

        const updatedBetList = betlist ? [...betlist, newItem] : [newItem];
        if (oldBetList.length > 0) {
          const updatedNextBetList = [...nextBetList, newItem];
          // setNextBetList(updatedNextBetList);

          setNextBetList(updatedNextBetList);
        }
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setBetListData(updatedBetList);
      } else {
        const removeBetObjList = removeBetNumberFromArray(Event, betlist);
        if (oldBetList.length > 0 && nextBetList.length > 0) {
          if (isBetNumberExists(Event, nextBetList)) {
            const removeNextBetObjList = removeBetNumberFromArray(
              Event,
              nextBetList
            );
            setNextBetList(removeNextBetObjList);
          }
        }
        setRAmount(0);
        //setLastRoom(0);
        setRValue("");
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
      }
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
    setIsShow(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsShow(false);
  };

  const openRModal = () => {
    if (
      !(CheckQuickSelect() || isKway || betlist?.length < 1 || checkNotRNumber())
    ) {
      setIsRModalOpen(true);
      setIsShow(true);
    }
  };
  const clearAll = () => {
    setRTime(0);
    setLastRoom(0);
    setBetListData([]);
    setOldBetList([]);
    setNextBetList([]);
    setRValue("");
    setRAmount(0);
    setMessage("");
    setKwayBetList([]);
    setBetAmount(0);
    setIsKway(false);
    setIsBig(false);
    setIsSmall(false);
    setIsOdd(false);
    setIsEven(false);
    setIsSame(false);
    setIsEE(false);
    setIsEO(false);
    setIsOE(false);
    setIsOO(false);
    setIsZeroInclude(false);
    setIsOneInclude(false);
    setIsTwoInclude(false);
    setIsThreeInclude(false);
    setIsFourInclude(false);
    setIsFiveInclude(false);
    setIsSixInclude(false);
    setIsSevenInclude(false);
    setIsEightInclude(false);
    setIsNineInclude(false);
    setIsHeadZeroInclude(false);
    setIsHeadOneInclude(false);
    setIsHeadTwoInclude(false);
    setIsHeadThreeInclude(false);
    setIsHeadFourInclude(false);
    setIsHeadFiveInclude(false);
    setIsHeadSixInclude(false);
    setIsHeadSevenInclude(false);
    setIsHeadEightInclude(false);
    setIsHeadNineInclude(false);
    setIsTailZeroInclude(false);
    setIsTailOneInclude(false);
    setIsTailTwoInclude(false);
    setIsTailThreeInclude(false);
    setIsTailFourInclude(false);
    setIsTailFiveInclude(false);
    setIsTailSixInclude(false);
    setIsTailSevenInclude(false);
    setIsTailEightInclude(false);
    setIsTailNineInclude(false);
    setIsPowerTypeOne(false);
    setIsPowerTypeTwo(false);
    setIsPowerTypeThree(false);
    setIsPowerTypeFour(false);
    setIsBrakeZero(false);
    setIsBrakeOne(false);
    setIsBrakeTwo(false);
    setIsBrakeThree(false);
    setIsBrakeFour(false);
    setIsBrakeFive(false);
    setIsBrakeSix(false);
    setIsBrakeSeven(false);
    setIsBrakeEight(false);
    setIsBrakeNine(false);
    setIsLimitOne(false);
    setIsLimitTwo(false);
    setIsLimitThree(false);
    setIsLimitFour(false);
    setIsLimitFive(false);
    setIsKway(false);
    setIsQuickSelect(false);
    setIsKway(false);
    setIsR(false);
    localStorage.removeItem("bet");
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
    setIsShow(false);
  };
  const closeRModal = () => {
    setRValue("");
    setRAmount(0);
    setIsRModalOpen(false);
    setIsShow(false);
  };
  const checkNotRNumber = () => {
    if (oldBetList.length > 0) {
      if (nextBetList.length === 1) {
        if (
          nextBetList[0].betNumber === "00" ||
          nextBetList[0].betNumber === "11" ||
          nextBetList[0].betNumber === "22" ||
          nextBetList[0].betNumber === "33" ||
          nextBetList[0].betNumber === "44" ||
          nextBetList[0].betNumber === "55" ||
          nextBetList[0].betNumber === "66" ||
          nextBetList[0].betNumber === "77" ||
          nextBetList[0].betNumber === "88" ||
          nextBetList[0].betNumber === "99"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        const allSameThreeDigit = nextBetList.every((bet) => {
          const num = bet.betNumber;
          if (
            num === "00" ||
            num === "11" ||
            num === "22" ||
            num === "33" ||
            num === "44" ||
            num === "55" ||
            num === "66" ||
            num === "77" ||
            num === "88" ||
            num === "99"
          ) {
            return true;
          } else {
            return false;
          }
        });
        return allSameThreeDigit;
      }
    } else {
      if (betlist?.length === 1) {
        if (
          betlist?.[0]?.betNumber === "00" ||
          betlist?.[0]?.betNumber === "11" ||
          betlist?.[0]?.betNumber === "22" ||
          betlist?.[0]?.betNumber === "33" ||
          betlist?.[0]?.betNumber === "44" ||
          betlist?.[0]?.betNumber === "55" ||
          betlist?.[0]?.betNumber === "66" ||
          betlist?.[0]?.betNumber === "77" ||
          betlist?.[0]?.betNumber === "88" ||
          betlist?.[0]?.betNumber === "99"
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        const allSameThreeDigit = betlist?.every((bet: any) => {
          const num = bet.betNumber;
          if (
            num === "00" ||
            num === "11" ||
            num === "22" ||
            num === "33" ||
            num === "44" ||
            num === "55" ||
            num === "66" ||
            num === "77" ||
            num === "88" ||
            num === "99"
          ) {
            return true;
          } else {
            return false;
          }
        });
        return allSameThreeDigit;
      }
    }
  };
  const onClickRModal = () => {
    //const updatedBetList = [];// Create a new array to hold updated items
    const updatedBetList: {
      betNumber: string;
      isSelected: boolean;
      status: boolean;
      isR: boolean;
      isKway: boolean;
      amount: number;
      maxAmount: number;
      minAmount: number;
      percent: string;
    }[] = [];
    let ismessage = false;
    if (oldBetList.length !== 0) {
      //update old bet list data in updatedBetList
      for (let j = 0; j < oldBetList.length; j++) {
        //no thing to do
        const newItem = {
          betNumber: oldBetList[j].betNumber,
          isSelected: false,
          status: true,
          isR: Boolean(oldBetList[j].isR),
          isKway: false,
          amount: oldBetList[j].amount,
          maxAmount: oldBetList[j].maxAmount,
          minAmount: oldBetList[j].minAmount,
          percent: oldBetList[j].percent,
        };
        updatedBetList.push(newItem);
      }
      for (let i = 0; i < nextBetList.length; i++) {
        if (
          nextBetList[i].betNumber === "00" ||
          nextBetList[i].betNumber === "11" ||
          nextBetList[i].betNumber === "22" ||
          nextBetList[i].betNumber === "33" ||
          nextBetList[i].betNumber === "44" ||
          nextBetList[i].betNumber === "55" ||
          nextBetList[i].betNumber === "66" ||
          nextBetList[i].betNumber === "77" ||
          nextBetList[i].betNumber === "88" ||
          nextBetList[i].betNumber === "99"
        ) {
          let maxAmount = 0;
          let minAmount = 0;
          let percentValue = "0";
          maxAmount = findMaxAmountById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          minAmount = findMinAmountById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          percentValue = findPercentById(
            nextBetList[i].betNumber,
            roundList?.data?.data
          );
          if (maxAmount < rAmount) {
            // setMessageModalOpen(true);
            setIsRModalOpen(false);
            setIsShow(false);
            // setMessage(
            //   "Your R amount of " +
            //     nextBetList[i].betNumber +
            //     " is greater than limit amount!"
            // );
            // ismessage = true;
          }
          if (minAmount > rAmount) {
            // setMessageModalOpen(true);
            setIsRModalOpen(false);
            setIsShow(false);
            // setMessage(
            //   "Your R amount of " +
            //     nextBetList[i].betNumber +
            //     " is less than limit amount!"
            // );
            // ismessage = true;
          }
          if (parseInt(percentValue) >= 100) {
            // setMessageModalOpen(true);
            setIsRModalOpen(false);
            setIsShow(false);
            // setMessage(nextBetList[i].betNumber + " is limited!");
            ismessage = true;
          }
          if (
            findStatusById(nextBetList[i].betNumber, roundList?.data?.data) &&
            parseInt(
              findPercentById(nextBetList[i].betNumber, roundList?.data?.data)
            ) < 100 &&
            maxAmount > rAmount &&
            minAmount <= rAmount
          ) {
            //no thing to do
            const newItem = {
              betNumber: nextBetList[i].betNumber,
              isSelected: false,
              status: true,
              isR: false,
              isKway: false,
              amount: 0,
              maxAmount: findMaxAmountById(
                nextBetList[i].betNumber,
                roundList?.data?.data
              ),
              minAmount: findMinAmountById(
                nextBetList[i].betNumber,
                roundList?.data?.data
              ),
              percent: findPercentById(
                nextBetList[i].betNumber,
                roundList?.data?.data
              ),
            };
            updatedBetList.push(newItem);
          }
        } else {
          let reverseBet = reverseString(nextBetList[i].betNumber);
          if (!isBetNumberExists(reverseBet, betlist)) {
            let maxAmount = findMaxAmountById(
              nextBetList[i].betNumber,
              roundList?.data?.data
            );
            let minAmount = findMinAmountById(
              nextBetList[i].betNumber,
              roundList?.data?.data
            );
            if (maxAmount < rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(content.rAmountIsGreater);
              // ismessage = true;
            }
            if (minAmount > rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(content.rAmountIsLess);
              // ismessage = true;
            }
            if (
              parseInt(
                findPercentById(nextBetList[i].betNumber, roundList?.data?.data)
              ) >= 100
            ) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(nextBetList[i].betNumber + " is limited!");
              ismessage = true;
            }
            if (
              findStatusById(nextBetList[i].betNumber, roundList?.data?.data) &&
              parseInt(
                findPercentById(nextBetList[i].betNumber, roundList?.data?.data)
              ) < 100
            ) {
              const RnewItem = {
                betNumber: nextBetList[i].betNumber,
                isSelected: false,
                status: true,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: findMaxAmountById(
                  nextBetList[i].betNumber,
                  roundList?.data?.data
                ),
                minAmount: findMinAmountById(
                  nextBetList[i].betNumber,
                  roundList?.data?.data
                ),
                percent: findPercentById(
                  nextBetList[i].betNumber,
                  roundList?.data?.data
                ),
              };
              updatedBetList.push(RnewItem);
            }
            if (
              findStatusById(reverseBet, roundList?.data?.data) &&
              parseInt(findPercentById(reverseBet, roundList?.data?.data)) < 100
            ) {
              const newItem = {
                betNumber: reverseBet,
                isSelected: false,
                status: true,
                isR: true,
                isKway: false,
                amount: rAmount,
                maxAmount: findMaxAmountById(reverseBet, roundList?.data?.data),
                minAmount: findMinAmountById(reverseBet, roundList?.data?.data),
                percent: findPercentById(reverseBet, roundList?.data?.data),
              };
              updatedBetList.push(newItem);
            }
          } else {
            if (
              findStatusById(reverseBet, roundList?.data?.data) &&
              parseInt(findPercentById(reverseBet, roundList?.data?.data)) < 100
            ) {
              const newItem = {
                betNumber: nextBetList[i].betNumber,
                isSelected: false,
                status: true,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: findMaxAmountById(
                  nextBetList[i].betNumber,
                  roundList?.data?.data
                ),
                minAmount: findMinAmountById(
                  nextBetList[i].betNumber,
                  roundList?.data?.data
                ),
                percent: findPercentById(
                  nextBetList[i].betNumber,
                  roundList?.data?.data
                ),
              };
              updatedBetList.push(newItem);
            }
          }
        }
      }
    } else {
      for (let i = 0; i < betlist?.length; i++) {
        if (
          betlist?.[i]?.betNumber === "00" ||
          betlist?.[i]?.betNumber === "11" ||
          betlist?.[i]?.betNumber === "22" ||
          betlist?.[i]?.betNumber === "33" ||
          betlist?.[i]?.betNumber === "44" ||
          betlist?.[i]?.betNumber === "55" ||
          betlist?.[i]?.betNumber === "66" ||
          betlist?.[i]?.betNumber === "77" ||
          betlist?.[i]?.betNumber === "88" ||
          betlist?.[i]?.betNumber === "99"
        ) {
          if (
            findStatusById(betlist?.[i]?.betNumber, roundList?.data?.data) &&
            parseInt(
              findPercentById(betlist?.[i]?.betNumber, roundList?.data?.data)
            ) < 100
          ) {
            //no thing to do
            const newItem = {
              betNumber: betlist?.[i]?.betNumber,
              isSelected: false,
              status: true,
              isR: false,
              isKway: false,
              amount: 0,
              maxAmount: findMaxAmountById(
                betlist?.[i]?.betNumber,
                roundList?.data?.data
              ),
              minAmount: findMinAmountById(
                betlist?.[i]?.betNumber,
                roundList?.data?.data
              ),
              percent: findPercentById(
                betlist?.[i]?.betNumber,
                roundList?.data?.data
              ),
            };
            updatedBetList.push(newItem);
          }
        } else {
          let reverseBet = reverseString(betlist?.[i]?.betNumber);
          let RemaxAmount = findMaxAmountById(
            reverseBet,
            roundList?.data?.data
          );
          let RepercentValue = findPercentById(
            reverseBet,
            roundList?.data?.data
          );
          let ReminAmount = findMinAmountById(
            reverseBet,
            roundList?.data?.data
          );
          if (!isBetNumberExists(reverseBet, betlist)) {
            let maxAmount = findMaxAmountById(
              betlist?.[i]?.betNumber,
              roundList?.data?.data
            );
            let percentValue = findPercentById(
              betlist?.[i]?.betNumber,
              roundList?.data?.data
            );
            let minAmount = findMinAmountById(
              betlist?.[i]?.betNumber,
              roundList?.data?.data
            );
            if (maxAmount < rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(
              //   "Your R amount of " +
              //     betlist[i].betNumber +
              //     " is greater than limit amount!"
              // );
              // ismessage = true;
            }
            if (minAmount > rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(
              //   "Your R amount of " +
              //     betlist[i].betNumber +
              //     " is less than limit amount!"
              // );
              // ismessage = true;
            }
            if (parseInt(percentValue) >= 100) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(betlist[i].betNumber + " is limited!");
              // ismessage = true;
            }
            if (
              findStatusById(betlist?.[i]?.betNumber, roundList?.data?.data) &&
              parseInt(percentValue) < 100 &&
              maxAmount > rAmount &&
              minAmount <= rAmount
            ) {
              const RnewItem = {
                betNumber: betlist?.[i]?.betNumber,
                isSelected: false,
                status: true,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: maxAmount,
                minAmount: minAmount,
                percent: percentValue,
              };

              updatedBetList.push(RnewItem);
            }
            if (RemaxAmount < rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(
              //   "Your R amount of " +
              //     reverseBet +
              //     " is greater than limit amount!"
              // );
              // ismessage = true;
            }
            if (ReminAmount > rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(
              //   "Your R amount of " + reverseBet + " is less than limit amount!"
              // );
              // ismessage = true;
            }
            if (parseInt(RepercentValue) >= 100) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(reverseBet + " is limited!");
              // ismessage = true;
            }
            if (
              findStatusById(reverseBet, roundList?.data?.data) &&
              parseInt(RepercentValue) < 100 &&
              RemaxAmount > rAmount &&
              ReminAmount <= rAmount
            ) {
              const newItem = {
                betNumber: reverseBet,
                isSelected: false,
                status: true,
                isR: true,
                isKway: false,
                amount: rAmount,
                maxAmount: RemaxAmount,
                minAmount: ReminAmount,
                percent: RepercentValue,
              };
              updatedBetList.push(newItem);
            }
          } else {
            if (RemaxAmount < rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(
              //   "Your R amount of " +
              //     reverseBet +
              //     " is greater than limit amount!"
              // );
              // ismessage = true;
            }
            if (ReminAmount > rAmount) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(
              //   "Your R amount of " + reverseBet + " is less than limit amount!"
              // );
              // ismessage = true;
            }
            if (parseInt(RepercentValue) >= 100) {
              // setMessageModalOpen(true);
              setIsRModalOpen(false);
              setIsShow(false);
              // setMessage(reverseBet + " is limited!");
              // ismessage = true;
            }
            if (
              findStatusById(reverseBet, roundList?.data?.data) &&
              parseInt(RepercentValue) < 100 &&
              RemaxAmount > rAmount &&
              ReminAmount <= rAmount
            ) {
              const newItem = {
                betNumber: betlist?.[i]?.betNumber,
                isSelected: false,
                status: true,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: RemaxAmount,
                minAmount: ReminAmount,
                percent: RepercentValue,
              };
              updatedBetList.push(newItem);
            }
          }
        }
      }
    }

    setIsR(true);
    setRValue("");
    setRAmount(0);
    setRTime(1);
    setLastRoom(updatedBetList.length);
    setOldBetList(updatedBetList);
    setNextBetList([]);
    setBetListData(updatedBetList);
    localStorage.setItem("bet", JSON.stringify(updatedBetList));

    setIsRModalOpen(false);
    setIsShow(false);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setRAmount(value);
  };

  const OnQuickClick = () => {
    if (!(isR || isKway)) {
      navigate("/twoD/quickselect/" + round_id);
    }
  };
  const CheckQuickSelect = () => {
    if (
      isBig ||
      isSmall ||
      isOdd ||
      isEven ||
      isSame ||
      isEE ||
      isEO ||
      isOE ||
      isOO ||
      isZeroInclude ||
      isOneInclude ||
      isTwoInclude ||
      isThreeInclude ||
      isFourInclude ||
      isFiveInclude ||
      isSixInclude ||
      isSevenInclude ||
      isEightInclude ||
      isNineInclude ||
      isHeadZeroInclude ||
      isHeadOneInclude ||
      isHeadTwoInclude ||
      isHeadThreeInclude ||
      isHeadFourInclude ||
      isHeadFiveInclude ||
      isHeadSixInclude ||
      isHeadSevenInclude ||
      isHeadEightInclude ||
      isHeadNineInclude ||
      isTailZeroInclude ||
      isTailOneInclude ||
      isTailTwoInclude ||
      isTailThreeInclude ||
      isTailFourInclude ||
      isTailFiveInclude ||
      isTailSixInclude ||
      isTailSevenInclude ||
      isTailEightInclude ||
      isTailNineInclude ||
      isPowerTypeOne ||
      isPowerTypeTwo ||
      isPowerTypeThree ||
      isPowerTypeFour ||
      isBrakeZero ||
      isBrakeOne ||
      isBrakeTwo ||
      isBrakeThree ||
      isBrakeFour ||
      isBrakeFive ||
      isBrakeSeven ||
      isBrakeSix ||
      isBrakeEight ||
      isBrakeNine ||
      isLimitOne ||
      isLimitTwo ||
      isLimitThree ||
      isLimitFour ||
      isLimitFive
    ) {
      return true;
    } else {
      return false;
    }
  };

  const OnClickBet = () => {
    if (agentSetting?.data?.isSuccess) {
      if (agentSetting?.data?.data?.maxBetCount >= betlist?.length) {
        if (betlist?.length > 0) {
          setRoundId(round_id || "");
          localStorage.setItem("round", JSON.stringify(round_id));
          navigate("/twoD/betchoosethreed/" + round_id);
        } else {
          setMessageModalOpen(true);
          setIsShow(true);
          setMessage(content.pleaseAtLeastOne);
        }
      } else {
        setMessageModalOpen(true);
        setIsShow(true);
        setMessage(
          content.betLimit +
            agentSetting?.data?.data?.maxBetCount +
            " !" +
            content.pleaseReduceBetNumber
        );
      }
    } else if (betlist?.length > 0) {
      setRoundId(round_id || "");
      navigate("/twoD/betchoosethreed/" + round_id);
    } else {
      setMessageModalOpen(true);
      setIsShow(true);
      setMessage(content.pleaseAtLeastTwo);
    }
  };
  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <div className="p-b-100">
            <div className="container">
              <div className="header-bet-container row p-t-10 p-l-10 p-r-10 item-center d-flex">
                <button
                  className="btn-center-container text-nowrap btn-color-defination "
                  onClick={openModal}
                >
                  {content.colorDefination}
                </button>
                <button
                  className="btn-center-container text-nowrap btn-clear-all"
                  onClick={clearAll}
                >
                  {content.clearAll}
                </button>
              </div>
            </div>
            <div className="container p-b-120">
              <div className="row bet-namber-row d-flex item-center p-t-10">
                {roundList?.data?.data ? (
                  roundList?.data?.data?.map((round: any, i: number) => (
                    <div className="col-3 g-2">
                      <div
                        className={`btn-number-center-container 
                      ${calculateBorderColor(parseInt(round.percent))} 
                      ${calculateDisable(round.status, round.percent)}
                      fs-16 fw-500 lh-20 d-h-32 
                      ${isSelectedBetNumber(round.betNumber)}`}
                        onClick={() =>
                          changeBetArray(
                            round.betNumber,
                            round.maxAmount,
                            round.minAmount,
                            parseInt(round.percent) < 100 && round.status,
                            round.percent
                          )
                        }
                      >
                        {round.betNumber}
                        {isRBetNumber(round.betNumber) ? (
                          <div className="text-center circle fs-10 fw-400 d-flex justify-content-center !-top-[5px] !-right-[5px]">
                            <div className="">{content.r}</div>
                          </div>
                        ) : isKBetNumber(round.betNumber) ? (
                          <div className="text-center circle fs-10 fw-400 d-flex justify-content-center !-top-[5px] !-right-[5px]">
                            <div className="flex justify-center items-center text-[8px]">
                              {content.k}
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <FooterButton
            isButton={true}
            isQSDisable={isR || isKway ? true : false}
            betto=""
            to={"/twoD/kway/" + round_id}
            isQuickSelect={true}
            OnClickQuick={OnQuickClick}
            quickto=""
            isDisable={isR || CheckQuickSelect() ? true : false}
            isKway={true}
            amount={
              wallet?.data?.data?.userWalletAmount?.amount
                ? wallet?.data?.data?.userWalletAmount?.amount
                : 0
            }
            endTime={agentSetting?.data?.data?.roundId?.endTime}
            endDate=""
            isRDisable={
              CheckQuickSelect() ||
              isKway ||
              betlist?.length < 1 ||
              checkNotRNumber()
                ? true
                : false
            }
            OnClickR={openRModal}
            OnClickBet={OnClickBet}
          />
        </>
      )}

      <Modal
        isShow={isShow}
        isOpen={isModalOpen}
        onClose={closeModal}
        popupModalStyle={{
          width: windowWidth < 400 ? windowWidth - 32 : 400,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-flex item-center">
            <div className="col-12 color-title color-defination-color">
              {content.colorDefination}
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center">
              <CircleColorIcon className="color-icon"></CircleColorIcon>{" "}
              <span className="p-l-10"> {content.tenPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <TwentyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.twentyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center">
              <ThirtyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.thirtyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <FoutyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.foutyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center">
              <FiftyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.firtyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <SixtyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.sixtyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center item-center">
              <SeventyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.seventyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center item-center">
              <EightyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.eightyPercent}</span>
            </div>
          </div>
          <div className="row d-flex item-center p-t-10 ">
            <div className="col-6 d-flex justify-content-center item-center">
              <NinetyPercentIcon className="color-icon" />{" "}
              <span className="p-l-10"> {content.nintyPercent}</span>
            </div>
            <div className="col-6 d-flex justify-content-center item-center">
              <HundardPercentIcon className="color-icon" />{" "}
              <span className="p-l-5">{content.hundardPercent}</span>
            </div>
          </div>
          <div className="pt-2 row primary-color noti-label">
            * {content.betLabel}
          </div>
          <div className="row d-flex justify-content-center p-t-10">
            <div
              className="cursor-pointer color-defination-ok"
              onClick={closeModal}
            >
              <span className="font-Quicksand py-2.5 white-color">
                {commom.buttonOk}
              </span>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isRModalOpen}
        onClose={closeRModal}
        popupModalStyle={{
          width: windowWidth < 400 ? windowWidth - 32 : 400,
        }}
      >
        <div className="container">
          <div className="text-center row p-b-10 d-flex item-center">
            <div className="px-0 primary-color">{content.rLabel}</div>
          </div>
          <div className="row d-h-50">
            <div className="col-12">
              <input
                type="number"
                placeholder={`${agentSetting?.data?.data?.minBetPerUser} ${commom.unit} ${commom.minumun}`}
                onChange={handleChange}
                className="common-text-box d-h-42 w-100 no-arrow"
              />
            </div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeRModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div
              className={`col-6 d-h-42 ${
                rAmount < agentSetting?.data?.data?.minBetPerUser ||
                rAmount > agentSetting?.data?.data?.maxBetAmount
                  ? "opacity-50 pointer-events-none "
                  : ""
              }`}
            >
              <button className="uppercase btn-ok" onClick={onClickRModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={messageModalOpen}
        onClose={closeMessageModal}
        popupModalStyle={{
          width: windowWidth < 400 ? windowWidth - 16 : 400,
        }}
      >
        <div className="container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.alert}</div>
          </div>
          <div className="text-center row d-flex item-center">
            <div className="pb-4 col primary-color">
              {/* {JSON.parse(localStorage.getItem("bet") || "[{}]")?.some(
                (_d: any) => _d?.isR === true
              )
                ? content.rAmountIsGreater
                : content.AmountIsGreater} */}
              <div>{message}</div>
            </div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="flex justify-center col-12 d-h-42">
              <button
                className="uppercase btn-ok max-w-[250px]"
                onClick={closeMessageModal}
              >
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default Betlist;
