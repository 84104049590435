import { strings, translations } from "../../services/localization";
interface ParentComponentProps {
  betnumber: string | undefined;
  title: string | undefined;
  set: string | undefined;
  value: string | undefined;
  date: string | undefined;
}

const RoundCard: React.FC<ParentComponentProps> = ({
  betnumber,
  title,
  set,
  value,
  date,
}) => {
  const content = translations.roundCard;
  return (
    <div className="container p-b-20 p-t-20">
      <div className="row">
        <div className="round-card ">
          <div className="row justify-content-center item-center">
            <div className="round-title d-w-110 d-h-42  d-flex item-center">
              <span>{title}</span>
            </div>
          </div>
          <div className="row justify-content-center item-center p-t-30 p-b-15">
            <div className="col-4">
              <span
                className={`round-card-bet-number lh-25 ${
                  !Number.isNaN(Number(betnumber))
                    ? "fs-20"
                    : "text-base sm:text-[20px] fw-300"
                }`}
              >
                {betnumber}
              </span>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-12 fs-14 p-b-5">
                  <span>{content.set}</span>
                </div>
                <div className="col-12 fs-14">
                  <span>{set}</span>
                </div>
              </div>
            </div>
            <div className="col-3">
              <div className="row">
                <div className="col-12 fs-14 p-b-5">
                  <span>{content.value}</span>
                </div>
                <div className="col-12 fs-14">
                  <span>{value}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-h-50 justify-content-center item-center">
            <hr className="full-line" />
            <div className="text-center">
              <span className="show-round-time fs-12">{date}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoundCard;
