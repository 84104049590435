import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { useParams, useNavigate } from "react-router-dom";
import {
  useCollectDetail,
  patchCollector,
  useCollectAllList,
} from "../../api/funs/twodbetlist";
import GridTable from "../../components/common/GridTable";
import Modal from "../../components/common/Modal";
import dateFormat from "dateformat";
import { translations } from "../../services/localization";
import { BeatLoader } from "react-spinners";
import moment from "moment";

const CollectDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { status } = useParams<{ status: string }>();
  const navigate = useNavigate();
  const { data: detail, mutate } = useCollectDetail(id || "", status || "");
  const [collectList, setCollectList] = useState<any[]>([]);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [betAmount, setBetAmount] = useState<number>(0);
  const [wonAmount, setWonAmount] = useState<number>(0);
  const [resultId, setResultId] = useState<string>("");
  const [messageTitle, setMessageTitle] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const { patchCollectorFunc } = patchCollector();
  const content = translations.collectDetail;
  const betHistoryDetail = translations.betHistoryDetail;
  const tableHeader = translations.tableHeader;
  const collectListTransaction = translations.collectList;
  const commom = translations.commom;
  const { mutate: mutateCollect } = useCollectAllList(true);
  const { mutate: mutateCollectWin } = useCollectAllList(false);
  const unclaimed =  collectList?.map((el) => (el?.status)).includes("UNCLAIMED")

  const headerdata = [
    {
      name: tableHeader.betNumber,
      class: "theader-25",
    },
    {
      name: tableHeader.amount,
      class: "theader-25",
    },
    {
      name: tableHeader.won,
      class: "theader-35",
    },
  ];

  const CollectWallet = async (
    betAmount: number,
    winAmount: number,
    result_id: string
  ) => {
    setResultId(result_id);
    setBetAmount(betAmount);
    setWonAmount(winAmount);

    const result = await patchCollectorFunc(result_id);
    if (result?.isSuccess) {
      setIsSuccessModalOpen(true);
      setIsShow(true);
      setResultId("");
      setBetAmount(0);
      setWonAmount(0);
      setMessageTitle(commom.success);
      setMessage(result?.message);
    } else {
      setIsShow(true);
      setIsSuccessModalOpen(true);
      setMessageTitle(commom.notice);
      setMessage(result?.message);
    }
  };

  useEffect(() => {
    if (detail?.data?.data) {
      setCollectList(detail.data.data);
    }
  }, [detail]);

  const openColletModal = () => {
    setIsModalOpen(true);
    setIsShow(true);
  };

  const closeColletModal = async () => {
    setIsModalOpen(false);
    setIsShow(false);
    // const result = await patchCollectorFunc(resultId);
    // if (result?.isSuccess) {
    //   setIsSuccessModalOpen(true);
    //   mutate();
    //   mutateCollect();
    //   mutateCollectWin();
    //   setResultId("");
    //   setBetAmount(0);
    //   setWonAmount(0);
    //   setMessageTitle(commom.success + " !!");
    //   setMessage(result?.message);
    // } else {
    //   setIsSuccessModalOpen(true);
    //   setMessageTitle("Error!");
    //   setMessage(result?.message);
    // }
    // if(result?.isSuccess){
    //     setIsSuccessModalOpen(true);
    // }
  };
  const closeSuccessModal = () => {
    mutate();
    mutateCollect();
    mutateCollectWin();
    setIsModalOpen(false);
    setIsSuccessModalOpen(false);
    setIsShow(false);
    setMessageTitle("");
    setMessage("");
    !unclaimed && navigate("/twoD/collectlist");
  };

  useEffect(() => {
    detail?.data?.data?.length === 0 && navigate("/twoD/collectlist");
  }, [detail?.data?.data]);

  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container default-height">
        {collectList.map((collect: any, i: number) => (
          <div>
            <div className="row d-flex p-t-15 justify-content-center align-items-center">
              <div className="collect-round-title d-flex justify-content-center d-w-100 d-h-50 item-center">
                <span>{collect.roundId.roundName}</span>
              </div>
            </div>
            <div className="row d-flex p-t-25 justify-content-center align-items-center">
              <div className="col-12 d-flex justify-content-center align-items-center">
                <span className="primary-color collect-detail-bet">
                  {betHistoryDetail.betDate} -{" "}
                  {moment(collectList?.[0]?.transactionId?.createdAt)?.format(
                    "MMM DD YYYY , hh:mm:ss A"
                  )}
                </span>
              </div>
            </div>
            <div className="row d-flex p-t-10 justify-content-center align-items-center">
              <div className="p-t-20 p-b-10 item-center">
                <GridTable headerdata={headerdata}>
                  <tr className="table-row bottom-dashed d-h-50 fs-14 fw-400 lh-20">
                    <td>{collect.winningNumber}</td>
                    <td>{collect.betAmount.toLocaleString()}</td>
                    <td>{collect.winningAmount.toLocaleString()}</td>
                  </tr>

                  {collect.status === "UNCLAIMED" ? (
                    <tr className="d-h-60 fs-14 lh-20">
                      <td className="p-l-10 p-r-10" colSpan={3}>
                        <button
                          className=" w-100 d-h-40 btn-collect-primary fs-16"
                          onClick={() =>
                            CollectWallet(
                              collect.betAmount,
                              collect.winningAmount,
                              collect._id
                            )
                          }
                        >
                          {collectListTransaction.headerTitle}
                        </button>
                      </td>
                    </tr>
                  ) : (
                    <tr className="d-h-60 fs-14 lh-20">
                      <td className="p-l-10 p-r-10" colSpan={3}>
                        <button className=" w-100 d-h-40 btn-collected-primary fs-16">
                          {collectListTransaction.collected}
                        </button>
                      </td>
                    </tr>
                  )}
                </GridTable>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Modal isShow={isShow} isOpen={isModalOpen} onClose={closeColletModal}>
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{commom.success}</div>
          </div>
          <div className="row d-h-50">
            <div className="col">
              {content.firstNoti}{" "}
              <span className="primary-color">
                {betAmount.toLocaleString()}{" "}
              </span>
              {content.secondNoti}{" "}
              <span className="primary-color">
                {wonAmount.toLocaleString()}{" "}
              </span>
              {content.unit}
            </div>
          </div>
          <div className="row d-h-50">
            <button className="btn-ok" onClick={closeColletModal}>
              {commom.buttonOk}
            </button>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{messageTitle}</div>
          </div>
          <div className="text-center row d-h-70 d-flex item-center">
            <div className="col">{message}</div>
          </div>
          <div className="row d-h-50">
            <button className="btn-ok" onClick={closeSuccessModal}>
              {commom.buttonOk}
            </button>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default CollectDetail;
