import SecondaryButton from "../../components/common/SecondaryButton";
import BetButton from "../../components/common/BetButton";
import React, { useState } from "react";
import Modal from "../../components/common/Modal";
import dateFormat from "dateformat";
import { convertTimeToAMPM } from "../../services/sharedFunction";
import { translations } from "../../services/localization";

interface ParentComponentProps {
  to: string;
  betto: string;
  isQuickSelect: boolean;
  isButton: boolean | undefined;
  isDisable: boolean;
  quickto: string;
  amount: number;
  endTime: string;
  isKway: boolean;
  isQSDisable: boolean;
  endDate: string;
  OnClickR: () => void;
  OnClickQuick: () => void;
  isRDisable: boolean;
  OnClickBet: () => void;
}
const FooterButton: React.FC<ParentComponentProps> = ({
  to,
  betto,
  isButton,
  isQSDisable,
  isQuickSelect,
  quickto,
  isDisable,
  amount,
  endTime,
  isKway,
  endDate,
  OnClickR,
  OnClickQuick,
  isRDisable,
  OnClickBet,
}) => {
  const todate = new Date();
  const content = translations.betList;
  const commom = translations.commom;
  const dateShow = () => {
    if (endDate === "") {
      //for two d
      return dateFormat(todate, "mmm dd,yyyy");
    } else {
      const threeDEndDate = new Date(dateFormat(endDate, "yyyy-mm-dd"));
      return dateFormat(threeDEndDate, "mmm dd,yyyy");
    }
  };
  return (
    <div className="footer-button ">
      <div className="container-fluid">
        {isKway ? (
          <div className="row p-t-10 d-flex item-center p-r-10 secondary-button-row">
            <div className="col-4" onClick={OnClickQuick}>
              {isQuickSelect ? (
                <SecondaryButton
                  to={quickto}
                  label={commom.buttonQuickSelect}
                  isNotUseTo={isQSDisable}
                  isDisabled={isQSDisable}
                />
              ) : (
                <SecondaryButton
                  to={to}
                  label={commom.buttonQuickSelect}
                  isNotUseTo={isQSDisable}
                  isDisabled={isQSDisable}
                />
              )}
            </div>
            <div className="col-4" onClick={OnClickR}>
              <SecondaryButton
                to={to}
                label={content.r}
                isNotUseTo={true}
                isDisabled={isRDisable}
              />
            </div>
            <div className="col-4">
              <SecondaryButton
                to={to}
                label={content.k}
                isNotUseTo={isDisable}
                isDisabled={isDisable}
              />
            </div>
          </div>
        ) : (
          <div className="row p-t-10 d-flex item-center p-r-10 secondary-button-row">
            <div className="col-6" onClick={OnClickQuick}>
              {isQuickSelect ? (
                <SecondaryButton
                  to={quickto}
                  label={commom.buttonQuickSelect}
                  isNotUseTo={false}
                  isDisabled={isDisable}
                />
              ) : (
                <SecondaryButton
                  to={to}
                  label={commom.buttonQuickSelect}
                  isNotUseTo={true}
                  isDisabled={isDisable}
                />
              )}
            </div>
            <div className="col-6" onClick={OnClickR}>
              <SecondaryButton
                to={to}
                label={content.r}
                isNotUseTo={true}
                isDisabled={isRDisable}
              />
            </div>
          </div>
        )}
        <div className="flex justify-between p-t-10 item-center">
          <div className="footer-label-title fs-12 fw-500 lh-15 ">
            {commom.wallet}
          </div>
          <div className="text-end footer-label-title fs-12 fw-500 lh-15 ">
            {content.betWillClose}
          </div>
        </div>
        <div className="flex justify-between p-t-10 item-center">
          <div className="footer-label-value fs-14 fw-600 lh-20 ">
            {amount.toLocaleString()} {commom.unit}
          </div>
          <div className="text-end footer-label-value fs-14 fw-500 lh-18 ">
            {dateShow()} {convertTimeToAMPM(endTime)}
          </div>
        </div>
        <div
          className="d-flex justify-content-center p-t-20 row h-76 p-r-10 p-l-10 p-b-10 item-center "
          onClick={OnClickBet}
        >
          <BetButton to={betto} label={commom.buttonBet} isDisabled={false} />
        </div>
      </div>
    </div>
  );
};

export default FooterButton;
