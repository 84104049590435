import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { RouteObject, redirect } from "react-router-dom";
import { MainLayout } from "../components/MainLayout";
import Home from "../pages/home";
import Profile from "../pages/profile";
import { RouteGuard } from "../components/RouteGuard";
import { useContext } from "react";
import { AuthContext } from "../contexts/authContext";

import ThreeDHome from "../pages/threeD/home";
import CollectList from "../pages/threeD/collectlist";
import CollectDetail from "../pages/threeD/collectDetail";
import BetList from "../pages/threeD/betlist";
import BetHistory from "../pages/threeD/bethistory";
import BetHistoryDetail from "../pages/threeD/bethistorydetail";
import BetResultList from "../pages/threeD/betresultlist";
import BetChooseThreeD from "../pages/threeD/betchooseThreeD";
import BetConfirm from "../pages/threeD/betconfirm";
import BetWinner from "../pages/threeD/betwinner";

import TwoDHome from "../pages/twoD/home";
import TwoBetList from "../pages/twoD/betlist";
import TwoBetWinner from "../pages/twoD/betwinner";
import TwoCollectList from "../pages/twoD/collectlist";
import TwoCollectDetail from "../pages/twoD/collectDetail";
import TwoBetResultList from "../pages/twoD/betresultlist";
import TwoBetHoliday from "../pages/twoD/betholiday";
import TwoBetHistory from "../pages/twoD/bethistory";
import TwoBetHistoryDetail from "../pages/twoD/bethistorydetail";
import TwoBetChooseThreeD from "../pages/twoD/betchooseTwoD";
import TwoBetConfirm from "../pages/twoD/betconfirm";
import TwoKway from "../pages/twoD/kway";
import TwoQuickSelect from "../pages/twoD/betquickselect";

export const RootRouter = () => {
  const { authenticated } = useContext(AuthContext);

  const routes: RouteObject[] = [
    {
      path: "/",
      element: <MainLayout />,
      children: [
        {
          index: true,
          element: (
            <RouteGuard auth={authenticated}>
              <h1>Hello Dashboard</h1>
            </RouteGuard>
          ),
        },
      ],
    },
    {
      path: "/home/:language_id/:token_id",
      element: <Home />,
    },
    {
      path: "/profile",
      element: <Profile />,
    },
    {
      path: "/threeD/threedhome",
      element: <ThreeDHome />,
    },
    {
      path: "/threeD/collectlist",
      element: <CollectList />,
    },

    {
      path: "/threeD/collectDeail/:id/:type/:status",
      element: <CollectDetail />,
    },
    {
      path: "/threeD/betlist/:round_id",
      element: <BetList />,
    },
    {
      path: "/threeD/bethistory",
      element: <BetHistory />,
    },
    {
      path: "/threeD/bethistory/bethistorydetail/:history_id",
      element: <BetHistoryDetail />,
    },
    {
      path: "/threeD/betresultlist",
      element: <BetResultList />,
    },
    {
      path: "/threeD/betchoosethreed/:round_id",
      element: <BetChooseThreeD />,
    },
    {
      path: "/threeD/betconfirm/:round_id",
      element: <BetConfirm />,
    },
    {
      path: "/threeD/betwinner",
      element: <BetWinner />,
    },
    {
      path: "/twoD/twodhome",
      element: <TwoDHome />,
    },
    {
      path: "/twoD/betlist/:round_id",
      element: <TwoBetList />,
    },
    {
      path: "/twoD/collectlist",
      element: <TwoCollectList />,
    },
    {
      path: "/twoD/collectDeail/:id/:status",
      element: <TwoCollectDetail />,
    },
    {
      path: "/twoD/betwinner",
      element: <TwoBetWinner />,
    },
    {
      path: "/twoD/betresultlist",
      element: <TwoBetResultList />,
    },
    {
      path: "/twoD/betholiday",
      element: <TwoBetHoliday />,
    },
    {
      path: "/twoD/bethistory",
      element: <TwoBetHistory />,
    },
    {
      path: "/twoD/betchoosethreed/:round_id",
      element: <TwoBetChooseThreeD />,
    },
    {
      path: "/twoD/bethistory/bethistorydetail/:history_id",
      element: <TwoBetHistoryDetail />,
    },
    {
      path: "/twoD/betconfirm",
      element: <TwoBetConfirm />,
    },
    {
      path: "/twoD/kway/:round_id",
      element: <TwoKway />,
    },
    {
      path: "/twoD/quickselect/:round_id",
      element: <TwoQuickSelect />,
    },
    { path: "*", element: <h1>Page Not Found</h1> },
  ];

  const router = createBrowserRouter(routes);

  return <RouterProvider router={router} />;
};

export default RootRouter;
