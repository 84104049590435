export const API_NAME = {
  home_page_check_api: "api/agent/info",
  wallet_get_api:"api/wallet/",
  round_list_api:"api/rounds/lists",
  two_d_bet_list_api:"api/twod-bets/numbers",
  agent_setting_api:"api/agent/setting",
  bet_twod_api:"api/twod-bets",
  two_bet_history_api:"api/twod-bets/history",
  two_bet_history_detail:"api/twod-bets/",
  two_bet_result_api:"api/twod-bets/results",
  two_bet_winner_api:"api/winning/lists/two-d",
  two_bet_colect_all_api:"api/winning/to-collect/two-d",
  two_bet_colect_history_api:"api/winning/collect-history/two-d",
  two_bet_collect_detail_api:"api/winning/twod-details/",
  two_bet_collect_patch_api:"api/winning/collect-twod/",
  two_bet_home_page_round:"api/rounds/get-rounds",
  holiday_api:"api/holidays",
  banner_api:"api/banners/lists",

  terms_api:"api/terms",
  terms_api_patch:"api/terms/agree",
  
  bet_threed_api:"api/threed-bets",
  bet_result_threed_api:"api/threed-bets/results",
  three_bet_history_api:"api/threed-bets/history",
  three_bet_history_detail:"api/threed-bets/",
  three_bet_winner_straight_api:"api/winning/lists/three-d/straight",
  three_bet_winner_box_api:"api/winning/lists/three-d/box",
  three_bet_colect_all_api:"api/winning/to-collect/three-d",
  three_bet_colect_history_api:"api/winning/collect-history/three-d",
  three_bet_collect_detail_api:"api/winning/threed-details/",
  three_bet_collect_patch_api:"api/winning/collect-threed/",
  three_bet_last_round:"api/winning/threed/last-rounds",
  
  three_d_bet_list_api:"api/threed-bets/numbers",
};
