import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import GridTable from "../../components/common/GridTable";
import { useParams } from "react-router-dom";
import { useHistoryBetDetail } from "../../api/funs/threedbetlist";
import Pagination from "../../components/common/Pagination";
import dateFormat from "dateformat";
import { translations } from "../../services/localization";
import { formatAmount } from "../../util/cn";

const BetHistoryDetail: React.FC = () => {
  const { history_id } = useParams<{ history_id: string }>();
  const [totalItem, setTotalItem] = useState<number>(0);
  const [itemsPerPage, setItemsPerPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageLimit, setpageLimit] = useState<number>(10);
  const { data: historyDetail } = useHistoryBetDetail(
    history_id || "",
    pageLimit,
    itemsPerPage
  );
  const [betDate, setBetDate] = useState<string>("");
  const [totalBetAmount, setTotalBetAmount] = useState<number>(0);
  const content = translations.betHistoryDetail;
  const common = translations.commom;
  const tableHeader = translations.tableHeader;

  const handlePageChange = (page: number) => {
    setItemsPerPage(page);
    setCurrentPage(page - 1);
  };
  const headerdata = [
    {
      name: tableHeader.no,
    },
    {
      name: tableHeader.betNumber,
    },
    {
      name: tableHeader.profit,
    },
    {
      name: tableHeader.amount,
    },
  ];
  useEffect(() => {
    if (historyDetail?.data?.isSuccess) {
      setTotalItem(historyDetail?.data?.totalCounts);
    }
    // Check if historyDetail exists and has the necessary nested structure
    if (
      historyDetail &&
      historyDetail.data &&
      historyDetail.data.data &&
      historyDetail.data.data.betData[0]
    ) {
      // Retrieve the betDate from historyDetail
      const betDate = historyDetail.data.data.betData[0].betDate;
      // Create a Date object from the betDate
      const dateData = new Date(betDate);
      // Format the date as 'yyyy-mm-dd'
      const formattedDate = dateFormat(dateData, "mmm d, yyyy , HH:MM:ss TT");
      // Set the formatted date to the betDate state
      setBetDate(formattedDate);
    }
    let totalAmount = 0;
    if (historyDetail && historyDetail.data && historyDetail.data.data) {
      const transactionData = historyDetail.data.data.transactionData;
      totalAmount = transactionData?.amount ? transactionData?.amount : 0;
    }
    setTotalBetAmount(totalAmount);
  }, [historyDetail]);
  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container default-height">
        <div className="row d-flex justify-content-center d-h-70 item-center fs-16 fw-400 primary-color font-extralight">
          {content.betDate} - {betDate}
        </div>
        <div>
          <GridTable headerdata={headerdata}>
            {historyDetail?.data?.data?.betData ? (
              historyDetail?.data?.data?.betData.map(
                (bet: any, index: number) => {
                  const tableRowNumber = currentPage * pageLimit + index + 1;
                  return (
                    <tr
                      key={index}
                      className="table-row bottom-dashed d-h-50 lh-20 px-2 font-light text-sm"
                    >
                      <td className="text-sm font-light pl-2">
                        {tableRowNumber}
                      </td>
                      <td className="text-sm font-light">{bet?.betNumber}</td>
                      <td className="text-sm font-light">{bet?.odds}x</td>
                      <td className="text-sm font-light">
                        {formatAmount(bet?.amount)}
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <></>
            )}
            <tr className="d-h-50">
              <td className="fw-700 fs-14 lh-17 black-color p-l-20" colSpan={3}>
                {tableHeader.totalBetAmount}
              </td>
              <td
                className="fw-700 fs-14 lh-17 red-color text-align-center"
                scope="col"
              >
                {formatAmount(totalBetAmount)?.toLocaleString()} {common.unit}
              </td>
            </tr>
          </GridTable>
          {totalItem > 10 ? (
            <div className="p-t-10">
              <Pagination
                totalItems={totalItem}
                itemsPerPage={pageLimit}
                onPageChange={handlePageChange}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </AppWrapper>
  );
};

export default BetHistoryDetail;
