import dateFormat from "dateformat";
import { useState } from "react";
import Modal from "./Modal";
import { translations } from "../../services/localization";
import dayjs from "dayjs";
import moment from "moment";

interface ParentComponentProps {
  title: string | undefined;
  firstdate: string | undefined;
  firstnumber: string | undefined;
  tootnumber: string[] | undefined;
  resultDate: string | undefined;
  announceTime?:string
}
const BetListOneCard: React.FC<ParentComponentProps> = ({
  title,
  firstdate,
  firstnumber,
  tootnumber,
  resultDate,
  announceTime
}) => {
  const commom = translations.commom;
  const [isShow, setIsShow] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const tootNumbers = tootnumber?.map((num) => {
    return num;
  });
  const closeMessageModal = () => {
    setMessageModalOpen(false);
    setIsShow(false);
  };
  const openModal = () => {
    setMessageModalOpen(true);
    setIsShow(true);
  };
  return (
    <div>
      <div
        onClick={openModal}
        className="cursor-pointer d-h-102 p-t-10"
      >
        <div className="flex items-center px-1 bet-list-card-body d-h-66">
          <div className="col d-flex bet-list-col-date justify-content-start text-nowrap fs-12 fw-500 lh-20 item-center">
            {dateFormat(firstdate, "mmm dd, yyyy")}
          </div>
          <div className="col d-flex bet-list-col-number justify-content-end primary-color fs-24 fw-500 lh-37 item-center">
            {firstnumber}
          </div>
        </div>
      </div>
      <Modal isShow={isShow} isOpen={messageModalOpen} onClose={closeMessageModal}>
        <div className="container modal-container d-w-250">
          <div className="py-4">
            <div className="text-center row d-h-30 d-flex item-center">
              <div className="col fs-18 fw-700 primary-color">
                {commom.buttonDade}
              </div>
            </div>
            <div className="text-center row d-h-30 d-flex item-center">
              <div className="col fs-30 fw-700 bet-list-col-date">
                {firstnumber}
              </div>
            </div>
            <div className="mt-4 text-center row d-h-30 d-flex item-center">
              <div className="col fs-18 fw-700 primary-color">
                {" "}
                {commom.buttonToot}{" "}
              </div>
            </div>
            <div className="text-center row d-flex item-center">
              <div className="flex flex-wrap items-center justify-center py-2 col fs-30 fw-700 bet-list-col-date">
                <div className="pr-2 text-lg">
                  {tootNumbers?.map((toot, i, array) =>
                    i === array.length - 1 ? toot : toot + ", "
                  )}
                </div>
              </div>
            </div>
            <div className="row d-h-20">
              <div className="col">
                <hr className="full-line" />
              </div>
            </div>
            <div className="text-center row d-h-20 d-flex item-center">
              <div className="col detail-popup-date">
                {commom.upDateTime}:{" "}
                {

announceTime ?`${moment(announceTime,"HH:mm A").format("hh:mm:ss A") } ${moment(resultDate).format("dddd, MMMM D, YYYY")}` :"--"
                 
                }
                
                {/* {dayjs(resultDate).format("h:mm:ss A dddd, MMMM D, YYYY")} */}
              </div>
            </div>
          </div>
          <div className="row d-h-50 d-flex item-center">
            <div className="col-12 d-h-42 ">
              <button className="uppercase btn-ok" onClick={closeMessageModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BetListOneCard;
