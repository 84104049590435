import React, { useEffect, useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import Collect from "../../components/common/Collect";
import { useNavigate } from "react-router-dom";
import { useCollectAllList } from "../../api/funs/threedbetlist";
import { translations } from "../../services/localization";
import emptyList from "../../assets/images/empty_list_icon.png";

const Collectlist: React.FC = () => {
  const [language] = useState(
    localStorage.getItem("language")
      ? localStorage.getItem("language")
      : "english"
  );
  const [isCollect, setCollect] = useState(
    false
    // localStorage.getItem("isCollect") === "true" ? true : false
  );
  const collectEnabled = (isCollect: any) => {
    setCollect(!isCollect);
    // localStorage.setItem("isCollect", !isCollect ? "true" : "false");
  };
  const { data: collectHistoryAll } = useCollectAllList(true);
  const { data: collectWinAll } = useCollectAllList(false);
  const content = translations.collectList;
  const navigate = useNavigate();

  const noHistoryToCollect = collectHistoryAll?.data?.data?.length === 0;
  const noCollectedHistory = collectWinAll?.data?.data?.length === 0;
  useEffect(() => {
    if (noHistoryToCollect && !noCollectedHistory) {
      setCollect(true);
    } else if (noHistoryToCollect && noCollectedHistory) {
      setCollect(false);
    } else {
      setCollect(false);
    }
  }, []);

  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container three-d-layout default-height">
        <div className="container p-t-15 p-l-10">
          <div className="row">
            <div className="col-4" onClick={() => collectEnabled(true)}>
              {
                !isCollect ? (
                  <button
                    className={`btn border-0 p-0 custom-underline-text w-auto fs-16 fw-700 text-nowrap ${
                      language === "myanmar" ? "pb-1" : ""
                    }`}
                  >
                    {content.toCollect}
                  </button>
                ) : (
                  <button
                    className={`disable btn border-0 p-0 text-nowrap ${
                      language === "myanmar" ? "pb-1" : ""
                    }`}
                  >
                    {content.toCollect}
                  </button>
                )
                // <div className='fs-16 fw-700 custom-underline-container'>
                //     <span className="cursor-pointer custom-underline-text text-nowrap">{content.toCollect}</span>
                // </div>:
                // <div className='cursor-pointer disable text-nowrap'>
                // {content.toCollect}
                // </div>
              }
            </div>
            <div className="col-4" onClick={() => collectEnabled(false)}>
              {
                isCollect ? (
                  <button
                    className={`btn border-0 p-0 custom-underline-text w-auto fs-16 fw-700 ${
                      language === "myanmar" ? "pb-1" : ""
                    }`}
                  >
                    {content.collected}
                  </button>
                ) : (
                  <button
                    className={`disable btn border-0 p-0 ${
                      language === "myanmar" ? "pb-1" : ""
                    }`}
                  >
                    {content.collected}
                  </button>
                )
                // <div className='fs-16 fw-700 custom-underline-container'>
                //     <span className="cursor-pointer custom-underline-text">{content.collected}</span>
                // </div>:
                // <div className='p-0 border-0 cursor-pointer btn'>
                // {content.collected}
                // </div>
              }
            </div>
          </div>
        </div>

        {isCollect ? (
          <div className="p-t-15">
            {collectWinAll?.data?.data?.length !== 0 ? (
              collectWinAll?.data?.data?.map(
                (collectHistory: any, i: number) => (
                  <Collect
                    isTwoD={false}
                    isDetail={true}
                    roundName={collectHistory.roundName}
                    winnumber={collectHistory.winningNumber}
                    seemoreamount={collectWinAll?.data?.data.length + 1}
                    coindate={collectHistory.resultDate}
                    //coindate='5 Jan 2024 (Round 1)'
                    betamount={collectHistory.totalBetAmount}
                    winamount={collectHistory.totalWinningAmount}
                    collectdate=""
                    iscollect={false}
                    collectId={collectHistory.threedResultId}
                    type={collectHistory.type}
                    status="claim"
                    isseemore={false}
                    isExpiredTime={collectHistory?.createdAt}
                  />
                )
              )
            ) : (
              <div className="container">
                <div className="d-flex justify-content-center p-t-120">
                  <img className=" w-14" src={emptyList} alt="empty-list" />
                </div>
                <div className="d-flex row p-t-5">
                  <div className="col-12 nodata-title d-flex justify-content-center">
                    {content.emptyTitle}
                  </div>
                </div>
                <div className="d-flex row p-t-10 p-b-10">
                  <div className="col-12 nodata-list d-flex justify-content-center">
                    {content.noCollectedMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          <div className="p-t-15">
            {collectHistoryAll?.data?.data?.length !== 0 ? (
              collectHistoryAll?.data?.data?.map(
                (collectHistory: any, i: number) => (
                  <Collect
                    isTwoD={false}
                    isDetail={true}
                    roundName={collectHistory.roundName}
                    winnumber={collectHistory.winningNumber}
                    seemoreamount={collectHistoryAll?.data?.data.length + 1}
                    coindate={collectHistory.resultDate}
                    //coindate='5 Jan 2024 (Round 1)'
                    betamount={collectHistory.totalBetAmount}
                    winamount={collectHistory.totalWinningAmount}
                    collectdate=""
                    iscollect={false}
                    collectId={collectHistory.threedResultId}
                    type={collectHistory.type}
                    status="unclaim"
                    isseemore={false}
                    isExpiredTime={collectHistory?.createdAt}
                  />
                )
              )
            ) : (
              <div className="container">
                <div className="d-flex justify-content-center p-t-120">
                  <img className=" w-14" src={emptyList} alt="empty-list" />
                </div>
                <div className="d-flex row p-t-5">
                  <div className="col-12 nodata-title d-flex justify-content-center">
                    {content.emptyTitle}
                  </div>
                </div>
                <div className="d-flex row p-t-10 p-b-10">
                  <div className="text-center col-12 nodata-list d-flex justify-content-center">
                    {content.noCollectMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </AppWrapper>
  );
};

export default Collectlist;
