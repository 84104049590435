import React from 'react'
import { ReactComponent as Back } from '../../assets/icons/Back.svg';
import { ReactComponent as Refresh } from '../../assets/icons/Group.svg';
import { ReactComponent as Win } from '../../assets/icons/WinIcon.svg';
import { ReactComponent as Winner } from '../../assets/icons/Winner.svg';
import { ReactComponent as Collect } from '../../assets/icons/Collect.svg';
import { ReactComponent as Result } from '../../assets/icons/Result.svg';
import { ReactComponent as History } from '../../assets/icons/History.svg';
import { ReactComponent as CircleColor } from '../../assets/icons/CircleColor.svg';
import { ReactComponent as Calendar } from '../../assets/icons/Calendar.svg';
import { ReactComponent as DownAirrow } from '../../assets/icons/DownAirrow.svg';
import { ReactComponent as Close } from '../../assets/icons/Close.svg';
import { ReactComponent as Edit } from '../../assets/icons/Edit.svg';
import { ReactComponent as Delete } from '../../assets/icons/Delete.svg';
import { ReactComponent as ThirdCrown } from '../../assets/icons/ThirdCrown.svg';
import { ReactComponent as FirstCrown } from '../../assets/icons/FirstCrown.svg';
import { ReactComponent as SecondCrown } from '../../assets/icons/SecondCrown.svg';
import { ReactComponent as Holiday } from '../../assets/icons/Holidays.svg';
import { ReactComponent as HundardPercent } from '../../assets/icons/100percent.svg';
import { ReactComponent as NinetyPercent } from '../../assets/icons/90percent.svg';
import { ReactComponent as EightyPercent } from '../../assets/icons/80percent.svg';
import { ReactComponent as SeventyPercent } from '../../assets/icons/70percent.svg';
import { ReactComponent as SixtyPercent } from '../../assets/icons/60percent.svg';
import { ReactComponent as FiftyPercent } from '../../assets/icons/50percent.svg';
import { ReactComponent as FoutyPercent } from '../../assets/icons/40percent.svg';
import { ReactComponent as ThirtyPercent } from '../../assets/icons/30percent.svg';
import { ReactComponent as TwentyPercent } from '../../assets/icons/20percent.svg';
import { ReactComponent as AirrowRight } from '../../assets/icons/arrowright.svg';
import { ReactComponent as UserProfile } from '../../assets/icons/UserProfile.svg';
import { ReactComponent as NoData } from '../../assets/icons/nodata.svg';
import { ReactComponent as English } from '../../assets/icons/english_flag.svg';
import { ReactComponent as Chinese } from '../../assets/icons/chinese_flag.svg';
import { ReactComponent as Myanmar } from '../../assets/icons/myanmar_flag.svg';
import { ReactComponent as CheckedRadioButton } from '../../assets/icons/radio_button_checked.svg';
import { ReactComponent as UnCheckedRadioButton } from '../../assets/icons/radio_button_unchecked.svg';
import { ReactComponent as Rank1 } from '../../assets/icons/Rank1.svg';
import { ReactComponent as Rank2 } from '../../assets/icons/Rank2.svg';
import { ReactComponent as Rank3 } from '../../assets/icons/Rank3.svg';

import { ReactComponent as RefreshRight } from '../../assets/icons/refreshRight.svg';

//export icon
export const BackIcon = (props:any) => <Back {...props} />
export const RefreshIcon = (props:any) => <Refresh {...props} />
export const WinIcon = (props:any) => <Win {...props} />
export const WinnerIcon = (props:any) => <Winner {...props} />
export const CollectIcon = (props:any) => <Collect {...props} />
export const ResultIcon = (props:any) => <Result {...props} />
export const HistoryIcon = (props:any) => <History {...props} />
export const CircleColorIcon = (props:any) => <CircleColor {...props} />
export const CalendarIcon = (props:any) => <Calendar {...props} />
export const DownAirrowIcon = (props:any) => <DownAirrow {...props} />
export const CloseIcon = (props:any) => <Close {...props} />
export const EditIcon = (props:any) => <Edit {...props} />
export const DeleteIcon = (props:any) => <Delete {...props} />
export const ThirdCrownIcon = (props:any) => <ThirdCrown {...props} />
export const FirstCrownIcon = (props:any) => <FirstCrown {...props} />
export const SecondCrownIcon = (props:any) => <SecondCrown {...props} />
export const HolidayIcon = (props:any) => <Holiday {...props} />
export const HundardPercentIcon = (props:any) => <HundardPercent {...props} />
export const NinetyPercentIcon = (props:any) => <NinetyPercent {...props} />
export const EightyPercentIcon = (props:any) => <EightyPercent {...props} />
export const SeventyPercentIcon = (props:any) => <SeventyPercent {...props} />
export const SixtyPercentIcon = (props:any) => <SixtyPercent {...props} />
export const FiftyPercentIcon = (props:any) => <FiftyPercent {...props} />
export const FoutyPercentIcon = (props:any) => <FoutyPercent {...props} />
export const ThirtyPercentIcon = (props:any) => <ThirtyPercent {...props} />
export const TwentyPercentIcon = (props:any) => <TwentyPercent {...props} />
export const AirrowRightIcon = (props:any) => <AirrowRight {...props} />
export const UserProfileIcon = (props:any) => <UserProfile {...props} />
export const NoDataIcon = (props:any) => <NoData {...props} />
export const EnglishIcon = (props:any) => <English {...props} />
export const ChineseIcon = (props:any) => <Chinese {...props} />
export const MyanmarIcon = (props:any) => <Myanmar {...props} />
export const CheckedRadioButtonIcon = (props:any) => <CheckedRadioButton {...props} />
export const UnCheckedRadioButtonIcon = (props:any) => <UnCheckedRadioButton {...props} />
export const RefreshRightIcon = (props:any) => <RefreshRight {...props} />
export const Rank1Icon = (props:any) => <Rank1 {...props} />
export const Rank2Icon = (props:any) => <Rank2 {...props} />
export const Rank3Icon = (props:any) => <Rank3 {...props} />