import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { useNavigate } from "react-router-dom";
import GridTable from "../../components/common/GridTable";
import Badge from "../../assets/images/badge.png";
import ThirdWinner from "../../assets/images/ThirdWinner.png";
import FirstWinner from "../../assets/images/FirstWinner.png";
import SecondWinner from "../../assets/images/SecondWinner.png";
import {
  ThirdCrownIcon,
  SecondCrownIcon,
  FirstCrownIcon,
  NoDataIcon,
  UserProfileIcon,
  Rank1Icon,
  Rank2Icon,
  Rank3Icon,
} from "../../components/common/Icon";
import {
  useStraightWinnerList,
  useBoxWinnerList,
} from "../../api/funs/threedbetlist";
import dateFormat from "dateformat";
import { convertTimeToAMPM } from "../../services/sharedFunction";
import { translations } from "../../services/localization";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const BetWinner: React.FC = () => {
  const navigate = useNavigate();
  const [winnerList, setWinnerList] = useState<any[]>([]);
  const [tootWinnerList, setTootWinnerList] = useState<any[]>([]);

  const content = translations.winner;
  const commom = translations.commom;
  const tableHeader = translations.tableHeader;
  const roundCard = translations.roundCard;
  const [cardlabel, setCardLabel] = useState<string>(content.dade);

  const [winNumber, setWinNumber] = useState<string>();
  const [tootWinNumber, setTootWinNumber] = useState<string>();
  const [type, setType] = useState<number>(1);
  const [typeString, setTypeString] = useState<string>("dae");
  const [firstName, setFirstName] = useState<string>();
  const [secondName, setSecondName] = useState<string>();
  const [thirdName, setThirdName] = useState<string>();
  const [firstPhone, setFirstPhone] = useState<string>();
  const [secondPhone, setSecondPhone] = useState<string>();
  const [thirdPhone, setThirdPhone] = useState<string>();
  const [firstBetAmount, setFirstBetAmount] = useState<number>(0);
  const [secondBetAmount, setSecondBetAmount] = useState<number>(0);
  const [thirdBetAmount, setThirdBetAmount] = useState<number>(0);
  const [firstWinAmount, setFirstWinAmount] = useState<number>(0);
  const [secondWinAmount, setSecondWinAmount] = useState<number>(0);
  const [thirdWinAmount, setThirdWinAmount] = useState<number>(0);
  const [isNoData, setIsNoData] = useState<boolean>(true);

  const [TootfirstName, setTootFirstName] = useState<string>();
  const [TootsecondName, setTootSecondName] = useState<string>();
  const [TootthirdName, setTootThirdName] = useState<string>();
  const [TootfirstPhone, setTootFirstPhone] = useState<string>();
  const [TootsecondPhone, setTootSecondPhone] = useState<string>();
  const [TootthirdPhone, setTootThirdPhone] = useState<string>();
  const [TootfirstBetAmount, setTootFirstBetAmount] = useState<number>(0);
  const [TootsecondBetAmount, setTootSecondBetAmount] = useState<number>(0);
  const [TootthirdBetAmount, setTootThirdBetAmount] = useState<number>(0);
  const [TootfirstWinAmount, setTootFirstWinAmount] = useState<number>(0);
  const [TootsecondWinAmount, setTootSecondWinAmount] = useState<number>(0);
  const [TootthirdWinAmount, setTootThirdWinAmount] = useState<number>(0);
  const [set, setSet] = useState<string>();
  const [value, setValue] = useState<string>();
  const [tootIsNoData, setTootIsNoData] = useState<boolean>(true);

  const { data: datalist, isLoading: isLoadingWinner } =
    useStraightWinnerList();
  const { data: databoxlist, isLoading: isLoadingWinnerToot } =
    useBoxWinnerList();

  const changeType = (Event: number) => {
    if (Event === 1) {
      setType(0);

      setCardLabel(content.tootWinnerTitle);
      const tootarray = datalist?.data?.data?.lastRound?.tootNumbers;
      if (tootarray?.length) {
        const stringValue = tootarray.join(",");
        setTootWinNumber(stringValue);
      }
      if (datalist?.data?.data?.threedStraightWinnerLists?.length === 0) {
        setIsNoData(true);
      } else {
        setIsNoData(false);
        if (datalist?.data?.data?.threedStraightWinnerLists?.length > 0) {
          const allData = datalist?.data?.data?.threedStraightWinnerLists;
          const updatedBetList = [];
          let index = 0;
          for (let i = 0; i < allData?.length; i++) {
            updatedBetList[index] = {
              ...allData[i],
            };
            index++;
          }
          if (datalist?.data?.data?.threedStraightWinnerLists[0]) {
            setFirstName(
              datalist?.data?.data?.threedStraightWinnerLists[0]?.userName
            );
            setFirstPhone(
              maskPhoneNumber(
                datalist?.data?.data?.threedStraightWinnerLists[0]?.phone
              )
            );
            setFirstBetAmount(
              datalist?.data?.data?.threedStraightWinnerLists[0]?.totalBetAmount
            );
            setFirstWinAmount(
              datalist?.data?.data?.threedStraightWinnerLists[0]
                ?.totalWinningAmount
            );
          }
          if (datalist?.data?.data?.threedStraightWinnerLists[1]) {
            setSecondName(
              datalist?.data?.data?.threedStraightWinnerLists[1]?.userName
            );
            setSecondPhone(
              maskPhoneNumber(
                datalist?.data?.data?.threedStraightWinnerLists[1]?.phone
              )
            );
            setSecondBetAmount(
              datalist?.data?.data?.threedStraightWinnerLists[1]?.totalBetAmount
            );
            setSecondWinAmount(
              datalist?.data?.data?.threedStraightWinnerLists[1]
                ?.totalWinningAmount
            );
          }
          if (datalist?.data?.data?.threedStraightWinnerLists[2]) {
            setThirdName(
              datalist?.data?.data?.threedStraightWinnerLists[2]?.userName
            );
            setThirdPhone(
              maskPhoneNumber(
                datalist?.data?.data?.threedStraightWinnerLists[2]?.phone
              )
            );
            setThirdBetAmount(
              datalist?.data?.data?.threedStraightWinnerLists[2]?.totalBetAmount
            );
            setThirdWinAmount(
              datalist?.data?.data?.threedStraightWinnerLists[2]
                ?.totalWinningAmount
            );
          }
          setWinnerList(updatedBetList);
        } else {
          setWinnerList([]);
        }
      }
    } else {
      setCardLabel(content.dade);
      setType(1);

      setWinNumber(datalist?.data?.data?.lastRound?.winningNumber);
      //setTootWinNumber(databoxlist?.data?.data?.lastRound?.winningNumber)
      if (datalist?.data?.data?.threedStraightWinnerLists?.length === 0) {
        setTootIsNoData(true);
      } else {
        setTootIsNoData(false);
        if (databoxlist?.data?.data?.threedTootWinnerLists?.length > 0) {
          const allData = databoxlist?.data?.data?.threedTootWinnerLists;
          const updatedBetList = [];
          let index = 0;
          for (let i = 0; i < allData?.length; i++) {
            updatedBetList[index] = {
              ...allData[i],
            };
            index++;
          }
          if (databoxlist?.data?.data?.threedTootWinnerLists[0]) {
            setTootFirstName(
              databoxlist?.data?.data?.threedTootWinnerLists[0]?.userName
            );
            setTootFirstPhone(
              maskPhoneNumber(
                databoxlist?.data?.data?.threedTootWinnerLists[0]?.phone
              )
            );
            setTootFirstBetAmount(
              databoxlist?.data?.data?.threedTootWinnerLists[0]?.totalBetAmount
            );
            setTootFirstWinAmount(
              databoxlist?.data?.data?.threedTootWinnerLists[0]
                ?.totalWinningAmount
            );
          }
          if (databoxlist?.data?.data?.threedTootWinnerLists[1]) {
            setTootSecondName(
              databoxlist?.data?.data?.threedTootWinnerLists[1]?.userName
            );
            setTootSecondPhone(
              maskPhoneNumber(
                databoxlist?.data?.data?.threedTootWinnerLists[1]?.phone
              )
            );
            setTootSecondBetAmount(
              databoxlist?.data?.data?.threedTootWinnerLists[1]?.totalBetAmount
            );
            setTootSecondWinAmount(
              databoxlist?.data?.data?.threedTootWinnerLists[1]
                ?.totalWinningAmount
            );
          }
          if (databoxlist?.data?.data?.threedTootWinnerLists[2]) {
            setTootThirdName(
              databoxlist?.data?.data?.threedTootWinnerLists[2]?.userName
            );
            setTootThirdPhone(
              maskPhoneNumber(
                databoxlist?.data?.data?.threedTootWinnerLists[2]?.phone
              )
            );
            setTootThirdBetAmount(
              databoxlist?.data?.data?.threedTootWinnerLists[2]?.totalBetAmount
            );
            setTootThirdWinAmount(
              databoxlist?.data?.data?.threedTootWinnerLists[2]
                ?.totalWinningAmount
            );
          }
          setTootWinnerList(updatedBetList);
        }
      }
    }
  };

  function maskPhoneNumber(phoneNumber: string) {
    const visibleDigits = 4; // Number of visible digits at the end
    const maskedLength = phoneNumber?.length - visibleDigits;

    // Repeat '*' characters for the masked part
    const maskedPart = "*".repeat(maskedLength);

    // Concatenate the masked part with the visible part (last four digits)
    const maskedPhoneNumber = maskedPart + phoneNumber?.slice(maskedLength);

    return maskedPhoneNumber;
  }

  useEffect(() => {
    // setCardLabel(datalist?.data?.data?.lastRound);

    setWinNumber(datalist?.data?.data?.lastRound?.winningNumber);
    if (datalist?.data?.data?.threedStraightWinnerLists?.length === 0) {
      setIsNoData(true);
    } else {
      if (datalist?.data?.data?.threedStraightWinnerLists?.length > 0) {
        const allData = datalist?.data?.data?.threedStraightWinnerLists;
        const updatedBetList = [];
        let index = 0;
        for (let i = 0; i < allData?.length; i++) {
          updatedBetList[index] = {
            ...allData[i],
          };
          index++;
        }
        setWinnerList(updatedBetList);
        if (datalist?.data?.data?.threedStraightWinnerLists[0]) {
          setFirstName(
            datalist?.data?.data?.threedStraightWinnerLists[0]?.userName
          );
          setFirstPhone(
            maskPhoneNumber(
              datalist?.data?.data?.threedStraightWinnerLists[0]?.phone
            )
          );
          setFirstBetAmount(
            datalist?.data?.data?.threedStraightWinnerLists[0]?.totalBetAmount
          );
          setFirstWinAmount(
            datalist?.data?.data?.threedStraightWinnerLists[0]
              ?.totalWinningAmount
          );
        }
        if (datalist?.data?.data?.threedStraightWinnerLists[1]) {
          setSecondName(
            datalist?.data?.data?.threedStraightWinnerLists[1]?.userName
          );
          setSecondPhone(
            maskPhoneNumber(
              datalist?.data?.data?.threedStraightWinnerLists[1]?.phone
            )
          );
          setSecondBetAmount(
            datalist?.data?.data?.threedStraightWinnerLists[1]?.totalBetAmount
          );
          setSecondWinAmount(
            datalist?.data?.data?.threedStraightWinnerLists[1]
              ?.totalWinningAmount
          );
        }
        if (datalist?.data?.data?.threedStraightWinnerLists[2]) {
          setThirdName(
            datalist?.data?.data?.threedStraightWinnerLists[2]?.userName
          );
          setThirdPhone(
            maskPhoneNumber(
              datalist?.data?.data?.threedStraightWinnerLists[2]?.phone
            )
          );
          setThirdBetAmount(
            datalist?.data?.data?.threedStraightWinnerLists[2]?.totalBetAmount
          );
          setThirdWinAmount(
            datalist?.data?.data?.threedStraightWinnerLists[2]
              ?.totalWinningAmount
          );
        }
        setIsNoData(false);
      } else {
        setWinnerList([]);
      }
    }
  }, [datalist]);

  useEffect(() => {
    const tootarray = databoxlist?.data?.data?.lastRound?.tootNumbers;
    if (tootarray?.length) {
      const stringValue = tootarray.join(",");
      setTootWinNumber(stringValue);
    }
    //setTootWinNumber(databoxlist?.data?.data?.lastRound?.winningNumber)
    if (databoxlist?.data?.data?.threedTootWinnerLists?.length === 0) {
      setTootIsNoData(true);
    } else {
      if (databoxlist?.data?.data?.threedTootWinnerLists?.length > 0) {
        const allData = databoxlist?.data?.data?.threedTootWinnerLists;
        const updatedBetList = [];
        let index = 0;
        for (let i = 0; i < allData?.length; i++) {
          updatedBetList[index] = {
            ...allData[i],
          };
          index++;
        }
        setTootWinnerList(updatedBetList);
        if (databoxlist?.data?.data?.threedTootWinnerLists[0]) {
          setTootFirstName(
            databoxlist?.data?.data?.threedTootWinnerLists[0]?.userName
          );
          setTootFirstPhone(
            maskPhoneNumber(
              databoxlist?.data?.data?.threedTootWinnerLists[0]?.phone
            )
          );
          setTootFirstBetAmount(
            databoxlist?.data?.data?.threedTootWinnerLists[0]?.totalBetAmount
          );
          setTootFirstWinAmount(
            databoxlist?.data?.data?.threedTootWinnerLists[0]
              ?.totalWinningAmount
          );
        }
        if (databoxlist?.data?.data?.threedTootWinnerLists[1]) {
          setTootSecondName(
            databoxlist?.data?.data?.threedTootWinnerLists[1]?.userName
          );
          setTootSecondPhone(
            maskPhoneNumber(
              databoxlist?.data?.data?.threedTootWinnerLists[1]?.phone
            )
          );
          setTootSecondBetAmount(
            databoxlist?.data?.data?.threedTootWinnerLists[1]?.totalBetAmount
          );
          setTootSecondWinAmount(
            databoxlist?.data?.data?.threedTootWinnerLists[1]
              ?.totalWinningAmount
          );
        }
        if (databoxlist?.data?.data?.threedTootWinnerLists[2]) {
          setTootThirdName(
            databoxlist?.data?.data?.threedTootWinnerLists[2]?.userName
          );
          setTootThirdPhone(
            maskPhoneNumber(
              databoxlist?.data?.data?.threedTootWinnerLists[2]?.phone
            )
          );
          setTootThirdBetAmount(
            databoxlist?.data?.data?.threedTootWinnerLists[2]?.totalBetAmount
          );
          setTootThirdWinAmount(
            databoxlist?.data?.data?.threedTootWinnerLists[2]
              ?.totalWinningAmount
          );
        }
        setTootIsNoData(false);
      } else {
        setTootWinnerList([]);
      }
    }
  }, [databoxlist]);

  const headerdata = [
    {
      name: tableHeader.no,
    },
    {
      name: tableHeader.name,
    },
    {
      name: tableHeader.bet,
    },
    {
      name: tableHeader.winAmount,
    },
  ];
  const CheckTopThreeIcon = (countNumber: number) => {
    if (countNumber === 1) {
      return <Rank1Icon />;
    } else if (countNumber === 2) {
      return <Rank2Icon />;
    } else if (countNumber === 3) {
      return <Rank3Icon />;
    } else {
      return countNumber;
    }
  };

  return (
    <AppWrapper title={content.headerTitleThree} isback={true} isRefresh={true}>
      {isLoadingWinner || isLoadingWinnerToot ? (
        <LoadingSpinner />
      ) : (
        <div className="container default-height">
          <div className="px-2 pb-4 row p-t-50">
            <div className="pt-3 information-card">
              <div className={`row justify-content-center item-center `}>
                <div
                  className={`card-custom-header primary-color bg-white d-h-42 d-flex item-center text-xs xs:text-sm ${
                    type === 1 ? "d-w-86" : "d-w-200"
                  } `}
                >
                  <span>{cardlabel ? cardlabel : content.dade}</span>
                </div>
              </div>
              <div className="container">
                <div className="py-4 row fs-16 fw-500 item-center">
                  <div className="col">
                    <div className="text-red-700 font-[500] text-[30px] flex flex-wrap justify-center">
                      {type === 1
                        ? winNumber?.split(",").join(", ")
                        : datalist?.data?.data?.lastRound?.tootNumbers
                            ?.toString()
                            .split(",")
                            .join(", ")}
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center row fs-16 fw-500 item-center lh-20">
                <hr className="full-line"></hr>
              </div>
              <div className="text-center row fs-16 fw-500 item-center lh-20 p-t-10">
                <div className="fs-14 fw-500 lh-17">
                  {content.resultDate}:{" "}
                  {type === 0
                    ? dateFormat(
                        datalist?.data?.data?.lastRound?.resultDate,
                        "mmm dd, yyyy, "
                      )
                    : dateFormat(
                        databoxlist?.data?.data?.lastRound?.resultDate,
                        "mmm dd, yyyy, "
                      )}
                  {convertTimeToAMPM(
                    datalist?.data?.data?.lastRound?.announceTime
                  )}
                </div>
                <div className="p-b-10 p-t-10">
                  <button
                    onClick={() => changeType(type)}
                    className="text-xs btn-ok justify-content-center item-center d-h-38 d-w-124 xs:text-sm"
                  >
                    {type === 1 ? commom.buttonToot : commom.buttonDade}
                  </button>
                </div>
              </div>
            </div>
          </div>

          {type === 1 ? (
            winnerList?.length === 0 ? (
              <div className="container">
                <div className="d-flex justify-content-center p-t-120">
                  <NoDataIcon />
                </div>
                <div className="d-flex row p-t-5">
                  <div className="col-12 nodata-title d-flex justify-content-center">
                    {commom.winnerNoDataLabel}
                  </div>
                </div>
                <div className="d-flex row p-t-10 p-b-10">
                  <div className="col-12 nodata-list d-flex justify-content-center">
                    {commom.winnerNoDataNextLabel}
                  </div>
                </div>
              </div>
            ) : (
              <>
                <section className="py-[40px]">
                  <div className="grid grid-cols-12">
                    <div className="col-span-4 pt-8">
                      <div className="relative flex justify-center">
                        <div className="absolute">
                          <img
                            src={require("../../assets/images/badge.png")}
                            className="w-full xxs:h-[170px] h-[170px]"
                            alt="badge"
                          />
                        </div>
                        <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                          <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                            <div className="flex flex-col items-center">
                              <SecondCrownIcon />
                              {datalist?.data?.data
                                ?.threedStraightWinnerLists?.[1]
                                ?.userImageUrl ? (
                                <img
                                  className="object-cover w-10 h-10 rounded-full"
                                  src={
                                    datalist?.data?.data
                                      ?.threedStraightWinnerLists?.[1]
                                      ?.userImageUrl
                                  }
                                  alt=""
                                />
                              ) : (
                                <UserProfileIcon width="40" height="40" />
                              )}{" "}
                            </div>
                            <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                              <div className="second">
                                <div className="text-xs lh-15">
                                  {content.second}
                                </div>
                              </div>
                              {secondName ? (
                                <div className="space-y-2 xxs:space-y-1">
                                  <div className="text-xs text-center text-yellow-400">
                                    {secondName}
                                  </div>
                                  <div className="text-xs text-center text-white">
                                    {secondPhone}
                                  </div>
                                  <div className="space-y-1">
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                      <span>{content.bet}</span>
                                      <span>
                                        {secondBetAmount.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                      <span>{content.win}</span>
                                      <span>
                                        {secondWinAmount.toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="mt-4 text-[10px] text-center text-yellow-400">
                                  {commom.noWinners}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4">
                      <div className="relative flex justify-center">
                        <div className="absolute">
                          <img
                            src={require("../../assets/images/badge.png")}
                            className="w-full xxs:h-[170px] h-[170px]"
                            alt="badge"
                          />
                        </div>
                        <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                          <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                            <div className="flex flex-col items-center">
                              <FirstCrownIcon />
                              {datalist?.data?.data
                                ?.threedStraightWinnerLists?.[0]
                                ?.userImageUrl ? (
                                <img
                                  className="object-cover w-10 h-10 rounded-full"
                                  src={
                                    datalist?.data?.data
                                      ?.threedStraightWinnerLists?.[0]
                                      ?.userImageUrl
                                  }
                                  alt=""
                                />
                              ) : (
                                <UserProfileIcon width="40" height="40" />
                              )}
                            </div>
                            <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                              <div className="first">
                                <div className="text-xs lh-15">
                                  {content.first}
                                </div>
                              </div>
                              {firstName ? (
                                <div className="space-y-2 xxs:space-y-1">
                                  <div className="text-xs text-center text-yellow-400">
                                    {firstName}
                                  </div>
                                  <div className="text-xs text-center text-white">
                                    {firstPhone}
                                  </div>
                                  <div className="space-y-1">
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                      <span>{content.bet}</span>
                                      <span>
                                        {firstBetAmount.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                      <span>{content.win}</span>
                                      <span>
                                        {firstWinAmount.toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="mt-4 text-[10px] text-center text-yellow-400">
                                  {commom.noWinners}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-4 pt-8">
                      <div className="relative flex justify-center">
                        <div className="absolute">
                          <img
                            src={require("../../assets/images/badge.png")}
                            className="w-full xxs:h-[170px] h-[170px]"
                            alt="badge"
                          />
                        </div>
                        <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                          <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                            <div className="flex flex-col items-center">
                              <ThirdCrownIcon />
                              {datalist?.data?.data
                                ?.threedStraightWinnerLists?.[2]
                                ?.userImageUrl ? (
                                <img
                                  className="object-cover w-10 h-10 rounded-full"
                                  src={
                                    datalist?.data?.data
                                      ?.threedStraightWinnerLists?.[2]
                                      ?.userImageUrl
                                  }
                                  alt=""
                                />
                              ) : (
                                <UserProfileIcon width="40" height="40" />
                              )}{" "}
                            </div>
                            <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                              <div className="third">
                                <div className="text-xs lh-15">
                                  {content.third}
                                </div>
                              </div>
                              {thirdName ? (
                                <div className="space-y-2 xxs:space-y-1">
                                  <div className="text-xs text-center text-yellow-400">
                                    {thirdName}
                                  </div>
                                  <div className="text-xs text-center text-white">
                                    {thirdPhone}
                                  </div>
                                  <div className="space-y-1">
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                      <span>{content.bet}</span>
                                      <span>
                                        {thirdBetAmount.toLocaleString()}
                                      </span>
                                    </div>
                                    <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                      <span>{content.win}</span>
                                      <span>
                                        {thirdWinAmount.toLocaleString()}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              ) : (
                                <div className="mt-4 text-[10px] text-center text-yellow-400">
                                  {commom.noWinners}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="pb-8 row item-center">
                  <div className="col-12">
                    <GridTable headerdata={headerdata}>
                      {winnerList.map((winner: any, i: number) => (
                        <tr
                          key={i}
                          className="table-row bottom-dashed h-50 fs-14 lh-25"
                        >
                          <td>{CheckTopThreeIcon(i + 1)}</td>
                          <td>
                            {winner.userName}
                            <br />
                            {maskPhoneNumber(winner.phone)}
                          </td>
                          <td>{winner.totalBetAmount.toLocaleString()}</td>
                          <td>{winner.totalWinningAmount.toLocaleString()}</td>
                        </tr>
                      ))}
                    </GridTable>
                  </div>
                </div>
              </>
            )
          ) : tootWinnerList?.length === 0 ? (
            <div className="container">
              <div className="d-flex justify-content-center p-t-120">
                <NoDataIcon />
              </div>
              <div className="d-flex row p-t-5">
                <div className="col-12 nodata-title d-flex justify-content-center">
                  {commom.winnerNoDataLabel}
                </div>
              </div>
              <div className="d-flex row p-t-10 p-b-10">
                <div className="col-12 nodata-list d-flex justify-content-center">
                  {commom.winnerNoDataNextLabel}
                </div>
              </div>
            </div>
          ) : (
            <>
              <section className="py-[40px]">
                <div className="grid grid-cols-12">
                  <div className="col-span-4 pt-8">
                    <div className="relative flex justify-center">
                      <div className="absolute">
                        <img
                          src={require("../../assets/images/badge.png")}
                          className="w-full xxs:h-[170px] h-[170px]"
                          alt="badge"
                        />
                      </div>
                      <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                        <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                          <div className="flex flex-col items-center">
                            <SecondCrownIcon />
                            {datalist?.data?.data?.threedTootWinnerLists?.[1]
                              ?.userImageUrl ? (
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={
                                  datalist?.data?.data
                                    ?.threedTootWinnerLists?.[1]?.userImageUrl
                                }
                                alt=""
                              />
                            ) : (
                              <UserProfileIcon width="40" height="40" />
                            )}{" "}
                          </div>
                          <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                            <div className="second">
                              <div className="text-xs lh-15">
                                {content.second}
                              </div>
                            </div>
                            {TootsecondName ? (
                              <div className="space-y-2 xxs:space-y-1">
                                <div className="text-xs text-center text-yellow-400">
                                  {TootsecondName}
                                </div>
                                <div className="text-xs text-center text-white">
                                  {TootsecondPhone}
                                </div>
                                <div className="space-y-1">
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                    <span>{content.bet}</span>
                                    <span>
                                      {TootsecondBetAmount.toLocaleString()}
                                    </span>
                                  </div>
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                    <span>{content.win}</span>
                                    <span>
                                      {TootsecondWinAmount.toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-4 text-[10px] text-center text-yellow-400">
                                {commom.noWinners}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4">
                    <div className="relative flex justify-center">
                      <div className="absolute">
                        <img
                          src={require("../../assets/images/badge.png")}
                          className="w-full xxs:h-[170px] h-[170px]"
                          alt="badge"
                        />
                      </div>
                      <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                        <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                          <div className="flex flex-col items-center">
                            <FirstCrownIcon />
                            {datalist?.data?.data?.threedTootWinnerLists?.[0]
                              ?.userImageUrl ? (
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={
                                  datalist?.data?.data
                                    ?.threedTootWinnerLists?.[0]?.userImageUrl
                                }
                                alt=""
                              />
                            ) : (
                              <UserProfileIcon width="40" height="40" />
                            )}{" "}
                          </div>
                          <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                            <div className="first">
                              <div className="text-xs lh-15">
                                {content.first}
                              </div>
                            </div>
                            {TootfirstName ? (
                              <div className="space-y-2 xxs:space-y-1">
                                <div className="text-xs text-center text-yellow-400">
                                  {TootfirstName}
                                </div>
                                <div className="text-xs text-center text-white">
                                  {TootfirstPhone}
                                </div>
                                <div className="space-y-1">
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                    <span>{content.bet}</span>
                                    <span>
                                      {TootfirstBetAmount.toLocaleString()}
                                    </span>
                                  </div>
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                    <span>{content.win}</span>
                                    <span>
                                      {TootfirstWinAmount.toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-4 text-[10px] text-center text-yellow-400">
                                {commom.noWinners}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-span-4 pt-8">
                    <div className="relative flex justify-center">
                      <div className="absolute">
                        <img
                          src={require("../../assets/images/badge.png")}
                          className="w-full xxs:h-[170px] h-[170px]"
                          alt="badge"
                        />
                      </div>
                      <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
                        <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                          <div className="flex flex-col items-center">
                            <ThirdCrownIcon />
                            {datalist?.data?.data?.threedTootWinnerLists?.[2]
                              ?.userImageUrl ? (
                              <img
                                className="object-cover w-10 h-10 rounded-full"
                                src={
                                  datalist?.data?.data
                                    ?.threedTootWinnerLists?.[2]?.userImageUrl
                                }
                                alt=""
                              />
                            ) : (
                              <UserProfileIcon width="40" height="40" />
                            )}{" "}
                          </div>
                          <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                            <div className="third">
                              <div className="text-xs lh-15">
                                {content.third}
                              </div>
                            </div>
                            {TootthirdName ? (
                              <div className="space-y-2 xxs:space-y-1">
                                <div className="text-xs text-center text-yellow-400">
                                  {TootthirdName}
                                </div>
                                <div className="text-xs text-center text-white">
                                  {TootthirdPhone}
                                </div>
                                <div className="space-y-1">
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                                    <span>{content.bet}</span>
                                    <span>
                                      {TootthirdBetAmount.toLocaleString()}
                                    </span>
                                  </div>
                                  <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                                    <span>{content.win}</span>
                                    <span>
                                      {TootthirdWinAmount.toLocaleString()}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="mt-4 text-[10px] text-center text-yellow-400">
                                {commom.noWinners}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {tootWinnerList?.length > 0 ? (
                <div className="row p-b-10 item-center">
                  <div className="col-12">
                    <GridTable headerdata={headerdata}>
                      {tootWinnerList.map((tootwinner: any, i: number) => (
                        <tr
                          key={i}
                          className="table-row bottom-dashed h-50 fs-14 lh-25"
                        >
                          <td>{CheckTopThreeIcon(i + 1)}</td>
                          <td>
                            {tootwinner.userName}
                            <br />
                            {maskPhoneNumber(tootwinner.phone)}
                          </td>
                          <td>{tootwinner.totalBetAmount.toLocaleString()}</td>
                          <td>
                            {tootwinner.totalWinningAmount.toLocaleString()}
                          </td>
                        </tr>
                      ))}
                    </GridTable>
                  </div>
                </div>
              ) : (
                <div className="mt-4 text-[10px] text-center text-yellow-400">
                  {commom.noWinners}
                </div>
              )}
            </>
          )}

          {/* {winnerList?.length === 0 && type === 0 && (
  <div className="container">
    <div className="d-flex justify-content-center p-t-120">
      <NoDataIcon />
    </div>
    <div className="d-flex row p-t-5">
      <div className="col-12 nodata-title d-flex justify-content-center">
        {commom.winnerNoDataLabel}
      </div>
    </div>
    <div className="d-flex row p-t-10 p-b-10">
      <div className="col-12 nodata-list d-flex justify-content-center">
        {commom.winnerNoDataNextLabel}
      </div>
    </div>
  </div>
)}

{tootWinnerList?.length === 0 && type === 1 && (
  <div className="container">
    <div className="d-flex justify-content-center p-t-120">
      <NoDataIcon />
    </div>
    <div className="d-flex row p-t-5">
      <div className="col-12 nodata-title d-flex justify-content-center">
        {commom.winnerNoDataLabel}
      </div>
    </div>
    <div className="d-flex row p-t-10 p-b-10">
      <div className="col-12 nodata-list d-flex justify-content-center">
        {commom.winnerNoDataNextLabel}
      </div>
    </div>
  </div>
)}

{type === 1 && winnerList?.length > 0 && (
  <>
    <section className="py-[40px]">
      <div className="grid grid-cols-12">
        <div className="col-span-4 pt-8">
          <div className="relative flex justify-center">
            <div className="absolute">
              <img
                src={require("../../assets/images/badge.png")}
                className="w-full xxs:h-[170px] h-[170px]"
                alt="badge"
              />
            </div>
            <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
              <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                <div className="flex flex-col items-center">
                  <SecondCrownIcon />
                  {datalist?.data?.data?.threedStraightWinnerLists?.[1]
                    ?.userImageUrl ? (
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        datalist?.data?.data
                          ?.threedStraightWinnerLists?.[1]?.userImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <UserProfileIcon width="40" height="40" />
                  )}{" "}
                </div>
                <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                  <div className="second">
                    <div className="text-xs lh-15">
                      {content.second}
                    </div>
                  </div>
                  {secondName ? (
                    <div className="space-y-2 xxs:space-y-1">
                      <div className="text-xs text-center text-yellow-400">
                        {secondName}
                      </div>
                      <div className="text-xs text-center text-white">
                        {secondPhone}
                      </div>
                      <div className="space-y-1">
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                          <span>{content.bet}</span>
                          <span>
                            {secondBetAmount.toLocaleString()}
                          </span>
                        </div>
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                          <span>{content.win}</span>
                          <span>
                            {secondWinAmount.toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 text-[10px] text-center text-yellow-400">
                      {commom.noWinners}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="relative flex justify-center">
            <div className="absolute">
              <img
                src={require("../../assets/images/badge.png")}
                className="w-full xxs:h-[170px] h-[170px]"
                alt="badge"
              />
            </div>
            <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
              <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                <div className="flex flex-col items-center">
                  <FirstCrownIcon />
                  {datalist?.data?.data?.threedStraightWinnerLists?.[0]
                    ?.userImageUrl ? (
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        datalist?.data?.data
                          ?.threedStraightWinnerLists?.[0]?.userImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <UserProfileIcon width="40" height="40" />
                  )}
                </div>
                <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                  <div className="first">
                    <div className="text-xs lh-15">{content.first}</div>
                  </div>
                  {firstName ? (
                    <div className="space-y-2 xxs:space-y-1">
                      <div className="text-xs text-center text-yellow-400">
                        {firstName}
                      </div>
                      <div className="text-xs text-center text-white">
                        {firstPhone}
                      </div>
                      <div className="space-y-1">
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                          <span>{content.bet}</span>
                          <span>{firstBetAmount.toLocaleString()}</span>
                        </div>
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                          <span>{content.win}</span>
                          <span>{firstWinAmount.toLocaleString()}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 text-[10px] text-center text-yellow-400">
                      {commom.noWinners}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 pt-8">
          <div className="relative flex justify-center">
            <div className="absolute">
              <img
                src={require("../../assets/images/badge.png")}
                className="w-full xxs:h-[170px] h-[170px]"
                alt="badge"
              />
            </div>
            <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
              <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                <div className="flex flex-col items-center">
                  <ThirdCrownIcon />
                  {datalist?.data?.data?.threedStraightWinnerLists?.[2]
                    ?.userImageUrl ? (
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        datalist?.data?.data
                          ?.threedStraightWinnerLists?.[2]?.userImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <UserProfileIcon width="40" height="40" />
                  )}{" "}
                </div>
                <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                  <div className="third">
                    <div className="text-xs lh-15">{content.third}</div>
                  </div>
                  {thirdName ? (
                    <div className="space-y-2 xxs:space-y-1">
                      <div className="text-xs text-center text-yellow-400">
                        {thirdName}
                      </div>
                      <div className="text-xs text-center text-white">
                        {thirdPhone}
                      </div>
                      <div className="space-y-1">
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                          <span>{content.bet}</span>
                          <span>{thirdBetAmount.toLocaleString()}</span>
                        </div>
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                          <span>{content.win}</span>
                          <span>{thirdWinAmount.toLocaleString()}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 text-[10px] text-center text-yellow-400">
                      {commom.noWinners}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div className="pb-8 row item-center">
      <div className="col-12">
        <GridTable headerdata={headerdata}>
          {winnerList.map((winner: any, i: number) => (
            <tr
              key={i}
              className="table-row bottom-dashed h-50 fs-14 lh-25"
            >
              <td>{CheckTopThreeIcon(i + 1)}</td>
              <td>
                {winner.userName}
                <br />
                {maskPhoneNumber(winner.phone)}
              </td>
              <td>{winner.totalBetAmount.toLocaleString()}</td>
              <td>{winner.totalWinningAmount.toLocaleString()}</td>
            </tr>
          ))}
        </GridTable>
      </div>
    </div>
  </>
)}

{type === 0 && tootWinnerList?.length > 1 && (
  <>
    <section className="py-[40px]">
      <div className="grid grid-cols-12">
        <div className="col-span-4 pt-8">
          <div className="relative flex justify-center">
            <div className="absolute">
              <img
                src={require("../../assets/images/badge.png")}
                className="w-full xxs:h-[170px] h-[170px]"
                alt="badge"
              />
            </div>
            <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
              <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                <div className="flex flex-col items-center">
                  <SecondCrownIcon />
                  {datalist?.data?.data?.threedTootWinnerLists?.[1]
                    ?.userImageUrl ? (
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        datalist?.data?.data?.threedTootWinnerLists?.[1]
                          ?.userImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <UserProfileIcon width="40" height="40" />
                  )}{" "}
                </div>
                <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                  <div className="second">
                    <div className="text-xs lh-15">
                      {content.second}
                    </div>
                  </div>
                  {TootsecondName ? (
                    <div className="space-y-2 xxs:space-y-1">
                      <div className="text-xs text-center text-yellow-400">
                        {TootsecondName}
                      </div>
                      <div className="text-xs text-center text-white">
                        {TootsecondPhone}
                      </div>
                      <div className="space-y-1">
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                          <span>{content.bet}</span>
                          <span>
                            {TootsecondBetAmount.toLocaleString()}
                          </span>
                        </div>
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                          <span>{content.win}</span>
                          <span>
                            {TootsecondWinAmount.toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 text-[10px] text-center text-yellow-400">
                      {commom.noWinners}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4">
          <div className="relative flex justify-center">
            <div className="absolute">
              <img
                src={require("../../assets/images/badge.png")}
                className="w-full xxs:h-[170px] h-[170px]"
                alt="badge"
              />
            </div>
            <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
              <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                <div className="flex flex-col items-center">
                  <FirstCrownIcon />
                  {datalist?.data?.data?.threedTootWinnerLists?.[0]
                    ?.userImageUrl ? (
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        datalist?.data?.data?.threedTootWinnerLists?.[0]
                          ?.userImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <UserProfileIcon width="40" height="40" />
                  )}{" "}
                </div>
                <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                  <div className="first">
                    <div className="text-xs lh-15">{content.first}</div>
                  </div>
                  {TootfirstName ? (
                    <div className="space-y-2 xxs:space-y-1">
                      <div className="text-xs text-center text-yellow-400">
                        {TootfirstName}
                      </div>
                      <div className="text-xs text-center text-white">
                        {TootfirstPhone}
                      </div>
                      <div className="space-y-1">
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                          <span>{content.bet}</span>
                          <span>
                            {TootfirstBetAmount.toLocaleString()}
                          </span>
                        </div>
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                          <span>{content.win}</span>
                          <span>
                            {TootfirstWinAmount.toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 text-[10px] text-center text-yellow-400">
                      {commom.noWinners}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-4 pt-8">
          <div className="relative flex justify-center">
            <div className="absolute">
              <img
                src={require("../../assets/images/badge.png")}
                className="w-full xxs:h-[170px] h-[170px]"
                alt="badge"
              />
            </div>
            <div className="xxs:h-[170px] h-[170px] -translate-y-[38px]">
              <div className="flex flex-col items-center space-y-4 xxs:space-y-2">
                <div className="flex flex-col items-center">
                  <ThirdCrownIcon />
                  {datalist?.data?.data?.threedTootWinnerLists?.[2]
                    ?.userImageUrl ? (
                    <img
                      className="object-cover w-10 h-10 rounded-full"
                      src={
                        datalist?.data?.data?.threedTootWinnerLists?.[2]
                          ?.userImageUrl
                      }
                      alt=""
                    />
                  ) : (
                    <UserProfileIcon width="40" height="40" />
                  )}{" "}
                </div>
                <div className="flex flex-col items-center space-y-2 xxs:space-y-1">
                  <div className="third">
                    <div className="text-xs lh-15">{content.third}</div>
                  </div>
                  {TootthirdName ? (
                    <div className="space-y-2 xxs:space-y-1">
                      <div className="text-xs text-center text-yellow-400">
                        {TootthirdName}
                      </div>
                      <div className="text-xs text-center text-white">
                        {TootthirdPhone}
                      </div>
                      <div className="space-y-1">
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-2">
                          <span>{content.bet}</span>
                          <span>
                            {TootthirdBetAmount.toLocaleString()}
                          </span>
                        </div>
                        <div className="text-[10px] lh-15 text-yellow-500 flex maxXs:flex-col maxXs:items-center justify-between px-1">
                          <span>{content.win}</span>
                          <span>
                            {TootthirdWinAmount.toLocaleString()}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4 text-[10px] text-center text-yellow-400">
                      {commom.noWinners}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {tootWinnerList?.length > 0 ? (
      <div className="row p-b-10 item-center">
        <div className="col-12">
          <GridTable headerdata={headerdata}>
            {tootWinnerList.map((tootwinner: any, i: number) => (
              <tr
                key={i}
                className="table-row bottom-dashed h-50 fs-14 lh-25"
              >
                <td>{CheckTopThreeIcon(i + 1)}</td>
                <td>
                  {tootwinner.userName}
                  <br />
                  {maskPhoneNumber(tootwinner.phone)}
                </td>
                <td>{tootwinner.totalBetAmount.toLocaleString()}</td>
                <td>
                  {tootwinner.totalWinningAmount.toLocaleString()}
                </td>
              </tr>
            ))}
          </GridTable>
        </div>
      </div>
    ) : (
      <div className="mt-4 text-[10px] text-center text-yellow-400">
        {commom.noWinners}
      </div>
    )}
  </>
)} */}
          {/* {type === 0 ? (
  tootIsNoData ? (
    <div className="container">
      <div className="d-flex justify-content-center p-t-120">
        <NoDataIcon />
      </div>
      <div className="d-flex row p-t-5">
        <div className="col-12 nodata-title d-flex justify-content-center">
          {commom.winnerNoDataLabel}
        </div>
      </div>
      <div className="d-flex row p-t-10 p-b-10">
        <div className="col-12 nodata-list d-flex justify-content-center">
          {commom.winnerNoDataNextLabel}
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="container">
        <div className="row p-t-30 d-h-255 d-flex justify-content-between">
          <div className="p-0 col-4 align-self-end d-flex justify-content-center">
            <div
              className="second-winner d-w-106 d-h-150 cover"
              style={{ backgroundImage: `url(${Badge})` }}
            >
              <div className="mr-2 winner-crown ">
                <SecondCrownIcon />
              </div>
              <div className="mr-2 winner-photo">
                <UserProfileIcon width="30" height="30" />
              </div>
              <div className="winner-card d-w-100 p-l-10 align-self-start">
                <div className="d-flex justify-content-center">
                  <div className="second-label d-flex justify-content-center d-w-60 d-h-20">
                    <span className="text-xs lh-15 p-t-3">
                      {content.second}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <span className="name fs-13 fw-500 lh-17 p-t-10">
                    {TootsecondName}
                  </span>
                  <span className="text-xs phone-number lh-15 p-t-10 d-h-30">
                    {TootsecondPhone}
                  </span>
                  <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                    {content.bet} {TootsecondBetAmount.toLocaleString()}
                  </span>
                  <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                    {content.win} {TootsecondWinAmount.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 align-self-center d-flex justify-content-center">
            <div
              className="p-t-10 first-winner d-flex flex-column align-items-center d-w-106 d-h-150 cover"
              style={{ backgroundImage: `url(${Badge})` }}
            >
              <div className="winner-crown">
                <FirstCrownIcon />
              </div>
              <div className="mr-2 winner-photo">
                <UserProfileIcon width="30" height="30" />
              </div>
              <div className="winner-card d-w-100 p-l-10 align-self-start">
                <div className="d-flex justify-content-center">
                  <div className="first-label d-flex justify-content-center d-w-60 d-h-20">
                    <span className="text-xs lh-15 p-t-3">
                      {content.first}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column">
                  <span className="name fs-13 fw-500 lh-17 p-t-10">
                    {TootfirstName}{" "}
                  </span>
                  <span className="text-xs phone-number lh-15 p-t-10 d-h-30">
                    {TootfirstPhone}
                  </span>
                  <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                    {content.bet} {TootfirstBetAmount.toLocaleString()}
                  </span>
                  <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                    {content.win} {TootfirstWinAmount.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="col-4 align-self-end d-flex justify-content-center">
            <div
              className="p-t-15 third-winner d-flex flex-column align-items-center d-w-106 d-h-150 cover"
              style={{ backgroundImage: `url(${Badge})` }}
            >
              <div className="winner-crown">
                <ThirdCrownIcon />
              </div>
              <div className="winner-photo">
                <UserProfileIcon width="30" height="30" />
              </div>
              <div className="winner-card d-w-100 align-self-start">
                <div className="d-flex justify-content-center">
                  <div className="third-label d-flex justify-content-center d-w-60 d-h-20">
                    <span className="text-xs lh-15 p-t-3">
                      {content.third}
                    </span>
                  </div>
                </div>
                <div className="d-flex flex-column p-l-6">
                  <span className="name fs-13 fw-500 lh-17 p-t-10">
                    {TootthirdName}
                  </span>
                  <span className="text-xs phone-number lh-15 p-t-10 d-h-30">
                    {TootthirdPhone}
                  </span>
                  <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                    {content.bet} {TootthirdBetAmount.toLocaleString()}
                  </span>
                  <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                    {content.win} {TootthirdWinAmount.toLocaleString()}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tootWinnerList?.length > 0 ? (
        <div className="container">
          <div className="row p-t-20 p-b-10 item-center">
            <div className="col-12">
              <GridTable headerdata={headerdata}>
                {tootWinnerList.map((tootwinner: any, i: number) => (
                  <tr
                    key={i}
                    className="table-row bottom-dashed h-50 fs-14 lh-25"
                  >
                    <td>{CheckTopThreeIcon(i + 1)}</td>
                    <td>
                      {tootwinner.userName}
                      <br />
                      {maskPhoneNumber(tootwinner.phone)}
                    </td>
                    <td>
                      {tootwinner.totalBetAmount.toLocaleString()}
                    </td>
                    <td>
                      {tootwinner.totalWinningAmount.toLocaleString()}
                    </td>
                  </tr>
                ))}
              </GridTable>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  )
) : isNoData ? (
  <div className="container">
    <div className="d-flex justify-content-center p-t-120">
      <NoDataIcon />
    </div>
    <div className="d-flex row p-t-5">
      <div className="col-12 nodata-title d-flex justify-content-center">
        {commom.winnerNoDataLabel}
      </div>
    </div>
    <div className="d-flex row p-t-10 p-b-10">
      <div className="col-12 nodata-list d-flex justify-content-center">
        {commom.winnerNoDataNextLabel}
      </div>
    </div>
  </div>
) : (
  <>
    <div className="container">
      <div className="row p-t-30 d-h-255 d-flex justify-content-between">
        <div className="p-0 col-4 align-self-end d-flex justify-content-center">
          <div
            className="second-winner d-w-106 d-h-150 cover"
            style={{ backgroundImage: `url(${Badge})` }}
          >
            <div className="mr-2 winner-crown">
              <SecondCrownIcon />
            </div>
            <div className="mr-2 winner-photo">
              <UserProfileIcon width="30" height="30" />
            </div>
            <div className="winner-card d-w-100 p-l-10 align-self-start">
              <div className="d-flex justify-content-center">
                <div className="second-label d-flex justify-content-center d-w-60 d-h-20">
                  <span className="text-xs lh-15 p-t-3">
                    {content.second}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column">
                <span className="name fs-13 fw-500 lh-17 p-t-10">
                  {secondName}
                </span>
                <span className="text-xs phone-number lh-15 p-t-10 d-h-30">
                  {secondPhone}
                </span>
                <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                  {content.bet} {secondBetAmount.toLocaleString()}
                </span>
                <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                  {content.win} {secondWinAmount.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 align-self-center d-flex justify-content-center">
          <div
            className="p-t-15 first-winner d-flex flex-column align-items-center d-w-106 d-h-150 cover"
            style={{ backgroundImage: `url(${Badge})` }}
          >
            <div className="winner-crown">
              <FirstCrownIcon />
            </div>
            <div className="mr-2 winner-photo">
              <UserProfileIcon width="30" height="30" />
            </div>
            <div className="winner-card d-w-100 p-l-10 align-self-start">
              <div className="d-flex justify-content-center">
                <div className="first-label d-flex justify-content-center d-w-60 d-h-20">
                  <span className="text-xs lh-15 p-t-3">
                    {content.first}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column">
                <span className="name fs-13 fw-500 lh-17 p-t-10">
                  {firstName}{" "}
                </span>
                <span className="text-xs phone-number lh-15 p-t-10 d-h-30">
                  {firstPhone}
                </span>
                <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                  {content.bet} {firstBetAmount.toLocaleString()}
                </span>
                <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                  {content.win} {firstWinAmount.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 align-self-end d-flex justify-content-center">
          <div
            className="p-t-15 third-winner d-flex flex-column align-items-center d-w-106 d-h-150 cover"
            style={{ backgroundImage: `url(${Badge})` }}
          >
            <div className="winner-crown">
              <ThirdCrownIcon />
            </div>
            <div className="winner-photo">
              <UserProfileIcon width="30" height="30" />
            </div>
            <div className="winner-card d-w-100 align-self-start">
              <div className="d-flex justify-content-center">
                <div className="third-label d-flex justify-content-center d-w-60 d-h-20">
                  <span className="text-xs lh-15 p-t-3">
                    {content.third}
                  </span>
                </div>
              </div>
              <div className="d-flex flex-column p-l-6">
                <span className="name fs-13 fw-500 lh-17 p-t-10">
                  {thirdName}
                </span>
                <span className="text-xs phone-number lh-15 p-t-10 d-h-30">
                  {thirdPhone}
                </span>
                <span className="fw-500 fs-12 lh-15 bet-amount d-h-20">
                  {content.bet} {thirdBetAmount.toLocaleString()}
                </span>
                <span className="fw-500 fs-12 lh-15 win-amount d-h-20">
                  {content.win} {thirdWinAmount.toLocaleString()}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {winnerList?.length > 0 ? (
      <div className="container">
        <div className="row p-t-20 p-b-10 item-center">
          <div className="col-12">
            <GridTable headerdata={headerdata}>
              {winnerList.map((winner: any, i: number) => (
                <tr
                  key={i}
                  className="table-row bottom-dashed h-50 fs-14 lh-25"
                >
                  <td>{CheckTopThreeIcon(i + 1)}</td>
                  <td>
                    {winner.userName}
                    <br />
                    {maskPhoneNumber(winner.phone)}
                  </td>
                  <td>{winner.totalBetAmount.toLocaleString()}</td>
                  <td>{winner.totalWinningAmount.toLocaleString()}</td>
                </tr>
              ))}
            </GridTable>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )}
  </>
)} */}
        </div>
      )}
    </AppWrapper>
  );
};

export default BetWinner;
