import dateFormat from "dateformat";
import React, { useState } from "react";
import Modal from "../../components/common/Modal";
import { translations } from "../../services/localization";
import { convertTimeToAMPM } from "../../services/sharedFunction";
interface ParentComponentProps {
  title: string | undefined;
  resultDate: string;
  firstAnnounceTime: string;
  firstdate: string | undefined;
  firstnumber: string | undefined;
  firstset: string;
  firstvalue: string | undefined;
  secondset: string | undefined;
  secondAnnounceTime: string;
  thirdAnnounceTime: string;
  fourAnnounceTime: string;
  seconddate: string | undefined;
  secondnumber: string | undefined;
  secondvalue: string;
  thirddate: string | undefined;
  thirdnumber: string | undefined;
  thirdset: string | undefined;
  thirdvalue: string | undefined;
  fourdate: string | undefined;
  fournumber: string | undefined;
  fourset: string | undefined;
  fourvalue: string | undefined;
}
const BetListTwoCard: React.FC<ParentComponentProps> = ({
  title,
  resultDate,
  firstAnnounceTime,
  firstdate,
  firstnumber,
  firstset,
  firstvalue,
  seconddate,
  secondset,
  secondnumber,
  secondvalue,
  secondAnnounceTime,
  thirddate,
  thirdnumber,
  thirdset,
  thirdvalue,
  fourdate,
  fournumber,
  fourset,
  fourvalue,
  thirdAnnounceTime,
  fourAnnounceTime,
}) => {
  const [isShow, setIsShow] = useState(false);
  const [modal, setModal] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const commom = translations.commom;
  const roundCard = translations.roundCard;
  const [type, setType] = useState<string>();
  const calculateHeight = () => {
    if (
      (firstnumber !== undefined && secondnumber !== undefined) ||
      (firstnumber !== undefined && thirdnumber !== undefined) ||
      (firstnumber !== undefined && fournumber !== undefined) ||
      (secondnumber !== undefined && thirdnumber !== undefined) ||
      (secondnumber !== undefined && fournumber !== undefined) ||
      (thirdnumber !== undefined && fournumber !== undefined)
    ) {
      return "d-h-100";
    } else {
      return "d-h-200";
    }
  };

  const closeMessageModal = () => {
    setMessageModalOpen(false);
    setIsShow(false);
  };
  const openModal = (type: string) => {
    setType(type);
    setMessageModalOpen(true);
    setIsShow(true);
  };
  return (
    <div className={`container ${calculateHeight}  p-t-15`}>
      <div className="pb-4 row d-flex justify-content-center d-h-36 item-center">
        <div className="col-bet-list-card-title primary-color lh-20">
          {title}
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        {firstdate && firstnumber ? (
          <div className="bet-two-card-col" onClick={() => openModal("1")}>
            <div className="p-1 bet-list-card-body d-h-66 row d-flex">
              <div className="capitalize col-8 d-flex bet-list-col-date justify-content-start fs-14 fw-300 lh-20 item-center ">
                {firstdate}
              </div>
              <div className="text-2xl capitalize col-4 d-flex bet-list-col-number justify-content-end primary-color lh-37 item-center text-primary ">
                {firstnumber}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {seconddate && secondnumber ? (
          <div className="bet-two-card-col" onClick={() => openModal("2")}>
            <div className="p-1 bet-list-card-body d-h-66 row d-flex">
              <div className="capitalize col-8 d-flex bet-list-col-date justify-content-start fs-14 fw-300 lh-20 item-center ">
                {seconddate}
              </div>
              <div className="text-2xl capitalize col-4 d-flex bet-list-col-number justify-content-end primary-color lh-37 item-center text-primary">
                {secondnumber}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="row d-flex justify-content-between p-t-10">
        {thirddate && thirdnumber ? (
          <div className="bet-two-card-col" onClick={() => openModal("3")}>
            <div className="p-1 bet-list-card-body d-h-66 row d-flex">
              <div className="capitalize col-8 d-flex bet-list-col-date justify-content-start fs-14 fw-300 lh-20 item-center ">
                {thirddate}
              </div>
              <div className="text-2xl capitalize col-4 d-flex bet-list-col-number justify-content-end primary-color lh-37 item-center text-primary">
                {thirdnumber}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
        {fourdate && fournumber ? (
          <div className="bet-two-card-col" onClick={() => openModal("4")}>
            <div className="p-1 bet-list-card-body d-h-66 row d-flex">
              <div className="capitalize col-8 d-flex bet-list-col-date justify-content-start fs-14 fw-300 lh-20 item-center ">
                {fourdate}
              </div>
              <div className="text-2xl capitalize col-4 d-flex bet-list-col-number justify-content-end primary-color fw-500 lh-37 item-center text-primary">
                {fournumber}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <Modal isShow={isShow} isOpen={messageModalOpen} onClose={closeMessageModal}>
        <div className="container modal-container d-w-250">
          <div className="d-h-200">
            <div className="text-center row d-h-30 d-flex item-center">
              <div className="col fs-18 fw-700 primary-color">
                {type === "1"
                  ? firstdate
                  : type === "2"
                  ? seconddate
                  : type === "3"
                  ? thirddate
                  : fourdate}
              </div>
            </div>
            <div className="text-center row d-h-50 d-flex item-center">
              <div className="col fs-40 fw-700 bet-list-col-date">
                {type === "1"
                  ? firstnumber
                  : type === "2"
                  ? secondnumber
                  : type === "3"
                  ? thirdnumber
                  : fournumber}
              </div>
            </div>
            <div className="text-center row d-h-20 d-flex item-center">
              <div className="col p-t-10 detail-popup">
                {roundCard.set}:{" "}
                {type === "1"
                  ? firstset
                  : type === "2"
                  ? secondset
                  : type === "3"
                  ? thirdset
                  : fourset}
              </div>
            </div>
            <div className="text-center row d-h-30 d-flex item-center">
              <div className="col p-t-10 detail-popup">
                {roundCard.value}:{" "}
                {type === "1"
                  ? firstvalue
                  : type === "2"
                  ? secondvalue
                  : type === "3"
                  ? thirdvalue
                  : fourvalue}
              </div>
            </div>
            <div className="row d-h-20">
              <div className="col p-t-10">
                <hr className="full-line" />
              </div>
            </div>
            <div className="text-center row d-h-20 d-flex item-center">
              <div className="col detail-popup-date">
                {commom.upDateTime}:{" "}
                {convertTimeToAMPM(
                  type === "1"
                    ? firstAnnounceTime
                    : type === "2"
                    ? secondAnnounceTime
                    : type === "3"
                    ? thirdAnnounceTime
                    : type === "4"
                    ? fourAnnounceTime
                    : "00:00"
                ) + dateFormat(resultDate, " dddd, mmmm d, yyyy")}
              </div>
            </div>
          </div>
          {/* {
                type==='1'?
                <div className="d-h-200">
                    <div className="text-center row d-h-30 d-flex item-center">
                        <div className="col fs-18 fw-700 primary-color">
                            {firstdate}
                        </div>
                    </div>
                    <div className="text-center row d-h-50 d-flex item-center">
                        <div className="col fs-40 fw-700 bet-list-col-date">
                            {firstnumber} 
                        </div>
                    </div>
                    <div className="text-center row d-h-20 d-flex item-center">
                        <div className="col p-t-10 detail-popup">
                            {roundCard.set}:{firstset} 
                        </div>
                    </div>
                    <div className="text-center row d-h-30 d-flex item-center">
                        <div className="col p-t-10 detail-popup">
                            {roundCard.value}:{firstvalue} 
                        </div>
                    </div>
                    <div className="row d-h-20">
                      <div className="col p-t-10">
                        <hr className="full-line"/>
                      </div>
                    </div>
                    <div className="text-center row d-h-20 d-flex item-center">
                        <div className="col detail-popup-date">
                            {commom.upDateTime}: {convertTimeToAMPM(firstAnnounceTime)+dateFormat(resultDate, " dddd, mmmm d, yyyy")} 
                        </div>
                    </div>
                </div>
                :
                <div className="d-h-200">
                    <div className="text-center row d-h-30 d-flex item-center">
                      <div className="col fs-18 fw-700 primary-color">
                            {seconddate}
                        </div>
                    </div>
                    <div className="text-center row d-h-50 d-flex item-center">
                      <div className="col fs-40 fw-700 bet-list-col-date">
                            {secondnumber} 
                        </div>
                    </div>
                    <div className="text-center row d-h-20 d-flex item-center">
                        <div className="col p-t-10 detail-popup">
                            {roundCard.set}:{secondset} 
                        </div>
                    </div>
                    <div className="text-center row d-h-30 d-flex item-center">
                        <div className="col p-t-10 detail-popup">
                            {roundCard.value}:{secondvalue} 
                        </div>
                    </div>
                    <div className="row d-h-20">
                      <div className="col p-t-10">
                        <hr className="full-line"/>
                      </div>
                    </div>
                    <div className="text-center row d-h-20 d-flex item-center">
                        <div className="col detail-popup">
                            {commom.upDateTime}: {convertTimeToAMPM(secondAnnounceTime)+dateFormat(resultDate, " dddd, mmmm d, yyyy")} 
                        </div>
                    </div>
                </div>
            } */}
          <div className="row d-h-50 d-flex item-center">
            <div className="col-12 d-h-42 ">
              <button className="btn-ok" onClick={closeMessageModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BetListTwoCard;
