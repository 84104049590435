export default {
  home: {
    headerTitle: "Welcome 2D",
    headerThreeTitle: "Welcome 3D",
    betClose: "Bet Will Close",
    betOpen: "Bet Will Open",
    notiParagraph: "2D ကို အဆ 80 ",
    notiParagraphThree: "3D ကို အဆ 720 နဲ့",
    notisecondParagraph: "နဲ့ တစ်နေ့ ၄ ကြိမ် ထိုးပြီး ကံစမ်း လိုက်စို့နော်",
    notisecondParagraphThree:
      "နဲ့ တစ်နေ့ ၄ ကြိမ် ထိုးပြီး ကံစမ်း လိုက်စို့နော်",
    button2D: "Bet 2D Number",
    button3D: "Bet 3D Number",
    roundSelect: "Select Round",
    roundSelectTitle: "Please choose your bet round",
    termAndCondition: "Terms & Conditions",
  },
  profile: {
    headerTitle: "My Profile",
    personalTitle: "Personal Information",
    name: "Username",
    phoneno: "Phone Number",
  },
  winner: {
    headerTitle: "2D Top Winners",
    headerTitleThree: "3D Top Winners",
    tootWinnerTitle: "တွပ်ပတ်လည် Winner",
    dade: "ဒဲ့",
    resultDate: "Result Date",
    first: "1st",
    second: "2nd",
    third: "3rd",
    bet: "Bet",
    win: "Win",
  },
  betHistory: {
    headerTitle: "Bet History",
  },
  betHistoryDetail: {
    headerTitle: "Bet History Detail",
    betDate: "Bet Date",
  },
  betHoliday: {
    headerTitle: "Closed Days",
    title: "Upcoming Closed Days",
  },
  betResult: {
    headerTitle: "Result List",
    popTitle: "Choose Month",
    January: "January",
    February: "February",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
  },
  collectList: {
    headerTitle: "Collect",
    toCollect: "To Collect",
    collected: "Collected",
    emptyTitle: "The List is Empty!",
    noCollectMessage: "You do not have any winning amount to collect yet.",
    noCollectedMessage: "You did not collect any winning yet.",
  },
  collect: {
    winNumber: "Win Number",
    firstNoti: "Your bet amount ",
    secondNoti: ", win amount ",
    secondNotiTwo: ".",
    thirdNoti: "You can collect within ",
    thirdNotiTwo: ".",
    fouthNoti: "You won ",
    firthNoti: ", you can collect ",
    seeMore: "See More",
    withdrawMessage: "Your withdrawal has been completed ",
  },
  collectDetail: {
    headerTitle: "Win History Detail",
    firstNoti: "You won ",
    secondNoti: " MMK, you can collect ",
    collect: "Collect",
    unit: "MMK.",
    noRecord: "No Record...",
  },
  betList: {
    headerTitle: "Welcome 2D",
    headerTitleThree: "Welcome 3D",
    colorDefination: "Color Definition",
    clearAll: "Clear All",
    r: "R",
    k: "ခွေ",
    tenPercent: "10%",
    twentyPercent: "20%",
    thirtyPercent: "30%",
    foutyPercent: "40%",
    firtyPercent: "50%",
    sixtyPercent: "60%",
    seventyPercent: "70%",
    eightyPercent: "80%",
    nintyPercent: "90%",
    hundardPercent: "100%",
    betLabel: "Lottery Numbers will be closed when bet trending is 100 %",
    rLabel: "Fill bet amount for R-numbers",
    betWillClose: "Bet Will Close at",
    rAmountIsGreater: "Your R amount is greater than limit amount!",
    AmountIsGreater: "Your  amount is greater than limit amount!",
    rAmountIsLess: "Your R amount is less than limit amount!",
    pleaseAtLeastTwo: "Please at least two bet number!",
    pleaseAtLeastOne: "Please at least one bet number!",
    betLimit: "Bet number limit is ",
    pleaseReduceBetNumber: ". Please reduce bet number.",
    alert: "Notice!",
    sameThree: "Same Three Numbers",
  },
  quickSelect: {
    headerTitle: "Quick Select",
    singleDouble: "Single and Double Numbers",
    big: "Big",
    smmall: "Small",
    odd: "Odd",
    even: "Even",
    same: "Same",
    ee: "EE",
    eo: "EO",
    oe: "OE",
    oo: "OO",
    head: "Head",
    tail: "Tail",
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    title: "ပါဝါ၊ နက္ခတ်၊ ညီကို၊ ဘရိတ်",
    power: "ပါဝါ",
    netkat: "နက္ခတ်",
    nyiko: "ညီကို",
    brake: "ဘရိတ်",
    selectionNumber: "Selection Number",
    selectionOne: "00-19",
    selectionTwo: "20-39",
    selectionThree: "40-59",
    selectionFour: "60-79",
    selectionFive: "80-99",
    message: "Please select only one small or big!",
  },
  kway: {
    headerTitle: "Kway",
    title: "Enter At Least Three Digits for Kway",
    warningMessage: ' Not allowed to enter "same two digits" ',
    warningMessageTwo: "At least 3 digits and at most 10 digits.",
  },
  betChoose: {
    headerTitle: "Choose One Bet Amount",
    messagePartOne: " of max bet amount is ",
    messagePartTwo: ". Please reduce bet amount.",
    messagePartThree: "Wallet amount is ",
    messagePartFour: ".Total bet amount is ",
  },
  betConfirm: {
    headerTitle: "Confirm Bet Info",
    editTitle: "Edit Bet Amount",
    betMaxMessage: "Bet Max Amount is ",
    betMinMessage: "Bet Min Amount is ",
    deleteMessage: "Are you sure to delete your bet number?",
    confirmTitle: "Confirm",
    successTitle: "Success",
    goodLuck: "Good Luck My Friend",
  },
  twoDLayout: {
    winner: "Winners",
    betHistory: "Bet History",
    collect: "Collect",
    result: "Result",
    holiday: "Holiday",
  },
  header: {
    languageSelect: "Select Language",
    languageTitle: "Please select your langauge",
    SystemMaintenance: "Service Unavailable - Under Maintenance!",
    SystemMaintenanceMessage:
      "Sorry for the inconvenience but we're performing some maintenance at the moment.",
    blockmessage: "Your account is blocked!",
    blockagentmessage: "Your agent have been blocked. Please Contact to Agent.",
    blocktitle: "Account Block!",
  },
  roundCard: {
    value: "Value",
    set: "Set",
  },
  commom: {
    wallet: "My Wallet",
    commingSoon: "Coming Soon",
    comming: "Coming",
    soon: "Soon",
    upDateTime: "Update Time",
    buttonSelect: "Select",
    viewProfile: "View Profile",
    winnerNoDataLabel: "Oops! No Winner For This Round.",
    winnerNoDataNextLabel: "Bet for the next round to be in the winner list.",
    buttonSearch: "Search",
    unit: "MMK",
    buttonOk: "OK",
    buttonAgree: "Agree",
    success: "Success",
    successfully: "successfully!",
    buttonCancel: "Cancel",
    buttonQuickSelect: "Quick Select",
    buttonBet: "Bet",
    message: "Notice",
    buttonToot: "တွပ်ပတ်လည်",
    buttonDade: "ဒဲ့",
    buttonAccept: "Accept",
    agree: "Agree",
    buttonConfirm: "Confirm",
    winners: "Winners",
    noWinners: "No winners",
    collect: "Collect",
    result: "Result",
    betHistory: "Bet History",
    emptyTitle: "The List is Empty!",
    emptyMessage: "There is no record and good luck.",
    minumun: "Minimum",
    processing: "Processing",
    notice: "Notice!",
  },
  tableHeader: {
    no: "No",
    name: "Player Name",
    bet: "Bet(MMK)",
    winAmount: "Win(MMK)",
    betNumber: "Bet Number",
    profit: "Profit",
    amount: "Amount",
    edit: "Edit",
    delete: "Delete",
    editAndDelete: "Edit / Delete",
    won: "Won",
    totalBetAmount: "Total Bet Amount",
  },
};
