import useSWR from "swr";
import { getBannerList, getHoliday, getHomePageCheckApi } from "../../home";
import { API_NAME } from "../../constant";

export const useHomePageCheck = () => {
  return useSWR(
    API_NAME.home_page_check_api,
    getHomePageCheckApi
  );
};

export const useHoliday = () => {
  return useSWR(
    API_NAME.holiday_api,
    getHoliday
  );
};

export const useBanner = () => {
  return useSWR(
    API_NAME.banner_api,
    getBannerList
  );
};
