import React, { useEffect, useState } from "react";
import AppWrapper from "../layout/AppWrapper";
import HomeButton from "../components/common/HomeButton";
import { useParams, useNavigate } from "react-router-dom";
import { useHomePageCheck } from "../api/funs/home";
import { setAccessToken } from "../services/storage";
import { setLanguage } from "../services/storage";
import ModalTerm from "../components/common/ModalTerm";
import decode from "html-entities-decoder";
import { CloseIcon } from "../components/common/Icon";
import { translations } from "../services/localization";
import Button from "../components/common/Button";
import { useTerm } from "../api/funs/twodbetlist";

const Home: React.FC = () => {
  const navigate = useNavigate();

  const home = translations.home;
  const commom = translations.commom;
  const { data: terms } = useTerm(
    localStorage.getItem("language") || "english"
  );
  const { language_id } = useParams<{ language_id: string }>();
  const { token_id } = useParams<{ token_id: string }>();
  const [isToTwoD, setIsTwoD] = useState(true);
  const [isToThreeD, setIsThreeD] = useState(true);
  useEffect(() => {
    setLanguage(language_id);
  }, [language_id]);
  useEffect(() => {
    setAccessToken(token_id);
  }, [token_id]);
  const { data: count, error, isLoading } = useHomePageCheck();
  useEffect(() => {
    if (count?.data?.data) {
      setIsThreeD(count.data.data?.existingAgent?.is3DAvailable);
      setIsTwoD(count.data.data?.existingAgent?.is2DAvailable);
    }
  }, [count]);


  return (
    <AppWrapper title="Main" isback={false} isRefresh={true}>
      <div className="container ">
        <div className="row p-t-50 d-flex item-center">
          <div className="col-12 d-flex justify-content-center item-center">
            <span className="fw-600 fs-20 l-h-25">Welcome Back</span>
          </div>
        </div>
        <div className="row p-t-10 d-flex item-center">
          <div className="col-12 d-flex justify-content-center item-center">
            <span className="fw-400 fs-16 l-h-20">
              Select and bet your game
            </span>
          </div>
        </div>
        {isToTwoD ? (
          <div className="row p-t-50 d-flex item-center">
            <div className="d-flex justify-content-center item-center">
              <HomeButton label="2D" to="/twoD/twodhome"></HomeButton>
            </div>
          </div>
        ) : (
          <></>
        )}
        {isToThreeD ? (
          <div className="row p-t-100 p-b-100 d-flex item-center">
            <div className="d-flex justify-content-center item-center">
              <HomeButton label="3D" to="/threeD/threedhome"></HomeButton>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

    </AppWrapper>
  );
};

export default Home;
