import useSWR from "swr";
import { getWalletGetApi } from "../../twodhome";
import { getRoundListApi } from "../../twodhome";
import { API_NAME } from "../../constant";

export const useWallet = () => {
  return useSWR(
    API_NAME.wallet_get_api,
    getWalletGetApi
  );
};

export const useRoundList = (type:string) => {
  return useSWR(
    [API_NAME.round_list_api,type],
    () => getRoundListApi(type)
  );
};

