import axios from "axios";
import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getHomePageCheckApi = async (data: any) => {
  return axiosInstance.get(`${URL}${API_NAME.home_page_check_api}`);
};

export const getHoliday = async () => {
  return axiosInstance.get(`${URL}${API_NAME.holiday_api}`);
};


export const getBannerList = async () => {
  return axiosInstance.get(`${URL}${API_NAME.banner_api}`);
};