import React, { useEffect, useState } from "react";
import AppWrapper from "../layout/AppWrapper";
import { CloseIcon } from "../components/common/Icon";
import { useWallet } from "../api/funs/twodhome";
import { useTerm } from "../api/funs/twodbetlist";
import { translations } from "../services/localization";
import ModalTerm from "../components/common/ModalTerm";
import Button from "../components/common/Button";
import decode from "html-entities-decoder";
import { maskPhoneNumber } from "../services/sharedFunction";
import { UserProfileIcon } from "../components/common/Icon";

const Home: React.FC = () => {
  const { data: wallet } = useWallet();
  const [isShow, setIsShow] = useState(false);
  const content = translations.profile;
  const home = translations.home;
  const commom = translations.commom;
  const { data: terms } = useTerm(
    localStorage.getItem("language") || "english"
  );
  const [termAndConditionModalOpen, setTermAndConditionModalOpen] =
    useState(false);
  const [privacyPolicyModalOpen, setPrivacyPolicyModalOpen] = useState(false);
  const closeTermAndConditionModal = () => {
    setTermAndConditionModalOpen(false);
    setIsShow(false);
  };
  const openTermAndConditionModal = () => {
    setTermAndConditionModalOpen(true);
    setIsShow(true);
  };

  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="container default-height">
        {wallet?.data?.data?.userProfile?.imageUrl ? (
          <div className="p-t-20 p-b-20 d-flex justify-content-center">
            <img
              src={wallet?.data?.data?.userProfile?.imageUrl}
              alt="Profile"
              className="rounded-circle w-[120px] h-[120px]"
            />
          </div>
        ) : (
          <div className="row p-t-20 p-b-20 d-flex item-center">
            <UserProfileIcon height="125" width="125" />
          </div>
        )}
        <div className="row p-t-20 p-b-20 d-flex item-center">
          <div className="col">
            <div className="round-card px-4 py-4">
              <div className="d-flex">
                <div className="col-12 fs-18 lh-21 fw-700 pb-2">
                  {content.personalTitle}
                </div>
              </div>
              <div className="d-flex py-2">
                <div className="col-12">
                  <hr />
                </div>
              </div>
              <div className="space-y-4">
                <div className="d-flex row space-y-3">
                  <div className="col-12 text-md text-gray-600">
                    {content.name}
                  </div>
                  <div className="col-12 text-md text-slate-900 font-medium">
                    {wallet?.data?.data?.userProfile?.name}
                  </div>
                </div>
                <div className="d-flex row space-y-2">
                  <div className="secondary-label col-12 fs-16">
                    {content.phoneno}
                  </div>
                  <div className="col-12 text-md text-slate-900 font-medium">
                    {wallet?.data?.data?.userProfile?.phone
                      ? maskPhoneNumber(wallet?.data?.data?.userProfile?.phone)
                      : ""}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Button
              buttonOnClick={openTermAndConditionModal}
              to=""
              label={home.termAndCondition}
              buttonClass={`ok-button w-100 d-h-44 d-flex justify-content-center item-center `}
              buttonLabelClass={`ok-button-label fs-16 fw-600 lh-20 `}
              isAction={true}
            />
          </div>
        </div>
      </div>
      <ModalTerm
        isShow={isShow}
        isOpen={termAndConditionModalOpen}
        onClose={closeTermAndConditionModal}
      >
        <div className="container px-2">
          <div className="flex text-center item-center">
            <div className="col-11 p-l-30 fs-14 fw-700 color-defination-color">
              {home.termAndCondition}
            </div>
            <div className="float-right">
              <button
                className="popup-close-button"
                onClick={closeTermAndConditionModal}
              >
                <CloseIcon width="10" />
              </button>
            </div>
          </div>

          <div className="flex justify-start item-center p-t-10">
            <div className="text-sm pb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: terms?.data?.data?.terms?.terms
                    ? decode(terms?.data?.data?.terms?.terms)
                    : decode(""),
                }}
              />
            </div>
          </div>

          <Button
            buttonOnClick={closeTermAndConditionModal}
            to=""
            label={commom.buttonAgree}
            buttonClass={`ok-button w-100 d-h-44 d-flex justify-content-center item-center `}
            buttonLabelClass={`ok-button-label fs-16 fw-600 lh-20 uppercase`}
            isAction={true}
          />
        </div>
      </ModalTerm>
    </AppWrapper>
  );
};

export default Home;
