import React, { useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { useParams, useNavigate } from "react-router-dom";
import Footer from "../../layout/mainlayouts/footer";
import {
  IsBig,
  IsSmall,
  betListAtom,
  isBetNumberExists,
  IsOdd,
  IsEven,
  IsSame,
  IsEE,
  IsEO,
  IsOE,
  IsOO,
  IsZeroInclude,
  IsOneInclude,
  IsTwoInclude,
  IsThreeInclude,
  IsFourInclude,
  IsFiveInclude,
  IsSixInclude,
  IsSevenInclude,
  IsEightInclude,
  IsNineInclude,
  IsHeadZeroInclude,
  IsHeadOneInclude,
  IsHeadTwoInclude,
  IsHeadThreeInclude,
  IsHeadFourInclude,
  IsHeadFiveInclude,
  IsHeadSixInclude,
  IsHeadSevenInclude,
  IsHeadEightInclude,
  IsHeadNineInclude,
  IsTailZeroInclude,
  IsTailOneInclude,
  IsTailTwoInclude,
  IsTailThreeInclude,
  IsTailFourInclude,
  IsTailFiveInclude,
  IsTailSixInclude,
  IsTailSevenInclude,
  IsTailEightInclude,
  IsTailNineInclude,
  IsPowerTypeOne,
  IsPowerTypeTwo,
  IsPowerTypeThree,
  IsPowerTypeFour,
  IsBrakeZero,
  IsBrakeOne,
  IsBrakeTwo,
  IsBrakeThree,
  IsBrakeFour,
  IsBrakeFive,
  IsBrakeSeven,
  IsBrakeSix,
  IsBrakeEight,
  IsBrakeNine,
  IsLimitOne,
  IsLimitTwo,
  IsLimitThree,
  IsLimitFour,
  IsLimitFive,
  removeBetNumberFromArray,
  findStatusById,
  IsQuickSelect,
} from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { useBetList } from "../../api/funs/twodbetlist";
import { translations } from "../../services/localization";
import { cn } from "../../util/cn";

const BetQuickSelect: React.FC = () => {
  const { round_id } = useParams<{ round_id: string }>();
  const navigate = useNavigate();
  const content = translations.quickSelect;
  const betList = translations.betList;
  const commom = translations.commom;

  //shared data
  const [isQuickSelect, setIsQuickSelect] = useAtom(IsQuickSelect);

  const [isBig, setIsBig] = useAtom(IsBig);
  const [isSmall, setIsSmall] = useAtom(IsSmall);
  const [isOdd, setIsOdd] = useAtom(IsOdd);
  const [isEven, setIsEven] = useAtom(IsEven);
  const [isSame, setIsSame] = useAtom(IsSame);
  const [isEE, setIsEE] = useAtom(IsEE);
  const [isEO, setIsEO] = useAtom(IsEO);
  const [isOE, setIsOE] = useAtom(IsOE);
  const [isOO, setIsOO] = useAtom(IsOO);

  const [isZeroInclude, setIsZeroInclude] = useAtom(IsZeroInclude);
  const [isOneInclude, setIsOneInclude] = useAtom(IsOneInclude);
  const [isTwoInclude, setIsTwoInclude] = useAtom(IsTwoInclude);
  const [isThreeInclude, setIsThreeInclude] = useAtom(IsThreeInclude);
  const [isFourInclude, setIsFourInclude] = useAtom(IsFourInclude);
  const [isFiveInclude, setIsFiveInclude] = useAtom(IsFiveInclude);
  const [isSixInclude, setIsSixInclude] = useAtom(IsSixInclude);
  const [isSevenInclude, setIsSevenInclude] = useAtom(IsSevenInclude);
  const [isEightInclude, setIsEightInclude] = useAtom(IsEightInclude);
  const [isNineInclude, setIsNineInclude] = useAtom(IsNineInclude);
  //for head number include
  const [isHeadZeroInclude, setIsHeadZeroInclude] = useAtom(IsHeadZeroInclude);
  const [isHeadOneInclude, setIsHeadOneInclude] = useAtom(IsHeadOneInclude);
  const [isHeadTwoInclude, setIsHeadTwoInclude] = useAtom(IsHeadTwoInclude);
  const [isHeadThreeInclude, setIsHeadThreeInclude] =
    useAtom(IsHeadThreeInclude);
  const [isHeadFourInclude, setIsHeadFourInclude] = useAtom(IsHeadFourInclude);
  const [isHeadFiveInclude, setIsHeadFiveInclude] = useAtom(IsHeadFiveInclude);
  const [isHeadSixInclude, setIsHeadSixInclude] = useAtom(IsHeadSixInclude);
  const [isHeadSevenInclude, setIsHeadSevenInclude] =
    useAtom(IsHeadSevenInclude);
  const [isHeadEightInclude, setIsHeadEightInclude] =
    useAtom(IsHeadEightInclude);
  const [isHeadNineInclude, setIsHeadNineInclude] = useAtom(IsHeadNineInclude);
  //end of head number include
  //for tail number include
  const [isTailZeroInclude, setIsTailZeroInclude] = useAtom(IsTailZeroInclude);
  const [isTailOneInclude, setIsTailOneInclude] = useAtom(IsTailOneInclude);
  const [isTailTwoInclude, setIsTailTwoInclude] = useAtom(IsTailTwoInclude);
  const [isTailThreeInclude, setIsTailThreeInclude] =
    useAtom(IsTailThreeInclude);
  const [isTailFourInclude, setIsTailFourInclude] = useAtom(IsTailFourInclude);
  const [isTailFiveInclude, setIsTailFiveInclude] = useAtom(IsTailFiveInclude);
  const [isTailSixInclude, setIsTailSixInclude] = useAtom(IsTailSixInclude);
  const [isTailSevenInclude, setIsTailSevenInclude] =
    useAtom(IsTailSevenInclude);
  const [isTailEightInclude, setIsTailEightInclude] =
    useAtom(IsTailEightInclude);
  const [isTailNineInclude, setIsTailNineInclude] = useAtom(IsTailNineInclude);
  //end of tail number include

  const [isPowerTypeOne, setIsPowerTypeOne] = useAtom(IsPowerTypeOne);
  const [isPowerTypeTwo, setIsPowerTypeTwo] = useAtom(IsPowerTypeTwo);
  const [isPowerTypeThree, setIsPowerTypeThree] = useAtom(IsPowerTypeThree);
  const [isPowerTypeFour, setIsPowerTypeFour] = useAtom(IsPowerTypeFour);

  //brake
  const [isBrakeZero, setIsBrakeZero] = useAtom(IsBrakeZero);
  const [isBrakeOne, setIsBrakeOne] = useAtom(IsBrakeOne);
  const [isBrakeTwo, setIsBrakeTwo] = useAtom(IsBrakeTwo);
  const [isBrakeThree, setIsBrakeThree] = useAtom(IsBrakeThree);
  const [isBrakeFour, setIsBrakeFour] = useAtom(IsBrakeFour);
  const [isBrakeFive, setIsBrakeFive] = useAtom(IsBrakeFive);
  const [isBrakeSix, setIsBrakeSix] = useAtom(IsBrakeSix);
  const [isBrakeSeven, setIsBrakeSeven] = useAtom(IsBrakeSeven);
  const [isBrakeEight, setIsBrakeEight] = useAtom(IsBrakeEight);
  const [isBrakeNine, setIsBrakeNine] = useAtom(IsBrakeNine);

  //limit
  const [isLimitOne, setIsLimitOne] = useAtom(IsLimitOne);
  const [isLimitTwo, setIsLimitTwo] = useAtom(IsLimitTwo);
  const [isLimitThree, setIsLimitThree] = useAtom(IsLimitThree);
  const [isLimitFour, setIsLimitFour] = useAtom(IsLimitFour);
  const [isLimitFive, setIsLimitFive] = useAtom(IsLimitFive);

  const [betlist, setBetListData] = useAtom(betListAtom);
  //end shared data

  const [small, setSmall] = useState<number>(0);
  const { data: roundList } = useBetList(round_id || "");

  const QuickSelect = (type: number) => {
    setIsQuickSelect(true);
    if (type === 1) {
      // type 1 is big
      if (isSmall) {
        //is small nothing to do
      } else {
        const allData = roundList?.data?.data;
        const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
        if (!isBig) {
          let lastroom = betlist.length;
          for (let i = 0; i < allData.length; i++) {
            if (50 <= parseInt(allData[i].betNumber)) {
              if (!isBetNumberExists(allData[i].betNumber, betlist)) {
                if (allData[i].status) {
                  const betItem = {
                    betNumber: allData[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: allData[i].maxAmount,
                    minAmount: allData[i].minAmount,
                    percent: allData[i].percent,
                  };
                  updatedBetList[lastroom] = {
                    ...betItem,
                  };
                  lastroom++;
                }
              }
            }
          }
          setBetListData(updatedBetList);
          localStorage.setItem("bet", JSON.stringify(updatedBetList));

          setIsBig(true);
        } else {
          let removeBetObjList: {
            betNumber: string;
            isSelected: Boolean;
            status: Boolean;
            isR: Boolean;
            isKway: Boolean;
            amount: number;
            maxAmount: number;
            minAmount: number;
            percent: string;
          }[] = [];
          for (const obj of betlist) {
            if (49 >= parseInt(obj.betNumber)) {
              if (findStatusById(obj.betNumber, allData)) {
                removeBetObjList.push(obj);
              }
            }
          }
          setBetListData(removeBetObjList);
          setIsBig(false);
        }
      }
    } else if (type === 2) {
      // type 2 is small
      if (isBig) {
        //is small nothing to do
      } else {
        const allData = roundList?.data?.data;
        const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
        if (!isSmall) {
          let lastroom = betlist.length;
          for (let i = 0; i < allData.length; i++) {
            if (50 > parseInt(allData[i].betNumber)) {
              if (!isBetNumberExists(allData[i].betNumber, betlist)) {
                if (allData[i].status) {
                  const betItem = {
                    betNumber: allData[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: allData[i].maxAmount,
                    minAmount: allData[i].minAmount,
                    percent: allData[i].percent,
                  };
                  updatedBetList[lastroom] = {
                    ...betItem,
                  };
                  lastroom++;
                }
              }
            }
          }
          setBetListData(updatedBetList);
          localStorage.setItem("bet", JSON.stringify(updatedBetList));
          setIsSmall(true);
        } else {
          let removeBetObjList: {
            betNumber: string;
            isSelected: Boolean;
            status: Boolean;
            isR: Boolean;
            isKway: Boolean;
            amount: number;
            maxAmount: number;
            minAmount: number;
            percent: string;
          }[] = [];
          for (const obj of betlist) {
            if (50 <= parseInt(obj.betNumber)) {
              if (findStatusById(obj.betNumber, allData)) {
                removeBetObjList.push(obj);
              }
            }
          }
          setBetListData(removeBetObjList);
          localStorage.setItem("bet", JSON.stringify(removeBetObjList));
          setIsSmall(false);
        }
      }
    } else if (type === 3) {
      // type 3 is odd
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isOdd) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (parseInt(allData[i].betNumber) % 2 !== 0) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsOdd(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (parseInt(obj.betNumber) % 2 === 0) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsOdd(false);
      }
    } else if (type === 4) {
      // type 4 is even
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isEven) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (parseInt(allData[i].betNumber) % 2 === 0) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsEven(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (parseInt(obj.betNumber) % 2 !== 0) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsEven(false);
      }
    } else if (type === 5) {
      // type 5 is same=
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isSame) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "00" ||
            parseInt(allData[i].betNumber) === 11 ||
            parseInt(allData[i].betNumber) === 22 ||
            parseInt(allData[i].betNumber) === 33 ||
            parseInt(allData[i].betNumber) === 44 ||
            parseInt(allData[i].betNumber) === 55 ||
            parseInt(allData[i].betNumber) === 66 ||
            parseInt(allData[i].betNumber) === 77 ||
            parseInt(allData[i].betNumber) === 88 ||
            parseInt(allData[i].betNumber) === 99
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsSame(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber !== "00" ||
              parseInt(obj.betNumber) !== 11 ||
              parseInt(obj.betNumber) !== 22 ||
              parseInt(obj.betNumber) !== 33 ||
              parseInt(obj.betNumber) !== 44 ||
              parseInt(obj.betNumber) !== 55 ||
              parseInt(obj.betNumber) !== 66 ||
              parseInt(obj.betNumber) !== 77 ||
              parseInt(obj.betNumber) !== 88 ||
              parseInt(obj.betNumber) !== 99
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsSame(false);
      }
    } else if (type === 6) {
      // type 6 is ee
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isEE) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          const first = allData[i].betNumber.substring(0, 1);
          if (first === "0") {
            if (parseInt(allData[i].betNumber.substring(1, 2)) % 2 === 0) {
              if (!isBetNumberExists(allData[i].betNumber, betlist)) {
                if (allData[i].status) {
                  const betItem = {
                    betNumber: allData[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: allData[i].maxAmount,
                    minAmount: allData[i].minAmount,
                    percent: allData[i].percent,
                  };
                  updatedBetList[lastroom] = {
                    ...betItem,
                  };
                  lastroom++;
                }
              }
            }
          } else {
            const firstDigit = Math.floor(parseInt(allData[i].betNumber) / 10);
            const secondDigit = parseInt(allData[i].betNumber) % 10;
            if (firstDigit % 2 === 0 && secondDigit % 2 === 0) {
              if (!isBetNumberExists(allData[i].betNumber, betlist)) {
                if (allData[i].status) {
                  const betItem = {
                    betNumber: allData[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: allData[i].maxAmount,
                    minAmount: allData[i].minAmount,
                    percent: allData[i].percent,
                  };
                  updatedBetList[lastroom] = {
                    ...betItem,
                  };
                  lastroom++;
                }
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsEE(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          const first = obj.betNumber.substring(0, 1);
          if (first === "0") {
            if (parseInt(obj.betNumber.substring(1, 2)) % 2 !== 0) {
              if (findStatusById(obj.betNumber, allData)) {
                removeBetObjList.push(obj);
              }
            }
          } else {
            const firstDigit = Math.floor(parseInt(obj.betNumber) / 10);
            const secondDigit = parseInt(obj.betNumber) % 10;
            if (!(firstDigit % 2 === 0 && secondDigit % 2 === 0)) {
              if (findStatusById(obj.betNumber, allData)) {
                removeBetObjList.push(obj);
              }
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsEE(false);
      }
    } else if (type === 7) {
      // type 7 is eo
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isEO) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          const first = allData[i].betNumber.substring(0, 1);
          if (first === "0") {
            if (parseInt(allData[i].betNumber.substring(1, 2)) % 2 !== 0) {
              if (!isBetNumberExists(allData[i].betNumber, betlist)) {
                if (allData[i].status) {
                  const betItem = {
                    betNumber: allData[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: allData[i].maxAmount,
                    minAmount: allData[i].minAmount,
                    percent: allData[i].percent,
                  };
                  updatedBetList[lastroom] = {
                    ...betItem,
                  };
                  lastroom++;
                }
              }
            }
          } else {
            const firstDigit = Math.floor(parseInt(allData[i].betNumber) / 10);
            const secondDigit = parseInt(allData[i].betNumber) % 10;
            if (firstDigit % 2 === 0 && secondDigit % 2 !== 0) {
              if (!isBetNumberExists(allData[i].betNumber, betlist)) {
                if (allData[i].status) {
                  const betItem = {
                    betNumber: allData[i].betNumber,
                    isSelected: false,
                    status: true,
                    isR: false,
                    isKway: false,
                    amount: 0,
                    maxAmount: allData[i].maxAmount,
                    minAmount: allData[i].minAmount,
                    percent: allData[i].percent,
                  };
                  updatedBetList[lastroom] = {
                    ...betItem,
                  };
                  lastroom++;
                }
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsEO(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          const first = obj.betNumber.substring(0, 1);
          if (first === "0") {
            if (parseInt(obj.betNumber.substring(1, 2)) % 2 === 0) {
              if (findStatusById(obj.betNumber, allData)) {
                removeBetObjList.push(obj);
              }
            }
          } else {
            const firstDigit = Math.floor(parseInt(obj.betNumber) / 10);
            const secondDigit = parseInt(obj.betNumber) % 10;
            if (!(firstDigit % 2 === 0 && secondDigit % 2 !== 0)) {
              if (findStatusById(obj.betNumber, allData)) {
                removeBetObjList.push(obj);
              }
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsEO(false);
      }
    } else if (type === 8) {
      // type 8 is oe
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isOE) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          const firstDigit = Math.floor(parseInt(allData[i].betNumber) / 10);
          const secondDigit = parseInt(allData[i].betNumber) % 10;
          if (firstDigit % 2 !== 0 && secondDigit % 2 === 0) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsOE(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          const firstDigit = Math.floor(parseInt(obj.betNumber) / 10);
          const secondDigit = parseInt(obj.betNumber) % 10;
          if (!(firstDigit % 2 !== 0 && secondDigit % 2 === 0)) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsOE(false);
      }
    } else if (type === 9) {
      // type 9 is oo
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isOO) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          const firstDigit = Math.floor(parseInt(allData[i].betNumber) / 10);
          const secondDigit = parseInt(allData[i].betNumber) % 10;
          if (firstDigit % 2 !== 0 && secondDigit % 2 !== 0) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsOO(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          const firstDigit = Math.floor(parseInt(obj.betNumber) / 10);
          const secondDigit = parseInt(obj.betNumber) % 10;
          if (!(firstDigit % 2 !== 0 && secondDigit % 2 !== 0)) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsOO(false);
      }
    }
  };

  const IncludeNumber = (number: string) => {
    setIsQuickSelect(true);
    let remove = false;
    if (number === "0") {
      if (!isZeroInclude) {
        setIsZeroInclude(true);
      } else {
        remove = true;
        setIsZeroInclude(false);
      }
    } else if (number === "1") {
      if (!isOneInclude) {
        setIsOneInclude(true);
      } else {
        remove = true;
        setIsOneInclude(false);
      }
    } else if (number === "2") {
      if (!isTwoInclude) {
        setIsTwoInclude(true);
      } else {
        remove = true;
        setIsTwoInclude(false);
      }
    } else if (number === "3") {
      if (!isThreeInclude) {
        setIsThreeInclude(true);
      } else {
        remove = true;
        setIsThreeInclude(false);
      }
    } else if (number === "4") {
      if (!isFourInclude) {
        setIsFourInclude(true);
      } else {
        remove = true;
        setIsFourInclude(false);
      }
    } else if (number === "5") {
      if (!isFiveInclude) {
        setIsFiveInclude(true);
      } else {
        remove = true;
        setIsFiveInclude(false);
      }
    } else if (number === "6") {
      if (!isSixInclude) {
        setIsSixInclude(true);
      } else {
        remove = true;
        setIsSixInclude(false);
      }
    } else if (number === "7") {
      if (!isSevenInclude) {
        setIsSevenInclude(true);
      } else {
        remove = true;
        setIsSevenInclude(false);
      }
    } else if (number === "8") {
      if (!isEightInclude) {
        setIsEightInclude(true);
      } else {
        remove = true;
        setIsEightInclude(false);
      }
    } else if (number === "9") {
      if (!isNineInclude) {
        setIsNineInclude(true);
      } else {
        remove = true;
        setIsNineInclude(false);
      }
    }
    const allData = roundList?.data?.data;
    const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
    if (!remove) {
      let lastroom = betlist.length;
      for (let i = 0; i < allData.length; i++) {
        if (allData[i].betNumber.indexOf(number) !== -1) {
          if (!isBetNumberExists(allData[i].betNumber, betlist)) {
            if (allData[i].status) {
              const betItem = {
                betNumber: allData[i].betNumber,
                isSelected: allData[i].percent === "100.00" ? true : false,
                status: allData[i].percent === "100.00" ? true : false,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: allData[i].maxAmount,
                minAmount: allData[i].minAmount,
                percent: allData[i].percent,
              };
              updatedBetList[lastroom] = {
                ...betItem,
              };
              lastroom++;
            }
          }
        }
      }
      setBetListData(updatedBetList?.filter((_d) => _d?.percent !== "100.00"));
      localStorage.setItem(
        "bet",
        JSON.stringify(updatedBetList?.filter((_d) => _d?.percent !== "100.00"))
      );
    } else {
      let removeBetObjList: {
        betNumber: string;
        isSelected: Boolean;
        status: Boolean;
        isR: Boolean;
        isKway: Boolean;
        amount: number;
        maxAmount: number;
        minAmount: number;
        percent: string;
      }[] = [];
      for (const obj of betlist) {
        if (!(obj.betNumber.indexOf(number) !== -1)) {
          if (findStatusById(obj.betNumber, allData)) {
            removeBetObjList.push(obj);
          }
        }
      }
      setBetListData(removeBetObjList);
      localStorage.setItem("bet", JSON.stringify(removeBetObjList));
    }
  };

  const HeadIncludeNumber = (number: string) => {
    setIsQuickSelect(true);
    let remove = false;
    if (number === "0") {
      if (!isHeadZeroInclude) {
        setIsHeadZeroInclude(true);
      } else {
        remove = true;
        setIsHeadZeroInclude(false);
      }
    } else if (number === "1") {
      if (!isHeadOneInclude) {
        setIsHeadOneInclude(true);
      } else {
        remove = true;
        setIsHeadOneInclude(false);
      }
    } else if (number === "2") {
      if (!isHeadTwoInclude) {
        setIsHeadTwoInclude(true);
      } else {
        remove = true;
        setIsHeadTwoInclude(false);
      }
    } else if (number === "3") {
      if (!isHeadThreeInclude) {
        setIsHeadThreeInclude(true);
      } else {
        remove = true;
        setIsHeadThreeInclude(false);
      }
    } else if (number === "4") {
      if (!isHeadFourInclude) {
        setIsHeadFourInclude(true);
      } else {
        remove = true;
        setIsHeadFourInclude(false);
      }
    } else if (number === "5") {
      if (!isHeadFiveInclude) {
        setIsHeadFiveInclude(true);
      } else {
        remove = true;
        setIsHeadFiveInclude(false);
      }
    } else if (number === "6") {
      if (!isHeadSixInclude) {
        setIsHeadSixInclude(true);
      } else {
        remove = true;
        setIsHeadSixInclude(false);
      }
    } else if (number === "7") {
      if (!isHeadSevenInclude) {
        setIsHeadSevenInclude(true);
      } else {
        remove = true;
        setIsHeadSevenInclude(false);
      }
    } else if (number === "8") {
      if (!isHeadEightInclude) {
        setIsHeadEightInclude(true);
      } else {
        remove = true;
        setIsHeadEightInclude(false);
      }
    } else if (number === "9") {
      if (!isHeadNineInclude) {
        setIsHeadNineInclude(true);
      } else {
        remove = true;
        setIsHeadNineInclude(false);
      }
    }
    const allData = roundList?.data?.data;
    const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
    if (!remove) {
      let lastroom = betlist.length;
      for (let i = 0; i < allData.length; i++) {
        if (allData[i].betNumber.substring(0, 1) === number) {
          if (!isBetNumberExists(allData[i].betNumber, betlist)) {
            if (allData[i].status) {
              const betItem = {
                betNumber: allData[i].betNumber,
                isSelected: false,
                status: true,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: allData[i].maxAmount,
                minAmount: allData[i].minAmount,
                percent: allData[i].percent,
              };
              updatedBetList[lastroom] = {
                ...betItem,
              };
              lastroom++;
            }
          }
        }
      }
      setBetListData(updatedBetList);
      localStorage.setItem("bet", JSON.stringify(updatedBetList));
    } else {
      let removeBetObjList: {
        betNumber: string;
        isSelected: Boolean;
        status: Boolean;
        isR: Boolean;
        isKway: Boolean;
        amount: number;
        maxAmount: number;
        minAmount: number;
        percent: string;
      }[] = [];
      for (const obj of betlist) {
        if (!(obj.betNumber.substring(0, 1) === number)) {
          if (findStatusById(obj.betNumber, allData)) {
            removeBetObjList.push(obj);
          }
        }
      }
      setBetListData(removeBetObjList);
      localStorage.setItem("bet", JSON.stringify(removeBetObjList));
    }
  };

  const TailIncludeNumber = (number: string) => {
    setIsQuickSelect(true);
    let remove = false;
    if (number === "0") {
      if (!isTailZeroInclude) {
        setIsTailZeroInclude(true);
      } else {
        remove = true;
        setIsTailZeroInclude(false);
      }
    } else if (number === "1") {
      if (!isTailOneInclude) {
        setIsTailOneInclude(true);
      } else {
        remove = true;
        setIsTailOneInclude(false);
      }
    } else if (number === "2") {
      if (!isTailTwoInclude) {
        setIsTailTwoInclude(true);
      } else {
        remove = true;
        setIsTailTwoInclude(false);
      }
    } else if (number === "3") {
      if (!isTailThreeInclude) {
        setIsTailThreeInclude(true);
      } else {
        remove = true;
        setIsTailThreeInclude(false);
      }
    } else if (number === "4") {
      if (!isTailFourInclude) {
        setIsTailFourInclude(true);
      } else {
        remove = true;
        setIsTailFourInclude(false);
      }
    } else if (number === "5") {
      if (!isTailFiveInclude) {
        setIsTailFiveInclude(true);
      } else {
        remove = true;
        setIsTailFiveInclude(false);
      }
    } else if (number === "6") {
      if (!isTailSixInclude) {
        setIsTailSixInclude(true);
      } else {
        remove = true;
        setIsTailSixInclude(false);
      }
    } else if (number === "7") {
      if (!isTailSevenInclude) {
        setIsTailSevenInclude(true);
      } else {
        remove = true;
        setIsTailSevenInclude(false);
      }
    } else if (number === "8") {
      if (!isTailEightInclude) {
        setIsTailEightInclude(true);
      } else {
        remove = true;
        setIsTailEightInclude(false);
      }
    } else if (number === "9") {
      if (!isTailNineInclude) {
        setIsTailNineInclude(true);
      } else {
        remove = true;
        setIsTailNineInclude(false);
      }
    }
    const allData = roundList?.data?.data;
    const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
    if (!remove) {
      let lastroom = betlist.length;
      for (let i = 0; i < allData.length; i++) {
        if (allData[i].betNumber.substring(1, 2) === number) {
          if (!isBetNumberExists(allData[i].betNumber, betlist)) {
            if (allData[i].status) {
              const betItem = {
                betNumber: allData[i].betNumber,
                isSelected: false,
                status: true,
                isR: false,
                isKway: false,
                amount: 0,
                maxAmount: allData[i].maxAmount,
                minAmount: allData[i].minAmount,
                percent: allData[i].percent,
              };
              updatedBetList[lastroom] = {
                ...betItem,
              };
              lastroom++;
            }
          }
        }
      }
      setBetListData(updatedBetList);
      localStorage.setItem("bet", JSON.stringify(updatedBetList));
    } else {
      let removeBetObjList: {
        betNumber: string;
        isSelected: Boolean;
        status: Boolean;
        isR: Boolean;
        isKway: Boolean;
        amount: number;
        maxAmount: number;
        minAmount: number;
        percent: string;
      }[] = [];
      for (const obj of betlist) {
        if (!(obj.betNumber.substring(1, 2) === number)) {
          if (findStatusById(obj.betNumber, allData)) {
            removeBetObjList.push(obj);
          }
        }
      }
      setBetListData(removeBetObjList);
      localStorage.setItem("bet", JSON.stringify(removeBetObjList));
    }
  };

  const PowerNatKatNyiKo = (number: string) => {
    setIsQuickSelect(true);
    if (number === "1") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isPowerTypeOne) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "05" ||
            allData[i].betNumber === "61" ||
            allData[i].betNumber === "72" ||
            allData[i].betNumber === "83" ||
            allData[i].betNumber === "94" ||
            allData[i].betNumber === "50" ||
            allData[i].betNumber === "16" ||
            allData[i].betNumber === "27" ||
            allData[i].betNumber === "38" ||
            allData[i].betNumber === "49"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsPowerTypeOne(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "05" ||
              obj.betNumber === "61" ||
              obj.betNumber === "72" ||
              obj.betNumber === "83" ||
              obj.betNumber === "94" ||
              obj.betNumber === "50" ||
              obj.betNumber === "16" ||
              obj.betNumber === "27" ||
              obj.betNumber === "38" ||
              obj.betNumber === "49"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsPowerTypeOne(false);
      }
    } else if (number === "2") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isPowerTypeTwo) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "07" ||
            allData[i].betNumber === "18" ||
            allData[i].betNumber === "24" ||
            allData[i].betNumber === "35" ||
            allData[i].betNumber === "69" ||
            allData[i].betNumber === "70" ||
            allData[i].betNumber === "81" ||
            allData[i].betNumber === "42" ||
            allData[i].betNumber === "53" ||
            allData[i].betNumber === "96"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsPowerTypeTwo(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "07" ||
              obj.betNumber === "18" ||
              obj.betNumber === "24" ||
              obj.betNumber === "35" ||
              obj.betNumber === "69" ||
              obj.betNumber === "70" ||
              obj.betNumber === "81" ||
              obj.betNumber === "42" ||
              obj.betNumber === "53" ||
              obj.betNumber === "96"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsPowerTypeTwo(false);
      }
    } else if (number === "3") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isPowerTypeThree) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "01" ||
            allData[i].betNumber === "12" ||
            allData[i].betNumber === "23" ||
            allData[i].betNumber === "34" ||
            allData[i].betNumber === "45" ||
            allData[i].betNumber === "56" ||
            allData[i].betNumber === "67" ||
            allData[i].betNumber === "78" ||
            allData[i].betNumber === "89" ||
            allData[i].betNumber === "90"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsPowerTypeThree(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "01" ||
              obj.betNumber === "12" ||
              obj.betNumber === "23" ||
              obj.betNumber === "34" ||
              obj.betNumber === "45" ||
              obj.betNumber === "56" ||
              obj.betNumber === "67" ||
              obj.betNumber === "78" ||
              obj.betNumber === "89" ||
              obj.betNumber === "90"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsPowerTypeThree(false);
      }
    } else if (number === "4") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isPowerTypeFour) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "01" ||
            allData[i].betNumber === "12" ||
            allData[i].betNumber === "23" ||
            allData[i].betNumber === "34" ||
            allData[i].betNumber === "45" ||
            allData[i].betNumber === "56" ||
            allData[i].betNumber === "67" ||
            allData[i].betNumber === "78" ||
            allData[i].betNumber === "89" ||
            allData[i].betNumber === "90"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsPowerTypeFour(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "01" ||
              obj.betNumber === "12" ||
              obj.betNumber === "23" ||
              obj.betNumber === "34" ||
              obj.betNumber === "45" ||
              obj.betNumber === "56" ||
              obj.betNumber === "67" ||
              obj.betNumber === "78" ||
              obj.betNumber === "89" ||
              obj.betNumber === "90"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsPowerTypeFour(false);
      }
    }
  };

  const Brake = (number: string) => {
    setIsQuickSelect(true);
    if (number === "0") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeZero) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "00" ||
            allData[i].betNumber === "19" ||
            allData[i].betNumber === "28" ||
            allData[i].betNumber === "37" ||
            allData[i].betNumber === "46" ||
            allData[i].betNumber === "55" ||
            allData[i].betNumber === "64" ||
            allData[i].betNumber === "73" ||
            allData[i].betNumber === "82" ||
            allData[i].betNumber === "91"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeZero(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "00" ||
              obj.betNumber === "19" ||
              obj.betNumber === "28" ||
              obj.betNumber === "37" ||
              obj.betNumber === "46" ||
              obj.betNumber === "55" ||
              obj.betNumber === "64" ||
              obj.betNumber === "73" ||
              obj.betNumber === "82" ||
              obj.betNumber === "91"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeZero(false);
      }
    } else if (number === "1") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeOne) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "01" ||
            allData[i].betNumber === "10" ||
            allData[i].betNumber === "29" ||
            allData[i].betNumber === "38" ||
            allData[i].betNumber === "47" ||
            allData[i].betNumber === "56" ||
            allData[i].betNumber === "65" ||
            allData[i].betNumber === "74" ||
            allData[i].betNumber === "83" ||
            allData[i].betNumber === "92"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeOne(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "01" ||
              obj.betNumber === "10" ||
              obj.betNumber === "29" ||
              obj.betNumber === "38" ||
              obj.betNumber === "47" ||
              obj.betNumber === "56" ||
              obj.betNumber === "65" ||
              obj.betNumber === "74" ||
              obj.betNumber === "83" ||
              obj.betNumber === "92"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeOne(false);
      }
    } else if (number === "2") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeTwo) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "02" ||
            allData[i].betNumber === "11" ||
            allData[i].betNumber === "20" ||
            allData[i].betNumber === "39" ||
            allData[i].betNumber === "48" ||
            allData[i].betNumber === "57" ||
            allData[i].betNumber === "66" ||
            allData[i].betNumber === "75" ||
            allData[i].betNumber === "84" ||
            allData[i].betNumber === "93"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeTwo(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "02" ||
              obj.betNumber === "11" ||
              obj.betNumber === "20" ||
              obj.betNumber === "39" ||
              obj.betNumber === "48" ||
              obj.betNumber === "57" ||
              obj.betNumber === "66" ||
              obj.betNumber === "75" ||
              obj.betNumber === "84" ||
              obj.betNumber === "93"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeTwo(false);
      }
    } else if (number === "3") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeThree) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "03" ||
            allData[i].betNumber === "12" ||
            allData[i].betNumber === "21" ||
            allData[i].betNumber === "30" ||
            allData[i].betNumber === "49" ||
            allData[i].betNumber === "58" ||
            allData[i].betNumber === "67" ||
            allData[i].betNumber === "76" ||
            allData[i].betNumber === "85" ||
            allData[i].betNumber === "94"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeThree(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "03" ||
              obj.betNumber === "12" ||
              obj.betNumber === "21" ||
              obj.betNumber === "30" ||
              obj.betNumber === "49" ||
              obj.betNumber === "58" ||
              obj.betNumber === "67" ||
              obj.betNumber === "76" ||
              obj.betNumber === "85" ||
              obj.betNumber === "94"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeThree(false);
      }
    } else if (number === "4") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeFour) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "04" ||
            allData[i].betNumber === "13" ||
            allData[i].betNumber === "22" ||
            allData[i].betNumber === "31" ||
            allData[i].betNumber === "40" ||
            allData[i].betNumber === "59" ||
            allData[i].betNumber === "68" ||
            allData[i].betNumber === "77" ||
            allData[i].betNumber === "86" ||
            allData[i].betNumber === "95"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeFour(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "04" ||
              obj.betNumber === "13" ||
              obj.betNumber === "22" ||
              obj.betNumber === "31" ||
              obj.betNumber === "40" ||
              obj.betNumber === "59" ||
              obj.betNumber === "68" ||
              obj.betNumber === "77" ||
              obj.betNumber === "86" ||
              obj.betNumber === "95"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeFour(false);
      }
    } else if (number === "5") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeFive) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "05" ||
            allData[i].betNumber === "14" ||
            allData[i].betNumber === "23" ||
            allData[i].betNumber === "32" ||
            allData[i].betNumber === "41" ||
            allData[i].betNumber === "50" ||
            allData[i].betNumber === "69" ||
            allData[i].betNumber === "78" ||
            allData[i].betNumber === "87" ||
            allData[i].betNumber === "96"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeFive(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "05" ||
              obj.betNumber === "14" ||
              obj.betNumber === "23" ||
              obj.betNumber === "32" ||
              obj.betNumber === "41" ||
              obj.betNumber === "50" ||
              obj.betNumber === "69" ||
              obj.betNumber === "78" ||
              obj.betNumber === "87" ||
              obj.betNumber === "96"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeFive(false);
      }
    } else if (number === "6") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeSix) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "06" ||
            allData[i].betNumber === "15" ||
            allData[i].betNumber === "24" ||
            allData[i].betNumber === "33" ||
            allData[i].betNumber === "42" ||
            allData[i].betNumber === "51" ||
            allData[i].betNumber === "60" ||
            allData[i].betNumber === "79" ||
            allData[i].betNumber === "88" ||
            allData[i].betNumber === "97"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeSix(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "06" ||
              obj.betNumber === "15" ||
              obj.betNumber === "24" ||
              obj.betNumber === "33" ||
              obj.betNumber === "42" ||
              obj.betNumber === "51" ||
              obj.betNumber === "60" ||
              obj.betNumber === "79" ||
              obj.betNumber === "88" ||
              obj.betNumber === "97"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeSix(false);
      }
    } else if (number === "7") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeSeven) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "07" ||
            allData[i].betNumber === "16" ||
            allData[i].betNumber === "25" ||
            allData[i].betNumber === "34" ||
            allData[i].betNumber === "43" ||
            allData[i].betNumber === "52" ||
            allData[i].betNumber === "61" ||
            allData[i].betNumber === "70" ||
            allData[i].betNumber === "89" ||
            allData[i].betNumber === "98"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeSeven(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "07" ||
              obj.betNumber === "16" ||
              obj.betNumber === "25" ||
              obj.betNumber === "34" ||
              obj.betNumber === "43" ||
              obj.betNumber === "52" ||
              obj.betNumber === "61" ||
              obj.betNumber === "70" ||
              obj.betNumber === "89" ||
              obj.betNumber === "98"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeSeven(false);
      }
    } else if (number === "8") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeEight) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "08" ||
            allData[i].betNumber === "17" ||
            allData[i].betNumber === "26" ||
            allData[i].betNumber === "35" ||
            allData[i].betNumber === "44" ||
            allData[i].betNumber === "53" ||
            allData[i].betNumber === "62" ||
            allData[i].betNumber === "71" ||
            allData[i].betNumber === "80" ||
            allData[i].betNumber === "99"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeEight(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "08" ||
              obj.betNumber === "17" ||
              obj.betNumber === "26" ||
              obj.betNumber === "35" ||
              obj.betNumber === "44" ||
              obj.betNumber === "53" ||
              obj.betNumber === "62" ||
              obj.betNumber === "71" ||
              obj.betNumber === "80" ||
              obj.betNumber === "99"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeEight(false);
      }
    } else if (number === "9") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isBrakeNine) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            allData[i].betNumber === "09" ||
            allData[i].betNumber === "18" ||
            allData[i].betNumber === "27" ||
            allData[i].betNumber === "36" ||
            allData[i].betNumber === "45" ||
            allData[i].betNumber === "54" ||
            allData[i].betNumber === "63" ||
            allData[i].betNumber === "72" ||
            allData[i].betNumber === "81" ||
            allData[i].betNumber === "90"
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsBrakeNine(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(
              obj.betNumber === "09" ||
              obj.betNumber === "18" ||
              obj.betNumber === "27" ||
              obj.betNumber === "36" ||
              obj.betNumber === "45" ||
              obj.betNumber === "54" ||
              obj.betNumber === "63" ||
              obj.betNumber === "72" ||
              obj.betNumber === "81" ||
              obj.betNumber === "90"
            )
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsBrakeNine(false);
      }
    }
  };

  const Limit = (number: string) => {
    setIsQuickSelect(true);
    if (number === "1") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isLimitOne) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (20 > parseInt(allData[i].betNumber)) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsLimitOne(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (!(20 > parseInt(obj.betNumber))) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsLimitOne(false);
      }
    } else if (number === "2") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isLimitTwo) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            20 <= parseInt(allData[i].betNumber) &&
            40 > parseInt(allData[i].betNumber)
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsLimitTwo(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(20 <= parseInt(obj.betNumber) && 40 > parseInt(obj.betNumber))
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsLimitTwo(false);
      }
    } else if (number === "3") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isLimitThree) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            40 <= parseInt(allData[i].betNumber) &&
            60 > parseInt(allData[i].betNumber)
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsLimitThree(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(40 <= parseInt(obj.betNumber) && 60 > parseInt(obj.betNumber))
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsLimitThree(false);
      }
    } else if (number === "4") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isLimitFour) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            60 <= parseInt(allData[i].betNumber) &&
            80 > parseInt(allData[i].betNumber)
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsLimitFour(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(60 <= parseInt(obj.betNumber) && 80 > parseInt(obj.betNumber))
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsLimitFour(false);
      }
    } else if (number === "5") {
      const allData = roundList?.data?.data;
      const updatedBetList = [...betlist]; // Create a copy of betlist to avoid mutating the original array
      if (!isLimitFive) {
        let lastroom = betlist.length;
        for (let i = 0; i < allData.length; i++) {
          if (
            80 <= parseInt(allData[i].betNumber) &&
            100 > parseInt(allData[i].betNumber)
          ) {
            if (!isBetNumberExists(allData[i].betNumber, betlist)) {
              if (allData[i].status) {
                const betItem = {
                  betNumber: allData[i].betNumber,
                  isSelected: false,
                  status: true,
                  isR: false,
                  isKway: false,
                  amount: 0,
                  maxAmount: allData[i].maxAmount,
                  minAmount: allData[i].minAmount,
                  percent: allData[i].percent,
                };
                updatedBetList[lastroom] = {
                  ...betItem,
                };
                lastroom++;
              }
            }
          }
        }
        setBetListData(updatedBetList);
        localStorage.setItem("bet", JSON.stringify(updatedBetList));
        setIsLimitFive(true);
      } else {
        let removeBetObjList: {
          betNumber: string;
          isSelected: Boolean;
          status: Boolean;
          isR: Boolean;
          isKway: Boolean;
          amount: number;
          maxAmount: number;
          minAmount: number;
          percent: string;
        }[] = [];
        for (const obj of betlist) {
          if (
            !(80 <= parseInt(obj.betNumber) && 100 > parseInt(obj.betNumber))
          ) {
            if (findStatusById(obj.betNumber, allData)) {
              removeBetObjList.push(obj);
            }
          }
        }
        setBetListData(removeBetObjList);
        localStorage.setItem("bet", JSON.stringify(removeBetObjList));
        setIsLimitFive(false);
      }
    }
  };

  const onClick = () => {
    //
  };

  return (
    <>
      <AppWrapper
        title={content.headerTitle}
        isback={true}
        isRefresh={true}
        removeScroll
      >
        <div className="pb-20">
          <div className="px-4">
            <div className="py-2 pt-4 kway-header fs-18 fw-400 lh-23">
              {content.singleDouble}
            </div>
            <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
              <div>
                <div
                  className={cn(
                    "btn-center-container !text-xs !font-semibold",
                    isBig && "selected",
                    isSmall &&
                      "opacity-25 cursor-not-allowed pointer-events-none"
                  )}
                  onClick={() => QuickSelect(1)}
                >
                  {content.big}
                </div>
              </div>
              <div>
                <div
                  className={cn(
                    "btn-center-container !text-xs !font-semibold",
                    isSmall && "selected",
                    isBig && "opacity-25 cursor-not-allowed pointer-events-none"
                  )}
                  onClick={() => QuickSelect(2)}
                >
                  {content.smmall}
                </div>
              </div>
              <div>
                <div
                  className={cn(
                    "btn-center-container !text-xs !font-semibold",
                    isOdd && "selected"
                  )}
                  onClick={() => QuickSelect(3)}
                >
                  {content.odd}
                </div>
              </div>
              <div>
                <div
                  className={cn(
                    "btn-center-container !text-xs !font-semibold",
                    isEven && "selected"
                  )}
                  onClick={() => QuickSelect(4)}
                >
                  {content.even}
                </div>
              </div>
              <div>
                <div
                  className={cn(
                    "btn-center-container !text-xs !font-semibold",
                    isSame && "selected"
                  )}
                  onClick={() => QuickSelect(5)}
                >
                  {content.same}
                </div>
              </div>
            </div>

            <div>
              <div>
                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isEE && "selected"
                    )}
                    onClick={() => QuickSelect(6)}
                  >
                    {content.ee}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isEO && "selected"
                    )}
                    onClick={() => QuickSelect(7)}
                  >
                    {content.eo}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isOE && "selected"
                    )}
                    onClick={() => QuickSelect(8)}
                  >
                    {content.oe}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isOO && "selected"
                    )}
                    onClick={() => QuickSelect(9)}
                  >
                    {content.oo}
                  </div>
                </div>

                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isZeroInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("0")}
                  >
                    {content.zero}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isOneInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("1")}
                  >
                    {content.one}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTwoInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("2")}
                  >
                    {content.two}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isThreeInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("3")}
                  >
                    {content.three}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isFourInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("4")}
                  >
                    {content.four}
                  </div>
                </div>
                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isFiveInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("5")}
                  >
                    {content.five}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isSixInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("6")}
                  >
                    {content.six}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isSevenInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("7")}
                  >
                    {content.seven}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isEightInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("8")}
                  >
                    {content.eight}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold ",
                      isNineInclude && "selected"
                    )}
                    onClick={() => IncludeNumber("9")}
                  >
                    {content.nine}
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="py-2 kway-header fs-18 fw-400 lh-23">
                    {content.head}
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadZeroInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("0")}
                  >
                    {content.zero}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadOneInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("1")}
                  >
                    {content.one}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadTwoInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("2")}
                  >
                    {content.two}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadThreeInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("3")}
                  >
                    {content.three}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadFourInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("4")}
                  >
                    {content.four}
                  </div>
                </div>
                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadFiveInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("5")}
                  >
                    {content.five}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadSixInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("6")}
                  >
                    {content.six}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadSevenInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("7")}
                  >
                    {content.seven}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadEightInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("8")}
                  >
                    {content.eight}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isHeadNineInclude && "selected"
                    )}
                    onClick={() => HeadIncludeNumber("9")}
                  >
                    {content.nine}
                  </div>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="py-2 kway-header fs-18 fw-400 lh-23">
                    {content.tail}
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailZeroInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("0")}
                  >
                    0
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailOneInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("1")}
                  >
                    1
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailTwoInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("2")}
                  >
                    2
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailThreeInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("3")}
                  >
                    3
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailFourInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("4")}
                  >
                    4
                  </div>
                </div>
                <div className="grid grid-cols-5 gap-2 py-2 sm:gap-3 sm:py-3">
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailFiveInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("5")}
                  >
                    5
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailSixInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("6")}
                  >
                    6
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailSevenInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("7")}
                  >
                    7
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailEightInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("8")}
                  >
                    8
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isTailNineInclude && "selected"
                    )}
                    onClick={() => TailIncludeNumber("9")}
                  >
                    9
                  </div>
                </div>
              </div>
              <div>
                <div className="py-2">
                  <div className="py-2 kway-header fs-16 fw-400 lh-23">
                    <b>{content.title}</b>
                  </div>
                </div>
              </div>
              <div className="space-y-3">
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isPowerTypeOne ? "selected" : ""
                    } `}
                    onClick={() => PowerNatKatNyiKo("1")}
                  >
                    <b>{content.power}</b> : 05, 61, 72, 83, 94, 50, 16, 27, 38,
                    49
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isPowerTypeTwo ? "selected" : ""
                    } `}
                    onClick={() => PowerNatKatNyiKo("2")}
                  >
                    <b>{content.netkat}</b> : 07, 18, 24, 35, 69, 70, 81, 42,
                    53, 96
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isPowerTypeThree ? "selected" : ""
                    } `}
                    onClick={() => PowerNatKatNyiKo("3")}
                  >
                    <b>{content.nyiko}</b> : 01, 12, 23, 34, 45, 56, 67, 78, 89,
                    90
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isPowerTypeFour ? "selected" : ""
                    } `}
                    onClick={() => PowerNatKatNyiKo("4")}
                  >
                    <b>
                      {content.nyiko} {betList.r}
                    </b>
                    : 10, 21, 32, 43, 54, 65, 76, 87, 98, 09
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeZero ? "selected" : ""
                    } `}
                    onClick={() => Brake("0")}
                  >
                    <b>
                      {content.zero} {content.brake}
                    </b>
                    : 00, 19, 28, 37, 46, 55, 64, 73, 82, 91
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeOne ? "selected" : ""
                    } `}
                    onClick={() => Brake("1")}
                  >
                    <b>
                      {content.one} {content.brake}
                    </b>
                    : 01, 10, 29, 38, 47, 56, 65, 74, 83, 92
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeTwo ? "selected" : ""
                    } `}
                    onClick={() => Brake("2")}
                  >
                    <b>
                      {content.two} {content.brake}
                    </b>
                    : 02, 11, 20, 39, 48, 57, 66, 75, 84, 93
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeThree ? "selected" : ""
                    } `}
                    onClick={() => Brake("3")}
                  >
                    <b>
                      {content.three} {content.brake}
                    </b>
                    : 03, 12, 21, 30, 49, 58, 67, 76, 85, 94
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeFour ? "selected" : ""
                    } `}
                    onClick={() => Brake("4")}
                  >
                    <b>
                      {content.four} {content.brake}
                    </b>
                    : 04, 13, 22, 31, 40, 59, 68, 77, 86, 95
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeFive ? "selected" : ""
                    } `}
                    onClick={() => Brake("5")}
                  >
                    <b>
                      {content.five} {content.brake}
                    </b>
                    : 05, 14, 23, 32, 41, 50, 69, 78, 87, 96
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeSix ? "selected" : ""
                    } `}
                    onClick={() => Brake("6")}
                  >
                    <b>
                      {content.six} {content.brake}
                    </b>
                    : 06, 15, 24, 33, 42, 51, 60, 79, 88, 97
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2 ${
                      isBrakeSeven ? "selected" : ""
                    } `}
                    onClick={() => Brake("7")}
                  >
                    <b>
                      {content.seven} {content.brake}
                    </b>
                    : 07, 16, 25, 34, 43, 52, 61, 70, 89, 98
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2  ${
                      isBrakeEight ? "selected" : ""
                    } `}
                    onClick={() => Brake("8")}
                  >
                    <b>
                      {content.eight} {content.brake}
                    </b>
                    : 08, 17, 26, 35, 44, 53, 62, 71, 80, 99
                  </div>
                </div>
                <div>
                  <div
                    className={`btn-left-container cursor-pointer p-2  ${
                      isBrakeNine ? "selected" : ""
                    } `}
                    onClick={() => Brake("9")}
                  >
                    <b>
                      {content.nine} {content.brake}
                    </b>
                    : 09, 18, 27, 36, 45, 54, 63, 72, 81, 90
                  </div>
                </div>
              </div>
              {/* 20 Selection Number */}
              <div>
                <div className="row">
                  <div className="py-3 kway-header fs-18 fw-400 lh-23">
                    20 {content.selectionNumber}
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 gap-2 pt-2 !pb-4 sm:!pb-8 sm:gap-3">
                <>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold ",
                      isLimitOne && "selected"
                    )}
                    onClick={() => Limit("1")}
                  >
                    {content.selectionOne}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isLimitTwo && "selected"
                    )}
                    onClick={() => Limit("2")}
                  >
                    {content.selectionTwo}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isLimitThree && "selected"
                    )}
                    onClick={() => Limit("3")}
                  >
                    {content.selectionThree}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isLimitFour && "selected"
                    )}
                    onClick={() => Limit("4")}
                  >
                    {content.selectionFour}
                  </div>
                  <div
                    className={cn(
                      "btn-center-container !text-xs !font-semibold",
                      isLimitFive && "selected"
                    )}
                    onClick={() => Limit("5")}
                  >
                    {content.selectionFive}
                  </div>
                </>
              </div>
            </div>
          </div>
          <Footer
            className="px-4 "
            firstOnClick={onClick}
            secondOnClick={onClick}
            is2D={false}
            isFirstButton={false}
            firstbuttonLabel={commom.buttonCancel}
            firstto={"/twoD/betlist/" + round_id}
            firstButtonClass="cancel-button w-100 d-h-42 d-flex justify-content-center item-center uppercase"
            firstButtonLabelClass="cancel-button-label fs-16 fw-600 lh-20 uppercase"
            isSecondButton={true}
            secondButtonClass="ok-button w-100 d-h-42 d-flex justify-content-center item-center uppercase"
            secondto={"/twoD/betlist/" + round_id}
            secondbuttonLabel={commom.buttonOk}
            secondButtonLabelClass="ok-button-label fs-16 fw-600 lh-20"
          />
        </div>
      </AppWrapper>
    </>
  );
};

export default BetQuickSelect;
