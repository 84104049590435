import React, { useState } from 'react';

interface PaginationProps {
  totalItems: number;
  itemsPerPage: number;
  onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handleClick = (page: number) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    const maxVisiblePages = 10; // Adjust this value to set the maximum number of visible pagination items
  
    let startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);
  
    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(1, endPage - maxVisiblePages + 1);
    }
  
    for (let i = startPage; i <= endPage; i++) {
      paginationItems.push(
        <li key={i} className='p-l-5 p-r-5'>
          <button  className={` ${currentPage === i ? 'page-button-active':'page-button'}`} onClick={() => handleClick(i)}>
            {i}
          </button>
        </li>
      );
    }
  
    return paginationItems;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-center">
        <li className={`p-l-5 p-r-5 ${currentPage === 1 ? 'disabled' : ''}`}>
          <button className='page-button' disabled={currentPage === 1} onClick={() => handleClick(currentPage - 1)}>
            {"<"}
          </button>
        </li>
        {renderPaginationItems()}
        <li className={`p-l-5 p-r-5 ${currentPage === totalPages ? 'disabled' : ''}`}>
          <button className="page-button" onClick={() => handleClick(currentPage + 1)} disabled={currentPage === totalPages}>
            {">"}
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
