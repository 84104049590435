import React, { useState, useEffect, useContext } from "react";
import AppWrapper from "../../layout/AppWrapper";
import Footer from "../../layout/mainlayouts/footer";
import MainWallet from "../../components/common/MainWallet";
import ThreeDCollect from "../../components/common/ThreeDCollect";
import ThreeDIconLayout from "../../components/common/ThreeDIconLayout";
import Advertisement from "../../components/common/Advertisement";
import InformationCard from "../../components/common/InformationCard";
import { useNavigate } from "react-router-dom";
import { useWallet, useRoundList } from "../../api/funs/twodhome";
import {
  useCollectAllList,
  useThreeDLastRound,
} from "../../api/funs/threedbetlist";
import Collect from "../../components/common/Collect";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import Modal from "../../components/common/Modal";
import ModalTerm from "../../components/common/ModalTerm";
import Button from "../../components/common/Button";
import { useBanner } from "../../api/funs/home";
import { convertTimeToAMPM } from "../../services/sharedFunction";
import {
  threeDBetListAtom,
  OldThreeDBetListAtom,
  NextThreeDBetListAtom,
} from "../../services/sharedAtoms";
import {
  useTwoDBetRoundList,
  useTerm,
  patchTermAndConditionCollector,
} from "../../api/funs/twodbetlist";
import { useAtom } from "jotai";
import dateFormat from "dateformat";
import { setAccessToken } from "../../services/storage";
import { setLanguage, setTerm } from "../../services/storage";
import { translations } from "../../services/localization";
import { setLanguageToStorage } from "../../services/storage";
import { appContext } from "../../contexts/appContext";
import decode from "html-entities-decoder";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import dayjs from "dayjs";
import moment from "moment";
import LoadingSpinner from "../../components/common/LoadingSpinner";

interface BetLastRoundInfo {
  winningNumber: string;
  resultDate: string;
  announceTime: string;
}
const ThreeDHome: React.FC = () => {
  const bannersettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };
  const { data: bannerList } = useBanner();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tValue = searchParams.get("t");
  const lang = searchParams.get("lang");
  const termAndCondition = searchParams.get("terms");
  const { changeLanguage } = useContext(appContext)!;
  const { data: wallet } = useWallet();
  const { data: roudList, isLoading } = useRoundList("3d");
  const { data: terms } = useTerm(
    localStorage.getItem("language") || "english"
  );
  const content = translations.home;
  const commom = translations.commom;

  const [betResult, setBetResult] = useState<BetLastRoundInfo>();
  const [isShow, setIsShow] = useState(false);
  const [inforTime, setInforTime] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [betlist, setBetListData] = useAtom(threeDBetListAtom);
  const [OldBetlist, setOldBetListData] = useAtom(OldThreeDBetListAtom);
  const [NextBetlist, setNextBetListData] = useAtom(NextThreeDBetListAtom);
  const { data: collectAll } = useCollectAllList(true);
  const { data: dataLast } = useThreeDLastRound();
  const [isLatestData, setIsLatestData] = useState(false);
  const [threeDAllo, setThreeDAllow] = useState(false);
  const [termAndConditionModalOpen, setTermAndConditionModalOpen] = useState<
    boolean | null
  >(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const termAndCondition = searchParams.get("terms");

    if (searchParams.get("terms") === "false") {
      setTermAndConditionModalOpen(true);
      setIsShow(true);
    }
    // setTermAndConditionModalOpen((termAndCondition as any) || false);

    localStorage.setItem("one-time-modal-3d", JSON.stringify(termAndCondition));
  }, [location.search]);

  const [agree, setAgree] = useState(localStorage.getItem("term"));
  const { patchTermAndConditionFunc } = patchTermAndConditionCollector();
  //const [betResultList, setBetResultList] = useState([]);
  const navigate = useNavigate();
  const onclickFooterButton = () => {
    if (checkThreeDBet()) {
      setBetListData([]);
      setOldBetListData([]);
      setNextBetListData([]);
      navigate("/threeD/betlist/" + roudList?.data?.data[0]?._id);
    }
  };
  // Function to check if a date is today's date
  const isToday = (date: Date): boolean => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };
  useEffect(() => {
    // setInforTime(commom.upDateTime+" "+convertTimeToAMPM(roudList?.data?.data[0]?.announceTime)+dateFormat(roudList?.data?.data[0]?.endDate, " dddd, mmmm d, yyyy"));
    setInforTime(
      dateFormat(roudList?.data?.data?.[0]?.endDate, "mmm d, yyyy") +
        ", " +
        convertTimeToAMPM(roudList?.data?.data?.[0]?.announceTime)
    );
    const currentTime = new Date();
    const targetTime = new Date(
      `${dateFormat(roudList?.data?.data?.[0]?.endDate, "yyyy-mm-dd")}T${
        roudList?.data?.data?.[0]?.endTime
      }`
    );
    //const targetTime = new Date(`${dateFormat('2024-04-17', "yyyy-mm-dd")}T${'19:05'}`);
    const targetTimeMinus15 = new Date(targetTime.getTime() - 15 * 60000); // 15 minutes earlier
    if (
      currentTime >= targetTimeMinus15 &&
      currentTime <= targetTime &&
      isToday(targetTime)
    ) {
      setIsLatestData(false);
    } else {
      setIsLatestData(true);
    }
  }, [roudList]);
  useEffect(() => {
    if (dataLast?.data?.isSuccess) {
      setBetResult(dataLast?.data?.data);
    }
  }, [dataLast]);
  const onClick = () => {};
  useEffect(() => {
    if (tValue) {
      setAccessToken(tValue);
    }
  }, [tValue]);
  useEffect(() => {
    if (lang) {
      if (lang === "en") {
        setLanguageToStorage("english");
        changeHomeRoute("english");
      } else if (lang === "mm") {
        setLanguageToStorage("myanmar");
        changeHomeRoute("myanmar");
      } else if (lang === "zh") {
        setLanguageToStorage("chinese");
        changeHomeRoute("chinese");
      }
    }
  }, [lang]);
  // useEffect(() => {
  //     if(wallet?.data?.data?.userProfile){
  //         if(!wallet.data.data.userProfile?.isAgreeTerms){
  //             setTermAndConditionModalOpen(true);
  //         }
  //     }
  // }, [wallet]);
  useEffect(() => {
    if (termAndCondition === "true" ? true : false) {
      setTermAndConditionModalOpen(true);
      setIsShow(true);
    }
  }, [agree]);

  const checkThreeDBet = () => {
    const currentTime = new Date();
    const targetTime = new Date(
      `${dateFormat(roudList?.data?.data?.[0]?.endDate, "yyyy-mm-dd")}T${
        roudList?.data?.data?.[0]?.endTime
      }`
    );
    //const targetTime = new Date(`${dateFormat('2024-04-17', "yyyy-mm-dd")}T${'19:05'}`);

    const inputDate = new Date(
      `${dateFormat(roudList?.data?.data?.[0]?.endDate, "yyyy-mm-dd")}`
    );
    const datesAreEqual =
      currentTime.toDateString() === inputDate.toDateString();
    if (datesAreEqual) {
      const endTime = roudList?.data?.data?.[0]?.endTime;
      // Compare dates and times
      const isInputDateTimeGreaterThanCurrent =
        currentTime.getTime() > targetTime.getTime();
      if (isInputDateTimeGreaterThanCurrent) {
        return false;
      } else {
        return true;
      }
    }

    // Convert dates to timestamps
    const isCurrentDateGreaterThan = inputDate > currentTime;

    if (isCurrentDateGreaterThan) {
      return true;
    } else {
      return false;
    }
  };
  const changeHomeRoute = (language: string) => {
    const parts = location.pathname.split("/");
    if (parts.length === 3) {
      if (parts[1] === "threeD") {
        if (parts[2] === "threedhome") {
          navigate("/threeD/threedhome");
          changeLanguage(language);
        }
      }
    }
  };

  // Function to handle checkbox change
  const onAgree = async () => {
    setTermAndConditionModalOpen(false);
    setIsShow(false);
    if (agree) {
      const result = await patchTermAndConditionFunc(true);
      if (result?.isSuccess) {
        localStorage.setItem("one-time-modal-3d", JSON.stringify("true"));
        setTermAndConditionModalOpen(false);
        setIsShow(false);
        setTerm(false);
      }
    }
  };

  useEffect(() => {
    if (termAndCondition) {
      setTerm(termAndCondition);
    }
  }, [termAndCondition]);

  const closeTermAndConditionModal = () => {
    setTermAndConditionModalOpen(false);
    setIsShow(false);
  };
  // console.log("announceTime => ", dataLast?.data?.data.roundId.announceTime);
  return (
    <AppWrapper
      title={content.headerThreeTitle}
      isback={false}
      isRefresh={true}
      removeScroll
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="container mb-10 three-d-layout p-b-30 min-h-[calc(100vh-140px)]">
          <MainWallet
            isShowProfile={true}
            label={commom.wallet}
            amount={
              wallet?.data?.data?.userWalletAmount?.amount
                ? wallet?.data?.data?.userWalletAmount?.amount
                : 0
            }
            image={wallet?.data?.data?.userProfile?.imageUrl}
          />

          {collectAll?.data?.data?.length ? (
            <Collect
              isTwoD={false}
              isDetail={true}
              roundName={collectAll?.data?.data[0].roundName}
              winnumber={collectAll?.data?.data[0].winningNumber}
              seemoreamount={collectAll?.data?.data.length - 1}
              coindate={collectAll?.data?.data[0].resultDate}
              //coindate='5 Jan 2024 (Round 1)'
              betamount={collectAll?.data?.data[0].totalBetAmount}
              winamount={collectAll?.data?.data[0].totalWinningAmount}
              collectdate="Dec 31, 2023, 11:23:05 AM"
              iscollect={false}
              status="unclaim"
              type={collectAll?.data?.data[0].type}
              isseemore={collectAll?.data?.data.length > 1}
              collectId={collectAll?.data?.data[0].threedResultId}
              isExpiredTime={collectAll?.data?.data[0]?.createdAt}
            />
          ) : (
            <></>
          )}
          <ThreeDIconLayout label=""></ThreeDIconLayout>
          {bannerList?.data?.data?.length === 1 && (
            <Advertisement
              bannerimage={bannerList?.data?.data?.[0]?.bannerImageUrl}
              notiParagraph={bannerList?.data?.data?.[0]?.bannerText}
              notisecondParagraph=""
              isboldnotisecondParagraph={true}
              bannerLink={bannerList?.data?.data?.[0]?.bannerLink}
            ></Advertisement>
          )}
          {bannerList?.data?.data?.length > 1 ? (
            <Slider {...bannersettings}>
              {bannerList?.data?.data?.map((banner: any, i: number) => (
                <Advertisement
                  key={i}
                  bannerimage={banner.bannerImageUrl}
                  notiParagraph={banner.bannerText}
                  notisecondParagraph=""
                  isboldnotisecondParagraph={true}
                  bannerLink={banner.bannerLink}
                ></Advertisement>
              ))}
            </Slider>
          ) : (
            <></>
          )}
          <br />
          <div className="d-flex justify-content-center">
            <div className="container text-center row fs-16 fw-500 lh-20">
              <div className="primary-color">{content.betClose}</div>
              <div className="fs-13 w-100 lh-15 p-t-10">
                {moment(roudList?.data?.data?.[0]?.endDate).format(
                  "MMM D, YYYY"
                )}
                {", "}
                {moment(roudList?.data?.data?.[0]?.endTime, "HH:mm").format(
                  "hh:mm A"
                )}
              </div>
            </div>
          </div>
          <br />
          {isLatestData ? (
            <InformationCard
              title={betResult?.winningNumber ?? commom.commingSoon}
              informationtime={
                commom.upDateTime +
                ": " +
                convertTimeToAMPM(
                  dataLast?.data?.data.roundId.announceTime || ""
                ) +
                dateFormat(betResult?.resultDate, " dddd, mmmm d, yyyy")
              }
            />
          ) : (
            <InformationCard
              title={commom.commingSoon}
              informationtime={inforTime}
            />
          )}
        </div>
      )}

      <Footer
        firstOnClick={onclickFooterButton}
        secondOnClick={onClick}
        is2D={true}
        isFirstButton={true}
        firstbuttonLabel={content.button3D}
        firstto=""
        firstButtonClass={
          checkThreeDBet()
            ? " ok-button w-100 d-h-44 d-flex justify-content-center item-center "
            : " ok-button-disable w-100 d-h-44 d-flex justify-content-center item-center "
        }
        firstButtonLabelClass="ok-button-label fs-16 fw-600 lh-20"
        isSecondButton={false}
        secondButtonClass=""
        secondto=""
        secondbuttonLabel=""
        secondButtonLabelClass=""
      />
      {terms && (
        <ModalTerm
          isShow={isShow}
          isOpen={termAndConditionModalOpen as any}
          onClose={closeTermAndConditionModal}
        >
          <div className="container">
            <div className="text-center row d-flex item-center">
              <div className="col-12 p-l-30 fs-18 fw-700 color-defination-color">
                {content.termAndCondition}
              </div>
            </div>
            <div className="row p-t-10 d-w-300">
              <div className="container modal-scroll-container">
                <div
                  dangerouslySetInnerHTML={{
                    __html: terms?.data?.data?.terms?.terms
                      ? decode(terms?.data?.data?.terms?.terms)
                      : decode(""),
                  }}
                />
              </div>
            </div>
            <div className="row p-t-10">
              <Button
                buttonOnClick={onAgree}
                to=""
                label={commom.buttonAccept}
                buttonClass={`ok-button w-100 d-h-44 d-flex justify-content-center item-center `}
                buttonLabelClass={`ok-button-label fs-16 fw-600 lh-20 `}
                isAction={true}
              />
            </div>
          </div>
        </ModalTerm>
      )}
    </AppWrapper>
  );
};

export default ThreeDHome;
