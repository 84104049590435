import React, { useState, ReactNode, useCallback } from "react";
import "../../style/modal.scss"; // You can style the modal in a separate CSS file
import { useWindowSize } from "../../hooks/useWindowSize";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  isShow?: boolean | undefined;
}

const ModalTerm: React.FC<ModalProps> = ({ isOpen, onClose, children, isShow }) => {
  const { windowWidth, windowHeight } = useWindowSize();
  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  if (isShow) {
    document.body.style.overflow = "hidden"
  } else {
    document.body.style.overflow = "auto"
  }

  return (
    <>
      {isOpen && (
        <div className="popup-modal-overlay">
          <div
            className="popup-modal-term"
            style={{
              width: windowWidth < 400 ? windowWidth - 25 : 400,
              height: windowHeight < 540 ? 410 : "auto",
              overflowY: windowHeight < 540 ? "scroll" : "auto",
              marginTop: windowHeight < 540 ? 60 : 0,
            }}
          >
            {children}
          </div>
        </div>
      )}
    </>
  );
};

export default ModalTerm;
