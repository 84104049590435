import React, { useState } from "react";
import AppWrapper from "../../layout/AppWrapper";
import MainWallet from "../../components/common/MainWallet";
import { useParams, useNavigate } from "react-router-dom";
import GridTable from "../../components/common/GridTable";
import Modal from "../../components/common/Modal";
import { EditIcon, DeleteIcon } from "../../components/common/Icon";
import { useWallet } from "../../api/funs/twodhome";
import { threeDBetListAtom, threeDBetAmount } from "../../services/sharedAtoms";
import { useAtom } from "jotai";
import { postBetNumber } from "../../api/funs/threedbetlist";
import { useAgentSettingList } from "../../api/funs/twodbetlist";
import { translations } from "../../services/localization";
import { formatAmount } from "../../util/cn";
import { BeatLoader } from "react-spinners";
import { getLanguageFromStorage } from "../../services/storage";

const BetConfirm: React.FC = () => {
  const navigate = useNavigate();
  const { round_id } = useParams<{ round_id: string }>();

  const betst =
    localStorage.getItem("bet") &&
    JSON.parse(localStorage.getItem("bet") || "[{}]");

  const betListSt = betst?.sort(
    (a: any, b: any) => +a?.betNumber - b?.betNumber
  );
  const { data: agentSetting } = useAgentSettingList(round_id || "");
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [isDisable, setIsDisable] = useState(false);

  const [oldbetnumber, setOldBetNumber] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [oldamount, setOldAmount] = useState<string>("");
  const [betnumber, setBetNumber] = useState<string>("");
  const [index, setIndex] = useState<number>(0);
  const [isShow, setIsShow] = useState(false);
  const [deletebetnumber, setDeleteBetNumber] = useState<string>("");
  const [amount, setAmount] = useState<string>("");
  const [maxAmount, setMaxAmount] = useState<number>(0);
  const [minAmount, setMinAmount] = useState<number>(0);

  const { data: wallet } = useWallet();
  // use shared Atoms
  const [isLoading, setIsLoading] = useState(false);

  const [betlist, setBetListData] = useAtom(threeDBetListAtom);
  const [betAmount, setBetAmount] = useAtom(threeDBetAmount);
  // end shared Atoms //
  const language = getLanguageFromStorage();
  const content = translations.betConfirm;
  const tableHeader = translations.tableHeader;
  const commom = translations.commom;
  const betList = translations.betList;
  const { postBet } = postBetNumber(language as string);
  const [totalBetAmount, setTotalBetAmount] = useState<number>(
    betListSt?.reduce((total: any, bet: any) => {
      return total + bet.amount;
    }, 0)
  );

  const updateAmountById = (i: number, number: string, newAmount: number) => {
    let totalbet = 0;
    const datalist = betListSt;
    datalist[i].betNumber = number;
    datalist[i].amount = newAmount;
    localStorage.setItem("bet", JSON.stringify(datalist));
    setBetListData(datalist);
    setTotalBetAmount(
      betListSt?.reduce((total: any, bet: any) => {
        return total + bet.amount;
      }, 0)
    );
  };

  const openEditModal = (
    i: number,
    betnumber: string,
    amount: string,
    maxAmount: number,
    minAmount: number
  ) => {
    setIndex(i);
    setOldBetNumber(betnumber);
    setOldAmount(amount);
    setBetNumber(betnumber);
    setAmount(amount);
    setMaxAmount(maxAmount);
    setMinAmount(minAmount);
    setIsEditModalOpen(true);
    setIsShow(true);
  };

  const OnEditModal = () => {
    if (
      !(
        (betnumber.length === 2 &&
          betnumber === oldbetnumber &&
          parseInt(amount) > maxAmount) ||
        parseInt(amount) < minAmount ||
        !amount
      )
    ) {
      updateAmountById(index, betnumber, parseInt(amount));
      setIsEditModalOpen(false);
      setIsShow(false);
    }
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setIsShow(false);
  };

  const openConfirmModal = () => {
    setIsConfirmModalOpen(true);
    setIsShow(true);
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
    setIsShow(false);
  };

  const closeMessageModal = () => {
    setMessage("");
    setMessageModalOpen(false);
    setIsShow(false);
  };

  const openDeleteModal = (betnumber: string) => {
    setDeleteBetNumber(betnumber);
    setIsDeleteModalOpen(true);
    setIsShow(true);
  };

  const closeDeleteModal = () => {
    setBetListData((prevBetList) => {
      // Filter out the bet with the given betNumber
      const updatedBetlist = betst.filter(
        (bet:any) => bet.betNumber !== deletebetnumber
      );

      localStorage.setItem("bet", JSON.stringify(updatedBetlist));

      return updatedBetlist;
    });

    // Calculate the new total bet amount
    const deletedBet = betListSt?.find(
      (bet: any) => bet.betNumber === deletebetnumber
    );
    if (deletedBet) {
      const deletedAmount = deletedBet.amount;
      setTotalBetAmount(
        (prevTotalBetAmount) => prevTotalBetAmount - deletedAmount
      );
    }
    if (betlist.length > 1) {
    } else {
      setDeleteBetNumber(betnumber);
      setBetListData([]);
      localStorage.removeItem("bet");
      navigate("/threeD/betlist/" + round_id);
      // setMessage(betList.pleaseAtLeastOne);
      // setMessageModalOpen(true)
    }
    // Close the delete modal
    setIsDeleteModalOpen(false);
    setIsShow(false);
  };
  const handlePostBet = async () => {
    setIsLoading(true);

    try {
      let betarray: { number: string; amount: number }[] = [];
      await Promise.all(
        betListSt?.map(async (bet: any) => {
          const newItem = {
            number: bet.betNumber,
            amount: bet.amount,
          };
          betarray.push(newItem);
        })
      );
      const betData = {
        roundId: round_id,
        betNumbers: betarray,
      };
      const result = await postBet(betData);
      if (result.isSuccess) {
        setIsSuccessModalOpen(true);
        setIsShow(true);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        setMessage(result.message);
        setMessageModalOpen(true);
        setIsShow(true);
      }
      // Handle successful post
    } catch (error) {
      setIsLoading(false);

      // Handle error
    }
  };
  const openSuccessModal = () => {
    handlePostBet();
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    setIsShow(false);
    setBetListData([]);
    localStorage.removeItem("bet");
    setBetAmount(0);
    navigate("/threeD/threedhome");
  };
  const headerdata = [
    {
      name: tableHeader.no,
    },
    {
      name: tableHeader.betNumber,
    },
    {
      name: tableHeader.profit,
    },
    {
      name: tableHeader.amount,
    },
    {
      name: tableHeader.editAndDelete,
      class: "w-[120px] sm:pr-2 sm:w-[100px]",
    },
  ];

  const validBetAmount = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    setAmount(event.target.value);
  };

  const betDisable = () => {
    if (wallet?.data?.data?.userWalletAmount?.amount) {
      if (wallet?.data?.data?.userWalletAmount?.amount > totalBetAmount) {
        return "btn-ok";
      } else {
        return "btn-ok-disable";
      }
    } else {
      return "btn-ok-disable";
    }
  };
  return (
    <AppWrapper
      title={content.headerTitle}
      isback={true}
      isRefresh={true}
      removeScroll
    >
      <div className="p-3">
        <div className="pb-12">
          <GridTable headerdata={headerdata}>
            {betListSt?.map((bet: any, i: number) => (
              <tr
                key={i}
                className="table-row bottom-dashed d-h-50 fw-500 fs-16 lh-20"
              >
                <td className="text-sm font-normal">{i + 1}</td>
                <td className="text-sm font-normal">{bet.betNumber}</td>
                <td className="text-sm font-normal">
                  {agentSetting?.data?.data?.odds}x
                </td>
                <td className="text-sm font-normal">
                  {formatAmount(bet.amount)}
                </td>
                <td className="text-sm font-normal">
                  <div className="flex items-center justify-center">
                    <EditIcon
                      onClick={() =>
                        openEditModal(
                          i,
                          bet.betNumber,
                          bet.amount,
                          bet.maxAmount,
                          bet.minAmount
                        )
                      }
                      className="p-r-3 icon-button w-[20px] h-[20px] xs:w-[25px] xs:h-[25px] sm:w-[26px] sm:h-[26px]"
                    ></EditIcon>
                    <DeleteIcon
                      className="icon-button w-[17px] h-[17px] xs:w-[22px] xs:h-[22px] sm:w-[23px] sm:h-[23px]"
                      onClick={() => openDeleteModal(bet.betNumber)}
                    ></DeleteIcon>
                  </div>
                </td>
              </tr>
            ))}
            <tr className="d-h-50">
              <td
                className="fw-700 fs-14 lh-17 text-nowrap black-color p-l-10"
                colSpan={2}
              >
                {tableHeader?.totalBetAmount}
              </td>
              {/* <td></td> */}
              <td></td>
              <td
                className="pr-2 fw-700 fs-14 lh-17 red-color text-nowrap text-align-center"
                scope="col"
              >
                {formatAmount(totalBetAmount)?.toLocaleString()} {commom.unit}
              </td>
            </tr>
          </GridTable>
          {/* <div className="bg-white ">
            <div className="flex items-center justify-between p-2 ">
              <td className="text-sm font-normal black-color" colSpan={3}>
                {tableHeader.totalBetAmount}
              </td>
              <td className="text-sm font-normal red-color" colSpan={2}>
                {totalBetAmount?.toLocaleString()} {commom.unit}
              </td>
            </div>
          </div> */}
          <div className="p-t-15">
            <MainWallet
              isShowProfile={false}
              label={commom.wallet}
              amount={
                wallet?.data?.data?.userWalletAmount?.amount
                  ? wallet?.data?.data?.userWalletAmount?.amount
                  : 0
              }
              image={wallet?.data?.data?.userProfile?.imageUrl}
            ></MainWallet>
          </div>
          <div className="p-t-15 p-b-15">
            <button
              className={`${betDisable()} w-100 d-h-44 d-flex justify-content-center item-center ${
                isLoading ? "opacity-30" : ""
              }`}
              onClick={openSuccessModal}
              disabled={isLoading}
            >
              {isLoading && <BeatLoader color="#fff" size={10} />}

              <span className="ok-button-label fs-16 fw-600 lh-20">
                {isLoading ? commom.processing : commom.buttonBet}
              </span>
            </button>
          </div>
        </div>
      </div>
      <Modal isShow={isShow} isOpen={isEditModalOpen} onClose={closeEditModal}>
        <div>
          <div className="mb-3 text-base text-center text-red-600">
            {content.editTitle}
          </div>
          {parseInt(amount) > maxAmount ? (
            <div className="row d-h-50">
              <div className="col primary-color">
                {content.betMaxMessage} {maxAmount}.
              </div>
            </div>
          ) : (
            <></>
          )}{" "}
          {parseInt(amount) < minAmount ? (
            <div className="row d-h-50">
              <div className="col primary-color">
                {content.betMinMessage} {minAmount}.
              </div>
            </div>
          ) : (
            <></>
          )}
          <div className="row d-h-50">
            <div className="col">
              <input
                type="number"
                className="common-text-box d-h-42 w-100 no-arrow"
                value={betnumber}
                disabled
              />
            </div>
          </div>
          <div className="relative">
            <input
              type="number"
              className="w-full d-h-42 pl-3 pr-[60px] rounded-lg no-arrow !border !border-gray-350 focus:!border-red-50"
              value={amount}
              onChange={validBetAmount}
            />
            <span className="absolute top-0 bottom-0 flex items-center text-yellow-100 right-3">
              {commom.unit}
            </span>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeEditModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="col-6 d-h-42 ">
              <button
                className={`uppercase ${
                  (betnumber.length === 2 &&
                    betnumber === oldbetnumber &&
                    parseInt(amount) > maxAmount) ||
                  parseInt(amount) < minAmount ||
                  !amount
                    ? "btn-ok-disable"
                    : "btn-ok"
                } `}
                onClick={OnEditModal}
              >
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.confirmTitle} </div>
          </div>
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col">{content.deleteMessage}</div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeDeleteModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="uppercase col-6 d-h-42">
              <button className="btn-ok" onClick={closeDeleteModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isConfirmModalOpen}
        onClose={closeDeleteModal}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.confirmTitle} </div>
          </div>
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col">{content.deleteMessage}</div>
          </div>
          <div className="row d-h-50 d-flex item-center ">
            <div className="text-sm uppercase col-6 d-h-42">
              <button className="btn-cancel" onClick={closeDeleteModal}>
                {commom.buttonCancel}
              </button>
            </div>
            <div className="uppercase col-6 d-h-42">
              <button className="btn-ok" onClick={closeDeleteModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={isSuccessModalOpen}
        onClose={closeSuccessModal}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{content.successTitle} </div>
          </div>
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col">{content.goodLuck}...</div>
          </div>
          <div className="row d-h-50 d-flex item-center">
            <div className=" d-h-42">
              <button
                className="uppercase btn-ok w-364"
                onClick={closeSuccessModal}
              >
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isShow={isShow}
        isOpen={messageModalOpen}
        onClose={closeMessageModal}
        popupModalStyle={{
          width: 300,
          minWidth: 300,
        }}
      >
        <div className="container modal-container">
          <div className="text-center row d-h-50 d-flex item-center">
            <div className="col primary-color">{commom.message}</div>
          </div>
          <div className="text-center row d-h-70 d-flex item-center">
            <div className="col primary-color">{message}</div>
          </div>
          <div className="text-sm uppercase row d-h-50 d-flex item-center">
            <div className="col-6 d-h-42 ">
              <button
                className="text-sm uppercase btn-cancel"
                onClick={closeMessageModal}
              >
                {commom.buttonCancel}
              </button>
            </div>
            <div className="uppercase col-6 d-h-42">
              <button className="uppercase btn-ok" onClick={closeMessageModal}>
                {commom.buttonOk}
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </AppWrapper>
  );
};

export default BetConfirm;
