export default {
  home: {
    headerTitle: "欢迎使用2D",
    headerThreeTitle: "欢迎使用3D",
    betClose: "下注即将关闭",
    betOpen: "Bet Will Open",
    notiParagraph: "2D ကို အဆ 80 ",
    notiParagraphThree: "3D ကို အဆ 720 နဲ့",
    notisecondParagraph: "နဲ့ တစ်နေ့ ၄ ကြိမ် ထိုးပြီး ကံစမ်း လိုက်စို့နော်",
    notisecondParagraphThree:
      "နဲ့ တစ်နေ့ ၄ ကြိမ် ထိုးပြီး ကံစမ်း လိုက်စို့နော်",
    button2D: "下注2D号码",
    button3D: "下注3D号码",
    roundSelect: "选择场次",
    roundSelectTitle: "请选择要下注的场次",
    termAndCondition: "规则与协议",
  },
  profile: {
    headerTitle: "我的主页",
    personalTitle: "个人信息",
    name: "用户名",
    phoneno: "电话号码",
  },
  winner: {
    headerTitle: "2D中奖者",
    headerTitleThree: "3D中奖者",
    tootWinnerTitle: "တွပ်ပတ်လည် Winner",
    dade: "ဒဲ့",
    resultDate: "结果日期",
    first: "第一",
    second: "第二",
    third: "第三",
    bet: "下注",
    win: "赢",
  },
  betHistory: {
    headerTitle: "下注历史",
  },
  betHistoryDetail: {
    headerTitle: "下注历史详情",
    betDate: "下注日期",
  },
  betHoliday: {
    headerTitle: "关彩日",
    title: "下一个关彩日",
  },
  betResult: {
    headerTitle: "结果列表",
    popTitle: "选择月份",
    January: "一月",
    February: "二月",
    March: "三月",
    April: "四月",
    May: "五月",
    June: "六月",
    July: "七月",
    August: "八月",
    September: "九月",
    October: "十月",
    November: "十一月",
    December: "十二月",
  },
  collectList: {
    headerTitle: "领取",
    toCollect: "去领取",
    collected: "已领取",
    emptyTitle: "列表暂无数据",
    noCollectMessage: "暂无可领取的中奖金额",
    noCollectedMessage: "暂无已领取的中奖金额",
  },
  collect: {
    winNumber: "中奖号码",
    firstNoti: "您的下注金额为 ",
    secondNoti: ", 中奖金额为 ",
    secondNotiTwo: ".",
    thirdNoti: "您可以在。。。领取 ",
    thirdNotiTwo: ".",
    fouthNoti: "您中奖  ",
    firthNoti: ", 您可以领取 ",
    seeMore: "查看更多",
    withdrawMessage: "取款已完成",
  },
  collectDetail: {
    headerTitle: "中奖记录详情",
    firstNoti: "您中奖  ",
    secondNoti: " MMK, 您可以领取 ",
    collect: "领取",
    unit: "MMK.",
    noRecord: "No Record...",
  },
  betList: {
    headerTitle: "欢迎使用2D",
    headerTitleThree: "欢迎使用3D",
    colorDefination: "颜色定义",
    clearAll: "清除所有",
    r: "R",
    k: "ခွေ",
    tenPercent: "10%",
    twentyPercent: "20%",
    thirtyPercent: "30%",
    foutyPercent: "40%",
    firtyPercent: "50%",
    sixtyPercent: "60%",
    seventyPercent: "70%",
    eightyPercent: "80%",
    nintyPercent: "90%",
    hundardPercent: "100%",
    betLabel: "当投注趋势达到 100% 时，彩票号码将被关闭",
    rLabel: "填写R号码的下注金额",
    betWillClose: "下注即将关闭",
    rAmountIsGreater: "您的R金额大于限制金额！",
    AmountIsGreater: "您的R金额大于限制金额！",
    rAmountIsLess: "您的R金额小于限制金额！",
    pleaseAtLeastTwo: "请最少选择二个下注号码",
    pleaseAtLeastOne: "请最少选择一个下注号码",
    betLimit: "下注号码的限制为 ",
    pleaseReduceBetNumber: " 请减少下注金额",
    alert: "信息!",
    sameThree: "相同的三个号码",
  },
  quickSelect: {
    headerTitle: "快选",
    singleDouble: "Single and Double Numbers",
    big: "大",
    smmall: "小",
    odd: "奇数",
    even: "偶数",
    same: "同",
    ee: "偶偶",
    eo: "偶奇",
    oe: "奇偶",
    oo: "奇奇",
    head: "字头",
    tail: "尾号",
    zero: "0",
    one: "1",
    two: "2",
    three: "3",
    four: "4",
    five: "5",
    six: "6",
    seven: "7",
    eight: "8",
    nine: "9",
    title: "ပါဝါ၊ နက္ခတ်၊ ညီကို၊ ဘရိတ်",
    power: "ပါဝါ",
    netkat: "နက္ခတ်",
    nyiko: "ညီကို",
    brake: "ဘရိတ်",
    selectionNumber: "已选号码",
    selectionOne: "00-19",
    selectionTwo: "20-39",
    selectionThree: "40-59",
    selectionFour: "60-79",
    selectionFive: "80-99",
    message: "请选择小或大！",
  },
  kway: {
    headerTitle: "Kway",
    title: "输入至少三个号码",
    warningMessage: " 不允许输入相同号码 ",
    warningMessageTwo: "最少3个号码，最多10个号码",
  },
  betChoose: {
    headerTitle: "选择一个下注金额",
    messagePartOne: " of max bet amount is ",
    messagePartTwo: ". 请减少下注金额",
    messagePartThree: "钱包金额为 ",
    messagePartFour: ". 合计下注金额为 ",
  },
  betConfirm: {
    headerTitle: "确认下注信息",
    editTitle: "修改下注金额",
    betMaxMessage: "最大下注金额为 。。。",
    betMinMessage: "最少下注金额为。。。",
    deleteMessage: "请确认是否删除下注号码？",
    confirmTitle: "确认！",
    successTitle: "成功！",
    goodLuck: "祝您好运",
  },
  twoDLayout: {
    winner: "中奖者",
    betHistory: "下注历史",
    collect: "领取",
    result: "结果",
    holiday: "假期",
  },
  header: {
    languageSelect: "选择语言",
    languageTitle: "请选择您的语言",
    SystemMaintenance: "暂无服务 - 正在维修",
    SystemMaintenanceMessage: "非常抱歉 系统目前正在维修中",
    blockmessage: "您的账号已被封！",
    blockagentmessage: "您的代理已被封！ 请联系代理",
    blocktitle: "账号被封！",
  },
  roundCard: {
    value: "Value",
    set: "Set",
  },
  commom: {
    wallet: "我的钱包",
    commingSoon: "即将开始",
    comming: "即将",
    soon: "开始",
    upDateTime: "更新时间",
    buttonSelect: "选择",
    viewProfile: "查看我的主页",
    winnerNoDataLabel: "暂无中奖者",
    winnerNoDataNextLabel: "下注成为下一个中奖者",
    buttonSearch: "查询",
    unit: "MMK",
    buttonOk: "OK",
    buttonAgree: "同意",
    success: "成功！",
    successfully: "successfully!",
    buttonCancel: "取消",
    buttonQuickSelect: "快选",
    buttonBet: "下注",
    message: "提醒！",
    buttonToot: "တွပ်ပတ်လည်",
    buttonDade: "ဒဲ့",
    buttonAccept: "接受",
    agree: "同意",
    buttonConfirm: "确认！",
    winners: "中奖者",
    noWinners: "暂无中奖者",
    collect: "领取",
    result: "结果",
    betHistory: "下注历史",
    emptyTitle: "列表暂无数据",
    emptyMessage: "暂无数据。 祝您好运",
    minumun: "最小",
    processing: "处理中",
    notice: "信息!",
  },
  tableHeader: {
    no: "序",
    name: "玩家名称",
    bet: "下注(MMK)",
    winAmount: "赢(MMK)",
    betNumber: "下注号码",
    profit: "赔率",
    amount: "金额",
    edit: "修改",
    delete: "删除",
    editAndDelete: "修改/删除",
    won: "赢",
    totalBetAmount: "合计下注金额",
  },
};
