import axios from "axios";
import { API_DOMAIN } from "../../config";
import axiosService from "../../services/axios-service";
import { API_NAME } from "../constant";
const URL = process.env.NODE_ENV === "development" ? API_DOMAIN : API_DOMAIN;
const axiosInstance: any = axiosService.getInstance();

export const getWalletGetApi = async (data: any) => {
  return axiosInstance.get(`${URL}${API_NAME.wallet_get_api}`);
};

export const getRoundListApi = async (type:string) => {
  return axiosInstance.get(`${URL}${API_NAME.round_list_api}`+'?type='+type);
};
