import React, { ReactNode } from "react";
import "../../style/Gridtable.scss";
import { cn } from "../../util/cn";
type Props = {
  headerdata: any[];
  children: ReactNode;
};
const GridTable = (props: Props) => {
  const { headerdata, children } = props;
  return (
    <div className="tbl-container bdr">
      <table className="grid-table p-2">
        <thead className="table-header p-2">
          <tr>
            {headerdata.map((header, i) => (
              <th key={i}
                className={cn(
                  "text-xs md:text-sm lg:text-md font-normal py-3",
                  header.class && header.class
                )}
              >
                {header.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{children}</tbody>
      </table>
    </div>
  );
};

export default GridTable;
