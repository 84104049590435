type Props = {
    label: string,
    to:string
}
const HomeButton = (props: Props) => {
  const { label,to } = props;
  
  return (
    <div className="home-button">
      <a href={to} className="remove-underline-a">
        <p className="home-button-label font-Quicksand">{label}</p>
      </a>
    </div>
  )
}

export default HomeButton