interface ParentComponentProps {
    title:string|undefined;
    informationtime:string|undefined;
}
const InformationCard: React.FC <ParentComponentProps>= ({ title,informationtime }) => {
  
  return (
    <div className='information-card w-100 d-h-107 d-flex container justify-content-center mb-2'>
        <div className='row fs-16 fw-500 text-center item-center d-flex justify-content-center lh-20 w-100'>
            <div className='col-12 fs-2 primary-color d-h-50 p-t-18'>
            {title}
            </div>
            <div className="col-12 ">
            <hr className="full-line" />
            </div>
            <div className="col-12 fs-12 w-100 lh-17">
            {informationtime}
            </div>
        </div>
    </div>
  )
}

export default InformationCard
