import React, { useState, useEffect } from "react";
import AppWrapper from "../../layout/AppWrapper";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import HistoryCardTwoD from "../../components/common/HistoryCardTwoD";
import { useHistoryBetList } from "../../api/funs/twodbetlist";
import { translations } from "../../services/localization";
import { CalendarIcon } from "../../components/common/Icon";
import dateFormat from "dateformat";
import dayjs, { Dayjs } from "dayjs";
import emptyList from "../../assets/images/empty_list_icon.png";
import moment from "moment";
import LoadingSpinner from "../../components/common/LoadingSpinner";

const BetHistory: React.FC = () => {
  const current = new Date();
  const today = new Date(current.getTime());
  today.setDate(today.getDate() - 7);
  useEffect(() => {
    setStartSelectDate(today);
  }, []);
  const [startDate, setStartDate] = useState<any | null>(null);
  const [startSelectDate, setStartSelectDate] = useState(new Date());
  const [endDate, setEndDate] = useState<any | null>(null);
  const [endSelectDate, setEndSelectDate] = useState(new Date());

  const navigate = useNavigate();
  const [betHistoryList, setBetHistoryList] = useState([]);
  const {
    data: datalist,
    getHistoryBetList,
    isLoading,
  } = useHistoryBetList(
    startDate
      ? dateFormat(startDate, "yyyy-mm-dd")
      : moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate
      ? dateFormat(endDate, "yyyy-mm-dd")
      : dateFormat(new Date(), "yyyy-mm-dd")
  );

  const content = translations.betHistory;
  const common = translations.commom;

  const handleFromDateChange = (date: Date) => {
    setStartSelectDate(date);
  };

  const handleToDateChange = (date: Date) => {
    setEndSelectDate(date);
  };

  useEffect(() => {
    if (datalist?.data?.data) {
      setBetHistoryList(datalist.data.data);
    }
  }, [datalist]);

  const handleSearch = async () => {
    try {
      if (endDate || startDate) {
        setStartSelectDate(today);
        setEndSelectDate(new Date());
        setEndDate(null);
        setStartDate(null);
      } else {
        if (endSelectDate && startSelectDate) {
          setEndDate(endSelectDate);
          setStartDate(startSelectDate);
        }
      }
    } catch (error) {
      console.error("Error fetching bet history:", error);
      // Handle error, display error message, etc.
    }
  };

  const onclickFooterButton = (e: any) => {
    e.preventDefault();
    navigate("twoD/betlist");
  };
  return (
    <AppWrapper title={content.headerTitle} isback={true} isRefresh={true}>
      <div className="default-height">
        <div className="container p-b-10">
          <div className="px-2 row d-h-50">
            <div className="p-1 search-col">
              <div className="custom-date-picker">
                <DatePicker
                  selected={startSelectDate}
                  onChange={handleFromDateChange}
                  dateFormat="MM/dd/yy"
                  className="text-sm custom-date-input w-140"
                  popperPlacement="top-start"
                  disabledKeyboardNavigation
                  onFocus={(e) => e.target.blur()}
                  disabled={startDate ? true : false}
                />
                <CalendarIcon className="calendar-icon"></CalendarIcon>
              </div>
            </div>
            <div className="p-1 search-col">
              <div className="custom-date-picker">
                <DatePicker
                  selected={endSelectDate}
                  onChange={handleToDateChange}
                  dateFormat="MM/dd/yy"
                  className="text-sm custom-date-input w-140"
                  disabledKeyboardNavigation
                  onFocus={(e) => e.target.blur()}
                  disabled={endDate ? true : false}
                />
                <CalendarIcon className="calendar-icon"></CalendarIcon>
              </div>
            </div>
            <div
              className="px-1 search-button-col d-flex item-center p-t-5"
              onClick={handleSearch}
            >
              <button className="p-2 text-sm btn-serch w-100 lh-20 ">
                {endDate || startDate ? "Reset" : common.buttonSearch}
              </button>
            </div>
          </div>
        </div>

        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <div className="container py-4 cursor-pointer list-card">
            {datalist?.data?.data?.length !== 0 ? (
              datalist?.data?.data?.map((bethsitory: any, i: number) => (
                <div key={i} className="row p-b-40">
                  <div className="col ">
                    <HistoryCardTwoD
                      title={bethsitory?.round?.roundName}
                      date={bethsitory?.betDate}
                      amount={bethsitory?.total}
                      number={bethsitory?.betNumbers}
                      to={"bethistorydetail/" + bethsitory?._id}
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="container">
                <div className="d-flex justify-content-center p-t-120">
                  <img className=" w-14" src={emptyList} alt="empty-list" />
                </div>
                <div className="d-flex row p-t-5">
                  <div className="col-12 nodata-title d-flex justify-content-center">
                    {common.emptyTitle}
                  </div>
                </div>
                <div className="d-flex row p-t-10 p-b-10">
                  <div className="col-12 nodata-list d-flex justify-content-center">
                    {common.emptyMessage}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </AppWrapper>
  );
};

export default BetHistory;
