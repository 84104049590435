import React from 'react';
import sanitizeHtml from 'sanitize-html';

export const convertTimeToAMPM = (timeString: string) => {
  if (timeString) {
    if (timeString.includes(':')) {
      const [hours, minutes, seconds = 0] = timeString.split(':').map(Number); // Add default 0 for seconds

      const date = new Date();
      date.setHours(hours);
      date.setMinutes(minutes);
      date.setSeconds(seconds); // Set seconds as well

      return date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit',
        second: '2-digit',
        hour12: true,
      });
    } else {
      return '';
    }
  } else {
    return '';
  }
};

export const sanitizeInput =(html: string) => {
    return sanitizeHtml(html, {
      allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']), // Add any additional allowed tags
      allowedAttributes: {
        img: ['src'], // Allow only the src attribute for img tags
        // Add any additional allowed attributes for other tags
      },
    });
}
export const maskPhoneNumber=(phoneNumber:string) => {
  const visibleDigits = 4; // Number of visible digits at the end
  const maskedLength = phoneNumber?.length - visibleDigits;

  // Repeat '*' characters for the masked part
  const maskedPart = '*'.repeat(maskedLength);

  // Concatenate the masked part with the visible part (last four digits)
  const maskedPhoneNumber = maskedPart + phoneNumber.slice(maskedLength);

  return maskedPhoneNumber; 
}
  